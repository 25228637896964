/* eslint-disable no-self-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import searchIcon from '../../../assets/icons/search.svg';
import viewIcon from "../../../assets/view.svg";
import { BsBorderStyle } from "react-icons/bs";
import { FaCheckSquare } from "react-icons/fa";
import { DataGrid, EditModal, Loading} from "../../../common";
import { APIEndpoint } from "../../../utils/axios";
import moment from 'moment';
import { onDownloadPDFFile, updateButtonTxtStatus } from "../../../utils/commonFunctions";
import { PageSizes, FilterBodyTemplate, DOWNLOAD_STATUSCHECK_INTERVAL } from "../../../utils/configs";




const EditFormFields = [
    { label: 'View GRN Details', type: 'viewGRNDetails', attribute: 'items' }
];

export const GRNForPurchaseOrder = (props) => {
    const { t,ready } = useTranslation();
    const navigate = useNavigate();
    const {state:poState}=useLocation();
    const urlParams = useParams();
    const warehouseId = urlParams.warehouseId;
    const purchaseOrderId = urlParams.purchaseOrderId;
    const poId = urlParams.id;
    const [poDetails, setPODetails] = useState({});

    const [state, setState] = useState({showLoadingBtn:false});
    const [editFormFields, setEditFormFields] = useState(EditFormFields);
    const [perPageLimit, setPerPageLimit] = useState(5);
    const [searchKey, setSearchKey] = useState('');
    const [ isReloadTableData, setIsReloadTableData ] = useState(false);

    const [ modalTitle, setModalTitle ] = useState('View Purchase Order Items');
    const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
    const [modelStyle, setModalStyle] = useState({ maxWidth: '80%' });
    const [actionItems, setActionItems] = useState([]);
    const [modalCustomButtons, setModalCustomButtons] = useState([]);
    const [noResultsMessage, setNoResultsMessage] = useState( 'No Data' );

    useEffect( () => {
        setNoResultsMessage( t('pages.procurementSub.purchaseOrdersSub.noGRNFound') )
    }, [t]);

    useEffect( () => {
        loadPODetailsByAPI();
    }, [warehouseId, purchaseOrderId, poId]);

    const loadPODetailsByAPI = async () => {
        const pODet = await APIEndpoint.get('indents/findById/' + poId ).then( async resp2 => {
            setPODetails(resp2.data);
        });
    };
const truncatedVendor=(data)=>{
   const str= data.vendorCode + ' (' + data?.vendorName + ')'
    return str.length>21? str.slice(0,22)+"...":str;
}
    const Columns = [
        { field: 'code', headerName: 'GRN Code', checkboxSelection: false,
            minWidth: 190,
            tooltipField: 'code'
        },
        { field: 'invoiceNo', headerName: 'Invoice No',
            minWidth: 160,
            tooltipField: 'invoiceNo'
        },
        { field: 'invoiceDate', headerName: 'Invoice Date',
            minWidth: 160,
            valueGetter: params => {
                if ( params.data ) {
                    return moment(params.data?.invoiceDate).format('DD-MM-YYYY');
                }
            },
            tooltipField: 'invoiceDate'
        },
        { field: 'warehouseCode', headerName: 'Warehouse',
            minWidth: 290,
            tooltipField: 'warehouseName',
            valueGetter: params => params.data ? params.data.warehouseCode + ' (' + params.data?.warehouseName + ')' : '',
        },
        { field: 'vendorCode', headerName: 'Vendor',
            minWidth: 200,
            tooltipField: 'vendorName',
            valueGetter: params => params.data ? truncatedVendor(params.data) : '',
        },
        { field: 'createdAt', headerName: 'Created At',
            minWidth: 140,
            tooltipField: 'createdAt',
            valueGetter: params => {
                if ( params.data ) {
                    return moment(params.data.createdAt).format('DD-MM-YYYY');
                }
            }
        },
        { field: 'createdBy', headerName: 'Created By',
            minWidth: 200,
            tooltipField: 'createdBy.employeeName',
            valueGetter: params => params.data ? params.data.createdBy?.employeeName : ''
        },
        { field: 'items', headerName: 'Summary',
            minWidth: 150,
            valueGetter: params => params.data ? params.data.items?.length + ' items' : ''
        },
        { field: 'actions',headerName:"Actions", autoResize:true,
        minWidth:260,
         width:275,
         flex:1,
         resizable: false,
         suppressSizeToFit:true, pinned:'right',cellRenderer: ActionComponent }

    ];


    function ActionComponent(props){

        if(!props.data) return null
        return (
          <div className='flexRow justifyContentSpaceBetween' style={{gap:"35px",padding:"10px"}}>
            <><img src={viewIcon} className="action-Component-items margTop5" alt='view' title='GRN Details' onClick={()=>onActionInner("viewGRNDetails",props.data)}/>
            <span
                      title={"Reverse GRN"}
                      onClick={() => onActionInner('reverseGRN', props.data)}
                      className="grid-LOI-stylebutton-po"
                    >
                      <FaCheckSquare size={10} style={{ marginRight: '4px' }} />Reverse GRN
                    </span>
           </>
          </div>
        )
      }


    const disableButtonStatus = (btnName, newBtnLabel, isDisable=true) => {
        const updatedData = modalCustomButtons.filter( btn => {
            if (btn.name === btnName ) {
                btn.label = newBtnLabel;
                btn.disabled = isDisable
            }
            return btn;
        });

        // setModalCustomButtons( updatedData );
    };
    let interval;
    const checkDownloadStatus = async (jobId, e) => {
        await APIEndpoint.get('downloads/status/' + jobId).then( async resp => {

            if (resp.status === 'completed') {
                console.log('Download status check: Completed', JSON.stringify(resp));

                await onDownloadPDFFile('downloads/analytics/' + resp.fileName + resp.fileExtension, resp.fileName );
                disableButtonStatus('grnDownloadBtn', 'Download', false);
                // setModalFormStatusObj({ text: 'File downloaded successfully.' });
                setState((_)=>({..._,showLoadingBtn:false}))

                updateButtonTxtStatus(true, e, 'Downloaded', false, { defaultButtonText: 'Download' });

                clearInterval(interval);
                onClose();
                return true;
            }
            else if (resp.status === 'scheduled') {
                clearInterval(interval);

                interval = setInterval( () => {
                    console.log("Download status check: After Interval", JSON.stringify(resp));
                    checkDownloadStatus(jobId, e);
                }, DOWNLOAD_STATUSCHECK_INTERVAL);
            }

        }).catch( error => {
            console.log('Log: GRN PO Download error:', error);
            setState((_)=>({..._,showLoadingBtn:false}))

            updateButtonTxtStatus(false, e, 'Download failed', false);
        });
    };

    const initiateDownloadGRN = async (poGRNObj, e) => {
        console.log('Downloading...', poGRNObj.id);

        // disableButtonStatus('grnDownloadBtn', 'Downloading...', true);
        // setModalFormStatusObj({ text: 'Downloading...' });

        try {
            setState((_)=>({..._,showLoadingBtn:true}))
            const inpData ={
                grnId: poGRNObj.id
            };
            let headers = {
                'Accept':'application/pdf',
                'Content-Type': 'application/json;charset=UTF-8'
            }
            await APIEndpoint.post('downloads/grn', inpData, headers).then( async resp => {
                checkDownloadStatus(resp.id, e);
            });
        }
        catch (err) {
            console.log('Log: Error occured while downloading GRN file,', err.message);
            setModalFormStatusObj({ error: 'Error while downloading file', });
        }
    };

    const onActionInner = (action, row) => {
        let updatedEditFields = [];
        if (action === 'reverseGRN') {
            navigate(`/procurement/purchase-orders/reversegrn/list/${row.warehouseId}/${row.id}`,{state:{...row}});
        }
        else if (action === 'viewGRNDetails') {
            /** Here, Resetting default input fields to array then updating */
            setModalStyle( (_prev) => {
                return { ..._prev, maxWidth: '80%'};
            });
            setEditFormFields(EditFormFields);
            updatedEditFields = EditFormFields.filter( field => {
                if (field.type === 'viewGRNDetails') {
                    setModalTitle('GRN - ' + row.code );
                    field.value = row;
                }
                return field;
            });

            setModalCustomButtons([
                /**
                 * Buttons
                 */
                { label: 'Download',
                    name: 'grnDownloadBtn',
                    attribute: 'button',
                    type: 'button',
                    disabled: false,
                    className: 'download-button-g btn',
                    action: async (e) => {
                        console.log('Intializing download...');
                        await initiateDownloadGRN(row, e);

                        return false;
                    }
                }
            ]);
        }
        setEditFormFields( updatedEditFields );

        setState((_prev) => {
            return { ..._prev, selected: { ...row, action: action} };
        });
    };


    const onClose = (e, action, row) => {
        setState((_prev) => {
            return { ..._prev, selected: false };
        });
        setModalFormStatusObj({ text: '' });
    };
    const fetchCreateState = async () => {
        if (!state.selected) return;
      };

    const fetchGRNsForPurchaseOrderRows = async (page=1, perPage=10) => {
        const filterData = JSON.parse(JSON.stringify(FilterBodyTemplate));
        filterData.page = page;
        filterData.limit = perPage;
        filterData.searchParam = searchKey;

        const res = await APIEndpoint.post('warehouses/' + warehouseId + '/purchaseOrders/' + purchaseOrderId + '/grns/process', JSON.stringify(filterData));
        return res;
    };

    const onPerLimitChange = (e) => {
        setPerPageLimit(Number(e.target.value));
    };

    const onSearch = (e) => {
        setSearchKey(e.target.value);
    };

    const onCreateGRNHandler = () => {

        const updatedEditFields = [
            { label: 'Invoice No.', name: 'invoiceNo', attribute: 'invoiceNo', type: 'text', required: true, disabled: false,
                placeholder: t('pages.procurementSub.purchaseOrdersSub.grnInvoceNo'),
                className: 'text-input' },
            { label: 'Invoice Date', name: 'invoiceDate', attribute: 'invoiceDate', type: 'date', required: true, disabled: false,
                placeholder: t('datePickerPlaceholder'),
                className: 'date-input'}
        ];
        setEditFormFields( updatedEditFields );

        setModalCustomButtons([
            /**
             * Buttons
             */
            { label: t('buttons.proceed'),
                name: 'proceed',
                attribute: 'proceed',
                type: 'button',
                disabled: false,
                className: 'procced-button',
                action: 'onSubmit' // async (e) => { console.log('Submitting your request...', e); return false; }
            }
        ]);
        setModalTitle( 'GRN for PO' + ' - ' + poState?.code ?? purchaseOrderId );
        setState( (_prev) => {
          return { ..._prev, selected: { action: 'createGRN' } };
        });
    }

    const onSubmit = async (e, updated) => {
        if(!updated?.invoiceNo){
            setModalFormStatusObj({ error: 'Invoice number is required!' });
            return;
        }
        if(!updated?.invoiceDate){
            setModalFormStatusObj({ error: 'Invoice date is required!' });
            return;
        }
        try {
            setModalFormStatusObj({ text: 'Submitting...' });
            const body = {
                ...updated,
            };
            if (body.invoiceDate) {
                body.invoiceDate = body.invoiceDate;
            }

            navigate('/procurement/purchase-orders/'
                + poDetails.warehouseId
                + '/' + poDetails.purchaseOrderId
                + '/' + poDetails.id
                + '/' + JSON.stringify(body)
                +'/grn/create');

        }
        catch(err) {
            setModalFormStatusObj({ error: 'Error: ' + err.data.msg});
        }
    }

    return (
        <>
            <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
                {
                    <>
                        <div className='flexRow width100 margBot10 margTop8 justifyContentSpaceBetween'>

                            <div className="justifyContentFlexStart">
                                {poDetails?.status && poDetails.status !== 'suspended' ? (
                                    <button className="add-item-button" onClick={ onCreateGRNHandler }>
                                        {t('buttons.createGRN')}
                                    </button>
                                ) : null}


							</div>
                            <div className="flexRow justifyContentCenter content-tabs">
	                                <span className="underline-grn"><img  className="icon-grn" alt='search' src={searchIcon} /></span>
	                                <input className="underline search-grn" onChange={onSearch} type="search" placeholder={t('filters.searchText')} value={searchKey}/>
	                            </div>
                        </div>

                       {ready? <DataGrid
                            className="full-flex"
                            columns={Columns}
                            initialPageLimit={perPageLimit}
                            showDefaultFilters={false}
                            searchKey={searchKey}
                            getRows={ fetchGRNsForPurchaseOrderRows }
                            noResultsMessage={ noResultsMessage }
                            rowSelection="multiple"
                            columnResize={true}
                            resetDataSource={isReloadTableData}
                        />:<Loading/>}
                    </>
                }
                {
                    fetchCreateState() && Boolean(state.selected) ? (
                        <EditModal
                            title={ modalTitle }
                            customModalStyle={ modelStyle }
                            onClose={onClose}
                            onSubmit={onSubmit}
                            columnViewType={state.selected.action === 'viewGRNDetails'?true:false}
                            showLoadingBtn={state.showLoadingBtn}
                            modalFormStatusObj={modalFormStatusObj}
                            fields={ editFormFields.map( (elt) => {
                                    if (state.selected.action === 'viewGRNDetails') {
                                        return {...elt, initialValue: state.selected[elt.attribute] || ''}
                                    }
                                    else {
                                        return {...elt, initialValue: '' }
                                    }
                                })
                            }
                            showFieldsInColumn={true}
                            modalCustomButtons={modalCustomButtons}
                        />
                    )
                    : null
                }
                 {
                  !ready && <div className='overlay_hide sub'></div>
                }
            </div>
        </>
    );
}