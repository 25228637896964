import React, {useState } from 'react';
import { FilterBodyTemplate } from "../../../utils/configs";
import { APIEndpoint } from "../../../utils/axios";
import { DataGrid, EditModal } from "../../../common";
 import Edit2 from "../../../assets/edit2.svg";
import viewIcon from "../../../assets/view.svg";
// import uploadItemIcon from '../../../assets/icons/upload-items.svg';
import addIcon from '../../../assets/icons/addIcon.svg';
import {translationLength} from '../../../utils/commonFunctions';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";




export const UpliftSegmentsPage = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({});
  const navigate = useNavigate();


  const EditFormFields = [
    {label: 'Csv', attribute: 'segmentItems', type: 'csv5', disabled: false, options: [], value: "" },
  ];




  const Columns = [
    { field: 'segment', headerName: 'Segment', suppressSizeToFit:true, resizable:true,flex:1, },
    { field: 'segmentCode', headerName: 'Timing',  suppressSizeToFit:true, resizable:true,flex:1, },
    {
      headerName:'Actions',
      pinned:'right',
       autoResize:true,
        width:280,
        minWidth:230,
        resizable: false,
        suppressSizeToFit:true,
        cellRenderer: actionsCorporateSegment,
        valueGetter: function(params) {
          return params.sectors;
        }
      }
  ]
  function actionsCorporateSegment(props){
    return (
      props.data?
      <>
      <div className='flexRow margTop8'>
       {props.data&&props.data?.segmentItems.length? <><div className='icon-aircraft-container'>
        <img className='aircraft-actions' alt='edit' onClick={(e) => setSector(props.data, 'edit')} src={Edit2}/>
        <span className="tooltip-action">Edit Items</span>
        </div>
        <div className='icon-aircraft-container'>
        <img className='aircraft-actions' alt='view' onClick={(e) => setSector(props.data, 'view')} src={viewIcon}/>
        <span className="tooltip-action">View</span>
        </div></>:<>
        <div className='icon-aircraft-container'>
        <img className='aircraft-actions' alt='add' onClick={(e) => setSector(props.data, 'add')} src={addIcon}/>
        <span className="tooltip-action">Add Items</span>
        </div>
        </>}
      </div>
      </> : <></>);
  }
  const setSector = (data,type) => {

    if(type==='view'){
      setState( (_prev) => {
        return { ..._prev, selected: {...data ,action: type},resetDataSource:false}
      });
    }
    else if(type==='add'){
        navigate(`/estimation/uplift-segments/segmentuplift/items/${data?.segment}`);

    }
    else if(type==='edit'){
      navigate(`/estimation/uplift-segments/segmentuplift/items/${data?.segment}`);

  }
   else{
    setState( (_prev) => {
      return { ..._prev, selected: {...data ,action: type},resetDataSource:false}
    });
   }
  };
  const fetchUpliftSegments = async (iPageNo = 1, iPageLimit = 10, iSearchText = '') => {
    const filterData = JSON.parse(JSON.stringify(FilterBodyTemplate));
    filterData.page = iPageNo;
    filterData.limit = iPageLimit;
    filterData.searchParam = iSearchText;
    const res = await APIEndpoint.post('estimation/upliftsegment/process', filterData);
    if (res.items) {
      res.items = res.items.map((_) => {
    console.log('"respnse in uplift"',_);

        return {
          ..._,
          percentageText: `${_.percentage}%`
        }
      })
    }
    return res;
  }
  const onClose = () => {
    setState((_prev) => {
      return { ..._prev, selected: false }
    });
  }

  return (
    <div className="device-page op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      <DataGrid
        columnResize
        showDefaultFilters
        enableGridSearch={false}
        className='full-flex'
        columns={Columns}
        getRows={fetchUpliftSegments}
        resetDataSource={state.resetDataSource}
      />
           {state && Boolean(state.selected) &&state.selected?.action === "view"&& (
          <EditModal
            key={state.selected?.action}
            title={ `${state.selected?.segment}-Items`}
            onClose={onClose}
            fields={ EditFormFields.map( (elt) => {
                  return {...elt, initialValue: state.selected[elt.attribute]}
                })
            }
          />
        )
     }
      {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }
    </div>
  )
}
