/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import Spreadsheet from "react-spreadsheet"; // , { createEmptyMatrix }
import * as ReactDOM from 'react-dom';

import { APIEndpoint } from "../../../utils/axios";

import {AlertModal } from "../../../common";
import { updateButtonTxtStatus, validateCellAndGetData } from "../../../utils/commonFunctions";
import { DEFAULT_SHEET_ROWS, defaultCellFieldData } from "../../../utils/configs";
import { useTranslation } from "react-i18next";
import { FaSave } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import closeIcon from "../../../images/icons/Icon2.png"

const iIndexes = {
    ITEM_CODE: 0,
    ITEM_NAME: 1,
    ITEM_UNIT: 2,
    ITEM_SENT_QTY: 3,
    ITEM_RECEIVED_QTY: 4,
    ITEM_QTY: 5,
    ITEM_REMARKS: 6
};
const sheetLabels = ['Item Code', 'Item Name', 'Unit', 'Sent', 'Received', 'Quantity', 'Remarks'];

const createEmptySheetRows = (noOfRows = DEFAULT_SHEET_ROWS) => {
    const rows = [];
    for( var i=0; i < noOfRows; i++) {
        rows.push([
            {
                ...defaultCellFieldData,
                id: 'itemCode',
                className: 'cellClass cellClass_' + i + '_' + iIndexes.ITEM_CODE,
                showPopup: false,
                readOnly: true
            },
            {
                ...defaultCellFieldData,
                id: 'itemName',
                className: 'cellClass cellClass_' + i + '_' + iIndexes.ITEM_NAME,
                showPopup: false,
                readOnly: true
            },
            {
                ...defaultCellFieldData,
                id: 'unit',
                className: 'cellClass cellClass_' + i + '_' + iIndexes.ITEM_UNIT,
                width: '400px',
                readOnly: true,
            },
            {
                ...defaultCellFieldData,
                id: 'sent',
                className: 'cellClass cellClass_' + i + '_' + iIndexes.ITEM_SENT_QTY,
                readOnly: true,
                required: false
            },
            {
                ...defaultCellFieldData,
                id: 'receivedQuantity',
                className: 'cellClass cellClass_' + i + '_' + iIndexes.ITEM_RECEIVED_QTY,
                readOnly: true,
                required: false
            },
            {
                ...defaultCellFieldData,
                type: 'number',
                id: 'grnQuantity',
                className: 'cellClass cellClass_' + i + '_' + iIndexes.ITEM_QTY,
                required: true
            },
            {
                ...defaultCellFieldData,
                id: 'remarks',
                className: 'cellClass cellClass_' + i + '_' + iIndexes.ITEM_REMARKS,
                required: true
            }
        ]);
    }
    return rows;
}

/**
 * Automatically creating rows
 */
// const initialData = createEmptyMatrix(10, sheetLabels.length);
/**
 * Manually creating rows
 */
const initialData = createEmptySheetRows( DEFAULT_SHEET_ROWS );

export const WarehouseTransfersGRNCreate = (props) => {
    const urlParams = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [editTransferDetails, setEditTransferDetails] = useState({});
    const [state, setState] = useState({ isInvalidAccess: false, message: '', isEditForm: false });

    const [sheetData, setSheetData] = useState(initialData);
    const [spreadsheetErrors, setSpreadsheetErrors] = useState([]);
    const modelStyle = { maxWidth: '50%', margin: 'auto 10% 100% 10%' };
    const [activeCell, setActiveCell] = useState({});
    const [selectedCellsArr, setSelectedCellsArr] = useState([]);

    useEffect( () => {
        if ( urlParams.hasOwnProperty('transferId') ) {
            setState( { ...state, isEditForm: true } );
            getEditTransferDetailsById(urlParams.transferId);

            if ( urlParams.hasOwnProperty('warehouseId') ) {
                getWarehouseItems(urlParams.warehouseId);
            }
        }
        // else if ( urlParams.hasOwnProperty('sourceWarehouseId') ) {
        //     setState( { ...state, isEditForm: false } );
            // getWarehouseItems(urlParams.sourceWarehouseId);
        // }
    }, [ urlParams ]);

    useEffect( () => {
        if (Object.keys(editTransferDetails).length > 0) {
            setEditTransferItemsToSheet(editTransferDetails);
            // getWarehouseItems(editTransferDetails.source.id);
        }
    }, [editTransferDetails]);

    const getWarehouseItems = (sourceWarehouseId) => {
        APIEndpoint.post('warehouses/items', { warehouseId: sourceWarehouseId })
        .then( resp => {
            if (resp) {
                // const warehouseItems = resp.items;
                // setWarehouseItemsFull(warehouseItems);
            }
        })
        .catch( error => {
            console.log('Invalid page access!, Warehouse transfer details not found for given ID.', error.message);

            setState({ ...state, isInvalidAccess: true,
                message: "Invalid page access!, Warehouse transfer details are not found, On click OK, you will be redirected to Warehouse Transfer page." });
        });
    };

    const getEditTransferDetailsById = (transferId) => {
        APIEndpoint.get('warehouseTransfers/findById/' + transferId )
        .then( resp => {
            if (resp) {
                const warehouseTransferDetails = resp.data;
                setEditTransferDetails(warehouseTransferDetails);
            }
        })
        .catch( error => {
            console.log('Invalid page access!, Warehouse transfer details not found for given ID.', error.message);

            setState({ ...state, isInvalidAccess: true,
                message: "Invalid page access!, Warehouse transfer details are not found, On click OK, you will be redirected to Warehouse Transfer page." });
        });
    };

    const setEditTransferItemsToSheet = (transferDetails) => {
        let jsonSpreadsheetData = JSON.stringify( sheetData );
        let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData);
        const items = transferDetails.items;
        
        items.map( (item, i) => {
            updatedSpreadsheetData[i][iIndexes.ITEM_CODE].value = item.itemCode;
            updatedSpreadsheetData[i][iIndexes.ITEM_NAME].value = item.itemName;
            updatedSpreadsheetData[i][iIndexes.ITEM_UNIT].value = item.unit;
            updatedSpreadsheetData[i][iIndexes.ITEM_SENT_QTY].value = item.quantity;
            updatedSpreadsheetData[i][iIndexes.ITEM_RECEIVED_QTY].value = item.receivedQuantity;
            updatedSpreadsheetData[i][iIndexes.ITEM_QTY].value = '';
            updatedSpreadsheetData[i][iIndexes.ITEM_REMARKS].value = '';

            return item;
        });
        
        ReactDOM.flushSync( () => {
            setSheetData( [ ...updatedSpreadsheetData ]);

        });
        
    };

    const addRowsToSheet = (rowsToAdd = 10) => {
        const newRows = createEmptySheetRows(rowsToAdd);
        setSheetData( [...sheetData, ...newRows ]);
    };

    const removeRow = (e) => {

        let jsonSpreadsheetData = JSON.stringify( sheetData );
        let inSheetData = JSON.parse(jsonSpreadsheetData);

        /**
         * Remove a last row from the sheet
         */ 
        // setSheetData( (data) => {
        //     return data.slice(0, data.length -1)
        // });

        /**
         * Clear the content of the cell content
         */
        // console.log('selectedCellsArr:', selectedCellsArr);
        selectedCellsArr.map( selectedCell => {
            const defaultVal = inSheetData[selectedCell.row][selectedCell.column].defaultValue;
            
            inSheetData[selectedCell.row][selectedCell.column].value = defaultVal || '';

            return selectedCell;
        });

        ReactDOM.flushSync( () => {
            setSheetData([ ...inSheetData ]);
        });

		setSelectedCellsArr([]);

    };

    const showValidationErrors = (e, inSheetData, errorClass='cellDataErrorCls' ) => {
        let haveErrors = false;
        let invalidRowsCount = 0;
        let dataRowsCount = 0;
        const postItems = [];
        
        inSheetData.map( (cellRow, rowIdx) => {
            
            let isRowHaveError = false;
            

            const itemCode = validateCellAndGetData(cellRow[iIndexes.ITEM_CODE]);
            const itemName = validateCellAndGetData(cellRow[iIndexes.ITEM_NAME]);

            if (!itemCode || !itemName ) {
                return cellRow;
            }
            ++dataRowsCount;

            let isThisRowHaveErrors = false;
            for (let colIdx = 0; colIdx < Object.keys(iIndexes).length; colIdx++) {

                // console.log('cellData:', cellRow[colIdx]);
                
                
                if ( cellRow[colIdx].required === true) {

                    const fieldValue = validateCellAndGetData(cellRow[colIdx]);

                    if ( !fieldValue ) {

                        // cellRow[colIdx].className = errorClass;
                        haveErrors = true;
                        isRowHaveError = true;
                        isThisRowHaveErrors = true;
                    }

                }

            }
            if (isThisRowHaveErrors) {
                ++invalidRowsCount;
            }

            if ( !isRowHaveError ) {

                const item = editTransferDetails.items.find( row => row.itemCode === cellRow[iIndexes.ITEM_CODE].value );
                if (item) {
                    postItems.push({
                        ...item,
                        _id: item._id,
                        index: rowIdx,
                        grnQuantity: parseInt(cellRow[iIndexes.ITEM_QTY].value),
                        remarks: cellRow[iIndexes.ITEM_REMARKS].value,
                    });
            
                }
                
                
            }

            return cellRow;
        });

        if (haveErrors) {
            updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' } );
            setSpreadsheetErrors([ { message: 'Please fill all required item details.' } ]);
        }

        // setSheetData( [ ...inSheetData ]);

        return {
            sheetData: inSheetData, 
            validRows: postItems,
            haveErrors,
            invalidRowsCount,
            dataRowsCount
        };

    };

    const saveSheetData = (e) => {
        updateButtonTxtStatus(true, e, 'Saving...', true);
        setSpreadsheetErrors([]);

        let inSheetData = Object.assign([], sheetData);

        const respArrayData = showValidationErrors(e, inSheetData);
        
        if (!spreadsheetErrors.length && respArrayData.validRows.length === 0) {
            // show global alert message
            updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' } );
            setSpreadsheetErrors([ ...spreadsheetErrors, { message: 'We did not find anything to save!' } ]);
            return false;
        }
        
        if ( respArrayData.haveErrors ) {
            updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' } );
            return false;
        }
        
        if ( !respArrayData.validRows.length ) {
            updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' } );
            return false;
        }


        const data = {
            id: urlParams.transferId || '',
            warehouseId: parseInt(urlParams.warehouseId) || '',
            items: respArrayData.validRows || []
        }

        APIEndpoint.post('warehouseTransfer/saveGrn', data)
        .then( apiResp => {
            /**
             * Update success response
             */
            setSheetData(initialData);
            updateButtonTxtStatus(true, e, 'Redirecting...', true, { defaultButtonText: 'Save'} );
            setSpreadsheetErrors([ ...spreadsheetErrors, { message: apiResp.msg || 'Successfully created GRN for Warehouse Transfer.' } ]);
            setTimeout( () => {
                goToModuleHome();
            }, 4000);
        })
        .catch( (error) => {
            const apiData = error.data;
            setSpreadsheetErrors([]);

            ReactDOM.flushSync( () => {
                console.log("Error occured while creating GRN for transfer:", apiData);
                if ( apiData.errors ) {
                    const errorMsg = apiData.errors.errors[0].message;
                    console.log('apiData.errors.errors: ', errorMsg);
                    setSpreadsheetErrors([ { error: errorMsg } ]);
                    updateButtonTxtStatus(false, e, 'Saving failed!.', true, { defaultButtonText: 'Save'} );
                }
                else if (apiData.msg) {
                    setSpreadsheetErrors([ { error: apiData.msg} ]);
                    updateButtonTxtStatus(false, e, 'Saving failed!..', true, { defaultButtonText: 'Save'} );
                }
                else {
                    setSpreadsheetErrors([ { error: 'Something went wrong, please try again later!' } ]);
                    updateButtonTxtStatus(false, e, 'Saving failed!...', true, { defaultButtonText: 'Save'} );
                }
            });

        });

        return false;
    };

    
    const handleActiveCellChange = (activeCell) => {
        setActiveCell({});
        console.log('ActiveCell:', activeCell);
        setActiveCell(activeCell);
    }
    const handleCellBlur = () => {
        console.log('CellBlur.');
        // setActiveCell({});
    }

    const handleCellKeyDown = (keyboardEvent) => {
        console.log('keyDownEvent.', keyboardEvent.target.value );
        // const searchText = keyboardEvent.target.value;

        if (activeCell && Object.keys(activeCell).length > 0) {
            if (sheetData[activeCell.row][activeCell.column].type === 'number' &&  keyboardEvent.target.value ) {
                keyboardEvent.target.value = keyboardEvent.target.value.replace(/[^0-9]$/g, '');
            }
        }
    }

    const handleModeChange = (mode) => {
        console.log('ModeCHanged to:', mode);
        // if (mode === 'edit') {
        //     setEditingCell
        // }
    };
    
    const handleCellSelect = (selectedCell) => {
        console.log('SelectedCell:', selectedCell);
        if (selectedCell.length !== 0) { 
            setSelectedCellsArr([ ...selectedCell ]);
        }
    };

    const onCellCommitHandle = (prevCell, nextCell, coordinates) => {
        console.log('OnCellChangesCommit-prevCell:', prevCell, 'nextCell:', nextCell, 'coordinates:', coordinates)
    }
    const goToModuleHome = () => {
        navigate('/procurement/warehouse-transfers');
    };
    const handleOnContinue = async () => {
        goToModuleHome();
    }

    return (
        <>
            <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
                <div className={ "flexRow alignItemsCenter margBot10 justifyContentSpaceBetween" } >
                    
                    {/* <button className='download dropdownStyle1-TextMedium margRight' onClick={(e) => onDonwloadReportsClick(e)}><span><img className="icon-size" alt='download reports' src={downloadMasterIcon} /><u>{t('filters.downloadReports')}</u></span></button> */}
                </div>
                <div className="sheet-container sm-scrollbar">
                    <div className="header mb-3 flexRow justifyContentSpaceBetween">
                        <div className="leftBlock alignItemsCenter">
                            <button onClick={ (e) => saveSheetData(e) }
                                className="add-item-button mr-2"><FaSave style={{marginRight:'5px',marginBottom:"3px"}}/>{ t('buttons.save') }</button> &nbsp;&nbsp;
                            <button onClick={ (e) => removeRow(e) }
                                className="remove-button ml-2"> <RiDeleteBin6Line style={{ marginRight: "5px" ,marginBottom:"3px" }} />{ t('buttons.deleteRows') }</button>
                        </div>
            
                        {spreadsheetErrors.length >= 1? <div className={`padding4 margTop9-5 alert alert-${spreadsheetErrors[0].message.includes('successfully') ? 'success' : 'danger'}`}>
                        {spreadsheetErrors.length >= 1 ? spreadsheetErrors[0]?.message : null}
                        </div>:null}
                        <button onClick={goToModuleHome}>
                            <img src={closeIcon} alt=""/>
                        </button>
                    </div>

                    <div className="se-titlebar"><h6>Warehouse Transfer - {state.isEditForm === true ? editTransferDetails.code : 'New*' }</h6></div>
                    
                    <Spreadsheet
                        // ref={sheetRef}
                         className='spread-sheet'
                        data={sheetData}
                        columnLabels={sheetLabels}
                        darkMode={true}

                        // getBindingsForCell={[]}
                        onChange={setSheetData}
                        onActivate={handleActiveCellChange}
                        onBlur={handleCellBlur}
                        onKeyDown={handleCellKeyDown}
                        onModeChange={handleModeChange}
                        onSelect={handleCellSelect}
                        onCellCommit={onCellCommitHandle}
                        // Cell={LOICELLComponent}
                        // DataViewer={LOIViewComponent}

                    />

                   <div className="flexRow alignItemsCenter margTop8">
                        <button onClick={ () => addRowsToSheet() }
                            className="add-item-button" title={ t('spreadsheet.addmoreRows') }>{ t('buttons.addRows') }</button>
                    </div>

                </div>
                
                {
                    Boolean(state.isInvalidAccess) && (
                    <AlertModal
                        title={'Alert: Invalid Access'}
                        customModalStyle={ modelStyle }
                        description={ state.message }
                        onContinue={handleOnContinue}
                        onClose={handleOnContinue}
                    />
                    )
                }

            </div>
        </>
    );
}