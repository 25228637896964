import React, { useState ,useEffect} from "react";
import { useTranslation } from 'react-i18next';
import searchIcon from '../../../assets/icons/search.svg';
import { DataGrid,EditModal } from "../../../common";
import { APIEndpoint } from "../../../utils/axios";
import viewIcon from "../../../assets/view.svg";
import { useNavigate } from "react-router-dom";
import usePermissions from '../../../utils/userPermissions';


import moment from "moment";
import { translationLength } from "../../../utils/commonFunctions";

const Columns = [
    {field: 'code', headerName: 'Code',resizable:true,flex:1},
    {field: '', headerName: 'Type',valueGetter: (params) => {
      return params.data?'Warehouse/InFlight':'';
    },flex:1},
    {field: 'createdAt',valueGetter: (params) => {
        return params.data?.createdAt?moment(params.data?.createdAt).format('DD-MM-YYYY HH:mm:ss'):'';
      }, headerName: 'Created At',flex:1},
    {field: 'createdBy',valueGetter: (params) => {
        return params.data?.createdBy.employeeName;
      }, headerName: 'Created By',lex:1},
    {field: 'unit',headerName: 'Summary',valueGetter: (params) => {
        return params.data?.items.length+" items";
      },lex:1},
];
const EditFormFields = [
    {label: 'Csv', attribute: 'items', type: 'csv2', disabled: false, options: [], value: "" },
  ];
  const AddFormFields = [
    {label: 'Warehouse', attribute: 'warehouses', type: 'selectSearch2', disabled: false, options: [], required: true },
  ];
export const PilferageReporting = (props) => {
  const { t } = useTranslation();
    const[state,setState]=useState();
    const navigate = useNavigate();
    const [pageLimit, setPageLimit] = useState(10);
    const [searchKey, setSearchKey] = useState('');
    const [wareHouseArr,setWareHouseArr]=useState([]);
    const [pliferageReportArr,setPilferageReportArr]=useState([]);
  const [isReloadTableData, setIsReloadTableData ] = useState(false);
  const [actionItems, setActionItems] = useState([]);
  const [buttonStatus,setButtonStatus]=useState(true);

  const { hasPermission: createPilferage } = usePermissions('create', "pilferage");
  const { hasPermission: viewPilferage } = usePermissions('viewDetails', "pilferage");


  let id=null;
    const preCompile = {
      warehouse: t('selectButtons.selWarehouse'),
      save:t('buttons.save')
    }


    React.useEffect(() => {
        fetchPilferages();
        getWarehouses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  useEffect(() => {
    setActionItems([
            {
                title: '',
                attribute: 'Items',
            },

        ]);
        setIsReloadTableData(true);
}, []);

const getActionCellIcons = (cellDef, data) => {

  if (cellDef.attribute === 'Items') {
      return [
          {src: viewIcon, value:"Items", title: 'View Items'},
      ];
  }


  return [];
};



const getWarehouseId=(w)=>{
  if(wareHouseArr.length){
    let warehouseObj=wareHouseArr.find((i)=>{return w===i.value})
if(Object.keys(warehouseObj).length){
return warehouseObj?.id;
}
    }
}

  const getWarehouses = async () => {
    try {
      const resp = await APIEndpoint.get('warehouses');
      if (resp.result.length > 0) {
        setWareHouseArr(resp.result);
        const options = [{key: 0, label: preCompile.warehouse, value: ''}];
        resp.result.filter( (fieldValue, index) => {
          options.push({key: index+1, label: `${fieldValue.value}`, value: `${fieldValue.value}`});
          return fieldValue;
        });
        AddFormFields.map( row => {
          if (row.attribute === "warehouses" ) {
            row.options = options;
          }
          return row;
        });
      }
    } catch (error) {

    }

}
    const filterData = {
        action: 4,
        collection: '',
        filterOperation: 'or',
        filters: [],
        limit: 10,
        page: 1,
        searchParam: ''
    };
    const fetchPilferages = async (page=1, perPage=10) => {
      try {
        filterData.page = page;
            filterData.limit = perPage;
            filterData.searchParam = searchKey;
            const res = await APIEndpoint.post('warehouses/pilferage/process', JSON.stringify(filterData));
            return res;
      } catch (error) {

      }


    };

    const onPerLimitChange = (e) => {
        setPageLimit(Number(e.target.value));
    };

    const onSearch = (e) => {
        setSearchKey(e.target.value);
    };

    const onAction = (e, action, selectedRowData) => {
        setState( (_prev) => {
          return { ..._prev, selected: {...selectedRowData, code:selectedRowData.code, action: action.value }}
        });
      };
      const onAddItemHandler = () => {
        getWarehouses();
        setState( (_prev) => {
          return { ..._prev, selected: { action: 'AddPilferage' } };
        });
      }

      const onClose = () => {
        setState((_prev) => {
          return { ..._prev, selected: false }
        });
        setButtonStatus(true)
      }

const handleFormSubmit = async (e,updated) => {
if(updated?.warehouses){
 id=getWarehouseId(updated.warehouses);
  if(id){
  const body=  {
      warehouseId: id
  }
  try {
    const resp = await APIEndpoint.post(`warehouses/pilferage/warehouseItems`,body);
    if(resp.success){
      setPilferageReportArr(resp.items);
      if(pliferageReportArr){
        navigate(`/procurement/pilferage-reporting/pilferage/warehouses/${id}`);
      }
}
    }
     catch (resp) {
       console.log(resp.msg)
     }
  }

}
      }

      const onFieldChangeHandler = (e,updated) => {
        if(!updated?.warehouses ){
          setButtonStatus(true)
        }else if(updated?.warehouses){
          setButtonStatus(false)
        }
      };

    return (
      <>
        <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
          {
            <>
            <div className="flexRow width100 margBot10 margTop8 justifyContentSpaceBetween alignItemsCenter">
              <div className="flexRow width100 margBot10 margTop8 justifyContentSpaceBetween">
                <div className="width100Menu justifyContentCenter flexRow">
                  <div className="margLeft margBot10 underline">
                    <span>
                      <img
                        className="icon-size"
                        alt={t("filters.searchText")}
                        src={searchIcon}
                      />
                    </span>
                    <span>
                      <input
                        className="search"
                        onChange={onSearch}
                        type="search"
                        placeholder={t("filters.searchText")}
                        value={props.searchKey}
                      />
                    </span>
                  </div>
                </div>
             {createPilferage? <div className="margLeft flexRow justifyContentFlexEnd alignItemsBaseline">
                  <button className="add-item-button" onClick={onAddItemHandler}>
                    {t("buttons.pilferage")}
                  </button>
                </div>:<></>}
              </div>
              </div>
              <DataGrid
                className="full-flex"
                columns={Columns}
                initialPageLimit={pageLimit}
                showDefaultFilters={false}
                searchKey={searchKey}
                getRows={fetchPilferages}
                getActionCellIcons={getActionCellIcons}
                rowSelection="multiple"
                actions={actionItems}
                resetDataSource={isReloadTableData}
                onAction={onAction}
                columnResize={true}
              />
            </>
          }
          {state && Boolean(state.selected) && state.selected?.action === "Items" && viewPilferage &&(
            <EditModal
              key={state.selected?.action}
              showDone={false}
              title={`Pilferage - ${state.selected?.code}`}
              onClose={onClose}
              fields={EditFormFields.map((elt) => {
                return { ...elt, initialValue: state.selected[elt.attribute] };
              })}
            />
          )}
          {state &&
            Boolean(state.selected) &&
            state.selected?.action === "AddPilferage" && (
              <EditModal
                key={state.selected?.action}
                title={"Create Pilferage"}
                buttonLabel={preCompile.save}
                onSubmit={handleFormSubmit}
                showFieldsInColumn={true}
                onClose={onClose}
                onChange = {onFieldChangeHandler}
                disabled={buttonStatus}
                fields={AddFormFields.map((elt) => {
                  return { ...elt, initialValue: state.selected[elt.attribute] };
                })}
              />
            )}
          {t("pages").length <= translationLength && (
            <div className="overlay_hide sub"></div>
          )}
        </div>
      </>
    );
}

