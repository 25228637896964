import React, { useRef, useState } from 'react';
import { APIEndpoint } from "../../../utils/axios";
import { DataGrid, NGODatePicker } from "../../../common";
import {translationLength} from '../../../utils/commonFunctions';
import { useTranslation } from 'react-i18next';
import Edit2 from "../../../assets/edit2.svg";
import  { useNavigate } from 'react-router-dom'
import moment from "moment";

const Columns = [
  { field: 'flightNumber',headerName: 'Route' },
  { field: 'originName',headerName: 'Origin', minWidth: 200 },
  { field: 'destinationName', headerName: 'Destination', minWidth: 135 },
  { field: 'flightDate',headerName: 'Flight Date', minWidth: 135 },
  { field: 'aircraftNumber',headerName: 'Aircraft Number', resizable:true,minWidth: 155 },
  { field: 'state', headerName: 'Status', minWidth: 125 },
];

export const SectorPage = (props) => {
  const routeListOptions = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [state, setState] = useState({ isListReady: false, filterPrice: '', resetDataSource: false, })
  const [buttonData] = useState(false);
  const [, setProducts] = useState([]);
 
  React.useEffect(() => {
    fetchRouteList();
  }, []);

  const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    fliters: [],
    limit: 10,
    page: 1,
    searchParam: ''
  };

  const fetchSectors = async (iPageNo = 1, iPageLimit = 10, iSearchText = '') => {
    filterData.page = iPageNo;
    filterData.limit = iPageLimit;
    filterData.searchParam = iSearchText;
   
    if (state.filterDate) {
      let flightDate = moment(state.filterDate).startOf('day').toString();
      filterData.filters = [{ flightDate: flightDate }]
    }
    if (state.storeId) {
      filterData.filters = [{ storeId: state.storeId }]
    }
    try {
      const res = await APIEndpoint.post('sessions', filterData);
    setProducts(res)
    return res;
    } catch (error) {
      
    }
    
  }

  const fetchRouteList = async () => {
    const routeList = await APIEndpoint.get('stores');
    if (routeList.result.length) {
      routeListOptions.current = routeList.result.map((_pl) => {
        return { value: _pl.id, label: _pl.flightNumber+' ('+_pl.originName+' - '+_pl.destinationName+') ' }
      })
    }
    setState((_) => {
      return { ..._, isListReady: true }
    })
  };

  const handleSelectRouteList = (e) => {
    setState((_) => {
      return { ..._, storeId: e.target.value, resetDataSource: true, showAddForm: false, editRow: null }
    });
    let timeoutId = setTimeout(() => {
      clearTimeout(timeoutId)
      setState((_) => {
        return { ..._, resetDataSource: false }
      });
    }, 100)
  }

  const handleFilterDateChange = (e) => {
    setState((_) => ({ ..._, filterDate: e.target.value, resetDataSource: true }));
    let timerId = setTimeout(() => {
      clearTimeout(timerId);
      setState((_) => {
        return { ..._, resetDataSource: false };
      })
    }, 500)
  }

  const handleRowAction = async (e, action, row) => {
    setState((_prev) => {
      return { ..._prev, editRow: row }
    })
  }

  const toNavigateRoute = (e) => {
    window.localStorage.setItem("sectorDetails", JSON.stringify(state.editRow));
    navigate("/flights/sector-details");
 
  }
  

  return (
    <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      {
        Boolean(state.isListReady) &&
        <>
          <div className="flexRow justifyContentFlexEnd alignItemsCenter margRight margBot10">
          </div>
          <DataGrid
            columnResize
            resetDataSource={state.resetDataSource}
            showDefaultFilters
            className='full-flex'
            columns={Columns}
            getRows={fetchSectors}
            {...buttonData}
            RightComponent={() => {
              return (
                <div className='flexRow alignItemsCenter'>
                  <select className='select-item' onChange={handleSelectRouteList} value={state.storeId} id="item-type">
                    <option value="">{t('pages.flightsContent.sectors.selectRoute')}</option>
                    {routeListOptions.current.map((item, index) => (
                      <option key={index} value={item.value}>{item.label}</option>
                    ))
                    }
                  </select>
                  <NGODatePicker
                  style={{marginLeft: '30px'}}
                  value={state.filterDate}
                  name='filterDate'
                  onChange={handleFilterDateChange} />
                </div>
              )
            }}
            actions={[
              { detail: [{ src: Edit2, value: "edit" }] }
            ]}
            onAction={handleRowAction}
          />
          {
            Boolean(state.editRow) &&
            toNavigateRoute() 
          }
        </>
      }
       {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }
    </div>
  )
}
