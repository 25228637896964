/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState} from "react";
import { useTranslation } from 'react-i18next';
import searchIcon from '../../../assets/icons/search.svg';
import loadingIcon from '../../../assets/loading.gif';
import downloadMasterIcon from '../../../assets/icons/download-master.svg';
import uploadItemIcon from '../../../assets/icons/upload-items.svg';
import publishIcon from '../../../assets/icons/tick.svg';
import { DataGrid,UploadModal,EditModal,NGODatePicker } from "../../../common";
import { APIEndpoint,DFPAPIEndpoint } from "../../../utils/axios";
import confirmIcon from "../../../assets/icons/confirm.svg";
import cancelIcon from "../../../assets/icons/cancel.svg";
import { MIMETypes, PageSizes } from "../../../utils/configs";
import moment from "moment";
import { formatDateByTimezone, onDownloadXlsFile, translationLength } from "../../../utils/commonFunctions";
import CustomSelect from "../../../common/CustomSelect";
import usePermissions from "../../../utils/userPermissions";
let downloadStatusChkInterval;

export const PhysicalInventory = (props) => {
 const { t } = useTranslation();
   const [warehouses, setWarehouses] = useState([]);
   const [state,setState]=useState({date:null});
   const [wareHouseArr,setWareHouseArr]=useState([]);
   const [pageLimit, setPageLimit] = useState(10);
   const [searchKey, setSearchKey] = useState('');
   const [modalCustomButtons,setModalCustomButtons]=useState([]);
   const [EditFieldsCess,setEditFieldsCess]=useState([]);
   const [modalTitle, setModalTitle ] = useState('Edit Cess');
   const [selWarehouse, setSelWarehouse] = useState('');
   const [isDownloading,setIsDownloading]=useState(false);
   const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
   const [isReloadTableData, setIsReloadTableData ] = useState(false);
   const [selFiltersMessage, setSelFiltersMessage] = useState('');
   const [showUpload,setShowUpload]=useState(false);
   const [uploadFileStatusBlock, setUploadFileStatusBlock ] = useState('');
   const [uploadedFile, setUploadedFile ] = useState([]);
   const [warehouseUpload,setWarehouseUpload]=useState([]);
   const [dateChange,setDateChange]=useState(null);
   const [noResultsMessage, setNoResultsMessage] = useState("Please select warehouse")
   const [updating, setUpdating] = useState(false)

   const { hasPermission: downloadPhyInv } = usePermissions('download', "inventory.physical-inventory");
   const { hasPermission: editPhyInv } = usePermissions('edit', "inventory.physical-inventory");
    const { hasPermission: uploadPhyInv } = usePermissions('upload', "inventory.physical-inventory");

   const Columns = [
    {field: 'code', headerName: 'Code',tooltipField: 'code',minWidth:250,flex:1,suppressSizeToFit:true,autoResize:true,resizable:true,valueGetter: (params) => {
     if (params.data&&params.data.code) {
     //  return params.data.code.length>21? params.data.code.slice(0,22)+"...": params.data.code;
      return params.data.code ;
     }
     return null;
   }, },
    {field: 'warehouseCode', headerName: 'Warehouse',flex:1,minWidth:100,suppressSizeToFit:true,autoResize:true,resizable:true},
    {field: 'handledAt',valueGetter: (params) => {
        return params.data?.targetDate?moment(params.data?.targetDate).format('MM-DD-YYYY'):'';
      }, headerName: 'Effective Date',minWidth:100,flex:1,suppressSizeToFit:true,autoResize:true,resizable:true},
    {field: 'createdBy',valueGetter: (params) => {
        return params.data?.createdBy.employeeName;
      }, headerName: 'Created By',tooltip:'createdBy',flex:1,minWidth:150,suppressSizeToFit:true,autoResize:true,resizable:true},
      {field: 'createdAt',valueGetter: (params) => {
        return params.data?moment(params.data?.createdAt).format('DD-MM-YYYY hh:mm A'):'';
      }, headerName: 'Created At',minWidth:150,flex:1,suppressSizeToFit:true,autoResize:true,resizable:true},
      {field: 'status',valueGetter: (params) => {
        let stat=params.data?.status;
        if(stat){
            return stat[0].toUpperCase()+stat.substring(1);
        }
        else{
            return params.data?.status;
        }
      }, resizable: true,minWidth: 150,flex:1,suppressSizeToFit:true,headerName: 'Status',},
      {
        field:"",
        headerName:"Actions",
        minWidth:200,
        cellRenderer:ActionCellRenderer
      }
 ];

 function ActionCellRenderer(props){

    if(props?.data && editPhyInv){
        if(props.data?.status === "pending"){
            return (
                <div>
                  {downloadPhyInv?  <button onClick={(e)=>onAction(e,{value:"Download",title:"Download"},props.data)} title="Download">
                        {state.selected?.code === props.data.code && isDownloading ? <img class="actionIcon" src={loadingIcon}/>:<img class="actionIcon" src={downloadMasterIcon}/>}
                    </button>:<></>}
                    <button onClick={(e)=>onAction(e,{value:"Approve",title:"Approve"},props.data)} title="Approve"><img class="actionIcon" src={confirmIcon}/></button>
                    <button onClick={(e)=>onAction(e,{value:"Reject",title:"Reject"},props.data)} title="Reject"><img class="actionIcon" src={cancelIcon}/></button>
                </div>
            )
        }else if(props.data?.status === "approved"){
            return (
                <div>
                   {downloadPhyInv? <button onClick={(e)=>onAction(e,{value:"Download",title:"Download"},props.data)} title="Download">
                    {state.selected?.code === props.data.code && isDownloading ? <img class="actionIcon" src={loadingIcon}/>:<img class="actionIcon" src={downloadMasterIcon}/>}
                    </button>:<></>}
                    <button className="grid-download-btnStyle1" onClick={(e)=>onAction(e,{value:"Publish",title:"Publish"},props.data)} title="Publish">
                        Publish
                    </button>
                </div>
            )
        }else if(props.data?.status === "rejected" || props.data?.status === "published"){
            return (
                <div>
                  { downloadPhyInv? <button onClick={(e)=>onAction(e,{value:"Download",title:"Download"},props.data)} title="Download">
                    {state.selected?.code === props.data.code && isDownloading ? <img class="actionIcon" src={loadingIcon}/>:<img class="actionIcon" src={downloadMasterIcon}/>}
                    </button>:<></>}
                </div>
            )
        }else{
            return null
        }
    }else{
        return null
    }
 }

   let stockDetails={};
   const preCompile = {
       warehouse: t('selectButtons.selWarehouse')
   }

   React.useEffect(() => {
       getWarehouses();
   // eslint-disable-next-line no-use-before-define
   }, [t]);

   React.useEffect(() => {
       getPhyInvDetails();
   // eslint-disable-next-line no-use-before-define
   }, []);

   React.useEffect(() => {
        setSelFiltersMessage("Please select a warehouse")
       if(selWarehouse&& selWarehouse.length > 0) {
        setSelFiltersMessage('');
        fetchPhysicalInventoryRows();
    }
   // eslint-disable-next-line no-use-before-define
   }, [state.date, selWarehouse]);



   const getWarehouses = useCallback(
    async () => {
        try {
          const resp = await APIEndpoint.get('warehouses');
        if (resp.result.length > 0) {
            setWareHouseArr(resp.result);
            const options = [{key: 0, label: preCompile.warehouse, value: ''}];
            resp.result.filter( (fieldValue, index) => {
              options.push({key: index+1, label: `${fieldValue.value}`, value: `${fieldValue.value}`});
              return fieldValue;
            });
            setWarehouses(options);
        }
        } catch (error) {

        }

    },
     [preCompile.warehouse],
   )

    const onUploadClose = () => {
       setWarehouseUpload("");
       setUploadedFile([]);
       setUploadFileStatusBlock('');
       setShowUpload(false);
    };

    const getDateBody=()=>{
       let localTime = moment().format('YYYY-MM-DD');
       return localTime + "T18:30:00.000Z";
    }

    const getEffectiveDate=(dateChange)=>{
        if(dateChange!==undefined){
            let effTime = formatDateByTimezone(dateChange)
            return effTime;
        }
    }
    const onDownloadSampleFile = async() => {
        setIsDownloading(false);
        setUploadFileStatusBlock('<p>Downloading sample file...</p>');
        let fileType={
          header: {
           'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        responseType: 'arrayBuffer'}
        const body=  {
           "startDate": getDateBody(),
           "endDate":getDateBody(),
           "warehouseId": getWarehousesDetails(),
           "effectiveDate": getEffectiveDate(dateChange),
        }
        try {
            const res = await APIEndpoint.post(`stockCorrection/sample`, body, fileType);
            if(res.status==='scheduled'){
                setUploadFileStatusBlock('<p>Scheduled to download sample file...</p>');
                checkStatus(res.id);
            }
            else {
                console.log('Log: Stock correction - Unhandled response', res);
            }
        }
        catch (error) {
            console.error('Log: Error occured while donwloading stock correction sample: ' + error.message );
            // setUploadFileStatusBlock('<p>Error downloading sample file, please again after some time</p>');
            setIsDownloading(false);

            let { data } = JSON.parse( error.message ); // status,
            const msgHeading = data?.Error || data?.message || error.message;

            // let msgContent = "";
            // if (status === 503) {
            //     data.errors.forEach( _ => {
            //         const lineNum = _.lineNumber;
            //         const errorMsgArr = [];
            //         _.errors.forEach(erObj => {
            //         errorMsgArr.push( erObj.field + ' | ' + erObj.message + ' \n<br>' );
            //         });
            //         msgContent += '<br>Line ' + lineNum + ': \n<br>' + errorMsgArr.join(' ');
            //     });
            // }
            // else {
            //     msgContent += 'Error occured while upload items sheet!';
            // }

            setUploadFileStatusBlock("<p class='listErrorMessagesCls'>"+ msgHeading + "</p>" +
                                        ""
                                    );
            // <p class='listErrorMessagesCls sm-scrollbar border p-2 pt-1'>" + msgContent +"</p>
        }
   }

   async function checkStatus(jobId) {
    try {
        const res = await APIEndpoint.get(`downloads/status/${jobId}`);
        if (res.status === 'completed') {
            setUploadFileStatusBlock('<p>Downloading sample file...</p>');

            clearInterval(downloadStatusChkInterval)


            onDownloadXlsFile(`downloads/analytics/${res.fileName}${res.fileExtension}`, res.fileName);

            setUploadFileStatusBlock('<p>Sample file downloaded.</p>');
            setIsDownloading(false)
        }
        else
        {
            downloadStatusChkInterval = setInterval(() => {
                checkStatus(jobId)
            }, 5000);
        }
    } catch (error) {
        console.log("Log: Error occured while downloading sample file...", error);
        setIsDownloading(false)
    }
  }

   const getWarehousesDetails=()=>{
       if(selWarehouse.length>0 ){
           const wareHs=  wareHouseArr.find(w=>w.value===selWarehouse)
           return wareHs?.id;
         }
         if(warehouseUpload.length>0 && !warehouseUpload.includes(null)){
           const wareHs=  wareHouseArr.find(w=>w.value===warehouseUpload[0].value)
           return wareHs?.id;
         }
   };

   const onUploadFileChangeHandler = async (e) => {
       const file=e.target.files[0];
       setUploadedFile(e.target.files[0]);
       setUploadFileStatusBlock('<p>Please wait while we process your data...</p>');

       const formData = new FormData();
       formData.append('scheduleLater', false);
       formData.append('listName', undefined);
       formData.append('targetSectorType', undefined);
       formData.append('stores', JSON.stringify([undefined]));
       formData.append('warehouses', JSON.stringify([undefined]));
       formData.append('file', file);

       try {
            await APIEndpoint.post('warehouses/inventory/stock/adjustment?warehouseId=' + getWarehousesDetails()
                + '&effectiveDate=' + encodeURIComponent(getEffectiveDate(dateChange)), formData);

            setUploadedFile([]);
            setUploadFileStatusBlock('<p style="margin:0px">File Imported Successfully.</p>');
            setIsReloadTableData(true);
       }
       catch (error) {
        e.target.value = null;
        setUploadedFile([]);
            console.error('Log: Error occured while uploading stock correction: ' + error.message );

            let { status, data } = JSON.parse( error.message );
            let msgHeading = data?.Error || data?.message || error.message;

            let msgContent = "";
            if (status === 400 && data && data.errors) {
                data.errors.forEach( _ => {
                    const lineNum = _.lineNumber;
                    const errorMsgArr = [];
                    _.errors.forEach(erObj => {
                    errorMsgArr.push( erObj.field + ' | ' + erObj.message + ' \n<br>' );
                    });

                    msgContent += '<br>Line ' + lineNum + ': \n<br>' + errorMsgArr.join(' ');
                });
                if(data?.Error){
                    msgHeading = data?.Error;
                  msgContent += `Error occurred while uploading employee sheet!<br>Please try again.`;

                 }
            }else if(status=== 0 && !data){
                msgContent += `Error occurred while uploading employee sheet!<br>Please try again.`;
            }
            else {
                msgContent += 'Error occured while upload stock correction!';
            }

            setUploadFileStatusBlock("<p class='listErrorMessagesCls'>"+ msgHeading + "</p>" +
                                        "<p class='listErrorMessagesCls sm-scrollbar border p-2 pt-1'>" + msgContent +"</p>"
                                    );
        }
   };

   const filterData = useMemo(() => ({
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 50,
    page: 1,
    searchParam: ''
    }), []);
   const fetchPhysicalInventoryRows = useCallback(
    async (page=1, perPage=50) => {

        if( selWarehouse?.length > 0){
            setNoResultsMessage(undefined)
            filterData.page = page;
            filterData.limit = perPage;
            filterData.searchParam = searchKey;
         if(!state?.date){
         filterData.filters=[{ warehouseId: getPhyInvDetails()}]
         }
         else{
         filterData.filters=[{ targetDate: getEffectiveDate(state?.date)},{ warehouseId: getPhyInvDetails()}]
         }
            const res = await DFPAPIEndpoint.post('warehouses/stockCorrections/listing', JSON.stringify(filterData));
            return res;
        }
        else{
            setNoResultsMessage("Please select warehouse")
            setIsReloadTableData(!isReloadTableData)
            return {
                items:[],count:0
            }
        }
    },
     // eslint-disable-next-line no-use-before-define
     [filterData, searchKey, selWarehouse?.length, state?.date],
   )
   ;

   const onPerLimitChange = (e) => {
       setPageLimit(Number(e.target.value));
   };

   const onSearch = (e) => {
       setSearchKey(e.target.value);
   };

   const onClose = () => {
    setModalFormStatusObj({ text: '' });
    stockDetails={};
    setState((_prev) => {
      return { ..._prev, selected: false,resetDataSource:modalFormStatusObj.text.length>0?true:false }
    });

    }

   const onAction = async(e, action, selectedRowData) => {

    console.log(action,selectedRowData)

    stockDetails={selectedRowData,action};

    let updatedEditFields=[];
       setState( (_prev) => {
         return { ..._prev, selected: {...selectedRowData, code:selectedRowData.code,action: action.value }}
       });
       if(action.title==='Download'){
         let header={
           'Accept':'text/csv'
         }

            try {
                setIsDownloading(true)
               const res = await APIEndpoint.post(`warehouses/${selectedRowData.warehouseId}/stockCorrections/${selectedRowData.id}/report`,{},header);
               if(res.status==='scheduled'){
                 const dowId=await APIEndpoint.get(`downloads/status/${res.id}`);
                 if(dowId.status==="completed"){
                 const physicalInvDownload=await APIEndpoint.get(`downloads/analytics/${dowId.fileName}${dowId.fileExtension}`);
                 const url = window.URL.createObjectURL(new Blob([physicalInvDownload]));
                 const link = document.createElement('a');
                 link.href = url;
                 link.setAttribute('download', 'Inventory_Audit.csv'); //or any other extension
                 document.body.appendChild(link);
                 link.click();
                  setIsDownloading(false)
                 }
               }
               }
                catch (error) {
                  console.log(error)
                 setIsDownloading(false)
                }
       }
       if(action.title==='Approve'){
        setModalTitle('Approve Stock Correction  -' + selectedRowData.code);
        updatedEditFields = [
            {
                label: 'You will not be able to reverse it once you approve it.Are you sure you want to continue?',
                attribute: 'alertMessage', type: 'alertMessage'
            }
        ];
        setModalCustomButtons([{label:'Cancel',attribute:'button',type:"button",disabled:false,className:'cancel-button',action:()=>onClose()},
                              {label:'Approve',attribute:'button',type:"button",disabled:false,className:'submit-button',action:(selectedRowData)=>onEnableDisableAction(selectedRowData)}])

        setEditFieldsCess(updatedEditFields);
       }
       if(action.title==='Reject'){
        setModalTitle('Reject Stock Correction  -' + selectedRowData.code);
        updatedEditFields = [
            {
                label: 'You will not be able to reverse it once you approve it.Are you sure you want to continue?',
                attribute: 'alertMessage', type: 'alertMessage'
            }
        ];
        setModalCustomButtons([{label:'Cancel',attribute:'button',type:"button",disabled:false,className:'cancel-button',action:()=>onClose()},
                              {label:'Reject',attribute:'button',type:"button",disabled:false,className:'submit-button',action:(selectedRowData)=>onEnableDisableAction(selectedRowData)}])

        setEditFieldsCess(updatedEditFields);


    }
    if(action.title==='Publish'){
        setModalTitle('Publish Stock Correction  -' + selectedRowData.code);
        updatedEditFields = [
            {
                label: 'You will not be able to reverse it once you publish it.Are you sure you want to continue?',
                attribute: 'alertMessage', type: 'alertMessage'
            }
        ];
        setModalCustomButtons([{label:'Cancel',attribute:'button',type:"button",disabled:false,className:'cancel-button',action:()=>onClose()},
                              {label:'Publish',attribute:'button',type:"button",disabled:false,className:'submit-button',action:(selectedRowData)=>onEnableDisableAction(selectedRowData)}])

        setEditFieldsCess(updatedEditFields);


    }
     };
     const onUploadDistributorMappingClick=()=>{
       getWarehouses();
       setShowUpload(true)
   }

   const onEnableDisableAction=async()=>{

const {warehouseId,id}=stockDetails?.selectedRowData;
const {value}=stockDetails?.action;

    let body={};
    if(value==='Approve'){
    body={warehouseId,id,action:'approve'};
        try {
          setUpdating(true)
          const res = await APIEndpoint.post(`stockCorrections/updateStatus`,body);
          setUpdating(false)
          if(res.success){
          setModalFormStatusObj({ text: res.message });
          setIsReloadTableData(!isReloadTableData)
          setTimeout(() => {
            onClose()
          }, 2000);

          }
        } catch (error) {
        setUpdating(false)
        setModalFormStatusObj({ text: error.data.message });
        }
    }
    if(value==='Reject'){
        body={warehouseId,id,action:'reject'};
            try {
              setUpdating(true)
              const res = await APIEndpoint.post(`stockCorrections/updateStatus`,body);
              setUpdating(false)
              if(res.success){
              setModalFormStatusObj({ text: res.message });
              setIsReloadTableData(!isReloadTableData)
              setTimeout(() => {
                onClose()
              }, 2000);
              }
            } catch (error) {
            setUpdating(false)
            setModalFormStatusObj({ text: error.data.message });
            }
        }
        if(value==='Publish'){
                try {
                  setUpdating(true)
                  const res = await APIEndpoint.post(`stockCorrections/${id}/publish`);
                  setUpdating(false)
                  if(res.success){
                  setModalFormStatusObj({ text: res.message });
                  setIsReloadTableData(!isReloadTableData)
                  setTimeout(() => {
                    onClose()
                  }, 2000);
                  }
                } catch (error) {
                setUpdating(false)
                setModalFormStatusObj({ text: error.data.message });
                }
        }
   }

   const onItemTypeChange = (e) => {
       setSelWarehouse(e?.value);
   }
   const getPhyInvDetails = useCallback(
    () =>{
        if(selWarehouse?.length>0){
            const wareHs = wareHouseArr.find(w=>w.value===selWarehouse)
            return wareHs?.id;
        }
    },
     [selWarehouse, wareHouseArr],
   )


   const onDateChange=(e)=>{
       setState((_) => {
           return { ..._, date: e.target.value };
         })
         getEffectiveDate(e.target.value);

   }

   return (
       <>
           <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
               {
                   <>
                  { uploadPhyInv? <div className="flexRow justifyContentFlexEnd alignItemsCenter margRight margBot10">
                       <button className='upload dropdownStyle1-TextMedium' onClick={() => onUploadDistributorMappingClick(true)}> <span><img  className="icon-size mt-2" alt='uploadItem' src={uploadItemIcon} /><u>{t('filters.uploadStockCrn')}</u></span></button>
                       </div>:<></>}
                       <div className='flexRow width100 margBot10 margTop8 justifyContentFlexEnd'>
                       <div className='flexRow width100  justifyContentFlexStart'>
                               <button className='saleType-div m-2'>
                                <CustomSelect
                                    options={warehouses}
                                    placeHolder="Select Warehouse"
                                    onChange={onItemTypeChange}
                                />
                                </button>
                                <div className={`dialog__description`}>
                                 <NGODatePicker
                                    value={state.date}
                                    name='filterDate'
                                    placeholder={t('effectiveDate')}
                                    maxDate={new Date()}
                                    placeholdertext={t('datePickerPlaceholder')}
                                    onChange={onDateChange} />
                                    </div>

                                    <div className='flexRow width100 margBot10 margTop8 justifyContentSpaceBetween'>

                          <div className=" margLeft  underline margTop10">
                             <span><img  className="icon-size" alt={t('filters.searchText')} src={searchIcon} /></span>
                             <span><input className="search" onChange={onSearch} type="search" placeholder={t('filters.searchText')} value={props.searchKey}/></span>
                         </div>
                          </div>

</div>


                       </div>


                       <>
                           <DataGrid
                               className="full-flex"
                               columns={Columns}
                               initialPageLimit={pageLimit}
                               showDefaultFilters={false}
                               searchKey={searchKey}
                               selWarehouse={selWarehouse}
                               date={state.date}
                               getRows={fetchPhysicalInventoryRows}
                               rowSelection="multiple"
                               resetDataSource={isReloadTableData}
                               noResultsMessage={noResultsMessage}
                               columnResize={true}
                               onAddItemHandler={ () => {} }
                           />
                       </>


                   </>
               }
               {showUpload &&
               <UploadModal
                   modelTitle={'Upload Stock Correction'}
                   onUploadClose={onUploadClose}
                   onDownloadSampleFile={onDownloadSampleFile}
                   uploadedFile = { uploadedFile }
                   setWarehouseUpload={setWarehouseUpload}
                   warehouseUpload={warehouseUpload}
                   warehouse={warehouses}
                   dateChange={dateChange}
                   isDownloading={isDownloading}
                   setDateChange={setDateChange}
                   onUploadFileChangeHandler = { (e) => onUploadFileChangeHandler(e) }
                   uploadFileStatusBlock = { uploadFileStatusBlock }
                   allowedExtensions={ MIMETypes.excel }
               />}
{
        (state?.selected?.action==='Approve' || state?.selected?.action==='Reject'|| state?.selected?.action==='Publish')&& Boolean(state?.selected)? (
          <EditModal
            key={state.selected.action}
            title={modalTitle }
            onClose={onClose}
            modalFormStatusObj={modalFormStatusObj}
            onSubmit={onEnableDisableAction}
            modalCustomButtons={modalCustomButtons}
            fields={EditFieldsCess.map( (elt) => {
              return { ...elt, initialValue: '' }
              })
            }
            showLoadingBtn={updating}
          />
        )
        : null
      }

               {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }
           </div>
       </>
   );
}