import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { DataGrid, NGODatePicker, EditModal } from "../../../common";
import { APIEndpoint, DFPAPIEndpoint } from "../../../utils/axios";
import {
  updateButtonTxtStatus,
  checkDownloadStatus,
  objectToQueryString,
  debounce,
} from "../../../utils/commonFunctions";
import {
  MIMETypes,
  ORDER_PAYMENT_TYPE_LIST,
  ORDER_SALE_TYPE_LIST,
  ORDER_STATUS_LIST,
  customSelSearch
} from "../../../utils/configs";
import * as ReactDOM from "react-dom";
import Select from 'react-select'
import LoadingIn from '../../../assets/loadingIn.gif';
import downloadMasterIcon from "../../../assets/icons/download-master.svg";
import searchIcon from "../../../assets/icons/search.svg";
import viewIcon from "../../../assets/view.svg";
import usePermissions from '../../../utils/userPermissions';
import {
  SalesReportsDownloadComponent,
  SectorRenderer,
  OrderStatusRenderSel,
  PaymentTypeRenderSel,
  OrdNoRenderer
} from "./renderers";
import CustomSelect from "../../../common/CustomSelect";
// import editIcon2 from "../../../assets/edit2.svg";

let axiosAbortCtrl = [];
axiosAbortCtrl["orderListAPI"] = new AbortController();
// axiosAbortCtrl['sectorListAPI'] = new AbortController();
// axiosAbortCtrl['reportTypesAPI'] = new AbortController();

const newAbortSignal = (apiName, timeoutMs = 5000) => {
  // const abortController = new AbortController();
  axiosAbortCtrl[apiName] = new AbortController();
  // setTimeout(() => axiosAbortCtrl[apiName].abort(), timeoutMs || 0);

  return axiosAbortCtrl[apiName].signal;
};
const abortEarlierRequest = (apiName = "orderListAPI") => {
  axiosAbortCtrl[apiName].abort(
    apiName + ": Cancelling API running earlier..."
  );
};

const DATE_FORMAT = "DD-MM-YYYY"; // hh:mm A

const filterData = {
  action: 4,
  collection: "",
  filterOperation: "or",
  filters: [],
  limit: 10,
  page: 1,
  searchParam: "",
  sort: [{ field: "timestampDateTime", order: "desc" }],
};




const OrderByFilters = [
  // { field: 'timestampDateTime', order: 'asc' }
  { value: "orderDateDesc", label: "Order Date - Desc" },
  { value: "orderDateAsc", label: "Order Date - Asc" },
];
// let currDate = moment().toDate();

// const getOrderStatus = (orderStatus) => {
//   if (orderStatus) {
//     const ordSts = ORDER_STATUS_LIST.find( _ => _.key === orderStatus );
//     if (ordSts) {
//       return ordSts.value;
//     }
//     return orderStatus[0].toUpperCase() + orderStatus.substring(1);
//   }
//   return orderStatus;
// }

export const getOrderSaleType = (saleType) => {
  if (saleType) {
    return  ORDER_SALE_TYPE_LIST.find((_) => _.value === saleType)?.label || saleType;
};
}
export const ReconcilledPage = (props) => {
  const { t } = useTranslation();
  const [isDownloading] = useState(false);
  const [isLoadingInvoice, setIsLoadingInvoice] = useState(false);
  const [buttonId, setButtonId] = useState(null);
  const [state, setState] = useState({message:null});
  // const tableRef = useRef();
  const [perPageLimit] = useState(10);
  const [ isReloadTableData, setIsReloadTableData ] = useState(false);

  const [actionItems, setActionItems] = useState([]);
  const [modalTitle, setModalTitle] = useState("Order Details");
  const [modalCustomButtons, setModalCustomButtons] = useState(null);
  const modelStyle = { maxWidth: '80%' };
  const [modalFormStatusObj] = useState({ text: '' });
  const [, setUpdatedFormFieldValues] = useState({});
  const [modalFormData, setModalFormData] = useState({});
  const [sectorList, setSectorsList] = useState([]);
  const [, setSearchKey] = useState("");
  const [message, setMessage] = useState('No Data')

  const [salesFilter, setSalesFilter] = useState({
    sectorVal: [],
    orderByVal: OrderByFilters[0].value,
    orderDateVal: new Date(),
    searchKey: "",
    orderStatusVal: "",
    saleType: "",
    paymentType: "",
  });

  const ActionCell = (params)=>{

    return (
      <div className="flexRow">
      {params?.data?<div className="margRight10"> <button onClick={(e)=>onActionInner(e,{value:"viewItems"},params?.data)} title="View Items" className="margRight10">
          <img className="edit" alt="" src={viewIcon}/>
        </button></div>:null}

        {params?.data && params.data?.saleTypeRaw === "preBooked" &&
          params?.data?.orderStatus !== "Cancelled Order" && (<div className="margRight10">
            <button onClick={(e)=>onActionInner(e,{value:"downloadInvoices"},params?.data)} title="Download Invoices">
              <img className="edit" alt="" style={{width:'20px',height:'20px'}} src={isLoadingInvoice && buttonId===params?.data.id?LoadingIn:downloadMasterIcon}/>
            </button></div>

          )}
      </div>
    )
  }

  const Columns = [
    {
      field: "orderNo",
      headerName: "Sector",
      minWidth: 184,
      tooltipField: "flightNumber",
      autoHeight: true,
      pinned: "left",flex:1, suppressSizeToFit:true,
      cellRendererSelector: (params) => {
        return {
          component: SectorRenderer,
        };
      },
    },
    {
      field: "orderNo",
      headerName: "Order Number",
      resizable:true,
      minWidth: 260,
      tooltipField: "orderNo",
      autoHeight: true,flex:1, suppressSizeToFit:true,
      cellRendererSelector: (params) => {
        return {
          component: OrdNoRenderer,
        };
      },
    },
    {
      field: "timestampDateTime",
      headerName: "Order Date",
      resizeable:true,
      minWidth: 100,
      tooltipField: "timestampDateTime",
      autoHeight: true,flex:1, suppressSizeToFit:true,
      valueGetter: (params) => {
        return params.data?.timestampDateTime
          ? moment(params.data?.timestampDateTime).format(DATE_FORMAT)
          : "";
      },
    },
    {
      field: "orderStatus",
      headerName: "Order Status",
      minWidth: 140,
      tooltipField: "orderStatus",
      autoHeight: true,flex:1, suppressSizeToFit:true,
      cellRendererSelector: (params) => {
        return {
          component: OrderStatusRenderSel,
        };
      },
    },
    {
      field: "saleTypeRaw",
      headerName: "Sale Type",
      minWidth: 150,
      autoHeight: true,flex:1, suppressSizeToFit:true,
      valueGetter: (params) => {
        return params.data ? getOrderSaleType(params.data.saleTypeRaw) : "";
      },
    },
    {
      field: "paymentTypeRaw",
      headerName: "Payment Type",
      minWidth: 160,
      tooltipField: "paymentTypeRaw",
      autoHeight: true,flex:1, suppressSizeToFit:true,
      cellRendererSelector: (params) => {
        return {
          component: PaymentTypeRenderSel,
        };
      },
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      minWidth: 160,
      autoHeight: true,flex:1, suppressSizeToFit:true,
      valueGetter: (params) => {
        return params.data
          ? params.data.currencyInfo.currencyCode + " " + params.data.totalAmount
          : "";
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      cellRenderer:ActionCell,
      minWidth:150,
      pinned:"right"
    }
  ];

  const [sectorPageObj, setSectorPageObj] = useState({
    page: 1,
    size: 2000,
    q: "",
  });

  const [, setReportTypes] = useState([]);
  // const [routes, setRoutes] = useState([]);
  const [editFormFields, setEditFormFields] = useState([]);

  const { hasPermission: viewRecon } = usePermissions('viewDetails', 'sales.reconcilled');
  const { hasPermission: downloadRecon } = usePermissions('download', 'sales.reconcilled');


  useEffect(() => {
    // {[{ title: 'Edit', detail: [{src: editIcon2, value:"EditItem"}] }]}
    setActionItems([
      {
        title: "",
        attribute: "viewItems",

      },
      {
        title: "",
        attribute: "invoices",
      },
      // {
      //     title: 'Actions',
      //     attribute: 'Suspend',
      // },
      // {
      //     title: '',
      //     attribute: 'purchaseOrders',
      // }
    ]);

    getSectorList();

    loadReportTypes();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(state.message){
      setTimeout(()=>{
          setState((prev)=>({...prev,message:null}))
          setButtonId(null);
      },3500)
  }
  }, [state])

  useEffect(() => {
    setIsReloadTableData(!isReloadTableData);
    fetchOrders();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesFilter]);

   const fetchOrders = useCallback(
    async (page = 1, perPage = 10) => {
      /**
       * Pagination
       */
      filterData.page = page;
      filterData.limit = perPage;

      /**
       * Filters
       */
      filterData.filters = [];
       if ( salesFilter.searchKey ) {
      filterData.searchParam = salesFilter.searchKey;
      filterData.filters.push({ orderNo: salesFilter.searchKey });
      }

      if (salesFilter.sectorVal[0]?.value) {
        filterData.filters.push({ storeId: salesFilter.sectorVal[0]?.value });
      }
      if (salesFilter.orderDateVal) {
        const dt = moment(salesFilter.orderDateVal);
        if (dt.isValid()) {
          filterData.filters.push({
            orderDate: dt.format("YYYY-MM-DDT00:00:00Z"),
          });
        }
      }
      if (salesFilter.orderStatusVal) {
        filterData.filters.push({ orderStatus: salesFilter.orderStatusVal });
      }
      if (salesFilter.saleType) {
        filterData.filters.push({ saleTypeRaw: salesFilter.saleType });
      }
      if (salesFilter.paymentType) {
        filterData.filters.push({ paymentTypeRaw: salesFilter.paymentType });
      }

      /**
       * Sort
       */
      if (salesFilter.orderByVal) {
        let sortOrder = "desc";
        if (salesFilter.orderByVal === "orderDateAsc") {
          sortOrder = "asc";
        } else if (salesFilter.orderByVal === "orderDateDesc") {
          sortOrder = "desc";
        }

        // eslint-disable-next-line array-callback-return
        filterData.sort.map((_) => {
          if (_.field === "timestampDateTime") {
            _.order = sortOrder;
          }
        })
      }
    try {
      const res = await APIEndpoint.post(
        "orders/process",
        JSON.stringify(filterData),
        { signal: newAbortSignal("orderListAPI", 5000) }
      );
      if(res&&res.items.length>0 && viewIcon){
        setMessage(undefined);
      setIsReloadTableData(!isReloadTableData);
        return res;
      }else{
        if(page===1){
             return {
                items:[],count:0
             }
        }
    }
    } catch (error) {

    }

    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // isReloadTableData,
      salesFilter.orderByVal,
      salesFilter.orderDateVal,
      salesFilter.orderStatusVal,
      salesFilter.paymentType,
      salesFilter.saleType,
      salesFilter.searchKey,
      salesFilter.sectorVal,
    ]
  );

  const getSectorList = useCallback(() => {
    // let nnn = [ ...sectorList ];
    // nnn.push( { id: '', label: 'Sectors - Loading...', value: ''} );
    // setSectorsList(nnn);

    // e.target.setAttribute('disabled', true);
    // setSectorsLoading(true); // , { signal: newAbortSignal('sectorListAPI', 5000) }
    try {
          APIEndpoint.get("stores/byPage?" + objectToQueryString(sectorPageObj)).then(
      (resp) => {
        // e.target.removeAttribute('disabled');
        // setSectorsLoading(false);

        setSectorPageObj({ ...sectorPageObj, page: sectorPageObj.page + 1 });
        const list = [];
        if (resp.result.length > 0) {
          // eslint-disable-next-line array-callback-return
          resp.result.map((_) => {
            list.push({ value: _.id, id: _.id, label: _.value });
          });

          setSectorsList(list);
        }
      }
    );
    } catch (error) {

    }


    return false;
  }, [sectorPageObj]);

  const onSectorChange = (e) => {
    setSalesFilter({ ...salesFilter, sectorVal: [e] });
    abortEarlierRequest("orderListAPI");
  };
  const onOrderByChange = (e) => {
    setSalesFilter({ ...salesFilter, orderByVal: e.target.value });
    abortEarlierRequest("orderListAPI");
  };

  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      position: "relative",
      zIndex: state.isOpenMenu ? 9999 : null,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999, // Set a high z-index for the portal
    }),

  };

  const onSearchChange = debounce((e)=>onSearch(e),400)

  const onSearch = (e) => {
    const searchTxt = e.target.value;
    setSearchKey(searchTxt);

    // if (searchTxt.length >= 3) {
    setSalesFilter({ ...salesFilter, searchKey: searchTxt });
    // }
    // abortEarlierRequest("orderListAPI");
  };
  const handleFilterOrderDateChange = (e) => {
    setSalesFilter({ ...salesFilter, orderDateVal: e.target.value });
    abortEarlierRequest("orderListAPI");
  };
  const handleFilterOrderStatusChange = (e) => {
    setSalesFilter({ ...salesFilter, orderStatusVal: e.target.value });
    abortEarlierRequest("orderListAPI");
  };
  const onSaleTypeChange = (e) => {
    const value= e?.value || ''
    setSalesFilter({ ...salesFilter, saleType:value });
    abortEarlierRequest("orderListAPI");
  };
  const onPaymentTypeChange = (e) => {
    setSalesFilter({ ...salesFilter, paymentType: e.target.value });
    abortEarlierRequest("orderListAPI");
  };

  const loadReportTypes = async () => {
    //, { signal: newAbortSignal('reportTypesAPI', 5000) }
    try {
        const resp = await DFPAPIEndpoint.get("reports/reportTypes");
    if (resp.result.length > 0) {
      const options = [{ key: 0, label: "Select", value: "", id: "" }];
      resp.result.map((fieldValue, index) => {
        options.push({
          key: index + 1,
          label: `${fieldValue.value}`,
          value: `${fieldValue.id}`,
          id: `${fieldValue.id}`,
        });

        return fieldValue;
      });

      setReportTypes(options);
      // updateEditFormInputs(options, null);
    }
    } catch (error) {

    }

  };

  const onDownloadReports = async (e) => {
    const action = { value: "downloadReportPopup" };
    onActionInner(e, action, {});
  };

  const onAction = (e, action, row) => {
    onActionInner(e, action, row);
  };

  const onActionInner = async(e, action, row) => {
    console.log(row);
    const {id}=row;
    setButtonId(id)
    ReactDOM.flushSync(() => {
      let updatedEditFields = [];

      if (action.value === "viewItems") {
        setModalTitle("Order No - " + row.orderNo);

        updatedEditFields = [
          {
            label: "View Order Details",
            type: "viewSalesDetails",
            attribute: "items",
          },
        ];
        updatedEditFields = updatedEditFields.filter((row) => {
          if (row.type === "viewSalesDetails") {
            row.value = row;
          }
          return row;
        });

        // setModalCustomButtons([[]]);
        setModalCustomButtons([
          /**
           * Buttons
           */
          {
            label: "Close",
            attribute: "button",
            type: "button",
            disabled: false,
            className: "close-button",
            action: (e) => {
              console.log("ModalClosed.");
              onClose();
              return false;
            },
          },
        ]);

        setEditFormFields([...updatedEditFields]);

        setState((_prev) => {
          return { ..._prev, selected: { ...row, action: action.value } };
        });
      } else if (action.value === "downloadReportPopup") {
        setState((_prev) => {
          return { ..._prev, selected: { ...row, action: action.value } };
        });
      } else if (action.value === "downloadInvoices") {

        const linkTxt = action.title;
        APIEndpoint.post(
          "downloads/invoice",
          { orderId: row.id },
          { Accept: MIMETypes.pdf }
        )
          .then((resp) => {
            if (resp.status === "scheduled") {
             setIsLoadingInvoice(true)

          checkDownloadStatus(resp.id, e).then((res)=>{
            const {data}=JSON.parse(res.message)
          if(!data.success){
            setState((prev)=>({...prev,message:data.message}))
          }
          setIsLoadingInvoice(false)
            }).catch((error)=>{
              setIsLoadingInvoice(false)

            });
            } else {
             setIsLoadingInvoice(false)
              updateButtonTxtStatus(true, e, linkTxt, false);
            }
          })
          .catch((error) => {
         setIsLoadingInvoice(false);

            console.error(
              "Log: Error occured while downloading invoice:",
              error
            );
            updateButtonTxtStatus(false, e, "Failed!", false, {
              defaultButtonText: linkTxt,
              className: "failedCls",
            });
          });
      }
    });
  };

  const onClose = () => {
    setState((_prev) => {
      return { ..._prev, selected: false };
    });
  };

  const updateEditFormValues = async (e, updatedFormObj) => {
    // ReactDOM.flushSync( () => {
    //     if (e) {
    //         const selectedReportType = reportTypes.find( item => item.value === e);
    //         console.log('selectedReportType:', selectedReportType);
    //         /** Set to respective fields */
    //         // const updatedEditFields = editFormFields.filter( (field) => {
    //         //     if (selectedReportType ) {
    //         //         if ( field.attribute === 'reportType' ) {
    //         //             field.value = selectedReportType.label;
    //         //         }
    //         //     }
    //         //     return field;
    //         // });
    //         // setEditFormFields( _prev => {
    //         //     return [ ...updatedEditFields ] ;
    //         // });
    //     }
    // })
  };

  const onModalFormInputChange = (e, newFormData) => {
    const { name, value } = e.target;

    let newUpdates = modalFormData ? { ...modalFormData } : {};
    newUpdates[name] = value ?? "";

    setModalFormData({ ...newFormData });
  };

  return (
    <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      {
        <>
        <div className="flexRow width100 margBot10 margTop8 justifyContentSpaceBetween">
        <div className="width100Menu justifyContentCenter flexRow">
        <div className="margLeft margBot10 margTop8  underline">
              <span>
                <img
                  className="icon-size"
                  alt={t("filters.searchText")}
                  src={searchIcon}
                />
              </span>
              <span>
                <input
                  className="search"
                  onChange={onSearchChange}
                  type="search"
                  placeholder={t("filters.searchText") + " Order Number "}
                  // value={searchKey}
                />
              </span>
            </div>
        </div>
  { downloadRecon ?  <div className="flexRow  margBot10 margTop8 justifyContentFlexEnd">
        <button
              className="download dropdownStyle1-TextMedium  justifyContentFlexEnd mb-0  "
              onClick={(e) => {
                onDownloadReports(e);
              }}
            >
              <span style={{whiteSpace:"nowrap"}}>
                <img
                  className="icon-size mt-1"
                  alt="Download Reports"
                  src={downloadMasterIcon}
                />
                <u
                  className={`download__banner_${
                    isDownloading ? "active" : ""
                  }`}
                >
                  {t("filters.downloadReports")}
                </u>
              </span>
            </button>
        </div>:<></>}
        </div>

          <div className="flexRow width100 justifyContentSpaceBetween alignItemsCenter">
            {/* <button className='page-button'>
                  <select className="darkBlue-bg" onChange={onPerLimitChange} value={perPageLimit} id="page-size">
                      {
                      PageSizes.map((_) => {
                          return (
                          <option key={_.value} value={_.value}>{_.label}</option>
                          )
                      })
                      }
                  </select>
              </button> */}

           <div className=" flexRow gap10 width100 justifyContentFlexStart alignItemsCenter">
           {salesFilter.sectorVal ? (<div className='orderBy-div m-2 margLeft'>
            <Select
              options={sectorList}
              defaultValue={salesFilter.sectorVal[0]?.label ? salesFilter.sectorVal[0]?.label : ''}
              value={sectorList?.filter(function (option) {
                return (
                  salesFilter.sectorVal.length && salesFilter.sectorVal[0]&&
                  salesFilter.sectorVal.find((sc) => sc.label === option.label)
                );
              })}
              onChange={onSectorChange}
              styles={customSelSearch}
              isOpenMenu={state.isOpenMenu}
              placeholder="Search Sectors"
              isSearchable={true}
              isClearable={true}
              className="selectMenu-meals-reconcilled"
            />
          </div>):<></>}

              <button className="orderBy-div m-2 margLeft">
                <select
                  className="select-item darkBlue-bg"
                  onChange={onOrderByChange}
                  value={salesFilter.orderByVal}
                  id="orderById"
                >
                  {OrderByFilters.map((_) => {
                    return (
                      <option key={_.value} value={_.value}>
                        {_.label}
                      </option>
                    );
                  })}
                </select>
              </button>

              <button className="orderStatus-div m-2">
                <select
                  className="select-item darkBlue-bg"
                  onChange={handleFilterOrderStatusChange}
                  value={salesFilter.orderStatusVal}
                  id="orderStatusId"
                >
                  <option key={""} value={""}>
                    Order Status
                  </option>
                  {ORDER_STATUS_LIST.map((_) => {
                    return (
                      <option key={_.key} value={_.key}>
                        {_.value}
                      </option>
                    );
                  })}
                </select>
              </button>

              <button className="saleType-div m-2">
              <CustomSelect
                options= {ORDER_SALE_TYPE_LIST}
                onChange={onSaleTypeChange}
                placeHolder="Search Sales Type"
              />
              </button>

              <button className="paymentType-div m-2">
                <select
                  className="select-item darkBlue-bg"
                  onChange={onPaymentTypeChange}
                  value={salesFilter.paymentType}
                  id="paymentTypeId"
                >
                  <option key="" value="">
                    Payment Type
                  </option>
                  {ORDER_PAYMENT_TYPE_LIST.map((_) => {
                    return (
                      <option key={_.key} value={_.key}>
                        {_.value}
                      </option>
                    );
                  })}
                </select>
              </button>

              <div className="flexRow m-2 margLeft">
              <NGODatePicker
                className="orderByDateInp"
                value={salesFilter.orderDateVal}
                name="filterOrderDate"
                style={{border:'1.48px solid #fff'}}
                placeholder={"DD-MM-YYYY"}
                onChange={handleFilterOrderDateChange}
                dateformat="dd-mm-yyyy"
                isClearable
              />
            </div>
           </div>
          </div>
          {  Boolean(state.message)?<div className="flexRow justifyContentFlexEnd alignItemsCenter margRight margBot10">

            <div className={`validationMsgDiv error`}>
              {state.message}
            </div></div>:null
          }
        <div  className='flexCol full-flex'>
          <DataGrid
            // ref={tableRef}
            className="full-flex"
            columns={[...Columns]}
            initialPageLimit={perPageLimit}
            showDefaultFilters={false}
            // searchKey={searchKey}
            // selItemType={selItemType}
            getRows={fetchOrders}
            rowSelection="multiple"
            // onAddItemHandler={() => onAddItemHandler()}
            columnResize={true}
            resetDataSource={isReloadTableData}
            noResultsMessage={message}
          /></div>

        </>
      }
      {state &&
      Boolean(state.selected) && downloadRecon &&
      state.selected?.action === "downloadReportPopup" ? (
        <>
          <SalesReportsDownloadComponent
            sectorList={sectorList}
            onClose={() => {
              onClose();
            }}
          />
        </>
      ) : null}
      {state &&
      Boolean(state.selected) && viewRecon &&
      state.selected?.action === "viewItems" ? (
        <EditModal
          className="salesPageModal"
          title={modalTitle}
          customModalStyle={modelStyle}
          onClose={onClose}
          // onSubmit={onSubmit}
          modalFormStatusObj={modalFormStatusObj}
          modalCustomButtons={modalCustomButtons}
          fields={editFormFields.map((elt) => {
            if (state.selected.action === "viewItems") {
              // [elt.attribute]
              return { ...elt, initialValue: state.selected ?? "" };
            } else {
              return { ...elt, initialValue: "" };
            }
          })}
          showFieldsInColumn={true}
          onChange={(e, updated) => {
            setUpdatedFormFieldValues(updated);

            if (typeof e === "string") {
              // Search select
              updateEditFormValues(e, updated);
            } else {
              onModalFormInputChange(e, updated);
            }
          }}
        />
      ) : null}
    </div>
  );
};