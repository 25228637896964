/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import moment from "moment";

import { AssetEndpoint } from "../../../utils/axios";
import searchIcon from '../../../assets/icons/search.svg';
import editIcon from "../../../assets/edit.svg";
import deleteIcon from '../../../assets/delete.svg'

import { DFPAPIEndpoint, DFPAPIEndpointGalley } from "../../../utils/axios";
import { FilterBodyTemplate } from "../../../utils/configs";
import { DataGrid, EditModal ,DeleteConf} from "../../../common";

// import { AddMenuItem } from './addMenuItem'

import { mealTypeList, mealCategoryList, cabinList,
    sectorResToSelectOptions, caterersResToSelectOptions, warehouseResToSelectOptions
} from './menuListingExtras';

let axiosAbortCtrl = [];
axiosAbortCtrl['menuListAPI'] = new AbortController();

const newAbortSignal = (apiName, timeoutMs=5000) => {
    // const abortController = new AbortController();
    axiosAbortCtrl[apiName] = new AbortController();
    // setTimeout(() => axiosAbortCtrl[apiName].abort(), timeoutMs || 0);

    return axiosAbortCtrl[apiName].signal;
};
const abortEarlierRequest = (apiName = 'menuListAPI') => {
    axiosAbortCtrl[apiName].abort(apiName + ': Cancelling API running earlier...');
}

const DATE_FORMAT = 'DD-MM-YYYY';// hh:mm A
const Columns = [
    { field: 'mealCode', headerName: 'Meal Code',  minWidth: 164, tooltipField: 'mealCode', autoHeight: true, pinned: 'left'
    },
    { field: 'name', headerName: 'Meal Name',  minWidth: 240, tooltipField: 'name', autoHeight: true
    },
    { field: 'mealType', headerName: 'Meal Type',   tooltipField: 'mealType', minWidth: 150, autoHeight: true,
        // valueGetter: params => { return params.data ? getOrderSaleType(params.data.saleTypeRaw) : ''; }
    },
    { field: 'category', headerName: 'Category',   tooltipField: 'category', minWidth: 150, autoHeight: true,
        // valueGetter: params => { return params.data ? getOrderSaleType(params.data.saleTypeRaw) : ''; }
    },
    { field: 'cabin', headerName: 'Cabin',   tooltipField: 'cabin', minWidth: 150, autoHeight: true,
        // valueGetter: params => { return params.data ? getOrderSaleType(params.data.saleTypeRaw) : ''; }
    },
    { field: 'cateringStation', headerName: 'Catering Station',   tooltipField: 'cateringStation', minWidth: 150, autoHeight: true,
        // valueGetter: params => { return params.data ? getOrderSaleType(params.data.saleTypeRaw) : ''; }
    },
    { field: 'caterer', headerName: 'Caterer',   tooltipField: 'caterer', minWidth: 150, autoHeight: true,
        // valueGetter: params => { return params.data ? getOrderSaleType(params.data.saleTypeRaw) : ''; }
    },
    { field: 'description', headerName: 'description',   tooltipField: 'description', minWidth: 150, autoHeight: true,
        // valueGetter: params => { return params.data ? getOrderSaleType(params.data.saleTypeRaw) : ''; }
    },
    { field: 'sector', headerName: 'sector',   tooltipField: 'sector', minWidth: 150, autoHeight: true,
        // valueGetter: params => { return params.data ? getOrderSaleType(params.data.saleTypeRaw) : ''; }
    },
    { field: 'createdAt', headerName: 'Created Date',  minWidth: 150,
        tooltipField: 'createdAt', autoHeight: true,
        valueGetter: params => {
            return params.data?.createdAt ? moment(params.data?.createdAt).format(DATE_FORMAT) : '';
        }
    }
];
const actionColumnProperties = { pinned: 'right', minWidth: 200, width: 200, autoHeight: true, tooltipField: 'View' };

export const MenuListing = (props) => {
    const { t } = useTranslation();

    const perPageLimit = 10;
    const [searchKey, setSearchKey] = useState('');
    const [ salesFilter, setSalesFilter ] = useState({
        searchKey: ''
    });
    const [ isReloadTableData, setIsReloadTableData ] = useState(false);
    const [actionItems, setActionItems] = useState([]);
    const [state, setState] = useState({});
    const [ modalTitle, setModalTitle ] = useState('Details');
    const [modalCustomButtons, setModalCustomButtons] = useState(null);
    const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
    const modelStyle = { maxWidth: '90%' };
    const [editFormFields, setEditFormFields] = useState([]);
    const [modalFormData, setModalFormData] = useState({});
    const [ cateringStationList , setCateringStationList ] = useState([]);
    const [ catererList , setCatererList ] = useState([]);
    const [ sectorList , setSectorList ] = useState([]);
    const [ deletePopup , setDelete ] = useState(false);
    const [,setDelConf]=useState(false);
    const [menuItem,setMenuItem]=useState(null);

    useEffect( () => {
        // {[{ title: 'Edit', detail: [{src: editIcon2, value:"EditItem"}] }]}
        setActionItems([
          {
              title: 'Actions',
              attribute: 'editDetails',
          },
          {
            title: '',
            attribute: 'delete'
          }
        //   {
        //     title: '',
        //     attribute: 'invoices'
        //   },
          // {
          //     title: 'Actions',
          //     attribute: 'Suspend',
          // },
          // {
          //     title: '',
          //     attribute: 'purchaseOrders',
          // }
        ]);

        loadCateringStations();
        loadCaterers();
        loadSectorList();

        setEditFormFields( [ ...EditFormFields ] );

    }, []);

    useEffect( () => {
        // updateEditFormValues();
        // if (e) {

        //         const selectedReportType = reportTypes.find( item => item.value === e);
        //         console.log('selectedReportType:', selectedReportType);

        //         /** Set to respective fields */

                const updatedEditFields = editFormFields.filter( (field) => {

                    if ( cateringStationList ) {
                        if ( field.attribute === 'cateringStation' ) {
                            field.options = cateringStationList;
                        }
                    }

                    if ( catererList ) {
                        if ( field.attribute === 'caterer' ) {
                            field.options = catererList;
                        }
                    }

                    if ( sectorList ) {
                        if ( field.attribute === 'sector' ) {
                            field.options = sectorList;
                        }
                    }

                    return field;
                });

                setEditFormFields( _prev => {
                    return [ ...updatedEditFields ] ;
                });
            // }
    }, [ cateringStationList, catererList, sectorList ]);


    const EditFormFields = [
        {
            key: 'mealCode', label: 'Meal Code', name: 'mealCode', id: 'mealCode', attribute: 'mealCode', type: 'text', disabled: false,
            required: true, className: 'text-input'
        },
        {
            key: 'mealName', label: 'Meal Name', name: 'mealName', id: 'mealName', attribute: 'name', type: 'text', disabled: false,
            required: true, className: 'text-input'
        },
        { key: 'mealType', label: 'Meal Type', name: 'mealType', id: 'mealType', attribute: 'mealType', type: 'select', disabled: false,
            options: mealTypeList || [], required: true, className: 'text-input'
        },
        { key: 'category', label: 'Meal Category', name: 'category', id: 'category', attribute: 'category', type: 'select', disabled: false,
            options: mealCategoryList || [], required: true, className: 'text-input'
        },
        { key: 'cabin', label: 'Cabin', name: 'cabin', id: 'cabin', attribute: 'cabin', type: 'select', disabled: false,
            options: cabinList || [], required: true, className: 'text-input'
        },
        { key: 'cateringStation', label: 'Catering Station', name: 'cateringStation', id: 'cateringStation', attribute: 'cateringStation', type: 'select', disabled: false,
            options: cateringStationList || [], required: true, className: 'text-input'
        },
        { key: 'caterer', label: 'Caterer', name: 'caterer', id: 'caterer', attribute: 'caterer', type: 'select', disabled: false,
            options: catererList || [],
            search: false,
            // getOptions: loadCaterers(),className:'select-search-cls'//'
            required: true,
            className: 'text-input'
        },
        { key: 'sector', label: 'Sector', name: 'sector', id: 'sector', attribute: 'sector', type: 'select', disabled: false,
            options: sectorList || [], required: true, className: 'text-input'
        },
        { key: 'description', label: 'Description', name: 'description', id: 'description', attribute: 'description', type: 'textarea', required: true, rows: 4, cols: 42, disabled: false
            , placeholder: t('description'), className: 'wide-textarea-input'
        },
        // {
        //     key: 'sector', label: 'Select Sector', name: 'sector', id: 'sector', attribute: 'sector', type: 'selectSearch', disabled: false,
        //     required: true, className: 'select-search-cls',
        //     options: sectorList
        //     // getOptions: getReportTypeOptions()

        //     // , defaultValue: ''
        //     // , value: []

        //     // , getOptions: () => {
        //     //     return
        //     // }
        //     /**
        //      * multiple(false): true/false
        //      * search(false): true/false
        //      * closeOnSelect(true): true/false
        //      * debounce(0): Number of ms to wait until calling get options when searching.
        //      * autoComplete(off): Options are on/off
        //      * autoFocus(false): true/false
        //      */
        //     // , multiple: true
        //     , search: true
        //     // , closeOnSelect: false
        //     // , debounce: 1000
        //     // , autoComplete: 'on'
        //     // , autoFocus: false
        //     , placeholder: 'Start typing...'
        // },
        // { label: 'View Order Details', type: 'viewOrderDetails', attribute: 'items' }

        // { key: 1, label: 'Report Type', name: 'reportType', id: 'reportType', attribute: 'reportType', type: 'select', disabled: false,
        //     options: reportTypes || [], required: true, className: 'text-input' },

        // {
            // key: 'reportType', label: 'Report Type', name: 'reportType', id: 'reportType', attribute: 'reportType', type: 'select', disabled: false,
            // required: true, className: 'select-search-cls'
            // , options: reportTypes
            // , getOptions: () => {
            //     return getReportTypeOptions();
            // }

            // , defaultValue: ''
            // , value: ''
            // , className: 'select-search-cls'
            // , getOptions: () => {
            //     return
            // }
            /**
             * multiple(false): true/false
             * search(false): true/false
             * closeOnSelect(true): true/false
             * debounce(0): Number of ms to wait until calling get options when searching.
             * autoComplete(off): Options are on/off
             * autoFocus(false): true/false
             */
            // , multiple: false
            // , search: true
            // // , closeOnSelect: false
            // // , debounce: 1000
            // , autoComplete: 'on'
            // , autoFocus: false
            // , placeholder: 'Start typing...'
        // },
        // { key: 'filterByTransactionDate', label: 'Filter by Transaction Date', name: 'filterByTransactionDate', id: 'filterByTransactionDate', attribute: 'filterByTransactionDate', type: 'checkbox',
        //     required: false, disabled: false,
        //     // placeholder: '', className: 'text-input'
        // },
        // { key: 'startDate', label: 'Start Date', name: 'startDate', id: 'startDate', attribute: 'startDate', type: 'date', required: true, disabled: false,
        //     placeholder: t('datePickerPlaceholder'),
        //     className: 'date-input',
        //     // startDate: moment().format()
        // },
        // { key: 'endDate', label: 'End Date', name: 'endDate', id: 'endDate', attribute: 'endDate', type: 'date', required: true, disabled: false,
        //     placeholder: t('datePickerPlaceholder'),
        //     className: 'date-input',
        //     // endDate: moment().format()
        // },
        // { key: 'transDate', label: 'Date', name: 'transDate', id: 'transDate', attribute: 'transDate', type: 'date', required: true, disabled: false,
        //         placeholder: t('datePickerPlaceholder'),
        //         className: 'date-input',
        //         // startDate: moment().format()
        // },

    ];

    const loadCaterers = async () => {
        const filterData = { ...FilterBodyTemplate, filters: [] };
        /**
         * Pagination
         */
        filterData.page = 1;
        filterData.limit = 10000;
        try {
        await DFPAPIEndpointGalley.post('galley/caterers/process', JSON.stringify(filterData) )
            .then( resp => {
                // console.log('Log: Caterer list:', resp.items);
                const selData = caterersResToSelectOptions(resp.items);

                setCatererList(selData);
                // return selData;

            }).catch( error => {
                console.log('Log: Error occured while fetching caterer list:', error.message);
            } );
        } catch (error) {

        }

    };

    const loadCateringStations = async () => {

        DFPAPIEndpoint.get('warehouses' )
        .then( resp => {
            console.log('Log: Warehouse list:', resp.result);
            const selData = warehouseResToSelectOptions(resp.result);

            setCateringStationList(selData);

        }).catch( error => {
            console.log('Log: Error occured while fetching sector list:', error.message);
        } );
    };

    const loadSectorList = async () => {
        const filterData = { ...FilterBodyTemplate, filters: [] };
        /**
         * Pagination
         */
        filterData.page = 1;
        filterData.limit = 10000;

        DFPAPIEndpoint.post('routes/process', JSON.stringify(filterData) )
        .then( resp => {
            console.log('Log: Sector list:', resp.items);
            const selData = sectorResToSelectOptions(resp.items);

            setSectorList(selData);

        }).catch( error => {
            console.log('Log: Error occured while fetching sector list:', error.message);
        } );

    }

    const onSearch = (e) => {
        const searchTxt = e.target.value;
        setSearchKey(searchTxt);

        // if (searchTxt.length >= 3) {
        setSalesFilter( { ...salesFilter, searchKey: searchKey } );
        // }
        abortEarlierRequest('menuListAPI');
    };

    const fetchMenuListing = async (page = 1, perPage = 10) => {
        const filterData = { ...FilterBodyTemplate, filters: [] };
        /**
         * Pagination
         */
        filterData.page = page;
        filterData.limit = perPage;

        const res = await DFPAPIEndpointGalley.post('galley/mealMenu/list', JSON.stringify(filterData), { signal: newAbortSignal('menuListAPI', 5000) })
        setIsReloadTableData( !isReloadTableData );

        return res;
    }

    const getActionCellIcons = (cellDef, data) => {

        if (cellDef.attribute === 'editDetails') {
            // if (data && data.status === 'created') {
            //     return [{src: editIcon2, value:"edit", title: 'Edit'}];
            // } else {}
            return [
                { src: editIcon, value:"editDetails", title: 'Edit', className: 'editIcon2' },
            ];
        }
        if (cellDef.attribute === 'delete') {
            // if (data && data.status === 'created') {
            //     return [{src: editIcon2, value:"edit", title: 'Edit'}];
            // } else {}
            return [
                { src: deleteIcon, value:"delete", title: 'Delete' },
            ];
        }
        // else if (cellDef.attribute === 'invoices') {
        //   if (data && data.saleTypeRaw === 'preBooked' && data.orderStatus !== 'Cancelled Order') {
        //     return [
        //       {src: downloadMasterIcon, value: 'downloadInvoices', title: 'Invoices' }
        //     ]
        //   }
        // }


        return [];
    };

    const onAddItemHandler = async (e) => {
        const action = { value: 'createMenu' };
        onActionInner(e, action, {});
    }

    const onCloseDelete=()=>{
        setDelete(false);
        setDelConf(false)
      }

    const onAction = (e, action, row) => {
        setMenuItem(row)
        if(action.value==="delete") setDelete(true)

        else if (action.value === 'editDetails') {
            setModalTitle('Edit Menu Item' );
            setModalFormStatusObj({ text:  '', error: 0 });

            setEditFormFields( [ ...EditFormFields ] );

            setModalCustomButtons([
                { label: 'Save', attribute: 'button', type: 'button', disabled: false, className: 'submit-button', action: "onSubmit"
                    // , action: (e) => {  onSubmit(e); return false; }
                }
            ]);

            setState((_prev) => {
             return { ..._prev, selected: { ...row, action: action.value} };
            });
        }
        onActionInner(e, action, row);
    };

    const onActionInner = (e, action, row) => {
        console.info('Log: Clicked on ' + action.value + ' action link.', row);

        // ReactDOM.flushSync( () => {

        let updatedEditFields = [];

        if (action.value === 'view') {
            setModalTitle('Order No - ' + row.orderNo );

            updatedEditFields = [{ label: 'View Order Details', type: 'viewSalesDetails', attribute: 'items' }];
            // eslint-disable-next-line no-unused-vars
            updatedEditFields = updatedEditFields.filter( row => {
                if (row.type === 'viewSalesDetails') {
                row.value = row;
                }
                return row;
            });

            // setModalCustomButtons([[]]);
            setModalCustomButtons([
                /**
                 * Buttons
                 */
                { label: 'Close', attribute: 'button', type: 'button', disabled: false, className: 'close-button', action: (e) => {
                        console.log('ModalClosed.');
                        onClose();
                        return false;
                    }
                }
            ]);

            //   setEditFormFields( [ ...updatedEditFields ] );

            setState((_prev) => {
            return { ..._prev, selected: { ...row, action: action.value} };
            });

        }
        else if (action.value === 'createMenu') {
            setModalTitle('Add Menu Item' );
            setModalFormStatusObj({ text:  '', error: 0 });

            setEditFormFields( [ ...EditFormFields ] );

            setModalCustomButtons([

                { label: 'Create', attribute: 'button', type: 'button', disabled: false, className: 'submit-button', action: 'onSubmit'
                    // , action: (e) => {  onSubmit(e); return false; }
                }
            ]);

            setState((_prev) => {
              return { ..._prev, selected: { ...row, action: action.value} };
            });
        }
        // else if (action.value === 'downloadInvoices') {
        //     console.log('Log: Download invoice option selected.');

            // onDownloadPDFFile('downloads/invoice', 'order_invoice_' + row.id, { method: 'POST', payload: { orderId: row.id } } );

            // const linkTxt = action.title;
            // updateButtonTxtStatus(false, e, '...', true);
            // APIEndpoint.post('downloads/invoice', { orderId: row.id }, { Accept: MIMETypes.pdf }).then( resp => {

            //   if (resp.status === 'scheduled') {

            //     console.info("Log: Download status check: Initial");
            //     checkDownloadStatus(resp.id, e);

            //   } else {
            //     updateButtonTxtStatus(true, e, linkTxt, false);
            //   }

            // })
            // .catch( error => {
            //     console.error('Log: Error occured while downloading invoice:', error);
            //     updateButtonTxtStatus(false, e, 'Failed!', false, { defaultButtonText: linkTxt, className: 'failedCls' });
            // });

        // }

        // });

    };

    const onClose = () => {

        setState((_prev) => {
          return { ..._prev, selected: false }
        })
    }

    const updateEditFormValues = async (e, updatedFormObj) => {

        // ReactDOM.flushSync( () => {
        //     if (e) {

        //         const selectedSector = sectorList.find( item => item.value === e);
        //         console.log('selectedSector:', selectedSector);

        // //         /** Set to respective fields */
        //         const updatedEditFields = editFormFields.filter( (field) => {

        //             if (selectedSector ) {
        //                 if ( field.attribute === 'sector' ) {
        //                     field.value = selectedSector.label;
        //                 }
        //             }
        //             return field;

        //         });

        //         setEditFormFields( _prev => {
        //             return [ ...updatedEditFields ] ;
        //         });
        //     }
        // })
    };

    const onModalFormInputChange = (e, newFormData) => {
        const { name, value } = e.target;

        let newUpdates = modalFormData ? { ...modalFormData } : {}
        newUpdates[name] = value ?? '';

        setModalFormData( { ...newFormData } );
    }

    const handleFormSubmit = async (e, updated) => {
        const {cabin,name,category,caterer,cateringStation,description,mealCode,mealType,sector,_id}=state.selected
        if (updated) {
          const body = {
            name: updated.mealName? updated.mealName:name,
            mealType: updated.mealType? updated.mealType:mealType,
            mealCode: updated.mealCode? updated.mealCode:mealCode,
            category: updated.category? updated.category: category,
            cabin: updated.cabin? updated.cabin:cabin,
            cateringStation: updated.cateringStation ?updated.cateringStation:cateringStation,
            caterer: updated.caterer?updated.caterer:caterer ,
            description: updated.description? updated.description:description,
            sector: updated.sector?updated.sector:sector,
          }
          if (state.selected.action === 'createMenu'){
          try {
            await DFPAPIEndpointGalley.post(`galley/mealMenu/create`, body)
            .then( resp => {
                setModalFormStatusObj({ text: resp.message });
                setIsReloadTableData( !isReloadTableData );
            })
            .catch( err => {
                let errMsg = 'Something went wrong. Please try again after some time.';
                if (err.data?.message) {
                    errMsg = err.data?.message;
                }
                setModalFormStatusObj({ error:  errMsg });
            })

          } catch (err) {
            setModalFormStatusObj({ error: err.message });
          }
        }else {
            try {
                await DFPAPIEndpointGalley.put(`galley/mealMenu/${_id}`, body)
                .then( resp => {
                    setModalFormStatusObj({ text: resp.message });
                    setIsReloadTableData( !isReloadTableData );
                })
                .catch( err => {
                    let errMsg = 'Something went wrong. Please try again after some time.';
                    if (err.data?.message) {
                        errMsg = err.data?.message;
                    }
                    setModalFormStatusObj({ text:  errMsg, error: 1 });
                })

              } catch (err) {
                setModalFormStatusObj({ text: err.message, error: 1 });
              }
        }
        //setModalFormStatusObj({ text: "" });
    }
      }

      const onDeleteMenu=async()=>{
       const{ _id }=menuItem
         try {
         const res= await AssetEndpoint.delete(`galley/mealMenu/`+_id);
        if(res.success){
         setDelete(false)
         fetchMenuListing(1,10)
        }
         }
          catch (error) {
            // setModalFormStatusObj({ text: error.data.message });
          }
       }

    //    const onEditMenu=async()=>{
    //     const{ _id }=menuItem
    //       try {
    //       const res= await AssetEndpoint.put(`galley/mealMenu/`+_id);
    //      if(res.success){
    //         setState((_prev) => {
    //             return { ..._prev, selected: false
    //             }});
    //       fetchMenuListing(1,10)
    //      }
    //       }
    //        catch (error) {
    //          // setModalFormStatusObj({ text: error.data.message });
    //        }
    //     }


       let delProps = {
        onContinue:onDeleteMenu,
        onCloseDelete,
          }


    return (
        <div className='op-aircraft-container flexCol full-flex ag-theme-alpine-dark'>
        {
            <>
                {/* <div className='flexRow width100 justifyContentSpaceBetween alignItemsCenter'>
                    <button className='download dropdownStyle1-TextMedium flexRow justifyContentFlexEnd mb-0 ' onClick={ (e) => { onDownloadReports(e) }}><span><img className="icon-size mt-1" alt='Download Reports' src={downloadMasterIcon} /><u className={`download__banner_${isDownloading ? "active" : ""}`}>{t('filters.downloadReports')}</u></span></button>
                </div> */}

                <div className="flexRow width100 margBot10 margTop8 justifyContentSpaceBetween alignItemsCenter">
                    <div className="flexRow underline m-2">
                        <span><img  className="icon-size" alt={t('filters.searchText')} src={searchIcon} /></span>
                        <span><input className="search" onChange={onSearch} type="search" placeholder={t('filters.searchText') + ' Order Number '} value={searchKey}/></span>
                    </div>

                    <button className='add-item-button' onClick={ onAddItemHandler }>
                        {t('buttons.create')}
                    </button>
                </div>

                <DataGrid
                    // ref={tableRef}
                    className="full-flex"
                    columns={[...Columns]}
                    initialPageLimit={perPageLimit}
                    showDefaultFilters={false}
                    // searchKey={searchKey}
                    // selItemType={selItemType}
                    getRows={ fetchMenuListing }
                    rowSelection="multiple"
                    actionProperties = { actionColumnProperties }
                    // actions={[{ title: 'Edit', detail: [{src: editIcon2, value:"EditItem"}] }]}
                    actions={actionItems}
                    getActionCellIcons={getActionCellIcons}
                    onAction={ (e, action, data) => { return onAction(e, action, data) } }
                    // onAddItemHandler={() => onAddItemHandler()}
                    columnResize={true}
                    resetDataSource={isReloadTableData}
                />
                </>
            }
            {deletePopup && <DeleteConf  {...delProps} modalFormStatusObj={modalFormStatusObj} description={t('pages.menuListPop.delConfirmDialogue')} />}

            {
                state && Boolean(state.selected) &&
                ( state.selected?.action === 'createMenu' || state.selected?.action === 'editDetails' ) ?
                (
                    <EditModal
                        className='menuPageModel'
                        title={ modalTitle }
                        customModalStyle={ modelStyle }
                        onClose={onClose}
                        onSubmit={handleFormSubmit}
                        modalFormStatusObj={modalFormStatusObj}
                        modalCustomButtons={modalCustomButtons}
                        fields={ editFormFields.map( (elt) => {

                                if ( state.selected.action === 'viewItems' ) { // [elt.attribute]
                                    return {...elt, initialValue: state.selected ?? '' }
                                }
                                else if ( state.selected.action === 'editDetails' ) { // [elt.attribute]
                                    return {...elt, initialValue: state.selected[elt.attribute] ?? '' }
                                }
                                else {
                                    return {...elt, initialValue: '' }
                                }
                        })
                        }
                        showFieldsInColumn={false}

                        onChange={ (e, updated) => {

                                if (typeof e === 'string') { // Search select
                                    updateEditFormValues(e, updated);
                                }
                                else {
                                    onModalFormInputChange(e, updated);
                                }

                            }
                        }
                    />
                )
                : null
            }
        </div>
    );
}