/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, {useState,useEffect,useRef} from 'react'
import { APIEndpoint } from '../../../utils/axios';
import { DataGrid,EditModal,NGODatePicker,ConfirmModal,SpreadsheetModal,Modal} from '../../../common';
import {formatDateByTimezone, translationLength, doTooltipFirstLetterCaps } from '../../../utils/commonFunctions';
import { useTranslation } from 'react-i18next';
import { FaRegCheckSquare } from "react-icons/fa";
import viewIcon from "../../../assets/view.svg";
import Edit2 from "../../../assets/edit2.svg";
import grnIcon from '../../../assets/check.svg';
import moment from 'moment';
import searchIcon from '../../../assets/icons/search.svg';
import * as ReactDOM from 'react-dom';
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import usePermissions from '../../../utils/userPermissions';
import {customSelSearch, DEFAULT_SHEET_ROWS } from "../../../utils/configs";
import CustomSelect from '../../../common/CustomSelect';

const iIndexes = {
    ITEM_CODE: 0,
    ITEM_NAME: 1,
    UNIT: 2,
    ACTUAL_OFFLOAD: 3,
    DAMAGE: 4,
    EXPIRED: 5,
    LOST: 6,
    BACK_TO_WAREHOUSE: 7,
    REMARKS: 8
};



 const ReconState=[
  {key:1,label:"Draft",value:"draft"},
  {key:2,label:"Finalized",value:"finalized"}
]

const EditTransferColumns = [
  { label: 'Item Code', type: 'select', labelProp: 'itemCode', attribute: 'itemCode', required: true },
  { label: 'Item Name', type: 'select', labelProp: 'itemName', attribute: 'itemName', required: true },
  { label: 'Unit', type: 'string', attribute: 'unit', required: true },
  { label: 'Actual Offload', type: 'number', attribute: 'actualOffload', required: true },
  { label: 'Damage', type: 'number', attribute: 'damage', required: false },
  { label: 'Wastage', type: 'number', attribute: 'wastage', required: false },
  { label: 'Expired', type: 'number', attribute: 'expired', required: false },
  { label: 'Lost', type: 'number', attribute: 'lost', required: false },
  { label: 'Back To Warehouse', type: 'number', attribute: 'backToWarehouse', required: false },
  { label: 'Remarks', type: 'string', attribute: 'remarks', required: false },
];
const RecocniliationView = [
  { field: 'itemCode',headerName: 'Item Code',tooltipField:'itemCode', flex:1, suppressSizeToFit:true, resizable:true, minWidth: 230,width:250 },
  { field: 'itemName',headerName: 'Name',  flex:1,suppressSizeToFit:true, resizable:true, minWidth: 200,
    tooltipField: 'itemName',  valueGetter: (params) => {
      if (params.data&&params.data.itemName) {
         return params.data.itemName.length>21? params.data.itemName.slice(0,22)+"...": params.data.itemName;
      }
      return null;
    },
  },
  { field: 'unit', headerName: 'Unit',flex:1, suppressSizeToFit:true, resizable:true, minWidth: 135 },
  { field: 'actualOffload',headerName: 'Actual Offload',  flex:1,suppressSizeToFit:true, resizable:true, minWidth: 135 },
  { field: 'damage',headerName: 'Damage', flex:1, suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'wastage',headerName: 'Wastage',flex:1, minWidth: 175,suppressSizeToFit:true, resizable:true, },
  { field: 'expired', headerName: 'Expired',flex:1,  suppressSizeToFit:true, resizable:true,minWidth: 125 },
  { field: 'lost', headerName: 'Lost',flex:1,  suppressSizeToFit:true, resizable:true,minWidth: 125 },
  { field: 'backToWarehouse', headerName: 'Back To Warehouse',flex:1,  suppressSizeToFit:true, resizable:true,minWidth: 125 },
  { field: 'remarks', headerName: 'Remarks',flex:1,  suppressSizeToFit:true, resizable:true,minWidth: 125 },

];





const emptyFields=[{value:null}];

export const Reconciliations = (props) => {
  const { t ,ready} = useTranslation();
  const [state, setState] = useState({showLoadingApi:false});
  const navigate = useNavigate();
  const [editFormFields, setEditFormFields] = useState( null );
  const [perPageLimit, setPerPageLimit] = useState(10);
  const [searchKey, setSearchKey] = useState('');
  const [date, setDate] = useState(new Date());
  const [storesList, setStoresList] = useState([]);
  const [items, setItems] = useState([]);
  const[editSheetTitle,setEditSheetTitle]=useState(null);
  const [modalCustomButtons,setModalCustomButtons]=useState([]);
  const [warehouseArray, setWareHouseArray] = useState([]);
  const[warehouseArr,setWareHouseArr]=useState([]);
  const [reconState,setReconstate]=useState([]);
  const [reconStateSel,setReconstateSel]=useState(null);
  const [updatedFormFieldValues, setUpdatedFormFieldValues] = useState({});
  const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
  const [wareHouseSel,setWarehouseSel]=useState('');
  const [wareHouseSelSearch,setWarehouseSelSearch]=useState([]);
  const [storeSelSearch,setStoreSelSearch]=useState([]);
  const [storeSelect, setStoreSelect] = useState([]);
  const[storeArray,setStoreArray]=useState([])
  const[storeId,setStoreId]=useState(null)
  const [modalFormData, setModalFormData] = useState({});
  const [wStoreId,setWStoreId]=useState(null);
  const [gridReset,setGridReset]=useState(false);
  const tableRef = useRef();

  const { hasPermission: viewReconciliations } = usePermissions('viewDetails', "reconciliations");
  const { hasPermission: saveReconciliation } = usePermissions('save', "reconciliations");
  const { hasPermission: createReconciliation } = usePermissions('create', "reconciliations");
  const { hasPermission: editReconciliations } = usePermissions('edit', "reconciliations");
  const { hasPermission: deleteReconciliation } = usePermissions('delete', "reconciliations");



  let reconStateCreate={warehouse:"",store:"",date:""}

  const Columns = [
    { field: 'code',headerName: 'Code', flex:1,suppressSizeToFit:true, resizable:true,tooltipField: 'code', minWidth: 270},
    { field: 'warehouseCode',headerName: 'Warehouse',flex:1,suppressSizeToFit:true, resizable:true, minWidth: 150, tooltipField: 'warehouseCode' },
    { field: '', headerName: 'Sector',valueGetter: params => {
      return params.data ? `${params.data.flightNumber}-${params.data.origin}-${params.data.destination}` : '';
  }, suppressSizeToFit:true,flex:1, resizable:true, minWidth: 180, tooltipField: 'sector' },
    { field: 'operationDate',headerName: 'Operation Date', valueGetter: params => {
      return params.data?.operationDate ? moment.parseZone(formatDateByTimezone(params.data?.operationDate)).format('DD-MM-YYYY') : '';
  },  filter: true,suppressSizeToFit:true, flex:1,resizable:true, minWidth: 180 },


    { field: 'flightDate',headerName: 'Flight Date',valueGetter: params => {
      return params.data?.flightDate ? moment.parseZone(formatDateByTimezone(params.data?.flightDate)).format('DD-MM-YYYY') : '';
  },  filter: true,suppressSizeToFit:true,flex:1, resizable:true, minWidth: 170 },
    { field: 'createdBy', headerName: 'Created By', valueGetter: (params) => {
      return params.data?.createdBy?.employeeName;
    }, suppressSizeToFit:true,flex:1, resizable:true,filter: true, minWidth: 160 },
    { field: 'createdAt', headerName: 'Created At',valueGetter: params => {
      return params.data?.createdAt ? moment(params.data?.createdAt).format('DD-MM-YYYY hh:mm A') : '';
  },suppressSizeToFit:true,flex:1, resizable:true, minWidth: 180 },
    { field: 'state', headerName: 'State',valueGetter: params => {
      return params.data ?
          params.data.state[0].toUpperCase() + params.data.state.substring(1) : '';
  },suppressSizeToFit:true,flex:1, resizable:true, minWidth: 170 },
    { field: 'progress', headerName: 'Progress',valueGetter: params => {
      return params.data ?
          params.data.progress[0].toUpperCase() + params.data.progress.substring(1) : '';
  },suppressSizeToFit:true,flex:1, resizable:true, minWidth: 150, tooltipField: 'progress', tooltipComponent: doTooltipFirstLetterCaps  },
  {field: 'actions',minWidth: 180,flex:1,headerName:"Actions",pinned:'right',cellRenderer:ActionCell}
  ];

  function ActionCell(props){
    if(!props?.data) return null;
    return (
      <div className='flexRow justifyContentSpaceBetween'>
        {props?.data?.state === "finalized" && (
          <button title='View Reconciliation'>
            <img width={20} src={viewIcon} alt="" onClick={(e)=>onAction(e,{value:"View Reconciliation"},props.data)}/>
          </button>
        )}
        {props?.data?.state === "draft" && (
          <div className='flexRow justifyContentFlexStart'>
          <button title='Edit Reconciliation' className='margRight30'>
            <img width={20} src={Edit2}  alt="" onClick={(e)=>onAction(e,{value:"Edit Reconciliation"},props.data)}/>
          </button>
            <button
                            title='Submit Reconciliation'
                            onClick={(e)=>onAction(e,{value:"Submit Reconciliation"},props.data)}
                             className="grid-download-btnStyle1"
                          >
                           Submit
                          </button>

          </div>
        )}
      </div>
    )
  }



const sectorTitle=()=>{
  const {action,data}=state.selected;
  if(action==='viewReconciliation' && data){

    return(<div className='flexRow justifyContentCenter'>
      <div className='flexRow'>

      <div className='flexRow'>Reconciliation - <div className='instance-member-name'>{data.code}</div></div>

      </div>
    </div>)
  }

  else{
    return(<></>)
  }

}

useEffect(() => {
if(updateEditFormValues&&updatedFormFieldValues.flightDate){
  const {flightDate}=updatedFormFieldValues;
  const previousDate = new Date(flightDate);
  previousDate.setDate(previousDate.getDate() - 1);
  if(previousDate){
    const updatedEditFields = editFormFields.map( (field) => {
      if ( field.attribute === 'operationDate' ) {
        return {...field,
          filterDate:(date)=> date.getTime() === flightDate.getTime() || date.getTime() === previousDate.getTime(),
          minDate:previousDate,
          maxDate:flightDate,
          disabled:false
        }

      }
      return field;

  });
      setEditFormFields( _prev =>updatedEditFields);
  }
}
}, [updatedFormFieldValues])



  const AddReconciliationField = [

    {key: 1, label: 'Warehouse ', name: 'warehouseName', id: 'warehouseName', attribute: 'warehouseName', type: 'selectSearch2'
        , defaultValue: ''
        , value: ''
        , className: 'select-search-cls'
        , options: []
        , multiple: false
        , search: true
        , autoComplete: 'on'
        , autoFocus: false
        , disabled: false, placeholder: 'Select Warehouse',required:true
    },
    {key: 2, label: 'Sector ', name: 'sector', id: 'sector', attribute: 'sector', type: 'selectSearch2'
    , defaultValue: ''
    , value: ''
    , className: 'select-search-cls'
    , options: []
    , multiple: false
    , search: true
    , autoComplete: 'on'
    , autoFocus: false
    , disabled: false, placeholder: 'Select Sector',required:true
},
{ label: 'Flight Date * (in IST)', attribute: 'flightDate', type: 'date', disabled: false,width:'200px'},
  { label: 'Operation Date * (in IST)', attribute: 'operationDate',type: 'date', disabled: true ,width:'200px'},
];

  const preCompile = {
    itemType: t('selectButtons.selSector'),
    flightTime: t('selectButtons.selFlightTime'),
    reconStates: t('selectButtons.selState'),
    warehouses:t('selectButtons.selWarehouse')
  }


const updateEditFormValues = async (e, updatedFormObj,type="") => {
  setModalFormStatusObj({text:''})
  ReactDOM.flushSync( () => {
    if (e || type === "selectSearch2") {
      if(updatedFormObj.warehouseName || !updatedFormObj.warehouseName){

        const selectWarehouse = wareHouseSelSearch.find( w => w.value === updatedFormObj.warehouseName);
        const updatedEditFields = AddReconciliationField.filter( (field) => {
          if ( field.attribute === 'warehouseName' ) {
              field.value = selectWarehouse?.value;
              field.options=wareHouseSelSearch;
          }
          if ( field.attribute === 'sector' ) {
            let warehouseCode;
            const warehouse = wareHouseSelSearch.find(wh=>wh.value == updatedFormObj.warehouseName)
            let whCode = warehouse?.whCode;
            if(whCode && whCode.length > 3){
              warehouseCode = whCode.slice(0, -2)
            }else{
              warehouseCode = whCode;
            }
            // const filteredStores = storeSelSearch.filter(store=>store.dest.startsWith(warehouseCode))
            // const formatted = filteredStores.map(s=>({...s,label:s.name}))
            // console.log(storeSelSearch)
            // field.options=[...formatted];
            field.options=storeSelSearch.map(s=>({...s,label:s.name}))
        }
      return field;
       });
        setEditFormFields( _prev => {
          return [ ...updatedEditFields ] ;
      });
      }

      if(updatedFormObj.sector){
        const selectedStore = storeSelSearch.find( s => s.value === updatedFormObj.sector);
        const updatedEditFields = AddReconciliationField.filter( (field) => {
          if ( field.attribute === 'sector' ) {
              field.value = selectedStore.value;
              // field.options=storeSelSearch;
          }
          if ( field.attribute === 'warehouseName' ) {
            field.options=wareHouseSelSearch;
        }
      return field;
      });
          setEditFormFields( _prev => {
            return [ ...updatedEditFields ] ;
        });

      }

        /** Set to respective fields */

    }
})

};

  React.useEffect(() => {
    fetchStores();
    fetchWarehouse();
    fetchStates();
  }, [t]);

  React.useEffect(() => {
      fetchReconciliation();
  }, [storeId,date,reconStateSel,wStoreId]);

  React.useEffect(() => {
    if(storeId&&date&&reconStateSel&&wStoreId){
      setGridReset(true);
    }
}, [storeId,date,reconStateSel,wStoreId]);

const handleReset = () => {
  setState((_) => {
    return { ..._, resetDataSource: !_.resetDataSource };
  })
  let timerId = setTimeout(() => {
    clearTimeout(timerId);
    setState((_) => {
      return { ..._, resetDataSource: !_.resetDataSource ,selected:false ,showLoadingApi:false};
    })
  }, 1500)
}


  const onDateSelect = (e) => {
    setDate(e.target.value)
 }

 const getEffectiveDate=(dateChange)=>{
  if(!dateChange) return null
  if(dateChange!==undefined){
      let effTime = formatDateByTimezone(dateChange)
      reconStateCreate={...reconStateCreate,date:moment(updatedFormFieldValues.flightDate).format('DD-MM-YYYY')}
      return effTime;
  }
}


  const fetchStores = async () => {
    try {
       const resp = await APIEndpoint.get('stores');
    if (resp.result.length > 0) {
      setStoreArray(resp.result)
      const selSearchData=[];
      const options = [{key: 0, label: preCompile.itemType, value: ''}];
      resp.result.filter( (fieldValue, index) => {
        options.push({key: fieldValue.id, label: `${fieldValue.value}`, value: fieldValue.id});
        return fieldValue;
      });
      resp.result.filter((row,index)=> {
        selSearchData.push({ key: index+1, label: row.flightNumber, name: row.value, value: index+1,origin:row.origin,dest:row.destination });
        return row;
    });
    setStoreSelSearch(selSearchData);
      setStoresList( options.slice(1) );
    }
    else {
      console.log('Log: Error while fetching item types API');
    }
    } catch (error) {

    }

  };

const fetchWarehouse = async() => {
    try {
       const resp = await APIEndpoint.get(`warehouses`);
       if(resp.success){
        const selSearchData=[];
        setWareHouseArr(resp.result)
          const options = [{key: 0, label: preCompile.warehouses, value: ''}];
          resp.result.filter( (fieldValue, index) => {
            options.push({key: fieldValue.id, label: fieldValue.value, value: fieldValue.value});
            return fieldValue;
          });
          resp.result.filter( (row,index )=> {
            selSearchData.push({ key: index+1, label: row.value, name: `${row.value} (${row.warehouseCode})`, value: index+1,whCode:row.warehouseCode });
            return row;
        });
        setWarehouseSelSearch(selSearchData);
        setWareHouseArray(options);
   }
}
        catch (resp) {
          console.log(resp.msg)
        }
  }

  const fetchStates =  () => {
    const options = [{key: 0, label: preCompile.reconStates, value: ''},...ReconState];
    setReconstate( options );
};
const removeNullValues=(arr)=>{
 const reconArr=arr.map(obj=>{return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null))}).filter((_)=>Object.keys(_).length!==0) ;
 return reconArr;
}

  const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 10,
    page: 1,
    searchParam: ''
  };

  const fetchReconciliation = async (page = 1, perPage = 10) => {
    if (!viewReconciliations) {
      return {item:[],count:0};
    }
    let selArr = [{ state: reconStateSel }, { warehouseId: wStoreId }, { storeId: storeId }, { flightDate: getEffectiveDate(date) }];
    let latestValues = {};
    selArr.forEach(obj => {
        Object.entries(obj).forEach(([key, value]) => {
            if (value !== '') {
                latestValues[key] = value;
            }
        });
    });
    let setNewArr = Object.keys(latestValues).map(key => ({ [key]: latestValues[key] }));
    filterData.page = page;
    filterData.limit = perPage;
    filterData.searchParam = searchKey;
    filterData.filters = setNewArr;
    if (filterData.filters.length) {
        try {
            const res = await APIEndpoint.post('manualRecons/listing', JSON.stringify(filterData));

            return res;
        } catch (error) {

            console.log(error);
        }
    } else {
        return [];
    }
}

// const onContinue=async()=>{
//   // setDelConf(true);
// }
// const onCloseDelete=()=>{
//   setState((_)=>({..._,selected:false}));
//   // setDelConf(false);
// }


//      let delProps = {
//         setShow,
//         show,
//         onContinue,
//         onCloseDelete,
//           }

      const onModalFormInputChange = (e, newFormData) => {
        setModalFormStatusObj({text:''})
        if(e!==null){
           const { name, value } = e.target;

        let newUpdates = modalFormData ? { ...modalFormData } : {}
        newUpdates[name] = value;

        setModalFormData( { ...newFormData } );
        }

    }
  const onPerLimitChange = (e) => {
    setPerPageLimit(Number(e.target.value));
  };

  const onSearch = (e) => {
      setSearchKey(e.target.value);
  };

  const onStoreChange = (e) => {
      setStoreSelect([e]);
        // const storeOrigin = e.target.value.substring(e.target.value.indexOf('(')+1,e.target.value.indexOf('-'));
        // const storeDestination = e.target.value.substring(e.target.value.indexOf('-')+1,e.target.value.length-1);
        // const l= getStoreId(storeOrigin,storeDestination)

          setStoreId(e===null?"":e.value)

  }
  const onCreateStoreRecon = (str) => {
    if(storeArray.length){
      let storeTemp=storeSelSearch.find((s)=>{return str===s.value});
      let storeSelObj=storeArray.find((i)=>{return storeTemp.name===i.value});
      reconStateCreate={...reconStateCreate,store:storeSelObj.value}
      return storeSelObj?.id;
      }
}
  const getStoreId=(storeOrigin,storeDestination)=>{
    if(storeArray.length){
      let storeSelObj=storeArray.find((i)=>{return storeOrigin===i.originName && storeDestination===i.destinationName});
      return storeSelObj?.id;
      }

  }
  const getWarehouseId=(w)=>{
    if(warehouseArr.length && typeof w==='string'){
      let warehouseTemp=wareHouseSelSearch?.find((wr)=>{return wr?.label===w})
      let warehouseObj=warehouseArr?.find((i)=>{return i.value===warehouseTemp?.label})
      if(warehouseObj){
          reconStateCreate={...reconStateCreate,warehouse:warehouseObj.warehouseCode}
      setWStoreId(warehouseObj.id)
      return warehouseObj.id;
      }
      }
      if(warehouseArr.length && Number.isInteger(w)){
        let warehouseTemp=wareHouseSelSearch.find((wr)=>{return wr?.value===w})
        let warehouseObj=warehouseArr?.find((i)=>{return i.value===warehouseTemp?.label})
        if(warehouseObj){
            reconStateCreate={...reconStateCreate,warehouse:warehouseObj?.warehouseCode}
      setWStoreId(warehouseObj.id)
        return warehouseObj.id;
        }
        }
  }



  const getTransferRow = (rowData) => {
    let row = [];
    EditTransferColumns.forEach(({ attribute, readOnly, type }, i) => {
        let cellVal = { readOnly: readOnly || false, value: '' }
        if (Array.isArray(rowData)) {
            cellVal.value = rowData[i].value;
            cellVal.error = rowData[i].error;
            if (!rowData[i].updated) {
                if (attribute === 'itemCode' && rowData[0].value !== '') {
                    cellVal.value = items.find((_) => _.itemCode === rowData[0].value)?.itemCode
                    cellVal.error = false
                } else if (attribute === 'itemName' && rowData[1].value !== '') {
                    cellVal.value = items.find((_) => _.itemName === rowData[1].value)?.itemName;
                    cellVal.error = false
                }
            }
        } else {

            cellVal.value = rowData[attribute] || (type === 'number' ? 0 : '')
        }

        row.push(cellVal)
    });

    return row;
}

const handleSaveTransfer = (rows) => {
const {editBody}=state
    setModalFormStatusObj({text:''})

    state.dialogData.rows.forEach( (items, i) => {
      const matchingItemA1 = rows.find(itemA1 => itemA1[0].value === items.itemCode || itemA1[1].value === items.itemName);

      if (matchingItemA1) {
          items.index = i;
          items.actualOffload = parseInt(matchingItemA1[3].value || 0);
          items.wastage = parseInt(matchingItemA1[5].value || 0);
          items.damage = parseInt(matchingItemA1[5].value || 0);
          items.damage = parseInt(matchingItemA1[4].value || 0);
          items.lost = parseInt(matchingItemA1[7].value || 0);
          items.backToWarehouse = parseInt(matchingItemA1[8].value || 0);
      }
    });

  let body={}
  if(editSheetTitle &&editBody){
    body  = {
      flightDate: editBody.flightDate,
      operationDate: editBody.operationDate,
      items: state.dialogData.rows,
      storeId:editBody.storeId,
      warehouseId:editBody.warehouseId
  }

  }
  if(editSheetTitle &&!editBody){
    body  = {
        manualReconId:state.dialogData?.recon?.id,
        items: state.dialogData.rows,
        warehouseId:state.dialogData?.recon?.warehouseId
    }

}

  else{
      body  = {
          flightDate: editBody.flightDate,
          operationDate: editBody.operationDate,
          items: state.dialogData.rows,
          storeId:editBody.storeId,
          warehouseId:editBody.warehouseId
      }
  }

   return APIEndpoint.post("manualRecons/save", body)

}

const handleModalCancel = () => {
  const {action}=state.selected;
  console.log(action)
  setState((_) => ({ ..._, showDialog: '',editBody:null,selected:false,showSheet: false, resetDataSource:action!=='viewReconciliation'? !_.resetDataSource:_.resetDataSource  }))
}


const handleOnSaveSuccess = (res) => {
  if (state.showDialog === 'edit') {
      return handleModalCancel()
  }
  setState((_) => ({ ..._, showSheet: false, newTransfer: { ..._.newTransfer, resetDataSource: !_.resetDataSource , message: { text: res.message } } }))

  let timmerId = setTimeout(() => {
      clearTimeout(timmerId)
      return handleModalCancel()
  }, 750)
}


  const onCreateReconciliation=()=>{
    setModalFormStatusObj({text:''})
    setState( (_prev) => {
      return { ..._prev, selected: { action: 'newReconciliation'},editBody:null,newTransfer:null };
    });
    setModalCustomButtons([{label:'Add Items',attribute:'button',type:"button",disabled:false,className:'submit-button',action:'onSubmit'}])

    const updatedEditFields = AddReconciliationField.filter( (field) => {

      if (wareHouseSelSearch.length > 0 && field.attribute === 'warehouseName') {
          field.disabled = false;
          field.options = wareHouseSelSearch;
          field.getOptions = () => {
              return wareHouseSelSearch;
          }
      }
      if (storeSelSearch.length > 1 && field.attribute === 'sector') {
        field.disabled = false;
        field.options = storeSelSearch.map(s=>({...s,label:s.name}));
        field.getOptions = () => {
            return storeSelSearch;
        }
    }
      return field;
   });

   setEditFormFields( updatedEditFields );
  }
const onReconStateSel=(e)=>{
  setReconstateSel(e?.value);
}
const onWareHouseChange=(e)=>{
  setWarehouseSel(e?.value)
  getWarehouseId(e?.value)
}

const onClose = () => {
  setModalFormStatusObj({ text: '' });
 setState((_prev) => {
    return { ..._prev, selected: false}
  })
}

 const checkNullKeys=(body)=> {
  for (var key in body) {
      if (body['warehouseId'] !== null && body['storeId'] !==null)
          return false;
  }
  return true;
}
const onEditSubmit = (e, updated) => {
 let body;
 if(!updated){
  setModalFormStatusObj({ error: 'Please fill all the required fields*' });
  return false;
 }

if (!updated?.warehouseName) {
  setModalFormStatusObj({ error: 'Select a Warehouse!'});
  return false;
}
if (!updated?.sector) {
  setModalFormStatusObj({ error: 'Select a Sector!' });
  return false;
}
if (!updated?.flightDate) {
  setModalFormStatusObj({ error: 'Select  Flight date!' });
  return false;
}
if (updated.flightDate&&!updated?.operationDate) {
  setModalFormStatusObj({ error: 'Select  operation date!' });
  return false;
}

 if(Boolean(updated)){
  body={
    operationDate:getEffectiveDate(updated.operationDate),
    flightDate:getEffectiveDate(updated.flightDate),
    reconId:null,
    warehouseId:getWarehouseId(updated.warehouseName),
    storeId: onCreateStoreRecon(updated.sector)
  }

let payloadCheck=checkNullKeys(body)
if(!payloadCheck){
setState((_prev) => {
  return { ..._prev, showSheet: true,editBody:{...body,flightDate:getEffectiveDate(updated.flightDate)}}
})
navigate(`reconciliations-create/${body.operationDate}/${body.flightDate}/${body.warehouseId}/${body.storeId}/new/create`);

}
 }
}

const onSubmitReconciliation=async(e)=>{
  const {id,warehouseId}=state.selected?.data;

  try {
    setState((_prev) => {return { ..._prev,showLoadingApi:true}})
    const resp = await APIEndpoint.post(`warehouses/${warehouseId}/manualRecons/finalize/${id}`);
    if(resp){
             setModalFormStatusObj({ text: resp.message });
             handleReset();
}
    }
     catch (error) {
      setModalFormStatusObj({ text: error.resp.message });

     }
}

const onAction = async(e, action, row) => {
  console.log(action,row)
  if(action.value==='Edit Reconciliation' && editReconciliations){
    navigate(`reconciliations-create/${row.operationDate}/${row.flightDate}/${row.warehouseId}/${row.storeId}/${row.id}/editReconciliation`);


     }

     if(action.value==='Submit Reconciliation' && saveReconciliation){
      setState( (_prev) => {
        return { ..._prev, selected: { action: 'Submit Reconciliation',data:row}};
      });


       }

       if(action.value==='Delete Reconciliation' && deleteReconciliation){
        setState( (_prev) => {
          return { ..._prev, selected: { action: 'Delete Reconciliation',data:row}};
        });


         }
         if(action.value==='View Reconciliation' && viewReconciliations){
          const {warehouseId,id}=row
          try {
            const resp = await APIEndpoint.get(`warehouses/${warehouseId}/manualRecons/${id}`);
            setState( (_prev) => {
              return { ..._prev, selected: { action: 'viewReconciliation',data:resp.manualRecon||{}} };
            });
          } catch (error) {

          }


         }

   }

  return (
      <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      {
        <>
           <div className='flexRow width100 margBot10 margTop8 justifyContentSpaceBetween'>
           <div className='width100Menu justifyContentCenter flexRow'>
            <div className=" margBot10  underline">
            <span><img  className="icon-size" alt={t('filters.searchText')} src={searchIcon} /></span>
            <span><input className="search" onChange={onSearch} type="search" placeholder={t('filters.searchText')} value={searchKey}/></span>
            </div>
            </div>
        </div>

        <div className='flexRow gap10 width100 margTop8 margBot10  justifyContentFlexStart alignItemsCenter'>

            <div className="flexRow justifyContentFlexEnd alignItemsBaseline">
            <button className='saleType-div m-2'>
                  <CustomSelect
                  options={warehouseArray}
                placeHolder="Search Warehouse"
                onChange={onWareHouseChange}
                />
                  </button>
              {storeSelect ? (<div className='orderBy-div m-2 margLeft' style={{marginBottom:'0.3rem'}}>
                          <Select
                            options={storesList}
                            defaultValue={storeSelect[0]?.label ? storeSelect[0]?.label : ''}
                            value={storesList?.filter(function (option) {
                              return (
                                storeSelect.length && storeSelect[0]&&
                                storeSelect.find((sc) => sc.label === option.label)
                              );
                            })}
                            onChange={onStoreChange}
                            styles={customSelSearch}
                            placeholder="Search Sector"
                            isSearchable
                            isClearable
                            className="selectMenu-meals"
                          />
                        </div>):<></>}


                  <button className='saleType-div m-2'>

                  <CustomSelect
                  options={reconState}
                placeHolder="Search State"
                onChange={onReconStateSel}
                />

                  </button>

              <div className={`dialog__description`}>
                <NGODatePicker
                  isClearable={true}
                  value={date}
                  style={{width:'11rem',border:'1.48px solid #fff'}}
                  placeholder={t('effectiveDate')}
                  placeholdertext={t('datePickerPlaceholder')}
                  onChange={onDateSelect} />
                </div>


            </div>

           {createReconciliation ? <div className="flexRow width100 justifyContentFlexEnd">
            <button className="add-item-button" onClick={ onCreateReconciliation }>
                  {t('buttons.create')}
             </button>
             </div>:<></>}

        </div>
       <DataGrid
            className="full-flex"
            columns={[...Columns]}
            initialPageLimit={perPageLimit}
            showDefaultFilters={false}
            searchKey={searchKey}
            gridReset={gridReset}
            storeId={storeId}
            date={date}
            reconStateSel={reconStateSel}
            warehouseId={wStoreId}
            onCreateReconciliation={() => onCreateReconciliation()}
            resetDataSource={state.resetDataSource}
            getRows={fetchReconciliation}
            noResultsMessage={ 'No Data' }
            rowSelection="multiple"
            columnResize={true}
          />
        </>
      }
      {
        state && Boolean(state.selected)&& state.selected.action==='editReconciliation' && (
        <SpreadsheetModal
        showDone={false}
        isSubModal={state.showDialog === 'add'}
        columns={EditTransferColumns.map((_) => {
        return { ..._, options: items }
        })}
        getRow={getTransferRow}
        data={ state.dialogData ? state.dialogData.rows : null }
        save={handleSaveTransfer}
        onSave={handleOnSaveSuccess}
        onClose={handleModalCancel}
        title={`Edit Reconciliation - ${editSheetTitle}`}
        darkMode={true}
        />

        )
      }

{
       state && Boolean(state.selected)&& state.selected.action==='viewReconciliation' &&
        <Modal
        className='cash-deposit-summary'
        title={``}
        TitleComponent={sectorTitle}
        onSubmit={handleModalCancel}
        onClose={handleModalCancel}
        >
           <div className='flexCol' style={{ height: 'auto', width: '100%' }}>
             <DataGrid
            rowData={state.selected.data && (state.selected.data?.items ||[])}
            columnResize
            ref={tableRef}
            className='full-flex'
            showDefaultFilters={false}
            enableGridSearch={false}
            columns={RecocniliationView}
            rowModelType='clientSide'
            />

          </div>
      </Modal>
      }


      {
        state && Boolean(state.selected)&& state.selected.action==='newReconciliation'&&(
          <EditModal
            title={'New Reconciliation'}
            onClose={onClose}
            onSubmit={onEditSubmit}
            modalCustomButtons={modalCustomButtons}
            modalFormStatusObj={modalFormStatusObj}
            fields={editFormFields.map((_) => {
              return { ..._, initialValue:''}
            })}
            onChange={(e, updated) => {
              setUpdatedFormFieldValues(updated);
              if (Number.isInteger(e)) { // Search select
                  updateEditFormValues(e, updated);
              }
              if (!e) { // Search select2
                updateEditFormValues(e, updated,"selectSearch2");
            }
              else {
                  onModalFormInputChange(e, updated);
              }
          }
      }
            showFieldsInColumn={true}
          />
        )
      }



       {
                    Boolean(state?.selected) && state.selected.data && state.selected.action==='Submit Reconciliation' && (
                      <ConfirmModal
                      title={`Submit Reconciliation -${state.selected.data?.code}`}
                      description={'Submitting a reconciliation is irreversible. Make sure that everything is in order before proceeding.'}
                      onSubmit={onSubmitReconciliation}
                      showLoadingApi={state.showLoadingApi}
                      modalFormStatusObj={modalFormStatusObj}
                      fields={emptyFields}
                      onClose={onClose}
                      />
                    )
        }
        {
                  !ready &&<div className='overlay_hide sub'></div>
                }
    </div>
  )
}
