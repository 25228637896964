/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from 'react';
import { APIEndpoint } from "../../../utils/axios";
import { DataGrid, EditModal,UploadModal } from "../../../common";
import { useTranslation } from 'react-i18next';
import Edit2 from "../../../assets/edit2.svg";
import { MIMETypes } from '../../../utils/configs';
import uploadItemIcon from '../../../assets/icons/upload-items.svg';
import { translationLength ,onDownloadXlsFile, getCultureFromLs} from '../../../utils/commonFunctions';
import usePermissions from '../../../utils/userPermissions';
const Columns = [
  { field: 'warehouseName', headerName: 'Name', resizable:true, suppressSizeToFit:true,flex:1,minWidth: 185},
  { field: 'warehouseCode', headerName: 'Code', resizable:true,tooltipField:'warehouseCode',suppressSizeToFit:true,flex:1, minWidth: 185 },
  { field: 'baseStation', headerName: 'Base Station',resizable:true,  suppressSizeToFit:true,flex:1,minWidth: 145 },
  { field: 'gstIn', headerName: 'GST IN', resizable:true,tooltipField:'gstin', suppressSizeToFit:true,flex:1,minWidth: 200},
  { field: 'fssai', headerName: 'FSSAI No',  resizable:true,tooltipField:'fssai',suppressSizeToFit:true,flex:1,minWidth: 200},
  { field: 'sapCode', headerName: 'SAP Code', resizable:true,tooltipField:'sapCode', suppressSizeToFit:true,flex:1,minWidth: 200},
  { field: 'salesOfficeNumber', headerName: 'Sales Office No',resizable:true,tooltipField:'salesOfficeNumber',  suppressSizeToFit:true,flex:1,minWidth: 200 },
  { field: 'location', headerName: 'Location', resizable:true,suppressSizeToFit:true,flex:1, minWidth: 200 },
];

const FormFields = [
  { label: 'Warehouse Name', attribute: 'warehouseName', type: 'text', required: true, },
  { label: 'Warehouse Code ', attribute: 'warehouseCode', type: 'text', required: true },
  { label: 'Base Station', attribute: 'clusterId', type: 'select', required: true },
  { label: 'GST IN', attribute: 'gstIn', type: 'text', required: true },
  { label: 'State', attribute: 'stateCode', type: 'select', required: true, },
  { label: 'FSSAI License Number', attribute: 'fssai', type: 'text' },
  { label: 'SAP Code', attribute: 'sapCode', type: 'text'},
  { label: 'Sales Office Number', attribute: 'salesOfficeNumber', type: 'text' },
  { label: 'Batch Number', attribute: 'batchNumber', type: 'text' },
 { label: 'Address', attribute: 'warehouseAddress', type: 'textarea', rows: 3 },
  { label: 'Address Line 2', attribute: 'warehouseAddressLine2', type: 'textarea', rows: 3 },

]

// const States = [
//   { value: 'KA', label: 'Karnataka' }
// ]

export const WarehousePage = (props) => {
  const Fields = useRef([]);
  const clusterOptions = useRef(null);
  const { t } = useTranslation();
  const [showUpload, setShowUpload] = useState(false);
  const [ uploadedFile, setUploadedFile ] = useState([])
  const [ uploadFileStatusBlock, setUploadFileStatusBlock ] = useState('');
  const [state, setState] = useState({ isListReady: false, filterBase: '', resetDataSource: false, })
  const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });

  const { hasPermission: editWarehouses } = usePermissions('edit', "configuration.fsc.warehouses");
  const { hasPermission: createWarehouses } = usePermissions('create', "configuration.fsc.warehouses");
  const { hasPermission: uploadWarehouses } = usePermissions('upload', "configuration.fsc.warehouses");

  React.useEffect(() => {
    fetchDefinedList(true);
  }, []);

  const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 10,
    page: 1,
    searchParam: ''
  };
  const fetchWarehouse = async (iPageNo = 1, iPageLimit = 10, iSearchText = '') => {
    filterData.page = iPageNo;
    filterData.limit = iPageLimit;
    filterData.searchParam = iSearchText;
    let res = await APIEndpoint.post('warehouses/process', filterData);
    if (res.items) {
      res.items = res.items.map((_) => {
        let baseStation = clusterOptions.current.find((bs) => {
          return Number(_.clusterId) === Number(bs.value)
        });
        return {
          ..._,
          location: `${_.stateName}, (${_.country})`,
          baseStation: baseStation ? baseStation.label : ''
        }
      })
    }
    return res;
  }

  const fetchDefinedList = async (iSetState) => {
    try {
       const [clusters] = await Promise.all([APIEndpoint.get('clusters')]);
      const countryDetails = getCultureFromLs()

    if (clusters.result.length) {
      clusterOptions.current = clusters.result.map((_c) => {
        return { value: _c.id, label: _c.value }
      })
      Fields.current = FormFields.map((_) => {
        let options;
        if (_.attribute === 'clusterId') {
          options = [{ label: t('pages.pagesContent.sectors.baseStationSelectPlaceholder'), value: '' }, ...clusterOptions.current];

        } else if (_.attribute === 'stateCode' && countryDetails.states) {
          if( countryDetails.states){
            options = [{ label: t('selectState'), value: '' }, ...countryDetails.states.map(st=>({ label:st.name, value:st.code }))];
          }else{
            options = [{ label: t('selectState'), value: '' }, ...countryDetails.states.map(st=>({ label:st.name, value:st.code }))];
          }
        }
        return {
          ..._,
          options: options
        }
      })
    }
    } catch (error) {

    }

    if (iSetState) {
      setState((_) => {
        return { ..._, isListReady: true }
      })
    }
  };
  const handleModalClose = () => {
    setState((currentState) => {
      return { ...currentState, showAddForm: false, editRow: null }
    })
  }
  const onUploadClose = () => {
    setShowUpload(false);
    setUploadedFile([]);
    setUploadFileStatusBlock('');
  }

  const onDownloadSampleFile = () => {
    onDownloadXlsFile('samples/downloads/Airports_Upload_Sample','Airports_Upload_Sample')
  }

  const onUploadFileChangeHandler = async (e) => {

};

  const handleAddWarehouse = () => {
    setModalFormStatusObj({text:""})
    setState((_) => {
      return { ..._, showAddForm:createWarehouses? true:false }
    })
  }
  const handleReset = () => {
    setState((_) => {
      return { ..._, resetDataSource: true, showAddForm: false, editRow: null };
    })
    let timerId = setTimeout(() => {
      clearTimeout(timerId);
      setState((_) => {
        return { ..._, resetDataSource: false };
      })
    }, 500)
  }

  const handleWarehouseFormSubmit = async (e, values) => {
    let isValid = true,
      isEdit = Boolean(state.editRow);
    if (values != null) {
      isValid = Fields.current.every((field) => {
        if (
          field.required &&
          ((isEdit &&
            !(values[field.attribute] || state.editRow[field.attribute])) ||
            (!isEdit && !values[field.attribute]))
        ) {
          setModalFormStatusObj({ error: `Missing ${field.label}`, });
          return false;
        }
        return true;
      });
      if (isValid) {
        try {
          let res;
          if (values.clusterId) values.clusterId = Number(values.clusterId);
          if (isEdit) {
            values = { ...state.editRow, ...values };
            res = await APIEndpoint.post(
              "warehouses/update/" + state.editRow.warehouseId,
              values,
            );
          } else {
            res = await APIEndpoint.post("warehouses/new", values);
          }
          if (res && res.success) {
            setModalFormStatusObj({ text: res.msg });
            setTimeout(()=>{
              handleReset(e, true)
            },1500)
          }
        } catch (error) {
          const err = JSON.parse(error.message);
          setModalFormStatusObj({ error: err?.data?.msg});
        }
      }
    }else if(!values && isEdit){
try {
    const res = await APIEndpoint.post(
      "warehouses/update/" + state.editRow.warehouseId,
      state.editRow,
    );

  if (res && res.success) {
    setModalFormStatusObj({ text: res.msg });
    setTimeout(()=>{
      handleReset(e, true)
    },1500)
  }
} catch (error) {
  const err = JSON.parse(error.message);
  setModalFormStatusObj({ error: err?.data?.msg});
}
    }

    else if(!values && state.showAddForm){
      setModalFormStatusObj({
        error: "Please fill all mandatory field!",
      });
    }
  };

  const handleRowAction = async (e, action, row) => {
    setModalFormStatusObj({text:""})
    let editRow = null;
    const warehouse = await APIEndpoint.get('warehouses/' + row.itemId);
    await fetchDefinedList();
    if (warehouse.success) {
      editRow = warehouse.result;
      setState((currentState) => {
        return { ...currentState, editRow: editRow }
      })
    } else {
      alert('Unable to fetch Warehouse')
    }
  }


  return (
    <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
       {uploadWarehouses?  <div title={'Not Available'} className="flexRow justifyContentFlexEnd alignItemsCenter margRight margBot10">
              <span className={`upload dropdownStyle1-TextMedium disabled`} onClick={() => setShowUpload(false)}><img  className="icon-size" alt='uploadItem' src={uploadItemIcon} /><u>{t('pages.pagesContent.warehouse.uploadButton')}</u></span>
          </div>:<></>}
      {
        Boolean(state.isListReady) &&
        <>
          <DataGrid
            columnResize
            resetDataSource={state.resetDataSource}
            showDefaultFilters
            className='full-flex'
            columns={Columns}
            pageType={false}
            getRows={fetchWarehouse}
            addButton={{
              title: t('pages.pagesContent.warehouse.addButtonTitle'),
              onClick: handleAddWarehouse
            }}
            actions={[
              { detail:editWarehouses? [{ src: Edit2,title:'Edit', value: "edit" }]:[] }
            ]}
            onAction={handleRowAction}
          />
          {
          state && Boolean(state.showAddForm || state.editRow) && fetchWarehouse() &&
            <EditModal
              title={
                Boolean(state.editRow) ? t('pages.pagesContent.warehouse.editModalTitle') :
                  t('pages.pagesContent.warehouse.addModalTitle')
              }
              onClose={handleModalClose}
              showFieldsInColumn={true}
              onSubmit={handleWarehouseFormSubmit}
              buttonLabel={t('buttons.save')}
              modalFormStatusObj={modalFormStatusObj}
              fields={
                (Fields.current || []).map((_) => {
                  const isEdit = Boolean(state.editRow);
                  return { ..._, initialValue: isEdit ? state.editRow[_.attribute] : '' }
                })
              }
            />
          }
       {
        showUpload &&
        <UploadModal
          modelTitle={t('pages.pagesContent.warehouse.uploadButton')}
          onUploadClose={onUploadClose}
          onDownloadSampleFile={onDownloadSampleFile}
          uploadedFile = { uploadedFile }
          onUploadFileChangeHandler = { (e) => onUploadFileChangeHandler(e) }
          uploadFileStatusBlock = { uploadFileStatusBlock }
          allowedExtensions={ MIMETypes.excel }
        />
      }
        </>
      }
       {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }

    </div>
  )
}