/* eslint-disable react-hooks/exhaustive-deps */
import React,{useState,useEffect} from 'react';
import '../assets/styles/modal.scss'
import downloadMasterIcon from '../assets/icons/download-master.svg';
import A350 from '../assets/images/A350.png';
import B777 from '../assets/images/B777.png';
import searchIcon from '../assets/icons/search.svg';
import { useTranslation } from 'react-i18next';
import onDownloadOffloadSummary from '../pages/flights/inner-components/offload.jsx';
import { SalesDetailsView } from '../components/SalesDetailsView.jsx';
import { MenuPlannerView } from '../components/MenuPlannerView.jsx';
import PassengerInflightDetView from '../components/PassengerInflightDetView.jsx';

export const CsvModal = (props) => {
  const { type, tableData,title } = props;
  const [searchKey, setSearchKey] = useState('');
  const [seatData,setSeatData]=useState(tableData||[]);
  const [seatDataFiltered,setSeatDataFiltered]=useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    if (!searchKey) {
      setSeatDataFiltered(seatData);
    } else {
      if (searchKey && tableData) {
        setSeatDataFiltered(
          seatData.filter((seat) => {
            const { seatNumber, seatLocation,cabin } = seat;
            const searchTermLower = searchKey.toLowerCase();
            const seatNumberLower = seatNumber.toLowerCase();
            const seatLocationText = seatLocation.toLowerCase();
            const seatCabinText = cabin.toLowerCase();
  
            if (
              seatNumberLower === searchTermLower ||
              seatLocationText === searchTermLower || 
              seatCabinText === searchTermLower
            ) {
              return true;
            } else if (
              seatNumberLower.includes(searchTermLower) ||
              seatLocationText.includes(searchTermLower) ||
              seatCabinText.includes(searchTermLower)
            ) {
              return true;
            } else {
              return false;
            }
          }),
        );
      }
    }
  }, [searchKey]);

  const onSearch = (e) => {
    setSearchKey(e.target.value);
  };
  
  if(type==='csv'){
     return (
      <div>
          <table>
          <thead>
            <tr  style={{backgroundColor:'#1d3e4e',color:'#fff'}} className='sticky'>
              <th>Item Code</th>
              <th>Item Name</th>
              <th>Bob</th>
              <th>Corp.</th>
              <th>Web</th>
              <th>Others</th>
              <th>Total</th>
            </tr>
            </thead>
              <tbody>
            {
          tableData.map((_,index) => {
              return (
                <tr key={index}>
                <td>{_.itemCode}</td>
                <td>{_.itemName}</td>
                <td>{_.bob}</td>
                <td>{_.corporate}</td>
                <td>{_.webMeal}</td>
                <td>{_.others}</td>
                <td>{_.total}</td>
              </tr>
              )
              })
            }
            </tbody>
          </table>
      </div>
  )
  }
  else if (type === 'csv2') {
    return (
      <div>
          <table>
          <thead>
            <tr  style={{backgroundColor:'#1d3e4e',color:'#fff'}} className='sticky'>
              <th>Item Code</th>
              <th>Item Name</th>
              <th>Reason</th>
              <th>Quantity</th>
              <th>Unit</th>
              <th>Remarks</th>
            </tr>
            </thead>
              <tbody>
            {
            tableData.map((_) => {
              return (
                <tr  key={_.itemId}>
                <td>{_.itemCode}</td>
                <td>{_.itemName}</td>
                <td>{_.reason}</td>
                <td>{_.quantity}</td>
                <td>{_.unit}</td>
                <td>{_.remarks}</td>
              </tr>
              )
              })
            }
            </tbody>
          </table>
      </div>
  )
  }
  else if (type === 'csv77') {
    return (
      <div>
          <table>
          <thead>
            <tr  style={{backgroundColor:'#1d3e4e',color:'#fff'}} className='sticky'>
              <th>Warehouse</th>
              <th>Invoice Id</th>
              <th>Caterer</th>

              <th>Age</th>
              <th>Status</th>
            </tr>
            </thead>
              <tbody>
            {
            tableData.map((_) => {
              return (
                <tr key={_.id}>
                <td>{_.warehouseName}</td>
                <td>{_.invoiceId}</td>
                <td>{_.catererGroup}</td>
                <td>{_.age}</td>
                <td>{_.status}</td>
              
              </tr>
              )
              })
            }
            </tbody>
          </table>
      </div>
  )
  }
  else if (type === 'viewPurchaseOrderItems' || props.type === 'viewLetterOfIntentItems') {
    return (
      <div>
        <table>
          <thead>
            <tr  style={{backgroundColor:'#1d3e4e',color:'#fff'}} className='sticky'>
              <th>Item Code</th>
              <th>Item Name</th>
              <th>Requested Qty.</th>
              <th>Received Qty.</th>
              <th>Unit</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
          {
            tableData.map( (item) => {
              return (
                <tr key={item.itemId}>
                  <td>{item.itemCode}</td>
                  <td>{item.itemName}</td>
                  <td>{item.requestedQuantity}</td>
                  <td>{item.receivedQuantity}</td>
                  <td>{item.unit}</td>
                  <td>{item.remarks}</td>
                </tr>
              )
            })
          }
          </tbody>
        </table>
      </div>
    );
  }
  else if (type === 'viewGRNDetails') {
    return (
      <div>
       <table>
  <thead>
    <tr  style={{backgroundColor:'#1d3e4e',color:'#fff'}} className='sticky'>
      <th>Item Code</th>
      <th>Item Name</th>
      <th>Requested Qty</th>
      <th>GRN Qty</th>
      <th>Reverse GRN</th>
      <th>Remarks</th>
    </tr>
  </thead>
  <tbody>
  {
    tableData.map( (item, idx) => {
      return (
        <tr key={item.itemId}>
          <td>{item.itemCode}</td>
          <td>{item.itemName}</td>
          <td>{item.requestedQuantity??""}</td>
          <td>{item.receivedQuantity??""}</td>
          <td>{item.reverseGrn??""}</td>
          <td>{item.remarks}</td>
        </tr>
      )
    })
  }
  </tbody>
</table>
      </div>
    );
  }
  else if (type === 'viewReverseGRNDetails') {
    return (
      <div>
        <table>
          <thead>
            <tr  style={{backgroundColor:'#1d3e4e',color:'#fff'}} className='sticky'>
              <th>Item Code</th>
              <th>Item Name</th>
              <th>Reverse GRN</th>
              <th>Mode of Return.</th>
              <th>Reason</th>
            </tr>
          </thead>
          <tbody>
          {
            tableData.map( (item) => {
              return (
                <tr key={item.itemId}>
                  <td>{item.itemCode}</td>
                  <td>{item.itemName}</td>
                  <td>{item.returnedQuantity}</td>
                  <td>{item.modeOfReturn}</td>
                  <td>{item.reason}</td>
                </tr>
              )
            })
          }
          </tbody>
        </table>
      </div>
    );
  }
  else if (type === 'viewWarehouseTransferItems') {
    return (
      <div>
        <table>
          <thead>
            <tr  style={{backgroundColor:'#1d3e4e',color:'#fff'}} className='sticky'>
              <th>Item Code</th>
              <th>Item Name</th>
              <th>Sent Quantity</th>
              <th>In Transit</th>
              <th>Received Quantity</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
          {
            tableData.map( (item) => {
              return (
                <tr key={item.itemId}>
                  <td>{item.itemCode}</td>
                  <td>{item.itemName}</td>
                  <td>{item.quantity}</td>
                  <td>{item.inTransitQuantity}</td>
                  <td>{item.receivedQuantity}</td>
                  <td>{item.remarks}</td>
                </tr>
              )
            })
          }
          </tbody>
        </table>
      </div>
    );
  }
  else if (type === 'viewWarehouseTransferGRNDetails') {
    return (
      <div>
        <table>
          <thead>
            <tr  style={{backgroundColor:'#1d3e4e',color:'#fff'}} className='sticky'>
              <th>Item Code</th>
              <th>Item Name</th>
              <th>Requested Qty</th>
              <th>GRN Qty</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
          {
            tableData.map( (item, idx) => {
              return (
                <tr key={item.itemId}>
                  <td>{item.itemCode}</td>
                  <td>{item.itemName}</td>
                  <td>{item.requestedQuantity || ''}</td>
                  <td>{item.receivedQuantity || ''}</td>
                  <td>{item.remarks}</td>
                </tr>
              )
            })
          }
          </tbody>
        </table>
      </div>
    );
  }
  else if (type === 'viewWarehouseTransferItems') {
    return (
      <div>
        <table>
          <thead>
          <tr  style={{backgroundColor:'#1d3e4e',color:'#fff'}} className='sticky'>
            <th>Item Code</th>
            <th>Item Name</th>
            <th>Sent Quantity</th>
            <th>In Transit</th>
            <th>Received Quantity</th>
            <th>Remarks</th>
          </tr>
          </thead>
          <tbody>
          {
            tableData.map( (item) => {
              return (
                <tr key={item.id}>
                  <td>{item.itemCode}</td>
                  <td>{item.itemName}</td>
                  <td>{item.quantity}</td>
                  <td>{item.inTransitQuantity}</td>
                  <td>{item.receivedQuantity}</td>
                  <td>{item.remarks}</td>
                </tr>
              )
            })
          }
          </tbody>
        </table>
      </div>
    );
  }
  else if (type === 'offloadSummary') {
    return (
      <div>
        <table>
          <thead>
          <tr  style={{backgroundColor:'#1d3e4e',color:'#fff'}} className='sticky'>
            <th>Item Code</th>
            <th>Item Name</th>
            <th>Consumption</th>
            <th>Wastage</th>
            <th>Closing</th>
          </tr>
          </thead>
          <tbody>
          {
            props.tableData[1].map( (item) => {
              return (
                <tr key={item.id}>
                  <td>{item.itemCode}</td>
                  <td>{item.itemName}</td>
                  <td>{item.consumption}</td>
                  <td>{item.wastage}</td>
                  <td>0</td>
                </tr>
              )
            })
          }
            </tbody>
        </table>
        <div class="col-md-4 col-xs-12">
            <h2 className="my-4 subheading-text-medium fontMedium">{props.tableData[0]}</h2>
            <button id="offload-summary-card" type="button" onClick={onDownloadOffloadSummary} class="btn btn-info" style={{ color: "white" }}>
              <span><img className="icon-size" alt='downloadMaster' src={downloadMasterIcon} /></span>
              Download
            </button>
        </div>
      </div>
    )
  }
  else if (type === 'csv5') {
    return (
      <div>
        <table>
          <thead>
          <tr  style={{backgroundColor:'#1d3e4e',color:'#fff'}} className='sticky'>
            <th>Item Code</th>
            <th>Item Name</th>
            <th>Percentage</th>
          </tr>
          </thead>
          <tbody>
          {
            props.tableData.map( (item,index) => {
              return (
                <tr key={index}>
                  <td>{item.itemCode}</td>
                  <td>{item.itemName}</td>
                  <td>{item.percentage}%</td>
                </tr>
              )
            })
          }
            </tbody>
        </table>
      </div>
    )
  }
  else if (type === 'viewSalesDetails') {
    return (<SalesDetailsView { ...props } />);
  }
  else if (type === 'viewPassengerDetails') {
    return (<PassengerInflightDetView { ...props } />);
  }
  else if (type === 'viewMenuPlanner') {
    return (<MenuPlannerView { ...props } />);
  }
  else if (type === 'csv3') {
    return (
      <div>
         { tableData?.length>0?<table>
          <thead>
            <tr  style={{backgroundColor:'#1d3e4e',color:'#fff'}} className='sticky'>
              <th>Item Code</th>
              <th>Item Name</th>
              <th>Unit</th>
              <th>Actual Offload</th>
              <th>Damage</th>
              <th>Expired</th>
              <th>Lost</th>
              <th>Back To Warehouse</th>
              <th>Remarks</th>

            </tr>
            </thead>
            <tbody>
            {
            tableData.map((_) => {
              return (
                <tr key={_.id}>
                <td>{_.itemCode}</td>
                <td>{_.itemName}</td>
                <td>{_.unit}</td>
                <td>{_.actualOffload}</td>
                <td>{_.damage}</td>
                <td>{_.expired}</td>
                <td>{_.lost}</td>
                <td>{_.backToWarehouse}</td>
                <td>{_.remarks}</td>
              </tr>

              )
              })
           }
           </tbody>
          </table>:<div className="dialog__body_container font-weight-margin flexCol alignItemsCenter justifyContentCenter flexWrap">
          No items found
      </div>}

      </div>
  )

  }
  else if (type === 'csv4') {
    return (
      <div>
        {tableData && tableData.length > 0 ? (<>
      {title&&title.includes('A350')?<img src={A350} style={{width:'100%',marginBottom:'0.5rem'}} alt="aircraft" />:title&&title.includes('777')?<img src={B777} style={{width:'100%',marginBottom:'0.5rem'}} alt="aircraftB777" />:<></>}
          <div className='flexRow width100 justifyContentCenter margBtm16 margTop8 alignItemsCenter'>
        <div className="underline">
        <span><img className="icon-size" alt={t('filters.searchText')} src={searchIcon} /></span>
        <span><input className="search-layout" onChange={onSearch} type="search" placeholder={t('filters.searchText')} value={searchKey} /></span>
        </div>
        </div>
          <table>
            <thead>
              <tr  style={{backgroundColor:'#1d3e4e',color:'#fff'}} className='sticky'>
                <th style={{width:"20%"}}>Seat Number</th>
                <th>Row</th>
                <th style={{width:"20%"}}>Column</th>
                <th style={{width:"20%"}}>Location</th>
                <th style={{width:"20%"}}>Cabin</th>
              </tr>
            </thead>
            <tbody>
              {seatDataFiltered.map((_) => (
                <tr key={_._id}>
                  <td>{_.seatNumber}</td>
                  <td>{_.row}</td>
                  <td>{`${_.col.toString()}(${_.colId})`}</td>
                  <td>{_.seatLocation}</td>
                  <td>{_.cabin.toUpperCase()}</td>
                </tr>
              ))}
              {seatDataFiltered.length === 0 && (
                <tr>
                  <td colSpan={4} style={{textAlign:"center"}}>Nothing Found!</td>
                </tr>
              )}
            </tbody>
          </table>
        </>
         
        ) : (
          <div className="dialog__body_container font-weight-margin flexCol alignItemsCenter justifyContentCenter flexWrap">
            No items found
          </div>
        )}
      </div>
    );


  }
  else {
    return (
      <div>
        No data to list
      </div>
    )
  }

}