import React, { useRef, useState ,useMemo} from 'react';
import { APIEndpoint, DFPAPIEndpoint } from "../../../utils/axios";
import { DataGrid, EditModal, Loading, NGODatePicker } from "../../../common";
import {formatDateByTimezone, translationLength} from '../../../utils/commonFunctions';
import usePermissions from '../../../utils/userPermissions';
import { useTranslation } from 'react-i18next';
import moment from "moment";
import CustomSelect from '../../../common/CustomSelect';
import viewIcon from "../../../assets/view.svg";
import downloadMasterIcon from "../../../assets/icons/download-master.svg"
import closeIcon from "../../../images/icons/Icon2.png"
import Select from 'react-select';
import searchIcon from "../../../assets/icons/search.svg";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoMdArrowDropdown } from "react-icons/io";

const DATE_FORMAT = "DD-MM-YYYY"; // hh:mm A

let SummaryId = 'id';

const viewInventoryColumns = [
  {field:"itemName",headerName:"Item Name",flex:1},
  {field:"itemCode",headerName:"Item Code",flex:1},
  {field:"consumption",headerName:"Consumption",flex:1},
  {field:"wastage",headerName:"Wastage",flex:1},
  {field:"carryForward",headerName:"Closing",flex:1},
]


// const EditFormFields = [
//   {label: 'Csv', attribute: 'inventorySummary', type: 'offloadSummary', disabled: false, options: [], value: "" },
// ];

export default function onDownloadOffloadSummary() {
  downloadApi()
}

const downloadApi = async () => {
  let header={
    'Accept':'application/pdf'
  }
  try {
  let request_payload = {
    id: SummaryId
  }
  const res= await APIEndpoint.post(`downloads/offloadSummary`,request_payload,header);
  if(res.status==='scheduled'){
    setTimeout(() => {
      const dowId = APIEndpoint.get(`downloads/status/${res.id}`);
      dowId.then((val) => {
        if (val.status === "completed") {
          APIEndpoint.get(`downloads/analytics/${val.fileName}${val.fileExtension}`)
                .then((ItemMasterDwnld) => {
                    const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', val.fileName + '.csv');
                    document.body.appendChild(link);
                    link.click();

                })
                .catch((error) => {

                    console.error('Error downloading CSV:', error);

                });
        }
    });
      }, 8000);
  }
  }
    catch (error) {
      let {data}=JSON.parse(error?.message);
      console.log("Error in download",data)
    }
}

export const OffloadSummaryPage = (props) => {
  const routeListOptions = useRef(null);
  const { t } = useTranslation();
  const [state, setState] = useState({ isListReady: false, filterPrice: '', resetDataSource: false,showDownloadReport:false,selected:null })
  const [storesList, setStoresList] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false)
  const [perPageLimit, ] = useState(10);
  const [reportDownloadErr, setReportDownloadErr] = useState(null)
  const [searchKey, setSearchKey] = useState('');
  const [reportFilter, setReportFilter] = useState({
    startDate:new Date(),
    endDate:new Date(),
    reportType:"",
    storeId:""
  })
  const tableRef = useRef();
  const sType = localStorage.getItem('sType');
  const DOMAIN = (sType === 'dfp' ? DFPAPIEndpoint : APIEndpoint);

  const { hasPermission: viewOfldSummary } = usePermissions('viewDetails', 'offload-summary');

  React.useEffect(() => {
    fetchSectorList();
  }, []);

  const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 10,
    page: 1,
    searchParam: ''
  };

  const Columns = [
    {
      field: 'tailNumber',
      headerName: 'Sector',
      flex:1,
      minWidth: 150,
      valueGetter: (params) => {
        return params.data
          ? params.data.sector.origin + "-" + params.data.sector.destination +"-"+ params.data.sector.flightNumber
          : "";
      },
    },
    { field: 'tailNumber',headerName: 'Tail Number',flex:1, minWidth: 130 },
    { field: 'flightDate',
      headerName: 'Flight Date',
      flex:1,
      minWidth: 100,
      valueGetter: (params) => {
        return params.data?.flightDate
          ? moment.parseZone(formatDateByTimezone(params.data?.flightDate)).format(DATE_FORMAT)
          : "";
      },
    },
    {
      field: 'offloadedAt',
      headerName: 'Offloaded At',
      flex:1,
      minWidth: 150,
      valueGetter: (params) => {
        return params.data?.offloadedAt
          ? moment(params.data?.offloadedAt).format("DD-MM-YYYY hh:mm A")
          : "";
      },
    },
    { field: 'offloadedBy',headerName: 'Offloaded By',flex:1, minWidth: 155 },
    { field: 'paymentSummary', headerName: 'Payment',flex:1, minWidth: 250 },
    {field:"actions",headerName:"Actions",flex:1,minWidth:150,cellRenderer:ActionCell}
  ];

  function ActionCell(params){
    if(params?.data){
      return (
<span
title={"View Items"}
onClick={()=>setState(s=>({...s,selected:params?.data}))}
>
<img className="aircraft-actions" alt="view" src={viewIcon} />
</span>
      )
    }else{
      return null
    }

  }


  const fetchOffload = async (iPageNo = 1, iPageLimit = 10) => {
    filterData.page = iPageNo;
    filterData.limit = iPageLimit;
    filterData.searchParam = searchKey;

    if (!viewOfldSummary) {
      return {item:[],count:0};
    }

    if (state.filterDate || state.storeId) {
      filterData.filters = [];

      if (state.filterDate) {
        let flightDate = formatDateByTimezone(state.filterDate)
        filterData.filters.push({ flightDate: flightDate });
      }

      if (state.storeId) {
        filterData.filters.push({ storeId: state.storeId });
      }
    }
    const res = await APIEndpoint.post('offloadSummary/get', JSON.stringify(filterData));
    // res.count = res.items.length
      return res;

  }

  const fetchSectorList = async () => {
    const routeList = await APIEndpoint.get('stores');
    if (routeList.result.length) {
      const options = [{key: 0, label: 'Select Sector', value: ''}];
       routeList.result.map((_pl,index) => {
        options.push({key: index+1,value: _pl.id, label: _pl.flightNumber+' ('+_pl.originName+' - '+_pl.destinationName+') ' });
        return _pl;
      })
      setStoresList( options );
    }
    setState((_) => {
      return { ..._, isListReady: true }
    })
  };

  const handleSelectRouteList = (data) => {
    setState((_) => {
      return { ..._, storeId: data?.value, resetDataSource: !state.resetDataSource, showAddForm: false, editRow: null }
    });
  }

  const handleFilterDateChange = (e) => {
    setState((_) => ({ ..._, filterDate: e.target.value, resetDataSource: !state.resetDataSource }));
  }

  const onSearch = (e) => {
    setSearchKey(e.target.value);
};

  // const handleRowAction = async (e, action, selectedRowData) => {
  //   SummaryId = selectedRowData.summaryId
  //   setState( (_prev) => {
  //     return { ..._prev, editRow: selectedRowData, selected: {...selectedRowData, action: action.value }}
  //   });
  // }

  // const onClose = () => {
  //   setState((_prev) => {
  //     return { ..._prev, editRow: false }
  //   });
  // }

  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#0C5061" : "#fff",
      backgroundColor: state.isSelected ? "black" : "#162E3E",
    }),
    input: (base, state) => ({
      ...base,
      '[type="text"]': {
        fontSize: 13,
        fontWeight: 900,
        color: 'black',
      }
    }),
    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "white",
      padding: "3px",
      width: '15rem',
      height: "2.5rem",
      border: "0.4px solid #FFFFFF",
      boxShadow: "none",
      fontColor: 'black',
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: 'black' }),
  };

  function onUploadClose(){
    setState(prev=>({...prev,showDownloadReport:false}))
  }

  function onDateChangeRep(e){
    const {name,value} = e.target
    if(name === "startDate"){
      setReportFilter(prev=>({...prev,startDate:value}))
    }else{
      setReportFilter(prev=>({...prev,endDate:value}))
    }
  }

  function clearErr(){
    setTimeout(() => {
        setReportDownloadErr(null)
    }, 3000);
  }

  async function onDownloadReport(){
    const body = {
      startDate: reportFilter.startDate,
      endDate: reportFilter.endDate,
      reportType: reportFilter.reportType,
    };
    if(reportFilter.storeId){
      body.storeId = reportFilter.storeId
    }
    if(!body.reportType){
      setReportDownloadErr("Select report type!")
      clearErr()
      return;
    }
    let header = {
      Accept: "text/csv",
    };
    try {
      setIsDownloading(true)
      const res = await DOMAIN.post(`sales/reports`, body, header);
      if(res.status === "scheduled"){
        setTimeout(async () => {
          try {
            const dowId = await DOMAIN.get(`downloads/status/${res.id}`);
            const ItemMasterDwnld = await DOMAIN.get(
              `downloads/analytics/${dowId.fileName}${dowId.fileExtension}`,
            );
            setIsDownloading(false)
            const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download",`${dowId.fileName}${dowId.fileExtension}`);
            document.body.appendChild(link);
            link.click();
          } catch (error) {
            setIsDownloading(false)
          }
        }, 5000);
      }
    } catch (error) {
      const err = JSON.parse(error.message);
      setReportDownloadErr(err.data?.message)
      setIsDownloading(false)
      clearErr()
    }
  }

  async function onOwnloadItemsHandler(type) {
    const header = {
      'Accept':type==="csv"?'text/csv':"application/pdf"
    }
    const body = {
      id:state.selected.summaryId
    }
    try {
      setIsDownloading(true)
      const res = await APIEndpoint.post("downloads/offloadSummary",body,header)
      if(res.status === "scheduled"){
        setTimeout(async () => {
          const fileInfo = await APIEndpoint.get(`downloads/status/${res.id}`)
          const ItemMasterDwnld = await APIEndpoint.get(`downloads/analytics/${fileInfo.fileName}${fileInfo.fileExtension}`)
          const blob = new Blob([ItemMasterDwnld]);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileInfo.fileName}${fileInfo.fileExtension}`);
          document.body.appendChild(link);
          link.click();
          setIsDownloading(false)
        }, 5000);
      }
    } catch (error) {
      setIsDownloading(false)
    }
  }

  return (
    <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      {
        Boolean(state.isListReady) ?
        <>
          <div className="flexRow justifyContentFlexEnd alignItemsCenter margRight margBot10">
          <button className='download dropdownStyle1-TextMedium' onClick={()=>setState(prev=>({...prev,showDownloadReport:true}))}>
                <span>
                  <img className="icon-size mt-1" alt='downloadMaster' src={downloadMasterIcon} />
                  <u>Download Reports</u>
                  </span>
              </button>
          </div>

              <div className='flexRow width100 justifyContentFlexStart alignItemsCenter margBot10'>
                <div className='orderBy-div m-2 margLeft margBottom9'>
                <CustomSelect
                  options={storesList.map((it) => ({
                  value: it.value,
                  label: it.label,
                }))}
                placeHolder="Search Sector"
                onChange={handleSelectRouteList}
                />
                </div>

                <div >
                  <NGODatePicker
                    value={state.filterDate}
                    name='filterDate'
                    onChange={handleFilterDateChange}
                    isClearable
                  />
                </div>

                <div className="margLeft margBot10 margTop8  underline">
              <span>
                <img
                  className="icon-size"
                  alt={t("filters.searchText")}
                  src={searchIcon}
                />
              </span>
              <span>
                <input
                  className="search"
                  onChange={onSearch}
                  type="search"
                  placeholder={t("filters.searchText")}
                  value={searchKey}
                />
              </span>
            </div>
              </div>

          <DataGrid
            ref={tableRef}
            className="full-flex"
            columns={[...Columns]}
            showDefaultFilters={false}
            initialPageLimit={perPageLimit}
            searchKey={searchKey}
            getRows={fetchOffload}
            rowSelection="multiple"
            columnResize
            resetDataSource={state.resetDataSource}
          />
        </>
      :<Loading/>}
      {
        state.showDownloadReport && (
          <div className="overlay mt-4" role="dialog">
            <div className="dialog sm-scrollbar">
              <div className="dialog__content">
                <img
                  className="modal__close_icon"
                  alt="Close"
                  src={closeIcon}
                  onClick={onUploadClose}
                />
                <h2 className="dialog__title subheading-text-medium fontMedium">
                  Download Reports
                </h2>
                <hr />

                <div className="dialog__body_container flexRow mt-4 justifyContentCenter flexWrap">
                  <div className={`dialog__container_item input-container`}>
                    <div className='flexCol'>
                      <label htmlFor="reportType" className="req">Report type</label>
                      <select onChange={(e)=>setReportFilter(prev=>({...prev,reportType:e.target.value}))} name="" id="" className='select-input-galley1'>
                        <option value="">Select Report type</option>
                        <option value="offloadSummaryAggregate">Offload Summary Aggregate</option>
                      </select>
                    </div>
                    <div style={{marginBottom:"0.9rem"}} className='flexCol'>
                      <label style={{marginBottom:"0.9rem"}} htmlFor="reportType">Select Route</label>
                      <Select
                       isClearable
                       isSearchable
                       options={storesList}
                       placeholder="Select Route"
                       styles={{
                        placeholder:(styles,state) => ({ ...styles, textAlign: "left",color:  state.isFocused ? '#6e7c84' : '#fff' }),
                        indicatorSeparator: (provided) => ({ ...provided,display: 'none'}),
                        singleValue: (styles) => ({ ...styles,textAlign:"left",color:"#fff" }),
                        input: (styles) => ({ ...styles,textAlign:"left",color:"#fff" }),
                       }}
                       onChange={data=>setReportFilter(prev=>({...prev,storeId:`${data?.value}`}))}
                      />
                    </div>
                    <div className='flexCol'>
                      <label htmlFor="startDate" className="req">Start Date</label>
                      <NGODatePicker
                        style={{width: '17rem'}}
                        value={reportFilter.startDate}
                        name="startDate"
                        isclearable={true}
                        placeholder={t('Start Date')}
                        placeholdertext={t('datePickerPlaceholder')}
                        onChange={onDateChangeRep}
                        maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                      />
                    </div>
                    <div className='flexCol'>
                      <label htmlFor="startDate" className="req">End Date</label>
                      <NGODatePicker
                        style={{width: '17rem'}}
                        value={reportFilter.endDate}
                        name="endDate"
                        isclearable={true}
                        placeholder={t('End Date')}
                        placeholdertext={t('datePickerPlaceholder')}
                        onChange={onDateChangeRep}
                        maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                      />
                    </div>
                    <div>
                    <button
                      type="button"
                      onClick={onDownloadReport}
                      className="btn btn-info mt-2"
                      style={{ color: "white", width: "100%" }}
                    >
                      <span>
                        <img
                          className="icon-size mt-1"
                          alt="downloadMaster"
                          src={downloadMasterIcon}
                        />
                      </span>
                      {isDownloading ? "Downloading...":"Download"}
                    </button>

                    </div>
                    {reportDownloadErr && (
                      <div className='mt-4'>
                        <p className='listErrorMessagesCls'>
                          {reportDownloadErr}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

        )
      }
      {state.selected && (
        <EditModal
         showDone={false}
         title={`Offload Summary - ${state.selected.sector?.origin}-${state.selected.sector?.destination}-${state.selected.sector?.flightNumber}`}
         onClose={()=>setState(p=>({...p,selected:null}))}>
          <div className="flexCol width100" style={{height: state.selected?.inventorySummary?.length > 4 ? '55vh' : 'auto'}}>
            <DataGrid
              className="full-flex width100"
              rowData={[...state.selected.inventorySummary]}
              columns={[...viewInventoryColumns]}
              showDefaultFilters={false}
              rowSelection="multiple"
              rowModelType="clientSide"
            />

            <div className='flexRow justifyContentSpaceBetween alignItemsCenter'>

                <button disabled={isDownloading} onClick={()=>onOwnloadItemsHandler("csv")} className='btn btn-info mt-2 flexRow'>
              {isDownloading ? (
                <div className="flexRow alignItemsCenter">
                  <span>Downloading&nbsp;</span>
                  <AiOutlineLoading3Quarters className="spin"/>
                </div>
              ):"Download"}
              <div className='dropDownBtnArrowWrapper'>
                <IoMdArrowDropdown/>
                <ul>
                  <li onClick={(e)=>{
                    e.stopPropagation()
                    onOwnloadItemsHandler("pdf")
                  }}>Download as pdf</li>
                  <li onClick={(e)=>{
                    e.stopPropagation()
                    onOwnloadItemsHandler("csv")
                  }}>Download as csv</li>
                </ul>
              </div>
            </button>

            <div className='paymentSummeryText'>
              {state.selected.paymentSummary}
            </div>
            </div>

          </div>
        </EditModal>
      )}
      {
        t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
      }
    </div>
  )
}
