import React, {useState } from 'react';
import { FilterBodyTemplate } from "../../../utils/configs";
import { APIEndpoint } from "../../../utils/axios";
import { DataGrid, UploadButton, EditModal, UploadModal } from "../../../common";
import { onDownloadXlsFile,translationLength } from '../../../utils/commonFunctions';
import { useTranslation } from 'react-i18next';
import searchIcon from '../../../assets/icons/search.svg';
import Edit2 from "../../../assets/edit2.svg";
import { MIMETypes } from '../../../utils/configs';
import LoadingGIF from '../../../assets/loadingIn.gif';
import downloadMasterIcon from '../../../assets/icons/download-master.svg';
import usePermissions from '../../../utils/userPermissions';
const Columns = [
  { field: 'itemCode', headerName: 'Item Code', flex:1,suppressSizeToFit:true, resizable:true,minWidth: 235 },
  { field: 'itemName', headerName: 'Name',flex:1,suppressSizeToFit:true, resizable:true, minWidth: 255,tooltipField: 'itemName',valueGetter: (params) => {
    if (params.data&&params.data.itemName) {
       return params.data.itemName.length>21? params.data.itemName.slice(0,22)+"...": params.data.itemName;
    }
    return null;
  },  },
  // { field: 'perFlightQuantity', headerName: 'Per Flight',flex:1,suppressSizeToFit:true, resizable:true, minWidth: 125 },
  { field: 'singleSector', headerName: 'Single Sector',flex:1,suppressSizeToFit:true, resizable:true, minWidth: 125 },
  { field: 'doubleSector', headerName: 'Double Sector',flex:1,suppressSizeToFit:true, resizable:true, minWidth: 125 },
  { field: 'tripleSector', headerName: 'Triple Sector',flex:1,suppressSizeToFit:true, resizable:true, minWidth: 125 },
  { field: 'multiSector', headerName: 'Multi Sector', flex:1,suppressSizeToFit:true, resizable:true, minWidth: 125 }
];
const FormFields = [
  { label: 'Item Code', attribute: 'itemCode', type: 'text', required: true, disabled: true },
  { label: 'Item Name', attribute: 'itemName', type: 'text', disabled: true },
  { label: 'Per Flight', attribute: 'perFlightQuantity', type: 'text' },
  { label: 'Single Sector', attribute: 'singleSector', type: 'text' },
  { label: 'Double Sector', attribute: 'doubleSector', type: 'text' },
  { label: 'Triple Sector', attribute: 'tripleSector', type: 'text' },
  { label: 'Multi Sector', attribute: 'multiSector', type: 'text' },
]

export const StandardItemsPage = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({ resetDataSource: false, editRow: null });
  const [errorObj, setErrorObj] = useState(null)
  const [isDownloading, setIsDownloading] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [ uploadedFile, setUploadedFile ] = useState([])
  const [ uploadFileStatusBlock, setUploadFileStatusBlock ] = useState('');
  const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });

  const { hasPermission: editStdItem } = usePermissions('edit', "configuration.standard-items");
  const { hasPermission: uploadStdItem } = usePermissions('upload', "configuration.standard-items");


  const fetchStandardItems = async (iPageNo = 1, iPageLimit = 10, iSearchText = '') => {
    const filterData = JSON.parse(JSON.stringify(FilterBodyTemplate));
    filterData.page = iPageNo;
    filterData.limit = iPageLimit;
    filterData.searchParam = iSearchText;
    const res = await APIEndpoint.post('estimation/standarditems/process', filterData);
    if (res.items) {
      res.items = res.items.map((_) => {
        return {
          ..._,
          percentageText: `${_.percentage}%`
        }
      })
    }
    return res;
  }
  const handleAction = async (e, action, selectedRowData) => {
    setState((_) => {
      return { ..._, editRow: selectedRowData }
    })
  };

  const onSearch = (e) => {
    setSearchKey(e.target.value);
};


  const handleReset = (e, reset) => {
    setState((_) => {
      return { ..._, resetDataSource: reset ? true : state.resetDataSource, editRow: null };
    })
    setModalFormStatusObj({text:""})
    if (reset) {
      let timerId = setTimeout(() => {
        clearTimeout(timerId);
        setState((_) => {
          return { ..._, resetDataSource: false };
        })
      }, 500)
    }
  }
  const handleFormSubmit = async (e, values) => {

      try {
        values = { ...state.editRow, ...values };
        const res = await APIEndpoint.post('estimation/standarditems/update/' + state.editRow.itemCode, values);
        if (res.success) {
          setModalFormStatusObj({text:res.msg})
          setTimeout(()=>{
            handleReset(null, true)
          },1500)
        }
      } catch (error) {
        // alert('Error While updating Standard Item')
        let {data}=JSON.parse(error?.message);
        setModalFormStatusObj({ error: data?.msg });
      }

  }
  const onDownloadSampleFile = async (e) => {
    onDownloadXlsFile('samples/downloads/Standard_Items_Upload_Sample', 'Standard_Items_Upload_Sample')
  }
  const onDownloadStandardItems=async()=>{
    let header={
      'Accept':'text/csv'
    }
        // try {
        // setIsDownloading(true)
        // const res= await APIEndpoint.post(`employees/activeUsers`,{},header);
        // if(res.status==='scheduled'){

        //   setTimeout(() => {
        //     setIsDownloading(true)
        //     const dowId= APIEndpoint.get(`downloads/status/${res.id}`);
        //     dowId.then((val) => {
        //       if (val.status === "completed") {
        //           APIEndpoint.get(`downloads/analytics/${val.fileName}${val.fileExtension}`)
        //               .then((ItemMasterDwnld) => {
        //                   const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
        //                   const link = document.createElement('a');
        //                   link.href = url;
        //                   link.setAttribute('download', 'Active_Users.csv');
        //                   document.body.appendChild(link);
        //                   link.click();
        //                   setIsDownloading(false);
        //               })
        //               .catch((error) => {

        //                   console.error('Error downloading CSV:', error);
        //                   setIsDownloading(false);
        //               });
        //       }
        //   });
        //     }, 8000);
        // }

        // }
        //  catch (error) {
        //   setState((_) => {
        //     return { ..._,message:{text:error?.data?.message||"Something went wrong!"},};
        //   })
        //   setTimeout(() => {
        //     setState((prevState) => ({
        //       ...prevState,
        //       message: null,
        //     }));
        //   }, 5000);
        //    setIsDownloading(false)
        //  }
      }

const onUploadClose=()=>{
  setState((_) => ({ ..._, showUpload: false }))
  setUploadedFile([]);
  setUploadFileStatusBlock('');
}
  const onUploadFileChangeHandler = async (e) => {
    const file=e.target.files[0];
    setUploadedFile(e.target.files[0]);
    setUploadFileStatusBlock('<p>Please wait while we process your data...</p>');

    const formData = new FormData();
    formData.append('scheduleLater', false);
    formData.append('listName', undefined);
    formData.append('targetSectorType', undefined);
    formData.append('stores', undefined);
    formData.append('warehouses', undefined);
    formData.append('file', file);

    try {
      await APIEndpoint.post('standarditems/upload', formData);
      setUploadedFile([]);
      setUploadFileStatusBlock('<p>File Imported Successfully.</p>');
      setState( (_prev) => {
        return { ..._prev,resetDataSource:true}
      });

    }
    catch(error) {

      e.target.value = null;
      setUploadedFile([]);
      let { status, data } = JSON.parse( error.message );
      const msgHeading = data?.Error || data?.message || error.message;

      let msgContent = "";
      if (status === 400) {
        if(data.errors){
                data.errors?.forEach( _ => {
            const lineNum = _.lineNumber;
            const errorMsgArr = [];
            _.errors.forEach(erObj => {
              errorMsgArr.push( erObj.field + ' | ' + erObj.message + ' \n<br>' );
            });

            msgContent += '<br>Line ' + lineNum + ': \n<br>' + errorMsgArr.join(' ');
        });
        }else{
          msgContent += `Failed to upload items sheet.`;
        }
      } else if(status===0 && !data){
        msgContent += `Error occurred while uploading items sheet!<br>Please try again.`;
    }
      else {
        msgContent += `Error occurred while uploading items sheet!<br>Please fix the errors and re-upload.`;
      }
      setUploadFileStatusBlock("<p class='listErrorMessagesCls'>"+ msgHeading + "</p>" +
                                "<p class='listErrorMessagesCls sm-scrollbar border p-2 pt-1'>" + msgContent +"</p>"
                              );
    }
};

  return (
    <div className="device-page op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      <div className='flexRow width100 margBot10 margTop8 justifyContentFlexEnd'>
          <div className="margLeft flexRow justifyContentFlexEnd alignItemsBaseline">
          {uploadStdItem?   <div className="flexRow justifyContentFlexEnd alignItemsCenter margRight">
        <UploadButton
          onClick={() => setState((_) => ({ ..._, showUpload: true }))}
          label=<u>{t('pages.estimationsContent.standardItems.uploadItems')}</u> />


      <button className='download dropdownStyle1-TextMedium' disabled={!isDownloading} onClick={onDownloadStandardItems}><span>{isDownloading?<img src={LoadingGIF} alt='loading' className='cell-loading' />:<img className="icon-size mt-1" alt='downloadMaster' src={downloadMasterIcon} />} <u className={`download__banner_${isDownloading ? "active" : ""}`}>{isDownloading?t('filters.downloadingMaster'):"Download Standard Items"}</u></span></button>
      </div>

      :<></>}
          </div>
        </div>

      <div className='flexRow width100 justifyContentSpaceBetween'>
          <div className='flexRow width100 margTop8 justifyContentSpaceBetween'>
              <div className="margLeft40 underline">
                  <span><img  className="icon-size" alt={t('filters.searchText')} src={searchIcon} /></span>
                  <span><input className="search" onChange={onSearch} type="search" placeholder={t('filters.searchText')} value={searchKey}/></span>
              </div>
          </div>
          </div>
      <DataGrid
        columnResize
        showDefaultFilters
        enableGridSearch={false}
        className='full-flex'
        columns={Columns}
        searchKey={searchKey}
        getRows={fetchStandardItems}
        actions={[
          { detail: editStdItem?[{ src: Edit2, value: "edit",title:'Edit' }]:[] }
        ]}
        onAction={handleAction}
        resetDataSource={state.resetDataSource}
      />
      {
        Boolean(state.editRow) && (
          <EditModal
            title={t('pages.estimationsContent.standardItems.editModalTitle')}
            onClose={handleReset}
            onSubmit={handleFormSubmit}
            buttonLabel={t('buttons.save')}
            modalFormStatusObj={modalFormStatusObj}
            fields={
              FormFields.map((elt) => {
                const isEdit = Boolean(state.editRow)
                return { ...elt, initialValue: isEdit ? state.editRow[elt.attribute] : '' }
              })
            }
          />
        )
      }
      {
        state.showUpload &&
        <UploadModal
          modelTitle={t('pages.estimationsContent.standardItems.uploadItems')}
          onUploadClose={onUploadClose}
          onDownloadSampleFile={onDownloadSampleFile}
          errorObj={errorObj}
          uploadedFile = { uploadedFile }
          onUploadFileChangeHandler = { (e) => onUploadFileChangeHandler(e) }
          uploadFileStatusBlock = { uploadFileStatusBlock }
          allowedExtensions={ MIMETypes.excel }
        />
      }
       {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }
    </div>
  )
}
