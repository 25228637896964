import React, { useState, useRef } from 'react'
import '../assets/styles/modal.scss'
import closeIcon from '../images/icons/Icon2.png'
import { Input } from "./Input"
import imageThumb from "../assets/imageThumb.svg"
import { HOST_SERVER } from '../utils/configs';
import editIcon2 from "../assets/edit2.svg";
import cancelIcon from "../assets/icons/cancel.svg";



export const Modal = (props) => {
  const fileInput = useRef(null)
  const showDone = props.showDone !== undefined ? props.showDone : true

  const handleImageUpload = (e) => {
    props.encodeImageFileAsURL(e.target.files[0])
  }

const getRegionCheck=()=>{
 let condCheck=props.cessDetails?.some(val=>val.variable==='region')
return condCheck;
}

  const removeCondition=(list)=>{
    props.onRemoveCondition(list);
  }
  const addConditions=()=>{
    props.onAddConditions();
  }
  const editConditions=(list)=>{
    props.editConditions(list)
  }
  const listItems = props.cessDetails?.map((list, index) => (
    <div className='row' key={index}>
      <div className='col-xs-11 wrapper'>
        <p>{list.predicate}</p>
        <div className='button_group'>
          <img src={editIcon2} alt="" onClick={() => editConditions(list)} />&nbsp;&nbsp;
          <img src={cancelIcon} alt="" onClick={() => removeCondition(index)} />
        </div>
      </div>
    </div>
  ));

    const getFields=(det,pos)=>{
    let modArr=[]
    if(pos==='left'){
    for(let i in det){
    if(parseInt(i)%2===0){
    modArr.push(det[i])
    }
    }
    return modArr;
    }
    if(pos==='right'){
    for(let i in det){
    if(parseInt(i)%2!==0){
    modArr.push(det[i])
    }
    }
    return modArr;
    }


    }
    const showCessForm=(det)=>{
    const fields=
    <div style={{display:'flex'}} className="flexRow justifyContentSpaceBetween flexWrap">
    <div className='width40'>
    <div className="dialog__body_container flexRow justifyContentSpaceEvenly flexWrap floatLeft">
    <div className='width40 floatLeft'>
    <div className="flexCol justifyContentSpaceBetween">
    <div className=" dialog__container_item input-container">
    <div className="dialog__description margBtm16">

    {getFields(det,'left')}

    </div>
    </div>
    </div>
    </div>
    </div>
    </div>

    <div className='width10'></div>
    <div className='width40'>
    <div className="dialog__body_container flexRow justifyContentSpaceEvenly flexWrap">
    <div className='width40 floatRight'>
    <div className="flexCol justifyContentSpaceBetween">
    <div className=" dialog__container_item input-container">
    <div className="dialog__description margBtm16">

    {getFields(det,'right')}

    </div>
    </div>
    </div>
    </div>
    </div>

    </div>
    </div>
    return fields;
    }


  let isError = false;
  let updateStatusText = '';

  if (props.modalFormStatusObj) {
    if ( ('text' in props.modalFormStatusObj)) {
      updateStatusText = props.modalFormStatusObj.text;
    }
    if ( ('error' in props.modalFormStatusObj)) {
      isError=props.modalFormStatusObj.error;
    }
  }

  return (
    <div className="overlay" role="dialog">
      <div className="dialog sm-scrollbar" style={props.customModalStyle}>
        <div className="dialog__content ng-grid-right">
          <img className="modal__close_icon" alt='Close' src={closeIcon} onClick={props.onClose} />
          <h2 className="dialog__title subheading-text-medium fontMedium">{props.title}</h2>
          <hr />


        {showCessForm(props.children)}
         <div className='margLeft33'>
      <>
      <h2 className="dialog__title__conditions subheading-text-medium-title">Conditions</h2>
         {!getRegionCheck()? <h4 className="small-body-text-regular-imp fontRegular-subscript">* Region condition is required</h4>:<></>}
          <button className="add-condition" onClick={addConditions}>+ Condition</button>
         
         {props.cessDetails.length>0? <div className="dialog__body_container flexRow justifyContentSpaceBetween flexWrap">
            <ul className='list-group'>
                {listItems}
            </ul>
          </div>:<>
          <p className='mt-1'>None</p>

          </>}
          </>

</div>

          {props.blobFile || props.imageUrl ? <div className="formGroup__formHorizontal"><img src={props.blobFile ? props.blobFile : `${HOST_SERVER}/${props.imageUrl}`} className="img-thumbnail" alt='' width="90" height="60" /> <img className="image__close__icon" alt='Close' src={closeIcon} onClick={() => props.resetImageSelection()} /><p className="pvm__text-center">{props.fileUpload?.name ? props.fileUpload?.name : ''}</p></div> : <></>}
          {updateStatusText ?
          <>
            <div className={ "dialog__statusBlock " + (isError ? 'alert_error' : 'alert_success') }>
              {updateStatusText}
            </div>
          </> : null }

           { props.modalCustomButtons.length > 0 ?
            <>
                <div className="dialog__footer flexRow justifyContentSpaceEvenly">
                  { props.modalCustomButtons.map( (button, i) => {
                      return <button className={ "done-button " + button.className } key={i} onClick={ button.action === 'onSubmit' ? props.onSubmit : button.action }>{button.label || "Okay"}</button>
                    })
                  }
                </div>
            </>
            :
            <>
              { props.title === "Edit Item" || props.title === "Add Item" ?
                <div className="dialog__footer flexCol alignItemsCenter">
                   <>
                    <input
                      ref={fileInput}
                      onChange={handleImageUpload}
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      className='input_display'
                    />
                    <button className="uploadImage-button" onClick={() => fileInput.current.click()}><img src={imageThumb} alt="upload" />&nbsp;&nbsp;Upload Image</button>
                  </>
                   <button className="done-button" onClick={props.onSubmit}>Done</button>
                </div>
                :
                <>
                  {showDone ?
                  <div className="dialog__footer flexCol alignItemsCenter">
                    <button disabled={!getRegionCheck()} id="cessEdit" className="done-button" onClick={props.onSubmit}>{props.buttonLabel?props.buttonLabel:"Done"}</button>
                  </div>
                  : null }
                </>
              }
            </>
          }
        </div>
      </div>
    </div>
  );
}
export const CessModifModal = (props) => {

  const [updated, setUpdated] = useState(null);
  const onChange = (e) => {
    const { name, value } = e.target;

    if (e.target.name === "itemType") {
      props.setItemTypeValue(e.target.value)
    };

    let newUpdates = updated ? { ...updated } : {}
    newUpdates[name] = value;
    setUpdated({ ...newUpdates });

    typeof (props.onChange) === 'function' && props.onChange(e, updated)
  }

  const onSubmit = (e) => {
    removeContainerOverlay();
    typeof (props.onSubmit) === 'function' && props.onSubmit(updated)
  }

  const onClose = (e) => {
    removeContainerOverlay();
    props.onClose();
  }

  const removeContainerOverlay = () => {
    /**
     * Remove overlay to parent class
     */
    (document.querySelectorAll('.content-container')[0]).classList.remove('modal-overlay-bg2');
  };

  return (
    <Modal
      title={props.title}
      customModalStyle={props.customModalStyle ? props.customModalStyle : {} }
      onClose={onClose}
      encodeImageFileAsURL={props.encodeImageFileAsURL}
      blobFile={props.blobFile}
      fileUpload={props.fileUpload}
      imageUrl={props.imageUrl}
      buttonLabel={props.buttonLabel}
      onRemoveCondition={props.onRemoveCondition}
      editConditions={ props.editConditions}
      onAddConditions={props.onAddConditions}
      cessDetails={props.cessDetails}
      modalCustomButtons={props.modalCustomButtons || []}
      onChange={onChange}
      resetImageSelection={props.resetImageSelection}
      showFieldsInColumn={props.showFieldsInColumn}
      onSubmit={(e) => onSubmit(e)}
      modalFormStatusObj={props.modalFormStatusObj}>
      {
        props.fields.map((_) => {
          const { initialValue, ...rest } = _;
          return (
            <Input
              key={_.attribute}
              name={_.attribute}
              value={updated ? updated[_.attribute] : initialValue}
              data={initialValue}
              onChange={onChange}
              {...rest}
            />
          )
        })
      }
    </Modal>
  )
}
