import React, { useState } from 'react'
import { DataGrid, Input, NGODatePicker } from '../../../common';
import usePermissions from '../../../utils/userPermissions';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { getDateFromTimezone } from './Cards';
import { APIEndpoint } from '../../../utils/axios';
import { formatDateByTimezone, truncateString } from '../../../utils/commonFunctions';
const Columns = [
  {field: "flightDate",headerName: "Flight Date",flex:1,minWidth:100},
  {field: "flightNumber",headerName: "Flt.No",flex:1,minWidth:70},
  {field: "txnDate",headerName: "Txn Datetime",flex:1,minWidth:150,valueGetter: params => {
    return params.data ? moment(params.data?.txnDate).format('DD-MM-YYYY hh:mm A') : '';
}},
  {field: "origin",headerName: "Origin",flex:1,minWidth:80},
  {field: "destination",headerName: "Destination",flex:1,minWidth:80},
  {field: "pnr",headerName: "PNR",flex:1,minWidth:80},
  {field: "seatNumber",headerName: "Seat No",flex:1,minWidth:80},
  {field: "paxName",headerName: "Pax Name",flex:1,minWidth:200,valueGetter:(params)=>{
    return truncateString(params?.data?.paxName,25)
  }},
  // {field: "mobileNumber",headerName: "Mobile Number",flex:1,minWidth:150},
  // {field: "emailId",headerName: "Email ID",flex:1,minWidth:180},
  {field: "cardNo",headerName: "Card No",flex:1,minWidth:130},
  {field: "currency",headerName: "Currency",flex:1,minWidth:70},
  {field: "amount",headerName: "Txn Amount",flex:1,minWidth:100},
  {field: "amountCredited",headerName: "Amount Credited",flex:1,minWidth:130, valueGetter:(params)=>{
    return params?.data?.paymentStatus === "Completed" ? params?.data?.amount:null
  }},
  {field: "paymentStatus",headerName: "Payment Status",flex:1,minWidth:120},
  {field: "orderNo",headerName: "Order No",flex:1,minWidth:250},
  {field: "orderStatus",headerName: "Order Status",flex:1,minWidth:100},
  {field: "bankTxnId",headerName: "Bank Ref.ID",flex:1,minWidth:100},
  // {field: "comment",headerName: "Comment",flex:1,minWidth:150},
  // {field: "reTryDate",headerName: "Re.Try Date",flex:1,minWidth:100},
  // {field: "fee",headerName: "Fee",flex:1,minWidth:100},
]

const body = {
  page:1,
  filters:[],
  limit:25
}

export const CardReconciliation = () => {
  const [perPageLimit, ] = useState(10);
  const [filterData, setFilterData] = useState({
    flightDate:"",
    flightNumber:"",
    pnrNumber:"",
    orderNumber:"",
    orderStatus:""
  })
  const [isReloadTable, setIsReloadTable] = useState(false)
  const { hasPermission: viewCardRecon } = usePermissions('view', "card-reconciliation");
  const { t,ready } = useTranslation();

  async function fetchCardTrancations(page=1, perPage=10){
    if(!viewCardRecon){
      return {items:[],count:0}
    }
    body.filters = [];
    body.page = page;
    body.limit = perPage;
    if(filterData.flightNumber){
      body.filters.push({flightNumber:filterData.flightNumber})
    }
    if(filterData.orderNumber){
      body.filters.push({orderNo:filterData.orderNumber})
    }
    if(filterData.pnrNumber){
      body.filters.push({pnr:filterData.pnrNumber})
    }
    if(filterData.flightDate){
      body.filters.push({flightDate:formatDateByTimezone(filterData.flightDate)})
    }
    if(filterData.orderStatus){
      body.filters.push({orderStatus:filterData.orderStatus})
    }
    try {
      const res = await APIEndpoint.post("payments/card/transactions",JSON.stringify(body))
      return res
    } catch (error) {
      console.log(error)
    }
  }

  function isValidDate(dateString) {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  }

  const handleInputChange = (e) => {
    setIsReloadTable(!isReloadTable)
    if(!e.target.value){
      setFilterData(prev=>({...prev,flightDate:null}))
      return;
    }
    let { name, value } = e.target;
    if(!isValidDate(value)) return;
    if (name === "flightDate") {
      let dataVal = getDateFromTimezone(value).toDate();
      setFilterData((prev) => {
        return { ...prev, flightDate: dataVal };
      });
    }
  };
  function onSearchInpChange(e){
    setIsReloadTable(!isReloadTable)
    let { name, value } = e.target;
    setFilterData(prev=>({...prev,[name]:value}))
  }
  return (
    <div className="flexCol full-flex ag-theme-alpine-dark">
      <div
          className="flex flexRow alignItemsCenter"
        >
          <div className='felxCol m-2'>
            <NGODatePicker
              value={filterData.flightDate}
              name='flightDate'
              onChange={handleInputChange}
              placeholder="Flight Date"
              isClearable={true}
            />
          </div>
          <div className='flexCol m-2'>
          <div className='operations-in'>
            <input 
              className="search-crew searchReconFilter" 
              onChange={onSearchInpChange} 
              type="search" 
              name='flightNumber' 
              placeholder="Search Flight Number" 
              value={filterData.flightNumber||null}
            />
          </div>
          </div>
          
          
          <div className="flexCol m-2">
          <div className='operations-in'>
            <input 
             name='pnrNumber'
             className="search-crew searchReconFilter" 
             onChange={onSearchInpChange} 
             type="search" 
             placeholder="Search PNR Number" 
             value={filterData.pnrNumber||null}
            />
          </div>
          </div>
          <div className="flexCol m-2">
          <div className='operations-in'>
            <input 
             name='orderNumber'
             className="search-crew searchReconFilter" 
             onChange={onSearchInpChange} 
             type="search" 
             placeholder="Search Order Number" 
             value={filterData.orderNumber||null}
            />
          </div>
          </div>
          <div className="flexCol m-2">
          <div className=''>
            <select onChange={onSearchInpChange} style={{fontWeight:"400",fontSize:"0.8rem"}} name="orderStatus" className='select-item darkBlue-bg' id="">
              <option value="">Order Status</option>
              <option value="accepted">Accepted</option>
              <option value="cancelled">Cancelled</option>
              <option value="denied">Denied Demand</option>
            </select>
          </div>
          </div>
        </div>

    <DataGrid
        className="full-flex"
        columns={[...Columns]}
        initialPageLimit={perPageLimit}
        showDefaultFilters={false}
        getRows={fetchCardTrancations}
        columnResize={true}
        rowSelection="multiple"
        resetDataSource={isReloadTable}
      />
        {
    !ready &&<div className='overlay_hide sub'></div>
    }
    </div>
  );
}