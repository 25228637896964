import React, { useRef, useState,useEffect } from 'react'
import { APIEndpoint, DFPAPIEndpoint } from "../../../utils/axios";
import { DataGrid, EditModal, CancelModal, CartModal, ConfirmModal, QRCodeModal,UpliftActionModal,NGODatePicker} from '../../../common';
import {capitalizeFirstLetter, translationLength, truncateString,formatDateByTimezone, getCultureFromLs} from '../../../utils/commonFunctions';
import {customSelSearch } from "../../../utils/configs";
import {
    InstanceStates,SectorRenderer
  } from "../../sales/inner-components/renderers";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import cartIcon from "../../../assets/icons/carts.svg";
import confirmIcon from "../../../assets/icons/confirm.svg";
import cancelIcon from "../../../assets/icons/cancel.svg";
import Select from 'react-select';
import qrcodeIcon from "../../../assets/qrCode.svg";
import viewIcon from "../../../assets/view.svg";
import galleyIcon from "../../../assets/icons/galley.svg";
import trolleyIcon from "../../../assets/icons/trolley.svg";
import moment from 'moment-timezone'
import usePermissions from '../../../utils/userPermissions';
import CustomSelect from '../../../common/CustomSelect';
import downloadMasterIcon from '../../../assets/icons/download-master.svg';
import closeIcon from "../../../images/icons/Icon2.png"

export const Uplifts = (props) => {
  const { t } = useTranslation();
  const [timezone, setTimezone] = useState(null)
  const [state, setState] = useState({searchResponse:[],sectorSelect:[],isReloadTableData:false});

  const { hasPermission: createUplifts } = usePermissions('create', 'uplifts');
  const { hasPermission: editUplifts } = usePermissions('edit', 'uplifts');
  const { hasPermission: viewUplifts } = usePermissions('viewDetails', 'uplifts');
  const { hasPermission: downloadUplift } = usePermissions('download', 'uplifts');
  const { hasPermission: printUplifts } = usePermissions('print', 'uplifts');
  const { hasPermission: deleteUplifts } = usePermissions('delete', 'uplifts');

  const tableRef = useRef();
  const navigate = useNavigate();
  const [perPageLimit, setPerPageLimit] = useState(10);
  // eslint-disable-next-line no-unused-vars
  const [searchKey, setSearchKey] = useState('');
  const [itemTypeList, setItemTypeList] = useState([]);
  const [itemType, setItemType] = useState('');
  const [upliftItems, setUpliftItems] = useState([]);
  const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
  const [showUpliftSector, setShowUpliftSector] = useState(false);
  const [createUpliftDate, setCreateUpliftDate] = useState(moment().format());
  const [promptError, setPromptError] = useState([]);
  const [optionsWarehouse, setOptionsWarehouse] = useState([{key: 0, label: 'Select Warehouse', label2: 'Select Warehouse',value: ''}]);
  const [optionsLocations,setOptionsLocations]=useState([{key: 0, label: 'Select Location', value: ''}]);
  const [optionsSector, setOptionsSector] = useState([{key: 0, label: 'Select Sector', value: ''}]);
  const [optionsSectorFilter,setOptionsSectorFilter]=useState([])
  const [message, setMessage] = useState("No Data")
  const [isDownloading, setIsDownloading] = useState(false)
  const [showDownload, setShowDownload] = useState(false)
  const [reportDownloadErr, setReportDownloadErr] = useState(null)
  const [buttonStatus,setButtonStatus]=useState(true);
  const [reportFilter, setReportFilter] = useState({
    startDate:new Date(),
    endDate:new Date(),
    reportType:"",
    storeId:""
  })

  useEffect(() => {
    getSectors();
    fetchCulureApi();
  }, [])
  async function getSectors(){
    try {
      const resp = await APIEndpoint.get('stores');
      if(resp.success){
        const setorList = resp.result.map(data=>{
          const sector = {
            key:data.id,
            label:`${data.flightNumber}-${data.origin}-${data.destination}`,
            value:data.id
          }
          return sector
        })
        if(setorList.length > 0){
          setOptionsSectorFilter([...setorList])
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchTimezone()
  }, [])

  async function fetchTimezone(){
    try {
      const res = await APIEndpoint.get("customer/app/config")
      if(res.success){
        const {name} = res.result.timezone
        setState(prev=>({...prev,timezone:name}))
      }
    } catch (error) {
      console.log(error)
    }
  }
  const SectorsCellRenderer = ({ value }) => {

    if( value && Array.isArray(value) && value.length){
    return (
      <div className='sector-container' style={{width:'100%',maxWidth:'275px'}}>
        {value.map((key, index) => (
          <span key={index} title={key} className="sector-label">{key}</span>
        ))}
      </div>
    );
    }else if(value && Array.isArray(value)&&!value.length){
      return (
        <div className='sector-container'>
          None
        </div>)
    }
    else if(value && !Array.isArray(value)){
      return (
        <div className='sector-container' style={{width:'100%',maxWidth:'285px'}}>
          <span className="uplift-user">{value?.employeeName??""}{value?.employeeCode && value?.employeeCode!==value?.employeeName ?` (${value?.employeeCode})`:null}</span>
        </div>)
    }else{
     return (<div className='sector-container'>

        </div>)
    }


  };

  const onColumnResizedHandler=(e)=>{

    if (e && e.column?.getColId() === 'sectors') {
      const newWidth = e?.column.actualWidth;

      const sectorCells = document.querySelectorAll('.sector-container');
      sectorCells.forEach(cell => {
        cell.style.maxWidth = `${newWidth}px`;
      });
    }
    if (e && e.column?.getColId() === 'upliftedBy') {
      const newWidth = e?.column.actualWidth;

      const sectorCells = document.querySelectorAll('.sector-container');
      sectorCells.forEach(cell => {
        cell.style.maxWidth = `${newWidth}px`;
      });
    }
    if (e && e.column?.getColId() === 'confirmedBy') {
      const newWidth = e?.column.actualWidth;

      const sectorCells = document.querySelectorAll('.sector-container');
      sectorCells.forEach(cell => {
        cell.style.maxWidth = `${newWidth}px`;
      });
    }

  }


  const Columns = [
    { field: 'storeName', headerName: 'Sector',resizable:true, minWidth: 165, width:190,pinned: 'left', cellRendererSelector: (params) => {
      return {
        component: SectorRenderer,
      };
    }},
    {
      field: 'targetDate', headerName: 'Flight Date', minWidth: 100,
      resizable: true,
      valueGetter: (params) => {
        return params?.data ? moment.parseZone(formatDateByTimezone(params?.data?.targetDate)).format("DD-MM-YYYY"):null
      }
    },
    { field: 'location', headerName: 'Location',resizable:true, minWidth: 80, },
    {
      field: 'warehouse', headerName: 'Warehouse', minWidth: 95,
      resizable: true,
      valueGetter: (params) => {
        return params.data?.warehouse?.warehouseCode? params.data?.warehouse?.warehouseCode:null
      }
    },
    {
      field: 'upliftedBy', headerName: 'Uplifted By', minWidth: 100,
      resizable: true,cellRendererFramework: SectorsCellRenderer,

    },
    {
      field: 'dateCreated', headerName: 'Created At', minWidth: 150,
      resizable: true,
      valueGetter: (params) => {
        return params?.data?.dateCreated ? moment.tz(params.data?.dateCreated,state.timezone).format('DD-MM-YYYY HH:mm A'):null
      }
    },
    { field: 'status', headerName: 'Status', filter:true, minWidth: 130, resizable: true,cellRendererSelector: (params) => {
            return {
              component: InstanceStates,
            };
          }},
    {
      field: 'confirmedBy', headerName: 'Confirmed By', minWidth: 110,
      resizable: true,cellRendererFramework: SectorsCellRenderer,

    },
    {
      field: 'confirmedAt', headerName: 'Confirmed At', minWidth: 190,width:200,
      resizable: true,
      valueGetter: (params) => {
        return params?.data?.confirmedAt ? moment.tz(params?.data?.confirmedAt,state.timezone).format('DD-MM-YYYY HH:mm A'):null
      }
    },
    {
      field: 'items', minWidth: 115,
      headerName: 'Summary',resizable: true,
      valueGetter: (params) => {
        return params?.data ? `${params.data?.items.length || 0} items`:null
      }
    },
    { field: 'sectors', headerName: 'Sectors', resizable: true,width:250, minWidth: 200,cellRendererFramework: SectorsCellRenderer, },
    {
      width: 135,
      resizable: true,minWidth: 90,
      cellRenderer: SectorComponent,
      // valueGetter: function(params) {
      //   return params.sectors;
      // }
    },
    { field: '',headerName: 'Update Status',resizable: true, minWidth: 150, width: 140,cellRenderer: (params)=> StatusRenderer(params,true)},
    {
      width: 135,
      resizable: true,
      cellRenderer: StatusComponent,
      valueGetter: function(params) {
        return params.status;
      }
    },
    // {
    //   width: 100,headerName: 'Trolley / Galley',
    //   resizable: true,
    //   cellRenderer: TrolleyComponent,
    //   pinned: 'right'
    // },
    {
      width: 185,headerName: 'Actions',
      resizable: true,
      cellRenderer: ActionComponent,
      pinned: 'right'
    }
  ];

  function ActionComponent(props) {
    if(!props.data) return null
    if(props.data?.status === 'created' && deleteUplifts){
      return (
        <div className="pointer flexRow" >
          <img className="edit mx-2" alt='items' title="Items" src={viewIcon} onClick={(e) => onAction(props.data, 'EditItem')}/>
          <img className="edit mx-2" alt='items' title="Confirm" src={confirmIcon} onClick={(e) => onAction(props.data, 'ConfirmItem')}/>
          <img className="edit mx-2" alt='items' title="Cancel" src={cancelIcon} onClick={(e) => onAction(props.data, 'CancelItem')}/>
        </div>
      );
    }else if(props.data?.status === 'confirmed' && deleteUplifts){
      return (
        <div className="pointer flexRow" >
          <img className="edit mx-2" alt='items' title="Items" src={viewIcon} onClick={(e) => onAction(props.data, 'ViewItem')}/>
          <img className="edit mx-2" alt='items' title="Carts" src={cartIcon} onClick={(e) => onAction(props.data, 'CartItem')}/>
          <img className="edit mx-2" alt='items' title="QR Code" src={qrcodeIcon} onClick={(e) => onAction(props.data, 'QRCode')}/>
          <img className="edit mx-2" alt='items' title="Cancel" src={cancelIcon} onClick={(e) => onAction(props.data, 'CancelItem')}/>
        </div>
      );
    }else if(props.data?.status === 'completed'&& printUplifts){
      return (
        <div className="pointer flexRow" >
          <img className="edit mx-2" title="Items" alt='items' src={viewIcon} onClick={(e) => onAction(props.data, 'ViewItem')}/>
          <img className="edit mx-2" title="Carts" alt='items' src={cartIcon} onClick={(e) => onAction(props.data, 'CartItem')}/>
          <img className="edit mx-2" title="QR Code" alt='items' src={qrcodeIcon} onClick={(e) => onAction(props.data, 'QRCode')}/>
        </div>
      );
    }else {
      return (
        <div className="pointer flexRow" >
          <img className="edit mx-2" alt='items' title="Items" src={viewIcon} onClick={(e) => onAction(props.data, 'ViewItem')}/>
          <img className="edit mx-2" alt='items' title="Carts" src={cartIcon} onClick={(e) => onAction(props.data, 'CartItem')}/>
        </div>
      );
    }

  }

  function TrolleyComponent(props) {
    if(props.data){
      return (
        <div className="pointer flexRow" >
          <img className="edit mx-2" alt='items' src={galleyIcon} onClick={(e) => onAction(props.data, 'GalleyItem')}/>
          <img className="edit mx-2" alt='items' src={trolleyIcon} onClick={(e) => onAction(props.data, 'TrolleyItem')}/>
        </div>
      );
    }else{
      return null
    }

  }

  function SectorComponent(props) {
    if(props.data?.sectors){
      return (
        <div>
          <button className="sector-button" onClick={(e) => setSector(props.data, 'sector')}>...</button>
        </div>
      );
    }else{
      return null
    }
  }

  function StatusRenderer(params, updatedStatus = null) {
    const getStatusLabel = (status) => {
      switch (status) {
        case 'completed':
          return 'Processed';
        case 'created':
          return 'Pending';
        default:
          return capitalizeFirstLetter(status);
      }
    };
    if (!updatedStatus) {
      return <span>{getStatusLabel(params?.data?.status)}</span>;
    }
    const updateStatus = params?.data?.updateStatus;
    return <span>{updateStatus ? capitalizeFirstLetter(updateStatus) : upliftItems && upliftItems.length?'None':""}</span>;
  }


  function onDateChange(e){
    setState( (_prev) => {
        return { ..._prev,flightDate:e.target.value,isReloadTableData:!state.isReloadTableData}
      });
  }

  function onChangeLocation(selectedVal){
    const val = selectedVal?.value;
    setState((_)=>({..._,locationSelect:val,isReloadTableData:!state.isReloadTableData}))
  }

  function onChangeSectors(e){

    setState((_)=>({..._,sectorSelect:[e],isReloadTableData:!state.isReloadTableData}))
  }

  function onWarehouseChange(selectedVal){
    const val = selectedVal?.value;
    setState((_)=>({..._,warehouseSel:val,isReloadTableData:!state.isReloadTableData}))
  }
  function StatusComponent(props) {
    if(props.data){
      return (
        <div>
          <button className="sector-button" onClick={(e) => setSector(props.data, 'status')}>...</button>
        </div>
      );
    }else{
      return null
    }

  }

  const setSector = (data,type) => {
    setState( (_prev) => {
      return { ..._prev, selected: {...data ,action: type}}
    });
    setShowUpliftSector(true)
  };

  const EditFormFields = [
    {label: 'Csv', attribute: 'items', type: 'csv', disabled: false, options: [], value: [] },
  ];

  const AddFormFields = [
    {label: 'Warehouse', attribute: 'warehouse', type: 'selectSearch2', disabled: false, options: optionsWarehouse, required: true },
    {label: 'Sector', attribute: 'sector', type: 'selectSearch2', disabled: false, options: optionsSector, required: true },
    {label:  'Flight Date  (in IST)', attribute: 'flightDate', type: 'date', disabled: false, required: true},
  ];


  React.useEffect(() => {
    fetchWarehouses();
    fetchAirports();
    setItemTypeList([{key: 0, label: "Confirmed", value: 'confirmed'},{key: 1, label: "Pending", value: 'created'},{key: 2, label: "Processed", value: 'completed'},{key: 3, label: "Cancelled", value: 'cancelled'}]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect( () => {
    refreshTable();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemType, tableRef]);

  const refreshTable = () => {
    tableRef.current.refreshTableHandler(itemType);
  };

  const fetchWarehouses = async () => {
    try {
       const resp = await APIEndpoint.get('warehouses');
    if (resp.result.length > 0) {
      const options = [{key: 0, label: 'Select Warehouse', label2:'Select Warehouse',value: ''}];
      resp.result.filter( (fieldValue, index) => {
        options.push({key: fieldValue.id, label: fieldValue.value,value: fieldValue.id});
        return fieldValue;
      });
      setOptionsWarehouse(options)
    } else {
      console.log('Log: Error while fetching warehouses');
    }
    } catch (error) {

    }

  };

  const fetchAirports = async () => {
    try {
       const resp = await APIEndpoint.get('airports');
    if (resp.items.length > 0) {
      const options = [{key: 0, label: 'Select Location', value: ''}];
      resp.items.filter( (fieldValue, index) => {
        options.push({key: fieldValue.id, label: fieldValue.locationCode, value: fieldValue.locationCode});
        return fieldValue;
      });
      setOptionsLocations(options)
    } else {
      console.log('Log: Error while fetching warehouses');
    }
    } catch (error) {

    }

  };

  const fetchSectors = async (id) => {
    try {
      const resp = await APIEndpoint.get('warehouses/sectors/'+id);
    if (resp.result.length > 0) {
      const options = [{key: 0, label: 'Select Sector', value: ''}];
      resp.result.filter( (fieldValue, index) => {
        options.push({key: fieldValue.storeId, label: fieldValue.flightNumber+ " ("+ fieldValue.originName+" - "+fieldValue.destinationName+") ", value: fieldValue.storeId});
        return fieldValue;
      });
      setOptionsSector(options)
    } else {
      const options = [{key: 0, label: 'Select Sector', value: ''}];
      setOptionsSector(options)
      console.log('Log: Error while fetching sectors');
    }
    } catch (error) {

    }

  };

  const onAction = (selectedRowData,action) => {
    setState( (_prev) => {
      return { ..._prev, selected: {...selectedRowData,  action: action }}
    });
    /**
     * Set to edit form
     */
    EditFormFields.map( editRow => {
      if (editRow.attribute === 'items' ) {
        editRow.value = selectedRowData;
      }
      return editRow;
    });
  };

  const onClose = () => {
    setModalFormStatusObj({ text: '' });
    setShowUpliftSector(false)
    setState((_prev) => {
      return { ..._prev, selected: false }
    });
    setPromptError([]);
    setButtonStatus(true)
  }

  const onSearch = (e) => {
    setSearchKey(e.target.value);
};

function upliftSeachFilters(){
  let filters=[];
  let filterConditions = [
    { condition: "itemType", filter: {"status":itemType||null } },
    { condition: "location", filter: {"location":state.locationSelect||null } },
    { condition: "warehouseId", filter: { "warehouseId":state.warehouseSel||null } },
    { condition: "storeId", filter: { "storeId":state.sectorSelect[0]?.value||null} },
    { condition: "flightDate", filter: {"flightDate":state.flightDate?formatDateByTimezone(state?.flightDate):null } },

  ];

  filterConditions.forEach(({ condition, filter }) => {
    if (condition) {
      const existingFilterIndex = filters.findIndex(existingFilter => {
        return Object.keys(existingFilter).every(key => filter.hasOwnProperty(key));
      });

      if (existingFilterIndex !== -1) {
        const existingFilter = filters[existingFilterIndex];
        filters[existingFilterIndex] = { ...existingFilter, ...filter };
      } else {
        filters.push(filter);
      }
    }
  });
  return filters;
}

  const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 10,
    page: 1,
    searchParam: ''
  };

  const fetchUplifts = async (page=1, perPage=10) => {
    filterData.page = page;
    filterData.limit = perPage;
    filterData.sort=[
      {
          field: "targetDate",
          order: "desc"
      }
  ]
  const filtersArr=upliftSeachFilters().filter(item=>Object.values(item)[0]!==null);
  filterData.filters = filtersArr;

try {
  const res = await APIEndpoint.post('uplifts/process', JSON.stringify(filterData));
    if(res.items.length > 0){
      setMessage(undefined);
      if (Boolean(upliftItems.length)) {
        setUpliftItems((prev) => {
          let updatedItems = [...prev];
          res.items.forEach((item) => {
            if (!prev.find((prevItem) => prevItem.storeId === item.storeId)) {
              updatedItems.push(item);
            }
          });
          return updatedItems;
        });
      }
      else {
        setUpliftItems(res.items);
      }
      return res;

    }else if(page === 1){
      return {items:[],count:0}
    }
} catch (error) {

}

  }


  const onEditSubmit = async (e, updated) => {
    if(state.selected.action === 'ViewItem'){
      onClose();
      return;
    }
    if(state.selected.action !== 'EditItem'){
      setModalFormStatusObj({ text: '' });
      if(updated) {
        // const body = { ...state.selected, ...updated };
        toUpliftAddNavigate(updated)
        // sType === 'dfp' ? await DFPAPIEndpoint.put('itemMaster', body) : await APIEndpoint.put('itemMaster', body);
        setModalFormStatusObj({ text: 'Saved successfully.' });
      }
    }
    onClose();
  };

  const onPromptSubmit = async (e, updated,mode) => {
    if (updated) {
      const body = { storeId: updated.storeId};
      try{
        (mode === 'Cancel Uplift') ?
        await APIEndpoint.post('uplifts/'+updated.id+'/cancel', body)
      :
      await APIEndpoint.post('uplifts/'+updated.id+'/confirm', body);
      refreshTable()
      onClose();
      }catch(error){
        let {data}=JSON.parse(error.message)
        setPromptError(data.message)
      }
    }
  };

  async function fetchCulureApi() {
    try {
      const res = getCultureFromLs()
      setTimezone(res?.timezone)
    } catch (error) {
      console.log(error)
    }
  }
  const onFieldChangeHandler = (e,updated) => {
    if(!updated?.warehouse || !updated?.sector || !updated.flightDate){
      setButtonStatus(true)
    }else if(updated?.warehouse && updated?.sector && updated?.flightDate){
      setButtonStatus(false)
    }
    setModalFormStatusObj({text:''})
    if(updated.flightDate){
      setCreateUpliftDate(moment(updated.flightDate).format())
    }
    if(updated.warehouse){
      fetchSectors(Number(updated.warehouse));
    }
  };

  const onAddItemHandler = () => {
    setState( (_prev) => {
      return { ..._prev, selected: { action: 'AddItem' } };
    });
  }

  // eslint-disable-next-line no-unused-vars
  const onPerLimitChange = (e) => {
    setPerPageLimit(Number(e.target.value));
  };

  const onItemTypeChange = (e) => {
      let status = e?.value || "";
      setItemType(status);
  }

  const toUpliftNavigate = (e) => {
    let id = state.selected.id
    navigate('main/inventory/editor/edituplift/'+id)
  }

  const toUpliftAddNavigate = (updatedid) => {
    let dateid = formatDateByTimezone(createUpliftDate);
    navigate('main/inventory/editor/'+updatedid.warehouse+'/'+updatedid.sector+'/'+dateid)
  }

  function clearErr(){
    setTimeout(() => {
        setReportDownloadErr(null)
    }, 3000);
  }

  function onUploadClose(){
    setShowDownload(false)
  }

  function showDownloadHandler(){
    setShowDownload(true)
  }

  async function onDownloadReport(){
    const body = {
      startDate: reportFilter.startDate,
      endDate: reportFilter.endDate,
      reportType: reportFilter.reportType,
      storeId:reportFilter.storeId
    };
    if(!body.reportType){
      setReportDownloadErr("Select report type!")
      clearErr()
      return;
    }
    let header = {
      Accept: "text/csv",
    };
    try {
      setIsDownloading(true)
      const res = await APIEndpoint.post(`sales/reports`, body, header);
      if(res.status === "scheduled"){
        setTimeout(async () => {
          try {
            const dowId = await APIEndpoint.get(`downloads/status/${res.id}`);
            const ItemMasterDwnld = await APIEndpoint.get(
              `downloads/analytics/${dowId.fileName}${dowId.fileExtension}`,
            );
            setIsDownloading(false)
            const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download",`${dowId.fileName}${dowId.fileExtension}`);
            document.body.appendChild(link);
            link.click();
          } catch (error) {
            setIsDownloading(false)
          }
        }, 5000);
      }
    } catch (error) {
      const err = JSON.parse(error.message);
      setReportDownloadErr(err.data?.message)
      setIsDownloading(false)
      clearErr()
    }
  }

  function onDateChangeRep(e){
    const {name,value} = e.target
    if(name === "startDate"){
      setReportFilter(prev=>({...prev,startDate:value}))
    }else{
      setReportFilter(prev=>({...prev,endDate:value}))
    }
  }

  return (
      <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      {
        <>

          <div className='flexRow width100 justifyContentFlexEnd'>
         {downloadUplift? <div className="flexRow justifyContentFlexEnd alignItemsCenter margRight">
              <button className='download dropdownStyle1-TextMedium' onClick={showDownloadHandler}>
                <span>
                  <img className="icon-size mt-1" alt='downloadMaster' src={downloadMasterIcon} />
                  <u className={`download__banner_${isDownloading ? "active" : ""}`}>Download Reports</u>
                  </span>
              </button>
          </div>:<></>}
          </div>
          <div className="flexRow width100 justifyContentFlexStart alignItemsCenter">

{optionsSectorFilter ? (<div className='orderBy-div m-2 margLeft' style={{paddingBottom:'0.2rem'}}>
                          <Select
                            options={optionsSectorFilter}
                            defaultValue={state.sectorSelect[0]?.label ? state.sectorSelect[0]?.label : ''}
                            value={optionsSectorFilter?.filter(function (option) {
                              return (
                                state.sectorSelect.length && state.sectorSelect[0]&&
                                state.sectorSelect.find((sc) => sc.label === option.label)
                              );
                            })}
                            onChange={onChangeSectors}
                            styles={customSelSearch}
                            placeholder="Search Sector"
                            isSearchable={true}
                            isClearable={true}
                            className="selectMenu-meals"
                          />
                        </div>):<></>}

            <button className="orderStatus-div m-2">
            <>
                    <NGODatePicker
                      style={{width: '10rem',border:'1.48px solid #fff'}}
                      value={state.flightDate}
                      dateFormat="dd/MM/yyy"
                      name='flightDate'
                      className="input-margin"
                      isClearable={true}
                      placeholder={"Flight date"}
                      placeholdertext={t('datePickerPlaceholder')}
                      onChange={onDateChange} />
                  </>
            </button>

            <button className="orderBy-div m-2 margLeft">
              <CustomSelect
                options={optionsLocations}
                placeHolder="Search location"
                onChange={onChangeLocation}
              />
            </button>
            <button className="orderBy-div m-2 margLeft">
              <CustomSelect
                options={optionsWarehouse}
                placeHolder="Search warehouse"
                onChange={onWarehouseChange}
              />
            </button>

            <button className='saleType-div m-2' style={{marginLeft:"1rem"}}>
                    <CustomSelect options={itemTypeList} placeHolder="Search Status" onChange={onItemTypeChange} />
                </button>
  { createUplifts?<div className='flexRow width100 justifyContentFlexEnd'>
            <div className='flexRow alignItemsCenter'>
                <button className="add-item-button" onClick={ onAddItemHandler }>
                  {t('pages.inventoryContent.uplifts.createUplift')}
                </button>

              </div>

          </div>:<></>}

          </div>



          <DataGrid
            ref={tableRef}
            className="full-flex"
            columns={[...Columns]}
            initialPageLimit={perPageLimit}
            showDefaultFilters={false}
            searchKey={searchKey}
            selItemType={itemType}
            onColumnResizedHandler={onColumnResizedHandler}
            resetDataSource={state.isReloadTableData}
            getRows={fetchUplifts}
            rowSelection="multiple"
            onAddItemHandler={() => onAddItemHandler()}
            noResultsMessage={message}
          />
        </>
      }
      {
        state && Boolean(state.selected) ?
         (state.selected.action === 'AddItem' || state.selected.action === 'ViewItem') && viewUplifts ? (
          <EditModal
           disabled={buttonStatus}
            key={state.selected.action}
            title={(state.selected.action === 'AddItem') ? 'Create Uplift' : (state.selected.action !== 'EditNewItem') ? 'Uplift -'+state.selected.storeName : 'View Items (Csv)'  }
            onClose={onClose}
            onSubmit={onEditSubmit}
            modalFormStatusObj={modalFormStatusObj}
            buttonLabel={"Continue"}
            onChange = {onFieldChangeHandler}
            showFieldsInColumn={true}
            fields={
              state.selected.action === 'ViewItem' ?
                EditFormFields.map( (elt) => {
                  return {...elt, initialValue: state.selected[elt.attribute]}
                })
              :
              AddFormFields.map( (elt) => {
                return {...elt, initialValue:""}
              })
            }
          />
        ) : state.selected.action === 'EditItem' && editUplifts ? (
            toUpliftNavigate()
        ) : state.selected.action === 'CancelItem' ? (
          <CancelModal
          title={t('pages.inventoryContent.uplifts.cancelUplift')}
          onClose={onClose}
          error={promptError}
          onSubmit={onPromptSubmit}
          fields={
            EditFormFields.map( (elt) => {
              return {...elt, initialValue: state.selected}
            })
          }
          />
        ) : state.selected.action === 'CartItem' ?(
          <CartModal
          title={t('pages.inventoryContent.uplifts.addCarts')}
          onClose={onClose}
          />
        ) :  state.selected.action === 'ConfirmItem'?(
        <ConfirmModal
        title={t('pages.inventoryContent.uplifts.confirmUplift')}
        onClose={onClose}
        error={promptError}
        onSubmit={onPromptSubmit}
        fields={
          EditFormFields.map( (elt) => {
            return {...elt, initialValue: state.selected}
          })
        }
        />)
        : state.selected.action === 'QRCode' && printUplifts ? (
          <QRCodeModal
        title="QR Code - Uplift"
        onClose={onClose}
        fields={state.selected}
        />
        ):null
        :null
      }
      {
        showUpliftSector && viewUplifts &&
        <UpliftActionModal
        title={state.selected.action === 'sector' ? "Sectors Included" : "Uplift update status"}
        onClose={onClose}
        onSubmit={onPromptSubmit}
        fields={state.selected.action === 'sector' ? state.selected.sectors : ['No Updates Available']}
        />
      }
      {
        showDownload && (
          <div className="overlay mt-4" role="dialog">
            <div className="dialog sm-scrollbar">
              <div className="dialog__content">
                <img
                  className="modal__close_icon"
                  alt="Close"
                  src={closeIcon}
                  onClick={onUploadClose}
                />
                <h2 className="dialog__title subheading-text-medium fontMedium">
                  Download Uplift Report
                </h2>
                <hr />

                <div className="dialog__body_container flexRow mt-4 justifyContentCenter flexWrap">
                  <div className={`dialog__container_item input-container`}>
                    <div className='flexCol'>
                      <label htmlFor="reportType" className="req">Report type</label>
                      <select onChange={(e)=>setReportFilter(prev=>({...prev,reportType:e.target.value}))} name="" id="" className='select-input-galley1'>
                        <option value="">Select Report type</option>
                        <option value="upliftReport">Uplift Report</option>
                      </select>
                    </div>
                    <div style={{marginBottom:"0.9rem"}} className='flexCol'>
                      <label style={{marginBottom:"0.9rem"}} htmlFor="reportType">Select Route</label>
                      <Select
                       isClearable
                       isSearchable
                       options={optionsSectorFilter}
                       placeholder="Select Route"
                       styles={{
                        placeholder:(styles,state) => ({ ...styles, textAlign: "left",color:  state.isFocused ? '#6e7c84' : '#fff' }),
                        indicatorSeparator: (provided) => ({ ...provided,display: 'none'}),
                        singleValue: (styles) => ({ ...styles,textAlign:"left",color:"#fff" }),
                        input: (styles) => ({ ...styles,textAlign:"left",color:"#fff" }),
                       }}
                       onChange={data=>setReportFilter(prev=>({...prev,storeId:`${data?.value}`}))}
                      />
                    </div>
                    <div className='flexCol'>
                      <label htmlFor="startDate" className="req">Start Date</label>
                      <NGODatePicker
                        style={{width: '17rem'}}
                        value={reportFilter.startDate}
                        name="startDate"
                        isclearable={true}
                        placeholder={t('Start Date')}
                        placeholdertext={t('datePickerPlaceholder')}
                        onChange={onDateChangeRep}
                        maxDate={new Date()}
                      />
                    </div>
                    <div className='flexCol'>
                      <label htmlFor="startDate" className="req">End Date</label>
                      <NGODatePicker
                        style={{width: '17rem'}}
                        value={reportFilter.endDate}
                        name="endDate"
                        minDate={new Date(reportFilter.startDate)}
                        isclearable={true}
                        placeholder={t('End Date')}
                        placeholdertext={t('datePickerPlaceholder')}
                        onChange={onDateChangeRep}
                        maxDate={new Date()}
                      />
                    </div>
                    <div>
                    <button
                      type="button"
                      onClick={onDownloadReport}
                      className="btn btn-info mt-2"
                      style={{ color: "white", width: "100%" }}
                    >
                      <span>
                        <img
                          className="icon-size mt-1"
                          alt="downloadMaster"
                          src={downloadMasterIcon}
                        />
                      </span>
                      {isDownloading ? "Downloading...":"Download"}
                    </button>

                    </div>
                    {reportDownloadErr && (
                      <div className='mt-4'>
                        <p className='listErrorMessagesCls'>
                          {reportDownloadErr}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

        )
      }
      {t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>}
    </div>
  )
}