/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState,useRef } from "react";
import { useTranslation } from "react-i18next";
import searchIcon from '../../../assets/icons/search.svg';
import downloadMasterIcon from '../../../assets/icons/download-master.svg';
import viewIcon from "../../../assets/view.svg";
import { FaPause } from "react-icons/fa";
import confirmIcon from "../../../assets/icons/confirm.svg";
import { FaRegCheckSquare } from "react-icons/fa";
import Edit2 from "../../../assets/edit2.svg";
import { DataGrid, EditModal } from "../../../common";
import { APIEndpoint } from "../../../utils/axios";
import moment from 'moment';
import { onDownloadCSVFile, getRemarks, updateButtonTxtStatus, translationLength } from "../../../utils/commonFunctions";
import {FilterBodyTemplate, MIMETypes, DOWNLOAD_STATUSCHECK_INTERVAL } from "../../../utils/configs";
import { useNavigate, useParams } from "react-router-dom";
import * as ReactDOM from "react-dom";
import { FaLock } from "react-icons/fa";
import CustomSelect from "../../../common/CustomSelect";
import usePermissions from "../../../utils/userPermissions";

export const PurchaseOrders = (props) => {

    const navigate = useNavigate();
    const urlParams = useParams();
    const { t } = useTranslation();
    const [state, setState] = useState({disabled:false,selected:false,isReloadTableData:false});
    const [perPageLimit, setPerPageLimit] = useState(10);
    const [searchKey, setSearchKey] = useState('');
    const [ modalTitle, setModalTitle ] = useState('View Purchase Order Items');
    const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
    const modelStyle = { maxWidth: '80%' };
    const [roiWarehouses,setROIwarehouses]=useState([]);
    const [updatedFormFieldValues, setUpdatedFormFieldValues] = useState({});
    const [modalCustomButtons, setModalCustomButtons] = useState(null);
    const [modalFormData, setModalFormData] = useState({});
    const [filterWarehouses, setFilterWarehouse] = useState([]);
    const [reportTypes, setReportTypes] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [filterSelWarehouse, setFilterSelWarehouse] = useState('');
    const [ isPOsOfLOI, setIsPOsOfLOI] = useState(false);
    const [ LOIDetails, setLOIDetails ] = useState(null);
    const stateRef = useRef(state);
    const { hasPermission: editPurchaseorder } = usePermissions('edit', "procurement.purchase-orders");
    const { hasPermission: downloadPurchaseorder } = usePermissions('download', "procurement.purchase-orders");
    const { hasPermission: approvePurchaseorder } = usePermissions('approve', "procurement.purchase-orders");

    const preCompile = {
        warehouse: 'Warehouse',
        selectWarehouses: 'Select Warehouses',
        selectReportType: 'Select Report Type',
    };

    let timeout;

const EditFormFields = [
    { label: 'View Purchase Order Items', type: 'viewPurchaseOrderItems', attribute: 'items' }
];

    const Columns = [
        { field: 'code', headerName: 'PO Code', resizable:true,checkboxSelection: false,
            minWidth: 167
        },
        { field: 'warehouseCode', headerName: 'Warehouse Name',
            minWidth: 250,
            tooltipField: 'warehouseName',
            valueGetter: params => params.data ? params.data?.warehouseCode + ' (' + params.data?.warehouseName + ')' : '',
        },
        { field: 'vendorCode', headerName: 'Vendor',
            minWidth: 250,
            tooltipField: 'vendorName',resizable:true,
            valueGetter: params => params.data ? params.data?.vendorCode + ' (' + params.data?.vendorName + ')' : '',
        },
        { field: 'createdAt', headerName: 'Created At',
            minWidth: 176,
            valueGetter: params => {
                return params.data ? moment(params.data?.createdAt).format('DD-MM-YYYY hh:mm A') : '';
            }
        },
        { field: 'createdBy', headerName: 'Created By',resizable:true,
            minWidth: 180,
            valueGetter: params => params.data ? params.data?.createdBy?.employeeName : ''
        },
        { field: 'finalizedAt', headerName: 'Finalized At',
            minWidth: 176,
            valueGetter: params => {
                return params.data && params.data?.finalizedAt ? moment(params.data?.finalizedAt).format('DD-MM-YYYY hh:mm A') : '';
            }
        },
        { field: 'finalizedBy', headerName: 'Finalized By',resizable:true,
            minWidth: 180,
            valueGetter: params => params.data ? params.data?.finalizedBy?.employeeName : ''
        },
        { field: 'status', headerName: 'Status',
            minWidth: 150,
            valueGetter: params => params.data ? params.data?.status.charAt(0).toUpperCase()+params.data?.status.slice(1): ''
        },
        { field: 'remarks', headerName: 'Remarks', resizable:true,
            minWidth: 160,
            maxWidth: 600,
            tooltipField: 'comment',
            valueGetter: params => getRemarks(params.data?.remarks, params.data?.comment),
        },
        { field: 'items', headerName: 'Summary',
            minWidth: 150,
            valueGetter: params => params.data ? params.data?.items?.length + ' items' : ''
        },
        { field: 'actions',headerName:"Actions",autoResize:true,
        minWidth:240,
         width:255,
         flex:1,
         resizable: false,
         suppressSizeToFit:true,pinned:'right',cellRenderer: ActionComponent }
    ];
    const [editFormFields, setEditFormFields] = useState(EditFormFields);

    useEffect( () => {
        if ( urlParams.hasOwnProperty('loiId') ) {
            setIsPOsOfLOI(true);
            setLOIDetails({ loiId: urlParams.loiId, loiCode: urlParams.loiCode || '' });
            fetchVendorsList();
            fetchPurchaseOrderRows(1, perPageLimit);
        } else {
            setIsPOsOfLOI(false);
            setLOIDetails(null);
            fetchPurchaseOrderRows(1, perPageLimit);
        }

    }, [urlParams] );
   
    useEffect( () => {
        /**
         * Load filter(warehouses) only after translation is available
         */
         stateRef.current = state;

    }, [state]);
    useEffect( () => {
        /**
         * Load filter(warehouses) only after translation is available
         */
        preCompile.warehouse = t('selectButtons.selWarehouse');
        preCompile.selectWarehouses = t('filters.selWarehouses');
        preCompile.selectReportType = t('filters.selReportType');

        loadReportTypes();
        getWarehouses();

    }, [t]);



    useEffect(() => {
        

        loadReportTypes();
        getWarehouses();

    }, []);

    useEffect( () => {
        /**
         * On filters change, filter datagrid
         */
        fetchPurchaseOrderRows(1, perPageLimit);

    }, [filterSelWarehouse]);



const EditFormFieldsRaisePO = [

    /**
     * The options array should contain objects.
     * Required keys are "name" and "value" but you can have and use any number of key/value pairs.
     */
    {key: 1, label: 'Warehouse', name: 'warehouseName', id: 'warehouseName', attribute: 'warehouseName', type: 'selectSearch'
        , defaultValue: ''
        , value: ''
        , className: 'select-search-cls'
        , options: []
        // , getOptions: () => {
        //     return
        // }
        /**
         * multiple(false): true/false
         * search(false): true/false
         * closeOnSelect(true): true/false
         * debounce(0): Number of ms to wait until calling get options when searching.
         * autoComplete(off): Options are on/off
         * autoFocus(false): true/false
         */
        , multiple: false
        , search: true
        // , closeOnSelect: false
        // , debounce: 1000
        , autoFocus: false,
        autoComplete:'on',
        placeholder: 'Start typing...'
        , disabled: false,required: true

    },

    {key: 2, label: 'Vendor Code', name: 'vendorId', id: 'vendorId', attribute: 'vendorId',type: 'selectSearch',
         required: true,autoComplete: 'on',options: []
        , autoFocus: false, disabled: false, value: '',placeholder: 'Start typing...',search: true },

    { key: 3, label: 'Remarks', name: 'remarks', id: 'remarks', attribute: 'remarks', type: 'textarea',  rows: 4, cols: 42, disabled: false
        , placeholder: t('selectButtons.commentsOrRemarks'), className: 'wide-textarea-input' }
];

const [editFormFieldsPo, setEditFormFieldsPo] = useState(EditFormFieldsRaisePO);

useEffect( () => {
    if (updatedFormFieldValues) {
        if ( state.selected?.action === 'RaisePO' && editPurchaseorder) {
            const extraFields = {};
            if (updatedFormFieldValues.warehouseName) {
                const selectedWarehouse = roiWarehouses.find( item => item.value === updatedFormFieldValues.warehouseName );
                extraFields.warehouseName = selectedWarehouse.value;
            }
            if (updatedFormFieldValues.vendorId) {
                const selectedVendor = vendors.find( item => item.value === updatedFormFieldValues.vendorId );
                extraFields.vendorId = selectedVendor?.value;
            }
            setState((_prev) => {
                return { ..._prev, selected: { ..._prev.selected, ...updatedFormFieldValues, ...extraFields } };
            });
        }

    }
}, [updatedFormFieldValues] );

    const checkDownloadStatus = async (jobId, e) => {
        await APIEndpoint.get('downloads/status/' + jobId).then( resp => {

            if (resp.status === 'completed') {
             
                
                clearTimeout(timeout)
             
                
                // updateButtonTxtStatus(true, e, 'Downloaded', false);

                // https://dfp.ngopos.com/api/downloads/analytics/PO%20vs%20GRN%20Report-2022-12-19T19-33--56c0695a-604a-4a38-9097-b8b815063e68.csv

                onDownloadCSVFile('downloads/analytics/' + resp.fileName + resp.fileExtension, 'purchase-order-report-a');
                updateButtonTxtStatus(true, e, 'Downloaded', false, { defaultButtonText: 'Download' });

                setState((_prev) => {
                    return { ..._prev, selected: false };
                });
                setModalFormStatusObj({ text: '' });

            }
            else {
                // checkDownloadStatus(jobId, e);

                timeout = setTimeout(() => {
                  
                    checkDownloadStatus(jobId, e);
                }, 5000);

                // interval = setInterval( () => {
                //     console.log("Download status check: After Interval", JSON.stringify(resp));
                //     checkDownloadStatus(jobId, e);
                // }, DOWNLOAD_STATUSCHECK_INTERVAL);
            }

        }).catch( error => {
          
            updateButtonTxtStatus(false, e, 'Download failed', false);
        });
    };


    function ActionComponent(props){

        if(!props.data) return null
        return (
            <>
            {props.data && props.data.status !== 'draft' && (
              <div className="flexRow justifyContentSpaceBetween" style={{padding:'10px'}}>
                <img 
                  src={viewIcon} 
                  className="action-Component-items margTop5" 
                  alt='edit' 
                  title='View Items' 
                  onClick={() => onActionInner("viewItems", props.data)} 
                />
                
                {props.data.status !== 'suspended' && (
                  <span
                    title="Suspend"
                    onClick={() => onActionInner('Suspend', props.data)}
                    className="grid-LOI-stylebutton-po-suspend margLeft8"
                  >
                    <FaPause size={10} style={{ marginRight: '4px' }} /> Suspend
                  </span>
                )}
                
                <span
                  title="GRN"
                  onClick={() => onActionInner('grn', props.data)}
                  className="grid-LOI-stylebutton-po"
                >
                  <FaRegCheckSquare size={10} style={{ marginRight: '4px' }} /> GRN
                </span>
              </div>
            )}
          
            {props.data && props.data.status === 'draft' && (
              <div className="flexRow justifyContentSpaceBetween" style={{padding:'10px'}}>
              <span
                title={"Finalize"}
                onClick={() => onActionInner("finalize", props.data)} 
                className="grid-LOI-stylebutton-fin"
                    >
                      <FaLock color={'#be6b58'} size={10} /> Finalize
                    </span>

                <img 
                  src={Edit2} 
                  className="action-Component-items" 
                  alt='edit' 
                  title='Edit Items' 
                  onClick={() => onActionInner("edit", props.data)} 
                />
              </div>
            )}
          </>
          
        )
      }
      const handleReset = () => {
       
        let timerId = setTimeout(() => {
          clearTimeout(timerId);
          setModalFormStatusObj({text:""})
          setState((_) => {
            return { ..._, selected: false,disabled:false,isReloadTableData:!_.isReloadTableData };
          })
          onClose();
        }, 2500)
      }
    
    const onActionInner = (action, row) => {
        let updatedEditFields = [];

        if (action === 'Suspend' && editPurchaseorder) {
            setModalTitle('Suspend Purchase Order - ' + row.code);

            updatedEditFields = [
                {
                    label: 'You will not be able to update this purchase order once you suspend it. Are you sure you want to continue?',
                    attribute: 'alertMessage', type: 'alertMessage'
                },
                { label: 'Remarks', attribute: 'remarks', type: 'textarea', required: true, rows: 10, cols: 50, disabled: state.disabled, placeholder: 'Please enter suspend remarks', className: 'wide-textarea-input' }
            ];
            setModalCustomButtons([
          
                { label: 'Suspend ', attribute: 'button', type: 'button', disabled: state.disabled, className: 'submit-button', action: 'onSubmit'
                    // , action: (e) => {  onSubmit(e); return false; }
                }
            ]);

        }
        else if (action === 'finalize' && approvePurchaseorder) {
            setModalTitle('Finalize Purchase Order - ' + row.code);

            updatedEditFields = [
                {
                    label: 'You will not be able to update this purchase order once you finalize it. Are you sure you want to continue?',
                    attribute: 'alertMessage', type: 'alertMessage',
                },
            ];
            setModalCustomButtons([
                { label: 'Finalize', attribute: 'button', type: 'button', disabled: state.disabled, className: 'submit-button', action:()=>onSubmit()}
            ]);
         setState((prev)=>({...prev,selected: {...row,...prev.selected,action:'finalize' }}))

        }
        else if (action === 'grn' && editPurchaseorder) {
            navigate('/procurement/purchase-orders/' + row.warehouseId + '/' + row.purchaseOrderId + '/' + row.id + '/grn/list',{ state: {...row}});
        }
        else if (action === 'downloadPOReportsPopup') {
            // setState( _prev => {
            //     return { ..._prev, selected: 'downloadPOReportsPopup' };
            // });

            setModalTitle('Download Reports');

            updatedEditFields = [


                // { key: 1, label: 'Report Type', name: 'reportType', id: 'reportType', attribute: 'reportType', type: 'select', disabled: false,
                //     options: reportTypes || [], required: true, className: 'text-input' },

                { key: 1, label: 'Report Type', name: 'reportType', id: 'reportType', attribute: 'reportType', type: 'select', disabled: false,

                    required: true, className: 'select-search-cls'
                    , options: reportTypes
                    // , getOptions: () => {
                    //     return getReportTypeOptions();
                    // }

                    // , defaultValue: ''
                    // , value: ''
                    // , className: 'select-search-cls'
                    // , getOptions: () => {
                    //     return
                    // }
                    /**
                     * multiple(false): true/false
                     * search(false): true/false
                     * closeOnSelect(true): true/false
                     * debounce(0): Number of ms to wait until calling get options when searching.
                     * autoComplete(off): Options are on/off
                     * autoFocus(false): true/false
                     */
                    // , multiple: false
                    // , search: true
                    // // , closeOnSelect: false
                    // // , debounce: 1000
                    // , autoComplete: 'on'
                    // , autoFocus: false
                    // , placeholder: 'Start typing...'
                },
                { key: 2, label: 'Select Warehouses', name: 'selectWarehouses', id: 'selectWarehouses', attribute: 'selectWarehouses', type: 'select', disabled: false,
                    required: true, className: 'select-search-cls',
                    options: filterWarehouses
                    // getOptions: getReportTypeOptions()

                    // , defaultValue: ''
                    // , value: []

                    // , getOptions: () => {
                    //     return
                    // }
                    /**
                     * multiple(false): true/false
                     * search(false): true/false
                     * closeOnSelect(true): true/false
                     * debounce(0): Number of ms to wait until calling get options when searching.
                     * autoComplete(off): Options are on/off
                     * autoFocus(false): true/false
                     */
                    // , multiple: true
                    // , search: true
                    // , closeOnSelect: false
                    // , debounce: 1000
                    // , autoComplete: 'on'
                    // , autoFocus: false
                    // , placeholder: 'Start typing...'
                },


                { key: 3, label: 'Start Date', name: 'startDate', id: 'startDate', attribute: 'startDate', type: 'date', required: true, disabled: false,
                    placeholder: t('datePickerPlaceholder'),
                    className: 'date-input',
                    // startDate: moment().format()
                },
                { key: 4, label: 'End Date', name: 'endDate', id: 'endDate', attribute: 'endDate', type: 'date', required: true, disabled: false,
                    placeholder: t('datePickerPlaceholder'),
                    className: 'date-input',
                    // endDate: moment().format()
                },

            ];
            setModalCustomButtons([
        
                { label: 'Download', attribute: 'button', type: 'button', disabled: state.disabled, className: 'submit-button'
                    , action: 'onSubmit'
                    // , action: (e) => {
                    //     console.log('Started downloading reports...');
                    //     // onDownloadXlsFile('samples/downloads/Distributor_Mappings_Upload_Sample', 'Distributor_Mappings_Upload_Sample');
                    //     return false;
                    // }
                }
            ]);

        }
        else if(action==='edit'){
            navigate(`/procurement/purchase-orders/loi/raise-purchaseorder/create/${row.vendorId}`, { state: {...row}});
            
        }
        else {
            /** Here, Resetting default input fields to array then updating */
            setEditFormFields(EditFormFields);
            updatedEditFields = EditFormFields.filter( field => {
                if (field.type === 'viewPurchaseOrderItems') {
                    setModalTitle('Purchase Order - ' + row.code + ' (Letter of Intent - ' + row.letterOfIndentCode + ')');
                    field.value = row;
                }
                return field;
            });

            setModalCustomButtons([[]]);
        }
        setEditFormFields( updatedEditFields );

        setState((_prev) => {
            return { ..._prev, selected: { ...row, action: action} };
        });
    };

    const updateEditFormInputs = (reportTypes=null, warehouseArr=null) => {
        const updatedEditFields = editFormFields.filter( (field) => {

            if (reportTypes && field.attribute === 'reportType') {
                field.disabled = false;
                field.options = reportTypes;
                field.getOptions = () => {
                    return reportTypes;
                }
                setModalFormStatusObj({ text: '' });
            }
            if (warehouseArr && field.attribute === warehouseArr['id'] ) {
                field.disabled = false;
                field.options = warehouseArr['data'];
                field.getOptions = () => {
                    return warehouseArr['data'];
                }
                setModalFormStatusObj({ text: '' });
            }

            return field;
        });
      
        setEditFormFields( updatedEditFields );
    };

    const onModalFormInputChange = (e, newFormData) => {
        const { name, value } = e.target;

        let newUpdates = modalFormData ? { ...modalFormData } : {}
        newUpdates[name] = value;

        setModalFormData( { ...newFormData } );
    }

    const onClose = (e, action, row) => {
        setState((_prev) => {
            return { ..._prev, selected: false,disabled:false };
        });
        setModalFormStatusObj({ text: '' });
    };

    const validateFormInputs = (body, frm='Suspend') => {
        if (!body) {
            setModalFormStatusObj({ error: 'Input validation failed!', });
            return false;
        }

        if ( frm ==='Suspend' ) {
            if (!body.remarks) {
                setModalFormStatusObj({ error: 'Please enter remarks.' });
                return false;
            }
        }
        if ( frm ==='RaisePO' ) {
            if ((!body.warehouseName ||!body.vendorId)) {
                if(!body.warehouseName){
                    setModalFormStatusObj({ error: 'Please Select a warehouse.' });
                    return false;
                }

                else if(!body.vendorId){
                    setModalFormStatusObj({ error: 'Please Select a vendor.' });
                    return false;
                }
                else{
                    setModalFormStatusObj({ error: '' });
                    return true;
                }
            }   else{
                setModalFormStatusObj({ error: '',  });
                return true;
            }

        }

        if ( frm ==='downloadPOReportsPopup' && downloadPurchaseorder ) {
            if (!body.reportType) {
                setModalFormStatusObj({ error: 'Please select warehouse report type.' });
                return false;
            }
            else if (!body.startDate || !body.endDate) {
                if(!body.startDate&&!body.endDate){
                    setModalFormStatusObj({ error: 'Please select start and end dates.'});
                }else if (!body.startDate) {
                    setModalFormStatusObj({ error: 'Please select start date.'});
                } else if (!body.endDate) {
                    setModalFormStatusObj({ error: 'Please select end date.'});
                }
                return false;
            }
            else if ( !body.selectWarehouses || body.selectWarehouses.length === 0) {
                setModalFormStatusObj({ error: 'Please select warehouses.' });
                return false;
            }
        }
        return true;
    };

    const onSubmit = async (e, updated) => {

        const delData = stateRef.current;

        try {
            setState((_)=>({..._,disabled:true}))
            if ( state.selected?.action === 'Suspend') {
                setModalFormStatusObj({ text: 'Submitting...' });
                const body = {
                    ...updated,
                    indentId: state.selected.id,
                    warehouseId: state.selected.warehouseId,
                };
                if (!validateFormInputs(body) ) {
                    return false;
                }
                const res = await APIEndpoint.post('indents/suspend', body);
                if (res.success) {
                    setModalFormStatusObj({ text: res.message });
                    handleReset()
                }
                else {
                    setModalFormStatusObj({ error: JSON.stringify(res) });
                    handleReset()
                }
            }
            else if ( state.selected.action === 'downloadPOReportsPopup' && downloadPurchaseorder) {
                updateButtonTxtStatus(true, e, 'Reports downloading...', true);
                console.log('Started downloading reports...', updated);

                if (!validateFormInputs(updated, 'downloadPOReportsPopup') ) {
                    updateButtonTxtStatus(true, e, 'Download', false);
                    return false;
                }

                try {
                    const reportTypeObj = reportTypes.find( row => updated.reportType === row.value);
                    if ( !reportTypeObj ) {
                        setModalFormStatusObj({ error: 'Invalid report type selected, Please check.' });
                        updateButtonTxtStatus(true, e, 'Download', false);
                        return false;
                    }

                    const warehouseIds = [];

                    const warehouseObj = filterWarehouses.find( row => row.value === updated.selectWarehouses )
                    if ( warehouseObj ) {
                        warehouseIds.push( warehouseObj.id );
                    }


                    const downloadPayload = {
                        startDate: moment(updated.startDate).format(),
                        endDate: moment(updated.endDate).format(),
                        warehousereportType: reportTypeObj.id,
                        warehouseId: warehouseIds
                    };

                    APIEndpoint.post('warehouse/reports', downloadPayload, { Accept: MIMETypes.csv }).then( resp => {

                        if (resp.status === 'scheduled') {

                            console.log("Download status check: Initial");
                            checkDownloadStatus(resp.id, e);

                        } else {
                            updateButtonTxtStatus(true, e, 'Download', false);
                        }

                    })
                    .catch( error => {
                        console.log('Log: DownloadLOI error:', error);
                        updateButtonTxtStatus(false, e, 'Download failed', false);
                    });
                }
                catch( er ) {
                    console.log('Error while downloading report...', er.message);
                }

                // onDownloadXlsFile('samples/downloads/Distributor_Mappings_Upload_Sample', 'Distributor_Mappings_Upload_Sample');
                // updateButtonTxtStatus(true, e, 'Downloaded', false, { defaultButtonText: 'Download' });
                // return false;
            }
            else if ( state.selected?.action === 'viewItems' ) {
                setState((_prev) => {
                    return { ..._prev, selected: false };
                });
            }
            else if(state.selected?.action==='RaisePO'){

                    setModalFormStatusObj({ text: 'Submitting...' });
                    const body = {
                        ...updated,
                        ...LOIDetails
                    };

                    if (!validateFormInputs(body, 'RaisePO') ) {
                        return false;
                    }

                    navigate(`/procurement/purchase-orders/loi/raise-purchaseorder/create/${delData.selected.vendorId}`, { state: {...body}});
                    
                }
                else if(delData.selected?.action==='finalize'){
                    const body={
                        warehouseId:delData.selected?.warehouseId,
                        indentId: delData.selected?.id
                    }
                    const res = await APIEndpoint.post('indents/finalize', body);
                    if (res.success) {
                        setModalFormStatusObj({ text: res?.message });
                        handleReset();
                    }
                   
                }

        }
        catch(error) {
            const errMsg=JSON.parse(error?.message)
            setModalFormStatusObj({ error: errMsg?.data?.msg|| 'Something went wrong. Please try again after some time.'});
            handleReset();
        }
    };

    const getWarehouseIdByName = () => {

        const warehouseObj = filterWarehouses.find( row => row.value === filterSelWarehouse );
        if (warehouseObj) {
            return warehouseObj.id * 1;
        }
        return '';
    };
    const fetchPurchaseOrderRows = async (page=1, perPage=10) => {
        const filterData = JSON.parse(JSON.stringify(FilterBodyTemplate));
        filterData.page = page;
        filterData.limit = perPage;
        filterData.searchParam = searchKey;
        if (filterSelWarehouse) {
            filterData.filters.push({warehouseId: getWarehouseIdByName()});
        }
        if (isPOsOfLOI && LOIDetails) {
            filterData.filters.push({ loiId: LOIDetails.loiId });
        }
        try {
            const res = await APIEndpoint.post('indents/process', JSON.stringify(filterData));
            return res;
        } catch (error) {

        }

    };

    const onPerLimitChange = (e) => {
        setPerPageLimit(Number(e.target.value));
    };

    const onSearch = (e) => {
        setSearchKey(e.target.value);
    };

    const onDonwloadReportsClick = (e) => {
        // onDownloadXlsFile('samples/downloads/Distributor_Mappings_Upload_Sample', 'Distributor_Mappings_Upload_Sample');
        onActionInner("downloadPOReportsPopup", {});

    }

    const onFilterWarehouseChange = (e) => {
        setFilterSelWarehouse(e?.value);
    }

    const getWarehouses = async () => {
        try {
        const resp = await APIEndpoint.get('warehouses');
        if (resp.result.length > 0) {
            const options = [{key: 0, label: preCompile.warehouse, value: ''}];
            resp.result.filter( (fieldValue, index) => {
              options.push({key: index+1, label: `${fieldValue.value}`, value: `${fieldValue.value}`, id: `${fieldValue.id}`});
              return fieldValue;
            });
            setROIwarehouses(options.map((row,index)=>({ key: index, label: row.label, name: row.value, value: row.id })).filter(row => row.value !== undefined))
            setFilterWarehouse(options);
        }
        } catch (error) {

        }

    }

    const loadReportTypes = async () => {
        try {
            const resp = await APIEndpoint.get('warehousereports/warehousereportTypes');
        if (resp.result.length > 0) {
            const options = [{key: 0, label: preCompile.selectReportType, value: '', id: ''}];
            resp.result.filter( (fieldValue, index) => {
              options.push({key: index+1, label: `${fieldValue.value}`, value: `${fieldValue.value}`, id: `${fieldValue.id}`});

              return fieldValue;
            });
            // updateEditFormInputs(options, null);
            setReportTypes(options);
        }
        } catch (error) {

        }

    }

    const getReportTypeOptions = async (query) => {
        // return new Promise((resolve, reject) => {
        //     fetch(`https://www.thecocktaildb.com/api/json/v1/1/search.php?s=${query}`)
        //         .then(response => response.json())
        //         .then(({ drinks }) => {
        //             resolve(drinks.map(({ idDrink, strDrink }) => ({ value: idDrink, name: strDrink })))
        //         })
        //         .catch(reject);
        // });

        return new Promise( (resolve, reject) => {
            APIEndpoint.get('warehousereports/warehousereportTypes')
            .then( resp => {
                if (resp.result.length > 0) {

                    // const options = [{key: 0, label: preCompile.selectReportType, value: ''}];
                    const newOptions = resp.result.map( (fieldValue, index) => ({key: index+1, label: `${fieldValue.value}`, value: `${fieldValue.value}`, id: `${fieldValue.id}`}) );
                    // console.log('newOptions:', newOptions);

                    updateEditFormInputs(newOptions, null);

                    resolve(newOptions);
                }
                else {
                    reject();
                }
            })
            .catch ( reject );
        })
    }

    const updateEditFormValues = async (e, updatedFormObj) => {
        ReactDOM.flushSync( () => {
            if (e) {
                const selectedDistributor = reportTypes.find( item => item.value === e);
                const selectedWarehouse = roiWarehouses.find( item => item.value === e);
                const selectedvendor = vendors.find( item => item.value === e);



                /** Set to respective fields */
                const updatedEditFields = editFormFields.filter( (field) => {

                    if (selectedDistributor ) {
                        if ( field.attribute === 'reportType' ) {
                            field.value = selectedDistributor.label;
                        }
                    }

                    return field;

                });

                setEditFormFields( _prev => {
                    return [ ...updatedEditFields ] ;
                });

                const updatedEditFieldsROI = editFormFieldsPo.filter( (field) => {

                    if (selectedWarehouse ) {
                        if ( field.attribute === 'warehouseName' ) {
                            field.value = selectedWarehouse.value;
                        }
                    }
                    if (selectedvendor ) {
                        if ( field.attribute === 'vendorId' ) {
                            field.value = selectedvendor.value;
                        }
                    }

                    return field;

                });
                setEditFormFieldsPo( _prev => {
                    return [ ...updatedEditFieldsROI ] ;
                });
            }
        })


    };
    const fetchVendorsList = async () => {
        if (vendors.length > 1) {
            return false;
        }

        const filterData = {
            action: 4,
            collection: '',
            filterOperation: 'or',
            filters: [],
            limit: 1000,
            page: 1,
            searchParam: ''
        };
        setModalFormStatusObj({ error: 'Please wait, Loading vendors...' });
        /**
         * POST distributors/process
         */
        const itemsRes = await APIEndpoint.get('warehouses/vendors/enumerate', filterData);
        const vendorsList = [];

        if (itemsRes.success) {
            itemsRes.items.filter( row => {
                vendorsList.push({ key: row.id, label: row.code, name: row.code, value: row.id });
                return row;
            });
            setVendors(vendorsList);
            setModalFormStatusObj({ text: '' });
        }
    };


    const onAddItemHandler=async()=>{
        fetchVendorsList();
        setModalTitle('Raise Purchase Order');
      ReactDOM.flushSync( () => {
            setState( (_prev) => {
                delete _prev.selected;
                return { ..._prev, selected: { action: 'RaisePO' } };
            });

            setUpdatedFormFieldValues({});


            setModalCustomButtons([
       
                { label: 'Save', attribute: 'button', type: 'submit', disabled: state.disabled, className: 'submit-button', action: 'onSubmit'
                    // , action: (e) => {  onSubmit(e); return false; }
                }
            ]);


            /** Here, Resetting default input fields to array then updating */
            const updatedEditFields = EditFormFieldsRaisePO.filter( (field) => {

                if (vendors.length > 1 && field.attribute === 'vendorId') {

                    field.disabled = false;
                    field.options = vendors;
                    field.getOptions = () => {
                        return vendors;
                    }
                    setModalFormStatusObj({ text: '' });
                }

                if (filterWarehouses.length > 1 && field.attribute === 'warehouseName') {
                    field.disabled = false;
                    field.options = roiWarehouses
                     field.getOptions = () => {
                        return roiWarehouses
                    }
                    setModalFormStatusObj({ text: '' });
                }

                return field;
            });

            setEditFormFieldsPo( updatedEditFields );

        });


    }
    return (
        <>
            <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
                {
                    <>
                       { downloadPurchaseorder?<div className={ "flexRow alignItemsCenter margBot10 " + (isPOsOfLOI ? 'justifyContentSpaceBetween' : 'justifyContentFlexEnd')} >
                            {isPOsOfLOI ? <h5>Purchase Orders - {LOIDetails.loiCode}</h5> : null }
                            <button className='download dropdownStyle1-TextMedium margRight' onClick={(e) => onDonwloadReportsClick(e)}><span><img className="icon-size" alt='download reports' src={downloadMasterIcon} /><u>{t('filters.downloadReports')}</u></span></button>
                        </div>:<></>}
                        <div className="flexRow width100 margBot10 margTop8 justifyContentSpaceBetween">
                        <div className="flexRow justifyContentFlexStart alignItemsCenter">
                        {LOIDetails && (
                        <div className="flexRow justifyContentFlexStart">
                        <button className="add-item-button m-2" onClick={onAddItemHandler}>
                        {t('buttons.raisePO')}
                        </button>
                        </div>
                        )}

                        <div className="flexRow justifyContentFlexEnd">
                        <button className="saleType-div m-2 margRight10">
                        <CustomSelect
                        options={filterWarehouses}
                        placeHolder="Select Warehouse"
                        onChange={onFilterWarehouseChange}
                        />
                        </button>
                        </div>
                        </div>
                        <div className="flexRow justifyContentCenter alignItemsCenter content-tabs">
                        <div className="underline margTop8 m-2">
                        <span><img className="icon-size" alt="search" src={searchIcon} /></span>
                        <span><input className="search" onChange={onSearch} type="search" placeholder="Search" value={searchKey} /></span>
                        </div>
                        </div>
                        <div className="flexRow content-tabs"></div>
                        </div>


                        <DataGrid
                            className="full-flex"
                            columns={Columns}
                            initialPageLimit={perPageLimit}
                            showDefaultFilters={false}
                            searchKey={searchKey}
                            selWarehouse={filterSelWarehouse}
                            getRows={ fetchPurchaseOrderRows }
                            rowSelection="multiple"
                            columnResize={false}
                            resetDataSource={state.isReloadTableData}
                        />
                    </>
                }
                {
                    state && Boolean(state.selected) ? (
                        <EditModal
                            title={ modalTitle }
                            customModalStyle={ modelStyle }
                            onClose={onClose}
                            onSubmit={onSubmit}
                            modalFormStatusObj={modalFormStatusObj}
                            modalCustomButtons={modalCustomButtons}
                            fields={state.selected.action!=='RaisePO'?editFormFields.map( (elt) => {
                                    if (state.selected.action === 'viewItems'
                                        || state.selected.action === 'Suspend'
                                        || state.selected.action === 'downloadPOReportsPopup' ) {
                                        return {...elt, initialValue:elt.attribute==='remarks'?"":(state.selected[elt.attribute] || '')}
                                    }
                                    else {
                                        return {...elt, initialValue: '' }
                                    }
                                }):editFormFieldsPo?.map( (elt) => {
                               
                                        return {...elt, initialValue:state.selected[elt.attribute] || ''}
                                    }

                                )
                            }
                            showFieldsInColumn={true}

                            onChange={ (e, updated) => {
                                setModalFormStatusObj({ text: '' });
                                setUpdatedFormFieldValues(updated);

                                if (typeof e === 'string') { // Search select
                                    updateEditFormValues(e, updated);
                                }
                                else {
                                    onModalFormInputChange(e, updated);
                                }

                            }
                        }
                        />
                    )
                    : null
                }
                {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }
            </div>
        </>
    );
}