
import React, { useRef, useState } from 'react'
import { APIEndpoint } from '../../../utils/axios';
import { DataGrid, EditModal, UploadModal } from '../../../common';
import { PageSizes } from "../../../utils/configs";
import { onDownloadXlsFile,translationLength } from '../../../utils/commonFunctions';
import { useTranslation } from 'react-i18next';
import editIcon2 from "../../../assets/edit2.svg";
import searchIcon from '../../../assets/icons/search.svg';
import uploadItemIcon from '../../../assets/icons/upload-items.svg';
import usePermissions from '../../../utils/userPermissions';
const Columns = [
  { field: 'hsn', headerName: 'HSN Code', flex:1,resizable:true,minWidth:130, suppressSizeToFit:true},
  { field: 'cgst',headerName: 'CGST', flex:1, suppressSizeToFit:true },
  { field: 'sgst',headerName: 'SGST' , flex:1, suppressSizeToFit:true },
  { field: 'utgst', headerName: 'UTGST',flex:1, suppressSizeToFit:true },
  { field: 'igst', headerName: 'IGST',flex:1, suppressSizeToFit:true },
  { field: 'cess',headerName: 'Cess' ,flex:1, suppressSizeToFit:true },
];

const EditFormFields = [
  {label: 'HSN Code', attribute: 'hsn', type: 'text', disabled: true,required:true },
  {label: 'CGST', attribute: 'cgst', type: 'number', disabled: false,required:true },
  {label: 'SGST', attribute: 'sgst', type: 'number', disabled: false,required:true },
  {label: 'UTGST', attribute: 'utgst', type: 'number', disabled: false,required:true },
  {label: 'IGST', attribute: 'igst', type: 'number', disabled: false,required:true },
  {label: 'Cess', attribute: 'cess', type: 'number', disabled: false,required:true },
];

export const Taxmaster = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({});
  const tableRef = useRef();
  const [ uploadedFile, setUploadedFile ] = useState([])
  const [ uploadFileStatusBlock, setUploadFileStatusBlock ] = useState('');
  const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
  const [perPageLimit, setPerPageLimit] = useState(10);
  const [searchKey, setSearchKey] = useState('');
  const [selItemType, setItemType] = useState('');
  const [showUpload, setShowUpload] = useState(false);
  const [actionItems, setActionItems] = useState([]);

  const { hasPermission: editTaxMaster } = usePermissions('edit', "configuration.tax-master");
  const { hasPermission: createTaxMaster } = usePermissions('create', "configuration.tax-master");
  const { hasPermission: uploadTaxMaster } = usePermissions('upload', "configuration.tax-master");
  React.useEffect( () => {
    refreshTable();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selItemType, tableRef]);

  const preCompile = {
    itemType: t('selectButtons.selItemType'),
    category: t('selectButtons.selCategory'),
    subCategory: t('selectButtons.selSubCategory'),
    unit: t('selectButtons.selUnit'),
    section:t('selectButtons.selSection'),
    uploadTitle:t('pages.inventoryContent.taxMaster.uploadTaxInfo')
  }

  React.useEffect( () => {
    setActionItems([{ title: 'Edit', detail:[{src: editIcon2,value:"EditItem",title:'Edit'}]}])
  }, []);

  const refreshTable = () => {
    tableRef.current.refreshTableHandler(selItemType);
  };

  const onAction = (e, action, selectedRowData) => {
    setState( (_prev) => {
      return { ..._prev, selected: {...selectedRowData,  action: 'editTaxMaster' }}
    });

    /**
     * Set to edit form
     */
    EditFormFields.map( editRow => {
      if (editRow.attribute === 'itemType' ) {
        editRow.value = selectedRowData.itemType;
      }
      if (editRow.attribute === 'itemCode') {
        editRow.disabled = true;
      }
      if (editRow.attribute === 'category' ) {
        editRow.value = selectedRowData.category;
      }

      return editRow;
    });

  };


  const handleReset = () => {
    setState((_) => {
      return { ..._, resetDataSource: true };
    })
    let timerId = setTimeout(() => {
      clearTimeout(timerId);
      setState((_) => {
        return { ..._, resetDataSource: false };
      })
    }, 500)
  }

  const onClose = () => {
    setState((_prev) => {
      return { ..._prev, selected: false,resetDataSource:modalFormStatusObj.text?.length>0?true:false }
    });
    setModalFormStatusObj({ text: '' });

  }

  const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 10,
    page: 1,
    searchParam: ''
  };

  const fetchTaxMaster = async (page=1, perPage=10) => {
    filterData.page = page;
    filterData.limit = perPage;
    filterData.searchParam = searchKey;
    const res = await APIEndpoint.post('taxes/process', JSON.stringify(filterData));
    return res;
  }
  const onEditSubmit = async (e, updated,editHsn,mode) => {
    if(mode==='Edit HSN Mapping' && editHsn!==undefined && editTaxMaster){
      if (updated) {
        const body = { ...state.selected, ...updated };
        try {
          const res= await APIEndpoint.put(`taxes/${state.selected.id}`, body);
          if(res.success){
            setModalFormStatusObj({ text: res.msg });
            setTimeout(()=>{
              onClose()
            },1500)
            handleReset();
          }
        }
        catch (error) {
          let {data}=JSON.parse(error.message)
          setModalFormStatusObj({ error:data.message})
         }
      }else{
        const body = { ...state.selected };
        try {
          const res= await APIEndpoint.put(`taxes/${state.selected.id}`, body);
          if(res.success){
            setModalFormStatusObj({ text: res.msg });
            setTimeout(()=>{
              onClose()
            },1500)
            handleReset();
          }
        }
        catch (error) {
          let {data}=JSON.parse(error.message)
          setModalFormStatusObj({ error:data.message})
         }
      }

    }

    else{
    if (updated) {
      try {
        const res= await APIEndpoint.post(`taxes`,updated);
        if(res.success){
            setModalFormStatusObj({ text: res.msg });
            setTimeout(()=>{
              onClose()
            },1500)
        }
      }
      catch (error) {
        let {data}=JSON.parse(error.message)
        setModalFormStatusObj({ error:(data.message || data.msg)})
      }

    }
    else{
      setModalFormStatusObj({ error: "Please fill all mandatory fields*" });
    }
    }
  };

  const onAddItemHandler = () => {
    setState( (_prev) => {
      return { ..._prev, selected: { action: 'AddNewItem' } };
    });

    /**
     * Set to edit form
     */
     EditFormFields.map( editRow => {
      if (editRow.attribute === 'itemCode') {
        editRow.disabled = false;
      }
      return editRow;
    });
  }

  const onPerLimitChange = (e) => {
    setPerPageLimit(Number(e.target.value));
  };

  const onSearch = (e) => {
      setSearchKey(e.target.value);
  };


  const onUploadTaxMaster=()=>{
    setShowUpload(true)
  }
  const onUploadClose = () => {
    setUploadedFile([]);
    setUploadFileStatusBlock('');
    setShowUpload(false);
  }

  // eslint-disable-next-line no-unused-vars
  const onItemTypeChange = (e) => {
      setItemType(e.target.value);
  }
  const onDownloadSampleFile = () => {
    onDownloadXlsFile('samples/downloads/Tax_Master_Upload_Sample','Tax_Master_Upload_Sample')
  }
  const onUploadFileChangeHandler = async (e) => {
    const file=e.target.files[0];
    setUploadedFile(e.target.files[0]);
    setUploadFileStatusBlock('<p>Please wait while we process your data...</p>');

    const formData = new FormData();
    formData.append('scheduleLater', false);
    formData.append('listName', undefined);
    formData.append('targetSectorType', undefined);
    formData.append('stores', []);
    formData.append('warehouses', []);
    formData.append('file', file);

    try {
      await APIEndpoint.post('taxes/upload', formData);
      setUploadedFile([]);
      setUploadFileStatusBlock('<p>File Imported Successfully.</p>');
      handleReset();
    }
    catch(error) {
      e.target.value = null;
      setUploadedFile([]);
      let { status, data } = JSON.parse( error.message );
      const msgHeading = data?.Error || data?.message || error.message;

      let msgContent = "";
      if (status === 400) {
            data.errors.forEach( _ => {
            const lineNum = _.lineNumber;
            const errorMsgArr = [];
            _.errors.forEach(erObj => {
              errorMsgArr.push( erObj.field + ' | ' + erObj.message + ' \n<br>' );
            });

            msgContent += '<br>Line ' + lineNum + ': \n<br>' + errorMsgArr.join(' ');
        });
      }
      else if(status===0 && !data){
          msgContent += `Error occurred while uploading tax sheet!<br>Please try again.`;
      }
      else {
        msgContent += `Error occurred while uploading tax sheet!<br>Please fix the errors and re-upload.`;
      }

      setUploadFileStatusBlock("<p class='listErrorMessagesCls'>"+ msgHeading + "</p>" +
                                "<p class='listErrorMessagesCls sm-scrollbar border p-2 pt-1'>" + msgContent +"</p>"
                              );
    }

};


  return (
      <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      {
        <>
          {uploadTaxMaster?<div className="flexRow justifyContentFlexEnd alignItemsCenter margRight margBot10">
           <button className='upload dropdownStyle1-TextMedium' onClick={() => onUploadTaxMaster(true)}> <span><img  className="icon-size" alt='uploadItem' src={uploadItemIcon} /><u>{t('filters.uploadHSNMappings')}</u></span></button>
            </div>:<></>}
            <div className='flexRow width100 margBot10 justifyContentSpaceBetween'>
          <div className='flexRow width100 margBot10 margTop8 justifyContentSpaceBetween'>


              <div className="margLeft40 margBot10 underline">
                  <span><img  className="icon-size" alt={t('filters.searchText')} src={searchIcon} /></span>
                  <span><input className="search" onChange={onSearch} type="search" placeholder={t('filters.searchText')} value={searchKey}/></span>
              </div>
            {createTaxMaster?  <div className="margLeft flexRow justifyContentFlexEnd alignItemsBaseline">
                  <button className="add-hsnmap-button" onClick={ onAddItemHandler }>
                  {t('buttons.addHSNMapping')}
                  </button>

              </div>:<></>}
          </div>
          </div>

          <DataGrid
            ref={tableRef}
            className="full-flex"
            columns={[...Columns]}
            initialPageLimit={perPageLimit}
            showDefaultFilters={false}
            searchKey={searchKey}
            selItemType={selItemType}
            getRows={fetchTaxMaster}
            rowSelection="multiple"
            actions={actionItems}
            onAction={onAction}
            onAddItemHandler={() => onAddItemHandler()}
            columnResize={true}
            resetDataSource={state.resetDataSource}
           />
        </>
      }
{
        state && Boolean(state.selected) && editTaxMaster && createTaxMaster ? (
          <EditModal
            key={state.selected.action}
            title={(state.selected.action === 'editTaxMaster') ? 'Edit HSN Mapping' : 'Add HSN Mapping'}
            onClose={onClose}
            buttonLabel={t('buttons.save')}
            modalFormStatusObj={modalFormStatusObj}
            onSubmit={onEditSubmit}
            fields={EditFormFields.map((_) => {
              if(state.selected.action !== "editTaxMaster"){
                if(_.attribute === "hsn" && modalFormStatusObj.error){
                  _.disabled = false;
                }
              }else{
                if(_.attribute === "hsn"){
                  _.disabled = true;
                }
              }
              return { ..._, initialValue: state.selected[_.attribute] }
            })}
          />
        )
        : null
      }

       {
        showUpload &&
        <UploadModal
        modelTitle={preCompile.uploadTitle}
        onUploadClose={onUploadClose}
        onDownloadSampleFile={onDownloadSampleFile}
        uploadedFile = { uploadedFile }
        onUploadFileChangeHandler = { (e) => onUploadFileChangeHandler(e) }
        uploadFileStatusBlock = { uploadFileStatusBlock }

        />
      }
       {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }
    </div>
  )
}
