import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ItemMasterPage, Uplifts,Taxmaster,ManageStocks,MealmasterPage,Offloads,Reconciliations,CessMaster, UpliftsItem, ReconciliationsCreate } from "./inner-components";

export const InventoryPage = (props) => {
  return (
    <div className="flexRow full p-0 m-0">
      <div className='full-flex flexCol width100 p-0 m-0'>
        <Routes>
          <Route path="/" element={<Navigate replace to="item-master" />} />
          <Route path="/item-master" element={<ItemMasterPage />} />
          <Route path="/meal-master" element={<MealmasterPage />} />
          <Route path="/manage-stocks" element={<ManageStocks />} />
          <Route path="/uplifts">
            <Route path="" element={ <Uplifts /> } />
            <Route path="main/inventory/editor/edituplift/:id" element={ <UpliftsItem /> } />
            <Route path="main/inventory/editor/:warehouseid/:sectorid/:dateid" element={ <UpliftsItem /> } />
          </Route>
          <Route path="/tax-master" element={<Taxmaster/>} />
          <Route path="/cess-master" element={<CessMaster/>} />
          <Route path="/offloads" element={<Offloads/>} />
          <Route path="/reconciliations">
          <Route path="" element={<Reconciliations />} />
          <Route path="reconciliations-create/:opDt/:ftDt/:wId/:sId/:rId/:params" element={<ReconciliationsCreate />} />
          </Route>
        </Routes>
      </div>
    </div>
  )
}

