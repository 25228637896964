/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"; //, useRef
import { useParams, useNavigate,useLocation } from 'react-router-dom';
import Spreadsheet from "react-spreadsheet"; // , { createEmptyMatrix }
// import * as ReactDoM from "react-dom";
import { APIEndpoint, DFPAPIEndpoint } from "../../../utils/axios";
import { AlertModal } from "../../../common";
import { DEFAULT_SHEET_ROWS, UPLOADED_LOI_ATTACHMENT_KEY } from "../../../utils/configs";
import { updateButtonTxtStatus, validateCellAndGetData } from "../../../utils/commonFunctions";
import { FaRegCheckSquare ,FaSave} from "react-icons/fa";
import closeIcon from "../../../images/icons/Icon2.png"
import { BiSolidSelectMultiple } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import * as ReactDOM from 'react-dom';
import { useTranslation } from "react-i18next";

let spreadSheetErrTimout;
const sType = localStorage.getItem('sType');
const DOMAIN = (sType === 'dfp' ? DFPAPIEndpoint : APIEndpoint);

const iIndexes = {
    ITEM_CODE: 0,
    ITEM_NAME: 1,
    ITEM_UNIT: 2,
    ITEM_LOI_QTY: 3,
    ITEM_INT_QTY: 4,
    ITEM_RECV_QTY: 5,
    ITEM_QTY: 6,
    ITEM_REMARKS: 7
};
const sheetLabels = ['Item Code', 'Item Name','Unit','LOI Quantity','Indented Quantity','Received Quantity','Quantity', 'Remarks'];
const createEmptySheetRows = (noOfRows = DEFAULT_SHEET_ROWS,sheetData) => {
    let len = sheetData ? +sheetData.length:0
    const rows = [];
    for( var i=0; i < noOfRows; i++) {
        rows.push([
            {
                id: 'itemCode',
                value: '',
                className: 'cellClass cellClass_' + len + '_' + iIndexes.ITEM_CODE,
                width: '200px',
                showPopup: true
            },
            {
                id: 'itemName',
                value: '',
                className: 'cellClass cellClass_' + len + '_' + iIndexes.ITEM_NAME,
                width: '200px',
                showPopup: true
            },
            {
                id: 'unit',
                value: '',
                className: 'cellClass1',
                width: '400px',
                readOnly: true
            },
            {
                id: 'loiQuantity',
                value: '',
                className: 'cellClass1',
                width: '200px',
                readOnly: true

            },
            {
                id: 'indentedQuantity',
                value: '',
                className: 'cellClass1',
                width: '200px',
                readOnly: true
            },
            {
                id: 'receviedQuantity',
                value: '',
                className: 'cellClass1',
                width: '200px',
                readOnly: true
            },

            {
                id: 'quantity',
                value: '',
                className: 'cellClass1',
                width: '200px',
                type:'number',
                required:true,
                readOnly: false
            },

            {
                id: 'remarks',
                value: '',
                className: 'cellClass1',
                width: '200px',
                required:true,
                readOnly: false
            }
        ]);
        len++
    }
    return rows;
}

/**
 * Automatically creating rows
 */
// const initialData = createEmptyMatrix(10, sheetLabels.length);
/**
 * Manually creating rows
 */
const initialData = [];// createEmptySheetRows(DEFAULT_SHEET_ROWS - 1);

const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 100,
    page: 1,
    searchParam: ''
  };



export const PurchaseOrderCreate = (props) => {
    const urlParams = useParams();
    const {state:poState}=useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [sheetData, setSheetData] = useState(initialData);
    const [spreadsheetErrors, setSpreadsheetErrors] = useState([]);
    const [state, setState] = useState({ isInvalidAccess: false, message: '' });
    const modelStyle = { maxWidth: '50%', margin: 'auto 10% 100% 10%' };
    const [LOIDetails, setLOIDetails] = useState(null);
    const [count, setCount] = useState(1);
    const [isEditForm, setIsEditForm] = useState(false);
    const [formData, setCreateFormData] = useState(null);
    const [itemsEnumerate, setItemsEnumerate] = useState([]);
    const [LOIAttachment, setLOIAttachment] = useState(null);
    const [vendorItemsLoi,setVendorItemsLOI]=useState([]);
    const [activeCell, setActiveCell] = useState([])
    const [itemData, setItemData] = useState([])

    const targetEltCodes = document.getElementsByClassName("cellSelectionBlockItemCodes")[0];
    const targetEltNames = document.getElementsByClassName("cellSelectionBlockItemNames")[0];

    // const sheetRef = useRef(null);
    // const LOICELLComponent = (props) =>  {
    //     console.log('LOICELLComponent:', props);
    //     return <>11</>;
    // }
    // const LOIViewComponent = (props) =>  {
    //     console.log('LOIViewComponent:', props);
    //     return <>222</>
    // }

    async function getItemData(data=false,edit=false) {
        try {
            const res = !edit?itemsEnumerate:await DOMAIN.get(`indents/findById/${data?.id}`)
            
            if(!edit && !data && vendorItemsLoi && vendorItemsLoi.length && itemsEnumerate.length){
                setItemData(vendorItemsLoi.filter((_,index)=>itemsEnumerate?.find(item=>item.itemId===_.itemId)))
            }else if
            (edit && data && vendorItemsLoi && vendorItemsLoi.length && res.data.items.length){
                setItemData(vendorItemsLoi.filter((_,index)=>res.data.items.find(item=>item.itemId===_.itemId)))
            }else{
                setItemData(vendorItemsLoi)

            }
               
                        // }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect( () => {
        if (poState && !poState.code) {
            const attachment = localStorage.getItem( UPLOADED_LOI_ATTACHMENT_KEY );
            setLOIAttachment( JSON.parse(attachment) );
            setCreateFormData(poState);
            if (sheetData.length === 0) {
                setSheetData( createEmptySheetRows( DEFAULT_SHEET_ROWS ) );
            }
        } else {
            setIsEditForm(true);
            const attachment = localStorage.getItem( UPLOADED_LOI_ATTACHMENT_KEY );
            setLOIAttachment( JSON.parse(attachment) );
            getLOIItems(poState,true);
            getItemData(poState,true);

        }
    }, [ urlParams,poState ]);

    useEffect( () => {
    if(formData){
        getLOIItems(formData);
    }
    }, [formData]);
    useEffect( () => {
        if(vendorItemsLoi && vendorItemsLoi.length){
            setItemData(vendorItemsLoi);
        }
        }, [vendorItemsLoi]);

    useEffect( () => {
        if (LOIDetails && LOIDetails.length) {
           !poState.code? getItemData(false,false):getItemData(poState,true);
            setLOIItemsToSheet(LOIDetails);
        }
    }, [LOIDetails]);

    const checkSheetData=()=>{
        let data=false;
        sheetData?.map(upl=>{
            upl?.forEach((val,ind)=>{
                if(val&&val?.value){
                    data=true
                }
            })

        })
        return data
    }

    const getLOIItems = async(data,edit=false) => {
      
        const payload={
            "indentId": edit?data.id:null,
            "warehouseId":edit?data.warehouseId: parseInt(data.warehouseName),
            "vendorId": data.vendorId,
            "loiId": edit?data.letterOfIndentId:data.loiId
        }
        try {
            const [resLOI,resVendor,resItems] = await Promise.all([
                APIEndpoint.post('indents/getAutoPopulateLOIItems',payload),
                APIEndpoint.post('warehouses/loi/vendorItems',payload),
                APIEndpoint.get('items/enumerate'),

              ]);
              if (resLOI.success && resLOI.items.length && resVendor.success && resVendor.items.length) {
                const lOIDetails = resLOI.items;
                setLOIDetails(lOIDetails);
                setItemsEnumerate(resItems.items);
                setVendorItemsLOI(resVendor.items)
            }
        } catch (error) {
            console.log('Invalid page access!, Letter of Intent ID not found.', error.message);

            setState({ ...state, isInvalidAccess: true,
                message: "Invalid page access!, Letter of Intent ID not found, On click OK, you will be redirected to Letter of Intents page." });
        }
    };

    const setLOIItemsToSheet = (LOIDetails) => {

        let updatedSpreadsheetData = Object.assign([], sheetData);
        const items = LOIDetails;
        const itmLngth = items?.length;
 
        /**
         * Validate whether required items already plotted
         */
        let newRows = [];
        if ( items && itmLngth > 0 && updatedSpreadsheetData.length < itmLngth ) {

            let newlyRequiredRows = itmLngth - updatedSpreadsheetData.length;
            if (newlyRequiredRows < DEFAULT_SHEET_ROWS) {

                newlyRequiredRows = DEFAULT_SHEET_ROWS;
            }
            newRows = createEmptySheetRows(newlyRequiredRows);

        } else {
            newRows = createEmptySheetRows(DEFAULT_SHEET_ROWS);
        }

        updatedSpreadsheetData = [ ...newRows ];

        updatedSpreadsheetData.forEach( (sheetRow, i) => {
            if (items &&items.length && items[i] ) {
                updatedSpreadsheetData[i][iIndexes.ITEM_CODE].value = items[i].itemCode;
                updatedSpreadsheetData[i][iIndexes.ITEM_NAME].value = items[i].itemName;
                updatedSpreadsheetData[i][iIndexes.ITEM_UNIT].value = items[i].unit;
                updatedSpreadsheetData[i][iIndexes.ITEM_LOI_QTY].value = items[i].loiQuantity;
                updatedSpreadsheetData[i][iIndexes.ITEM_INT_QTY].value = items[i].loiIndentedQuantity;
                updatedSpreadsheetData[i][iIndexes.ITEM_RECV_QTY].value = items[i].loiReceivedQuantity;
                updatedSpreadsheetData[i][iIndexes.ITEM_QTY].value = items[i].requestedQuantity;
                updatedSpreadsheetData[i][iIndexes.ITEM_REMARKS].value = items[i].remarks;
                // sheetRef.setCellData(updatedSpreadsheetData[i]);
            }

        });

        // const updatedSpreadsheetData = sheetData.map( (row) => {
        //     const nextRow = [ ...row ];
        //     // nextRow.length = 10;
        //     nextRow.value = ;
        //     return nextRow;
        // } );

        setSheetData( [ ...updatedSpreadsheetData ]);

    };

    const addRowsToSheet = (rowsToAdd = 10) => {
        const newRows = createEmptySheetRows(rowsToAdd,sheetData);
        setSheetData( [...sheetData, ...newRows ]);
    };

    const removeRow = () => {
            let row = activeCell.row;
            let deletedData=sheetData.filter((data,index)=>{
                if(index!==row){
                    return data
                }
            })
            setSheetData(deletedData)
        };

    const saveSheetData = (e) => {
        clearTimeout(spreadSheetErrTimout)
        updateButtonTxtStatus(true, e, 'Saving...', true);

        const postItems = [];

        let inSheetData = [ ...sheetData ];

        inSheetData.map( (cellRow, idx) => {
            const itemCode = validateCellAndGetData(cellRow[iIndexes.ITEM_CODE]);
            const itemName = validateCellAndGetData(cellRow[iIndexes.ITEM_NAME]);
            const loiQuantity = validateCellAndGetData(cellRow[iIndexes.ITEM_LOI_QTY]);
            const loiIndentedQuantity = validateCellAndGetData(cellRow[iIndexes.ITEM_INT_QTY]);
            const loiReceivedQuantity = validateCellAndGetData(cellRow[iIndexes.ITEM_RECV_QTY]);
            const requestedQuantity = validateCellAndGetData(cellRow[iIndexes.ITEM_QTY]);
            const unit = validateCellAndGetData(cellRow[iIndexes.ITEM_UNIT]);
            const remarks = validateCellAndGetData(cellRow[iIndexes.ITEM_REMARKS]);

            if (itemCode && itemName && requestedQuantity>=0) {
                postItems.push({
                    index:idx,
                    itemCode,
                    itemName,
                    loiQuantity:parseInt(loiQuantity),
                    loiIndentedQuantity:parseInt(loiIndentedQuantity),
                    loiReceivedQuantity:parseInt(loiReceivedQuantity),
                    requestedQuantity: parseInt(requestedQuantity),
                    unit,
                    remarks
                });
            }
            else if (itemCode || itemName) {
                /**
                 * SET Error status
                 */
                // cellRow[iIndexes.ITEM_UNIT].className += ' errorCellData';
                // cellRow[iIndexes.ITEM_REQ_QTY].className= 'errorCellData';
                // cellRow[iIndexes.ITEM_RECI_QTY].className= 'errorCellData';
                // cellRow[iIndexes.ITEM_QTY].className= 'errorCellData';
                // cellRow[iIndexes.ITEM_REMARKS].className= 'errorCellData';

                updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' } );
                setSpreadsheetErrors([ { message: 'Please fill all required item details.' } ]);
            }
            return cellRow;
        });

        // sheetData.map( (row) => {
        //     const nextRow = [ ...row ];
        //     nextRow.length = 10;
        //     return nextRow;
        // } );

        setSheetData(inSheetData);

        if (!spreadsheetErrors.length && postItems.length === 0) {
            // show global alert message
            updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' } );
            setSpreadsheetErrors([ ...spreadsheetErrors, { message: 'We did not find anything to save!' } ]);
            return false;
        }

        if ( !postItems.length ) {
            updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' } );
            return false;
        }
       
        let data = {}, apiURL = '';
        if (isEditForm) {
          data=  {
                "warehouseId": poState.warehouseId,
                "vendorId": poState.vendorId,
                "loiId": poState.letterOfIndentId,
                "items": postItems,
                "remarks": "",
                "id": poState.id
            }
            apiURL = 'indents/update';
        }else {
            data = {
                remarks: poState.remarks,
                warehouseId: parseInt(poState.warehouseName),
                vendorId: poState.vendorId,
                loiId: poState.loiId,
                items: postItems
            }
            apiURL = 'indents/update';
        }

        APIEndpoint.post(apiURL, data).then( apiResp => {
            /**
             * Update success response
             */
            updateButtonTxtStatus(true, e, 'Redirecting...', true, { defaultButtonText: 'Save'} );
            setSheetData(initialData);
            setSpreadsheetErrors([ ...spreadsheetErrors, { message: apiResp.msg || 'Successfully created a Letter of Intent.' } ]);
            setTimeout( () => {
                goToLOIHome();
            }, 1500);
        })
        .catch( (error) => {
            const apiData = JSON.parse(error.message);
            updateButtonTxtStatus(false, e, 'Save failed', false, { defaultButtonText: 'Save' });
            if ( apiData?.data.errors ) {
                const errorMsg = apiData.data.errors.message;
                const errorArr = apiData.data.errors.errors;
                setSpreadsheetErrors([ ...spreadsheetErrors, { message: errorMsg, error: 1 },...errorArr ]);
                spreadSheetErrTimout=setTimeout(() => {
                    setSpreadsheetErrors([])
                }, 3500);
            }
            else if (apiData.msg) {
                setSpreadsheetErrors([ ...spreadsheetErrors, { message: apiData.msg, error: 1 } ]);
            }
        });

    };

    const showItemListPopup = (eltPosition, activeCellIn) => {
        if (activeCellIn.column === iIndexes.ITEM_CODE) {
            if (targetEltCodes) {
                targetEltCodes.style.display = 'block';
                targetEltCodes.style.top = ( eltPosition.height + eltPosition.top + 2 ).toFixed() + 'px';
                targetEltCodes.style.left = eltPosition.left.toFixed() + 'px';
            }
        } else if (activeCellIn.column === iIndexes.ITEM_NAME) {
            if (targetEltNames) {
                targetEltNames.style.display = 'block';
                targetEltNames.style.top = ( eltPosition.height + eltPosition.top + 2 ).toFixed() + 'px';
                targetEltNames.style.left = eltPosition.left.toFixed() + 'px';
            }
        }
    }

    const selectAllCells = (e) => {
        setCount((prev)=>prev+1)
        let updatedSheetData=[]
        if(count%2!==0){
            updatedSheetData = sheetData.map((row, rowIndex) => {
                return row.map((cell, colIndex) => {
                    return {
                        ...cell,
                        className: `selectcell-class cellClass cellClass_${rowIndex}_${colIndex}`
                    };
                });
            });
        }else{
            updatedSheetData = sheetData.map((row, rowIndex) => {
                return row.map((cell, colIndex) => {
                    return {
                        ...cell,
                        className: `cellClass cellClass_${rowIndex}_${colIndex}`
                    };
                });
            });
        }

    setSheetData(updatedSheetData);

    };

    const handleActiveCellChange = (activeCell) => {
        setActiveCell(activeCell)
        console.log('ActiveCell:', activeCell);

        //lsjhjhdsjfh
        if ( sheetData[activeCell.row][activeCell.column] ) {
            const cellDetailsObj = sheetData[activeCell.row][activeCell.column];
            if ( cellDetailsObj.hasOwnProperty('showPopup') && cellDetailsObj.showPopup === true ) {

                const sourceElt = document.getElementsByClassName('cellClass_' + activeCell.row + '_' + activeCell.column)[0];
                if ( sourceElt ) {
                    const eltPosition = sourceElt.getBoundingClientRect();

                    showItemListPopup(eltPosition, activeCell);
                }

            }

        }
    }

    const onClickPopupListItem = (e, row) => {
   
        setItemToSheet(row);
    }

    const setItemToSheet = (item) => {
        if (Object.keys(item).length === 0) {
            return 0;
        }

        let jsonSpreadsheetData = JSON.stringify( sheetData );
        let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData);

        updatedSpreadsheetData.map( (sheetRow, i) => {
            if (i === activeCell.row) {
                sheetRow[iIndexes.ITEM_CODE].value = item.itemCode;
                sheetRow[iIndexes.ITEM_NAME].value = item.itemName;
                sheetRow[iIndexes.ITEM_UNIT].value = item.unit;
                sheetRow[iIndexes.ITEM_LOI_QTY].value = item.loiQuantity;
                sheetRow[iIndexes.ITEM_INT_QTY].value = item.loiIndentedQuantity;
                sheetRow[iIndexes.ITEM_RECV_QTY].value = item.loiReceivedQuantity;
                sheetRow[iIndexes.ITEM_QTY].value = item.requestedQuantity;
                sheetRow[iIndexes.ITEM_REMARKS].value = item.remarks;
            }
            return sheetRow;
        });

        ReactDOM.flushSync( () => {
            setSheetData( [ ...updatedSpreadsheetData ]);
        });

        setActiveCell({});
        hideItemListPopup();
    }

    const hideItemListPopup = () =>{
        // if (activeCell.column === iIndexes.ITEM_CODE) {
            if (targetEltCodes) {
                targetEltCodes.style.display = 'none';
            }
        // } else if (activeCell.column === iIndexes.ITEM_NAME) {
            if (targetEltNames) {
                targetEltNames.style.display = 'none';
            }
        // }
    }


    const handleCellBlur = () => {
        console.log('CellBlur.');
    }
    const handleCellKeyDown = (keyboardEvent) => {
        console.log('keyDownEvent.', keyboardEvent.target.value);
    }
    const handleModeChange = (mode) => {
        console.log('ModeCHanged to:', mode);
    };
    const handleCellSelect = (selectedCell) => {
        console.log('SelectedCell:', selectedCell);
    };

    const goToLOIHome = () => {
        navigate('/procurement/letter-of-intents');
    };
    const handleOnContinue = async () => {
        goToLOIHome();
    }

    return (
        <>
            <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
            <div className="header m-2 mb-2 flexRow justifyContentSpaceBetween" >
            <div className="leftBlock alignItemsCenter flexRow">
            <button onClick={ (e) => saveSheetData(e) }
            className="alignItemsCenter add-item-button"><FaSave style={{marginRight:'5px'}}/>{ t('buttons.save') }</button> &nbsp;
            {checkSheetData()?<><button onClick={ (e) => selectAllCells(e) }
            className="alignItemsCenter flexRow add-item-button-all m-3">{count%2===0?<BiSolidSelectMultiple  style={{marginRight:'5px'}} />:<FaRegCheckSquare  style={{marginRight:'5px'}} />}Select All</button>   <button onClick={ (e) => removeRow(e) }
            className="alignItemsCenter flexRow remove-button"><RiDeleteBin6Line style={{marginRight:'5px'}} />{ t('buttons.deleteRows') }</button></>:<></> }&nbsp;
            </div>
            {spreadsheetErrors.length >= 1? <div className={`padding4 margTop9-5 alert alert-${spreadsheetErrors[0].message.includes('Successfully') ? 'success' : 'danger'}`}>
            {spreadsheetErrors.length >= 1 ? spreadsheetErrors[0]?.message : null}
            </div>:null}
            <button onClick={goToLOIHome}>
            <img src={closeIcon} alt=""/>
            </button>
            </div>
            <div className="se-titlebar">
            {<span>{!isEditForm?`Indent for ${formData?.loiCode||""}`:`Indent - ${poState?.code} (Letter of Intent - ${poState.letterOfIndentCode})`}</span>}
                   </div>
                   <div className="sheet-container sm-scrollbar">

                    <Spreadsheet
                        // ref={sheetRef}
                        data={sheetData}
                        columnLabels={sheetLabels}
                        darkMode={true}

                        // getBindingsForCell={[]}
                        onChange={setSheetData}
                        onActivate={handleActiveCellChange}
                        onBlur={handleCellBlur}
                        onKeyDown={handleCellKeyDown}
                        onModeChange={handleModeChange}
                        onSelect={handleCellSelect}

                        // Cell={LOICELLComponent}
                        // DataViewer={LOIViewComponent}

                    />

                    <div className=" flexRow justifyContentFlexStart alignItemsCenter margTop8">
                        <button onClick={ () => addRowsToSheet() }
                            className="add-item-button"title={ t('spreadsheet.addmoreRows') }>{ t('buttons.addRows') }</button> 
                    </div>
                    <div className = "cellSelectionBlockItemCodes sm-scrollbar">
                       <ul>
                        {
                           itemData.length?itemData.map((d,i)=>{
                                return <li key={i} onClick={(e) => onClickPopupListItem(e, d) }>{d.itemCode}</li>
                            }): <li key={0} >No Items Found</li>
                        }
                       </ul>
                    </div>
                    <div className = "cellSelectionBlockItemNames sm-scrollbar">
                       <ul>
                        {
                             itemData.length?itemData.map((d,i)=>{
                                return <li key={i} onClick={(e) => onClickPopupListItem(e, d) }>{d.itemName}</li>
                            }): <li key={0} >No Items Found</li>
                        }
                       </ul>
                    </div>
                </div>


                {
                    Boolean(state.isInvalidAccess) && (
                    <AlertModal
                        title={'Alert: Invalid Access'}
                        customModalStyle={ modelStyle }
                        description={ state.message }
                        onContinue={handleOnContinue}
                        onClose={handleOnContinue}
                    />
                    )
                }

            </div>
        </>
    );
}
