import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { WarehouseInventory, PilferageReportCreate,DistributorMappings, LetterOfIntents, LetterOfIntentsCreate,
  PurchaseOrders, GRNForPurchaseOrder, GRNForPurchaseOrderCreate, PilferageReporting,VendorMappings,
  PerishablePurchaseOrders, WarehouseTransfers, WarehouseTransfersCreate, WarehouseTransfersGRNList, WarehouseTransfersGRNCreate, PhysicalInventory,
  ReverseGRNList, ReverseGRNCreate, PurchaseRequisition, CatererInvoicePage,
  PurchaseOrderCreate} from "./inner-components";

export const WarehousePage = (props) => {
  return (
    <div className="flexRow full p-0 m-0">
      <div className='full-flex flexCol width100 p-0 m-0'>
        <Routes>
          <Route path="/" element={<Navigate replace to="inventory" />} />
          <Route path="/inventory" element={<WarehouseInventory />} />
          <Route path="/distributor-mappings" element={<DistributorMappings />} />
          <Route path="/letter-of-intents">
            <Route path="/letter-of-intents/" element={ <LetterOfIntents /> } />
            <Route path="/letter-of-intents/loi-items/create/:formInputs" element={ <LetterOfIntentsCreate /> } />
            <Route path="/letter-of-intents/loi-items/edit/:LOI_ID/:LOI_CODE" element={ <LetterOfIntentsCreate /> } />
          </Route>
          <Route path="/purchase-requisition" element={<PurchaseRequisition />}/>
          <Route path="/caterer-invoice" element={<CatererInvoicePage />}/>

          <Route path="/purchase-orders">
            <Route path="" element={ <PurchaseOrders /> } />
            <Route path="loi/:loiId/:loiCode" element={ <PurchaseOrders /> } />
            <Route path="reversegrn/list/:warehouseId/:grnId" element={ <ReverseGRNList /> } />
            <Route path="reversegrn/create/:warehouseId/:grnId" element={ <ReverseGRNCreate /> } />
            <Route path="loi/raise-purchaseorder/create/:formInputs" element={ <PurchaseOrderCreate /> } />
            <Route path=":warehouseId/:purchaseOrderId/:id/grn/list" element={ <GRNForPurchaseOrder /> } />
            <Route path=":warehouseId/:purchaseOrderId/:id/:invoiceInputs/grn/create" element={ <GRNForPurchaseOrderCreate /> } />
          </Route>
          <Route path="/perishable-purchase-orders" element={<PerishablePurchaseOrders />} />
          <Route path="/warehouse-transfers">
            <Route path="" element={ <WarehouseTransfers /> } />
            <Route path="items/create/:sourceWarehouseId/:destinationWarehouseId" element={ <WarehouseTransfersCreate /> } />
            <Route path="items/edit/:transferId" element={ <WarehouseTransfersCreate /> } />
            <Route path="grn/list/:warehouseId/:transferId" element={ <WarehouseTransfersGRNList /> } />
            <Route path="grn/create/:warehouseId/:transferId" element={ <WarehouseTransfersGRNCreate /> } />
          </Route>
          <Route path="/vendor-mappings" element={<VendorMappings />} />
          <Route path="/pilferage-reporting">
          <Route path="" element={<PilferageReporting />} />
          <Route path="pilferage/warehouses/:id" element={<PilferageReportCreate />} />
            </Route>
          <Route path="/physical-inventory" element={<PhysicalInventory />} />
        </Routes>
      </div>
    </div>
  )
}
