/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import ReactSpreadsheet from "react-spreadsheet"; // , { createEmptyMatrix }
import * as ReactDOM from 'react-dom';
import '../../../assets/styles/styles.scss'
import { APIEndpoint } from "../../../utils/axios";
import { FaRegCheckSquare ,FaSave} from "react-icons/fa";
import { BiSolidSelectMultiple } from "react-icons/bi";
import {AlertModal } from "../../../common";
import { RiDeleteBin6Line } from "react-icons/ri";
import { updateButtonTxtStatus, validateCellAndGetData } from "../../../utils/commonFunctions";
import { DEFAULT_SHEET_ROWS_PLIFERAGE } from "../../../utils/configs";
import { useTranslation } from "react-i18next";
import close from "../../../images/icons/Icon2.png"

const reasonTypes = [
    { id: 'lost', label: 'Lost' },
    { id: 'damage', label: 'Damage'  },
    { id: 'complimentary', label: 'Complimentary' },
    { id: 'suppliedToStaff', label: 'Supplied to Staff' },
    { id: 'expired', label: 'Expired' }
];
const iIndexes = {
    ITEM_CODE: 0,
    ITEM_NAME: 1,
    ITEM_REASON: 2,
    ITEM_QTY: 3,
    ITEM_UNIT: 4,
    ITEM_REMARKS: 5
};
const sheetLabels = ['Item Code', 'Item Name', 'Reason', 'Quantity', 'Unit', 'Remarks'];

const defaultCellFieldData = {
    id: 'id',
    value: '',
    defaultValue: '',
    className: 'cellClass',
    width: '200px',
    readOnly: false,
    type: 'string',
    required: true,
    showPopup: false
};

const createEmptySheetRows = (noOfRows = DEFAULT_SHEET_ROWS_PLIFERAGE,length=null) => {
    const rows = [];
    let i = length ? length:0
    for( var j=0; j < noOfRows; j++) {
        rows.push([
            {
                ...defaultCellFieldData,
                id: 'itemCode',
                className: 'cellClass cellClass_' + i + '_' + iIndexes.ITEM_CODE,
                showPopup: true
            },
            {
                ...defaultCellFieldData,
                id: 'itemName',
                className: 'cellClass cellClass_' + i + '_' + iIndexes.ITEM_NAME,
                showPopup: true
            },
            {
                ...defaultCellFieldData,
                id: 'reason',
                className: 'cellClass cellClass_' + i + '_' + iIndexes.ITEM_REASON,
                width: '400px',
                required:true,
                readOnly: true,
                showPopup: true,
            },
            {
                ...defaultCellFieldData,
                type:'number',
                id: 'quantity',
                showPopup: true,
                className: 'cellClass cellClass_' + i + '_' + iIndexes.ITEM_QTY,
                readOnly: false,
                required: true
            },
            {
                ...defaultCellFieldData,
                id: 'unit',
                className: 'cellClass cellClass_' + i + '_' + iIndexes.ITEM_UNIT,
                readOnly: false,
                required: true
            },
            {
                ...defaultCellFieldData,
                id: 'remarks',
                required:false,
                readOnly:false,
                className: 'cellClass cellClass_' + i + '_' + iIndexes.ITEM_REMARKS,
            }
        ]);
        i++;
    }
    return rows;
}

/**
 * Automatically creating rows
 */
// const initialData = createEmptyMatrix(10, sheetLabels.length);
/**
 * Manually creating rows
 */
const initialData = createEmptySheetRows( DEFAULT_SHEET_ROWS_PLIFERAGE );

export const PilferageReportCreate = (props) => {
    const urlParams = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [warehouseItemsFull, setWarehouseItemsFull] = useState([]);
    const [warehouseItemsFiltered, setWarehouseItemsFiltered] = useState([]);
    const [editTransferDetails, setEditTransferDetails] = useState({});
    const [state, setState] = useState({ isInvalidAccess: false, message: '', isEditForm: false });
    const [count, setCount] = useState(1);
    const [sheetData, setSheetData] = useState(initialData);
    const [spreadsheetErrors, setSpreadsheetErrors] = useState([]);
    const modelStyle = { maxWidth: '50%', margin: 'auto 10% 100% 10%' };
    const [activeCell, setActiveCell] = useState({});
    const [selectedCellsArr, setSelectedCellsArr] = useState([]);

    const targetEltCodes = document.getElementsByClassName("cellSelectionBlockItemCodes")[0];
    const targetEltNames = document.getElementsByClassName("cellSelectionBlockItemNames")[0];
    const targetEltReasonTypes = document.getElementsByClassName("cellSelectionBlockReasonTypes")[0];

    useEffect( () => {

      if ( urlParams.hasOwnProperty('id') ) {
            setState( { ...state, isEditForm: false } );
            getWarehouseItems(urlParams.id);
        }
    }, [ urlParams ]);

    useEffect( () => {
        if (Object.keys(editTransferDetails).length > 0) {
            setEditTransferItemsToSheet(editTransferDetails);
            getWarehouseItems(editTransferDetails.source.id);
        }
    }, [editTransferDetails]);
    const getWarehouseItems = (sourceWarehouseId) => {
        APIEndpoint.post('warehouses/pilferage/warehouseItems', { warehouseId: sourceWarehouseId })
        .then( resp => {
            if (resp) {
                const warehouseItems = resp.items;
                setWarehouseItemsFull(warehouseItems);
                setWarehouseItemsFiltered(warehouseItems);
            }
        })
        .catch( error => {
            console.log('Invalid page access!, Warehouse transfer details not found for given ID.', error.message);

            setState({ ...state, isInvalidAccess: true,
                message: "Invalid page access!, Warehouse transfer details are not found, On click OK, you will be redirected to Warehouse Transfer page." });
        });
    };


    const setEditTransferItemsToSheet = (transferDetails) => {
        let jsonSpreadsheetData = JSON.stringify( sheetData );
        let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData);
        const items = transferDetails.items;

        items.map( (item, i) => {
            updatedSpreadsheetData[i][iIndexes.ITEM_CODE].value = item.itemCode;
            updatedSpreadsheetData[i][iIndexes.ITEM_NAME].value = item.itemName;
            updatedSpreadsheetData[i][iIndexes.ITEM_REASON].value = item.reason;
            updatedSpreadsheetData[i][iIndexes.ITEM_QTY].value = item.quantity;
            updatedSpreadsheetData[i][iIndexes.ITEM_UNIT].value = item.unit;
            updatedSpreadsheetData[i][iIndexes.ITEM_REMARKS].value = item.remarks;

            return item;
        });

        ReactDOM.flushSync( () => {
            setSheetData( [ ...updatedSpreadsheetData ]);

        });

    };

    const addRowsToSheet = (rowsToAdd = 10) => {
        const existingRows = sheetData.length
        const newRows = createEmptySheetRows(rowsToAdd,existingRows);
        setSheetData( [...sheetData, ...newRows ]);
    };

    const removeRow = (e) => {
        if(count%2===0){
            let updatedSheetData = initialData.map((row, rowIndex) => {
                return row.map((cell, colIndex) => {
                    return {
                        ...cell,
                        className: `cellClass cellClass_${rowIndex}_${colIndex}`
                    };
                });
            });
            setSheetData(updatedSheetData);
        }else{
        let jsonSpreadsheetData = JSON.stringify( sheetData );
        let inSheetData = JSON.parse(jsonSpreadsheetData);
        let selectedRowIndex = activeCell.row
        if(selectedRowIndex){
            inSheetData.splice(selectedRowIndex,1)
        }else{
            inSheetData.pop()
        }

        /**
         * Remove a last row from the sheet
         */
        // setSheetData( (data) => {
        //     return data.slice(0, data.length -1)
        // });

        /**
         * Clear the content of the cell content
         */
        // console.log('selectedCellsArr:', selectedCellsArr);
        selectedCellsArr.map( selectedCell => {
            const defaultVal = inSheetData[selectedCell.row][selectedCell.column].defaultValue;

            inSheetData[selectedCell.row][selectedCell.column].value = defaultVal || '';

            return selectedCell;
        });

        ReactDOM.flushSync( () => {
            setSheetData([ ...inSheetData ]);
        });

		setSelectedCellsArr([]);
    }
    };

    const showValidationErrors = (e, inSheetData, errorClass='cellDataErrorCls' ) => {
        let haveErrors = false;
        const postItems = [];

        inSheetData.map( (cellRow, rowIdx) => {
            let isRowHaveError = false;
            let reasonOrg = ""

            const itemCode = validateCellAndGetData(cellRow[iIndexes.ITEM_CODE]);
            const itemName = validateCellAndGetData(cellRow[iIndexes.ITEM_NAME]);
            reasonOrg = reasonTypes.find(r=>r.label===cellRow[iIndexes.ITEM_REASON].value)?.id

            if (!itemCode || !itemName) {
                return cellRow;
            }

            for (let colIdx = 0; colIdx < Object.keys(iIndexes).length; colIdx++) {

                if ( cellRow[colIdx].required === true) {

                    const fieldValue = validateCellAndGetData(cellRow[colIdx]);
                    if ( !fieldValue ) {
                        haveErrors = true;
                        isRowHaveError = true;
                    }
                }
            }
            if ( !isRowHaveError && warehouseItemsFull.length ) {

                const item = warehouseItemsFull?.find( row => row.itemCode === cellRow[iIndexes.ITEM_CODE].value );
                if (item) {
                    postItems.push({
                        _id: item._id,
                        index: rowIdx,
                        itemId: item.itemId,
                        itemCode: item.itemCode, // cellRow[iIndexes.ITEM_CODE].value,
                        itemName: item.itemName, // cellRow[iIndexes.ITEM_NAME].value,
                        reason: cellRow[iIndexes.ITEM_REASON].value, //item.reason
                        transactionType: reasonOrg, //'lost', 'damage', 'complimentary', 'suppliedToStaff', 'expired'
                        quantity: parseInt(cellRow[iIndexes.ITEM_QTY].value),
                        unit: item.unit, // parseInt(cellRow[iIndexes.ITEM_AVAILABLE_STOCK].value),
                        remarks: cellRow[iIndexes.ITEM_REMARKS].value,
                    });
                }

            }
            return cellRow;
        });

        if (haveErrors) {
            // updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' } );
            setSpreadsheetErrors([ { message: 'Please fill all required item details.' } ]);
        }

        // setSheetData( [ ...inSheetData ]);

        // console.log('inSheetData after update:', inSheetData, postItems, haveErrors);
        return {
            sheetData: inSheetData,
            validRows: postItems,
            haveErrors
        };

    };
    const saveSheetData = (e) => {
        // updateButtonTxtStatus(true, e, 'Saving...', true);
        setSpreadsheetErrors([]);

        let inSheetData = Object.assign([], sheetData);
        const respArrayData = showValidationErrors(e, inSheetData);
        if (!spreadsheetErrors.length && respArrayData.validRows.length === 0 &&!respArrayData.haveErrors) {
            // show global alert message
            // updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' } );
            setSpreadsheetErrors([ ...spreadsheetErrors, { message: 'We did not find anything to save!' } ]);
            return false;
        }

        if ( respArrayData.haveErrors ) {
            // updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' } );
            return false;
        }

        if ( !respArrayData.validRows.length ) {
            // updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' } );
            return false;
        }

        const data = {
            warehouseId: urlParams.id,
            items: respArrayData.validRows || []
        }

        if (state.isEditForm === true) {
            data.transferId = editTransferDetails.id;

            data.sourceId = editTransferDetails.source.id;
            data.destinationId = editTransferDetails.destination.id;
        } else {
            data.sourceId = parseInt(urlParams.sourceWarehouseId) || '';
            data.destinationId = parseInt(urlParams.destinationWarehouseId) || '';
        }

        APIEndpoint.post('warehouses/pilferage/create', data)
        .then( apiResp => {
            /**
             * Update success response
             */
            setSheetData(initialData);
            updateButtonTxtStatus(true, e, 'Redirecting...', true, { defaultButtonText: 'Save'} );
            setSpreadsheetErrors([ ...spreadsheetErrors, { message: apiResp.msg || 'Successfully created Pilferage Report.' } ]);
            setTimeout( () => {
                goToModuleHome();
            }, 4000);
        })
        .catch( (error) => {
            const apiData = JSON.parse(error.message);
            setSpreadsheetErrors([]);
            console.log('Log: Error creating pilferage report:', error.message);
            // updateButtonTxtStatus(false, e, 'Failed to save...', true, { defaultButtonText: 'Save'} );

            ReactDOM.flushSync( () => {
                if ( apiData.data.errors ) {
                    const errorMsg = apiData.data.errors.message;
                    const errorArr = apiData.data.errors.errors;
                    setSpreadsheetErrors([ ...spreadsheetErrors, { message: errorMsg, error: 1 } ,...errorArr]);
                    // updateButtonTxtStatus(false, e, 'Please wait...', true, { defaultButtonText: 'Save'} );
                }
                else if (apiData.msg) {
                    setSpreadsheetErrors([ ...spreadsheetErrors, { message: apiData.msg, error: 1 } ]);
                    // updateButtonTxtStatus(false, e, 'Please wait...', true, { defaultButtonText: 'Save'} );
                }
                else {
                    setSpreadsheetErrors([ ...spreadsheetErrors, { message: 'Something went wrong, please try again later!', error: 1 } ]);
                    // updateButtonTxtStatus(false, e, 'Please wait...', true, { defaultButtonText: 'Save'} );
                }
            });

        });

        return false;
    };


    const handleActiveCellChange = (activeCell) => {
        setActiveCell({});
        hidePopupList();
        setSpreadsheetErrors([]);
        setActiveCell(activeCell);

        if ( sheetData[activeCell.row][activeCell.column] ) {
            const cellDetailsObj = sheetData[activeCell.row][activeCell.column];
            if ( cellDetailsObj.hasOwnProperty('showPopup') && cellDetailsObj.showPopup === true ) {

                const sourceElt = document.getElementsByClassName('cellClass_' + activeCell.row + '_' + activeCell.column)[0];
                if ( sourceElt ) {
                    const eltPosition = sourceElt.getBoundingClientRect();

                    showItemListPopup(eltPosition, activeCell);
                }

            }

        }

    }
    const handleCellBlur = () => {
        // setActiveCell({});
    }

    const handleCellKeyDown = (keyboardEvent) => {
        const searchText = keyboardEvent.target.value;

        if (activeCell && Object.keys(activeCell).length > 0) {
            if (sheetData[activeCell.row][activeCell.column].type === 'number' &&  keyboardEvent.target.value ) {
                keyboardEvent.target.value = keyboardEvent.target.value.replace(/[^0-9]$/g, '');
            }
        }

        // const eltPosition = keyboardEvent.target.getBoundingClientRect();
        // showItemListPopup(eltPosition);

        /**
         * Filter poup items
         */
        const searchPattern = new RegExp((searchText || '').replace(/[.*+?^=!:${}()|\[\]\/\\]/g, '\\$&'),'gi');
        const filteredList = warehouseItemsFull.filter( row => row.itemCode.search( searchPattern ) !== -1 );
        setWarehouseItemsFiltered([ ...filteredList ]);
    }

    const showItemListPopup = (eltPosition, activeCellIn) => {
        if (activeCellIn.column === iIndexes.ITEM_CODE) {
            if (targetEltCodes) {
                targetEltCodes.style.display = 'block';
                targetEltCodes.style.top = ( eltPosition.height + eltPosition.top + 2 ).toFixed() + 'px';
                targetEltCodes.style.left = eltPosition.left.toFixed() + 'px';
            }
        } else if (activeCellIn.column === iIndexes.ITEM_NAME) {
            if (targetEltNames) {
                targetEltNames.style.display = 'block';
                targetEltNames.style.top = ( eltPosition.height + eltPosition.top + 2 ).toFixed() + 'px';
                targetEltNames.style.left = eltPosition.left.toFixed() + 'px';
            }
        } else if (activeCellIn.column === iIndexes.ITEM_REASON) {
            if (targetEltReasonTypes) {
                targetEltReasonTypes.style.display = 'block';
                targetEltReasonTypes.style.top = ( eltPosition.height + eltPosition.top + 2 ).toFixed() + 'px';
                targetEltReasonTypes.style.left = eltPosition.left.toFixed() + 'px';
            }
        }
    }

    const hidePopupList = () =>{
        // if (activeCell.column === iIndexes.ITEM_CODE) {
            if (targetEltCodes) {
                targetEltCodes.style.display = 'none';
            }
        // } else if (activeCell.column === iIndexes.ITEM_NAME) {
            if (targetEltNames) {
                targetEltNames.style.display = 'none';
            }
        // } else if (activeCell.column === iIndexes.ITEM_NAME) {
            if ( targetEltReasonTypes ) {
                targetEltReasonTypes.style.display = 'none';
            }
        // }
    }


  

    const selectAllCells = (e) => {
        setCount((prev)=>prev+1)
        let updatedSheetData=[]
        if(count%2!==0){
            updatedSheetData = sheetData.map((row, rowIndex) => {
                return row.map((cell, colIndex) => {
                    return {
                        ...cell,
                        className: `selectcell-class cellClass cellClass_${rowIndex}_${colIndex}`
                    };
                });
            });
        }else{
            updatedSheetData = sheetData.map((row, rowIndex) => {
                return row.map((cell, colIndex) => {
                    return {
                        ...cell,
                        className: `cellClass cellClass_${rowIndex}_${colIndex}`
                    };
                });
            });
        }

    setSheetData(updatedSheetData);

    };

    const onClickPopupListItem = (e, row, type='reason') => {
        if (type === 'reason') {
            setReasonField(row);
        }
        else {
            setWarehouseTransferItemToSheet(row);
        }
    }
    const setReasonField = (reason) => {
        console.log('reason:', reason);
        if (Object.keys(reason).length === 0) {
            return 0;
        }

        let jsonSpreadsheetData = JSON.stringify( sheetData );
        let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData);

        updatedSpreadsheetData.map( (sheetRow, i) => {
            if (i === activeCell.row) {
                sheetRow[iIndexes.ITEM_REASON].value = reason.label;
            }

            return sheetRow;
        } );


        ReactDOM.flushSync( () => {
            setSheetData( [ ...updatedSpreadsheetData ]);
        });

        setActiveCell({});
        hidePopupList();
    }

    const setWarehouseTransferItemToSheet = (item) => {

        if (Object.keys(item).length === 0) {
            return 0;
        }

        let jsonSpreadsheetData = JSON.stringify( sheetData );
        let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData);

        updatedSpreadsheetData.map( (sheetRow, i) => {
            if (i === activeCell.row) {
                sheetRow[iIndexes.ITEM_CODE].value = item.itemCode;
                sheetRow[iIndexes.ITEM_NAME].value = item.itemName;
                sheetRow[iIndexes.ITEM_REASON].value = item.reason;
                sheetRow[iIndexes.ITEM_QTY].value = item.quantity;
                sheetRow[iIndexes.ITEM_UNIT].value = item.unit;
                sheetRow[iIndexes.ITEM_REMARKS].value = item.remarks;
            }

            return sheetRow;
        } );


        ReactDOM.flushSync( () => {
            setSheetData( [ ...updatedSpreadsheetData ]);
        });

        setActiveCell({});
        hidePopupList();
    }

    const handleModeChange = (mode) => {
        // if (mode === 'edit') {
        //     setEditingCell
        // }
    };

    const handleCellSelect = (selectedCell) => {
        if (selectedCell.length !== 0) {
            setSelectedCellsArr([ ...selectedCell ]);
        }
    };
    const onCellCommitHandle = (prevCell, nextCell, coordinates) => {
    }
    const goToModuleHome = () => {
        navigate('/procurement/pilferage-reporting');
    };
    const handleOnContinue = async () => {
        goToModuleHome();
    }

    return (
        <>
            <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
                <div>
                <div className="header m-2 flexRow justifyContentSpaceBetween">
                        <div className="leftBlock alignItemsCenter flexRow">
                            <button onClick={ (e) => saveSheetData(e) }
                                className="alignItemsCenter add-item-button margRight10"><FaSave style={{marginRight:'5px',marginBottom:"3px"}}/>{ t('buttons.save') }</button>
                          <button onClick={ (e) => selectAllCells(e) }
                                className="alignItemsCenter flexRow add-item-button-all margRight10">{count%2===0?<BiSolidSelectMultiple  style={{marginRight:'5px'}} />:<FaRegCheckSquare  style={{marginRight:'5px'}} />}Select All</button>   <button onClick={ (e) => removeRow(e) }
                                className="alignItemsCenter flexRow remove-button"><RiDeleteBin6Line style={{marginRight:'5px'}} />{ t('buttons.deleteRows') }</button> &nbsp;
                        </div>
                        {spreadsheetErrors.length >= 1? <div className={`padding4 margTop9-5 alert alert-${spreadsheetErrors[0].message.includes('successfully') ? 'success' : 'danger'}`}>
                        {spreadsheetErrors.length >= 1 ? spreadsheetErrors[0]?.message : null}
                        </div>:null}
                        <button onClick={goToModuleHome}>
                            <img src={close} alt="close" />
                        </button>
                    </div>
                </div>
                <div className="se-titlebar margBtm16">
               {<span>Pilferage New*</span>}
                   </div>
                <div className="sheet-container sm-scrollbar pilferageCreate">

            <ReactSpreadsheet
                data={sheetData}
                columnLabels={sheetLabels}
                darkMode={true}
                onChange={setSheetData}
                onActivate={handleActiveCellChange}
                onBlur={handleCellBlur}
                onKeyDown={handleCellKeyDown}
                onModeChange={handleModeChange}
                onSelect={handleCellSelect}
                onCellCommit={onCellCommitHandle}
                />
                </div>
                <div className="flexRow alignItemsCenter margTop8">
                        <button onClick={ () => addRowsToSheet() }
                            className="add-item-button" title={ t('spreadsheet.addmoreRows') }>{ t('buttons.addRows') }</button>
                    </div>
                <div className = "cellSelectionBlockItemCodes sm-scrollbar">
                {
                    warehouseItemsFiltered.length ? (
                    <ul>
                        {warehouseItemsFiltered.length && warehouseItemsFiltered.map( (row, i) => (
                            <li key={ i } onClick={ (e) => onClickPopupListItem(e, row, 'item') }>{ row.itemCode }</li>
                        ) )
                        }
                    </ul>
                    )
                    : (
                        <ul>
                            <li>No items Found!</li>
                        </ul>
                    )
                }
                </div>
                <div className = "cellSelectionBlockItemNames sm-scrollbar">
                {
                    warehouseItemsFiltered.length ? (
                    <ul>
                        {warehouseItemsFiltered.length && warehouseItemsFiltered.map( (row, i) => (
                            <li key={ i } onClick={ (e) => onClickPopupListItem(e, row, 'item') }>{ row.itemName }</li>
                        ) )
                        }
                    </ul>
                    )
                    : (
                        <ul>
                            <li>No items Found!</li>
                        </ul>
                    )
                }
                </div>
                <div className = "cellSelectionBlockReasonTypes sm-scrollbar">
                {
                    reasonTypes.length ? (
                    <ul>
                        {reasonTypes.length && reasonTypes.map( (row, i) => (
                            <li key={ i } onClick={ (e) => onClickPopupListItem(e, row, 'reason') }>{ row.label }</li>
                        ) )
                        }
                    </ul>
                    )
                    : (
                        <ul>
                            <li>No items Found!</li>
                        </ul>
                    )
                }
                </div>
                {
                    Boolean(state.isInvalidAccess) && (
                    <AlertModal
                        title={'Alert: Invalid Access'}
                        customModalStyle={ modelStyle }
                        description={ state.message }
                        onContinue={handleOnContinue}
                        onClose={handleOnContinue}
                    />
                    )
                }

            </div>
        </>
    );
}