import React ,{useState,useEffect} from  'react';
import {Chart as ChartJS,ArcElement,Tooltip,Legend} from 'chart.js'
import zoom from '../../assets/icons/zoom.svg';
import {Pie} from 'react-chartjs-2';
import { durationFilterSelect} from '../../utils/configs';
import DownloadVector from '../../assets/icons/svg/download-vector.svg';
import VerticalDivider from '../../assets/icons/svg/vertical-divider.svg';
import {downloadFileDashboard} from '../../utils/commonFunctions';
// import { useTranslation } from 'react-i18next';
import { APIEndpoint } from '../../utils/axios';


ChartJS.register(
    ArcElement,Tooltip,Legend
);

export const PieChart = ({chartLabel,data,options,filtersPie,setFilteredWidgetData,filtersChartZoom,setFiltersChartZoom,handleZoom,chartZoom}) => {

	// const { t } = useTranslation();
	const [filters,setFilters]=useState("")
	const [responseChart,setResponseChart]=useState({})

    useEffect( () => {
        setFilters(filtersPie?.sortBy)
       }, [filtersPie]);

	   const setView=()=>{
		if(filters){
		  setFiltersChartZoom(filters)
		  handleZoom(chartLabel,data,options)
		}
	  }
	  const onBtExport=()=>{
		downloadFileDashboard(data,responseChart)
		}

	const changeFilter=async(e)=>{
		e.preventDefault();
		let wId=data.widgetId
	   setFilters(e.target.value)
  	  setFiltersChartZoom(e.target.value)
		let filterCriteria=e.target.value;
	  if(!chartZoom){
		 try {
		const resp = await APIEndpoint.get(`portal/v1/widget/${wId}?sortBy=${filterCriteria}`);
		if(resp.success){
		  setFilteredWidgetData(resp.data)
	  }
		}
		 catch (error) {
		   console.log(error)
		 }
	  }
	  else{
		try {
			const resp = await APIEndpoint.get(`portal/v1/widget/${wId}?sortBy=${filterCriteria}`);
			if(resp.success){
			
				setResponseChart(resp.data);
				
		  }
			}
			 catch (error) {
			   console.log(error)
			 }
	  }

	  }

	return (
	<>
		<div className='width100 flexRow alignItemsCenter'>
			<div className='width100 flexRow alignItemsCenter mt-3 justifyContentSpaceBetween'>
				<div style={{whiteSpace:'nowrap'}} className='mb-3 component-header-space'>{ data.widgetName }</div>
				<div className="cmpHeadingRight flexRow alignItemsCenter justifyContentSpaceEvenly">
        <img className="icon-charts" src={DownloadVector} alt="Download icon" onClick={onBtExport}/>
        <img className="icon-divider-charts" src={VerticalDivider} alt="|" />

		<span>
				<button className='dashboard-border select-dashboard-border'>
					<select
					className='dashboard-button-report'
					onChange={changeFilter} value={!chartZoom?filters:filtersChartZoom}>
					    {durationFilterSelect.map( (item, index) => (
                    <option key={index} value={item.value}>{item.label}</option>
                ))
                }
					</select>
				</button>
				</span>
                </div>

			</div>
		 </div>
		 <div className="card-content2" style={{position:"relative"}}>
			{data?.datasets && !Boolean(Object.keys(responseChart).length)?(
			(data?.datasets[0]?.data?.length > 0 && data?.datasets[0]?.data?.some(d => d !== 0)) ? (<Pie
			datasetIdKey={data.widgetId}
			data={{...data,labels: data.labels || [] }}
			options={{
			responsive: false,
			elements: {
				arc: {
					borderWidth: 0
				  }
			  },
			legend:{
				display:false,
				position:'bottom'
			},
			plugins:{datalabels:{display:false}}
			}}
			/> ) : (
            <div className='flexRow justifyContentCenter alignItemsCenter dashboard-message'>
            <p>No data available</p>
            </div> )
             ):(responseChart?.datasets?.length > 0 && responseChart?.datasets[0]?.data?.length > 0 && responseChart?.datasets[0]?.data.some(d => d !== 0) ? (<Pie
			datasetIdKey={responseChart.widgetId}
			data={{ ...responseChart, labels: responseChart.labels || [] }}
			options={{
			responsive: false,
			elements: {
				arc: {
				  borderWidth: 0
				}
			  },
			legend:{
				display:false,
				position:'bottom'
			},
			plugins:{datalabels:{display:false}}
			}}
			/> ) : ( <div className='flexRow justifyContentCenter alignItemsCenter dashboard-message'>
            <p>No data available</p> </div> )
			)}

		</div>
		
		<div className="container-fluid sm-scrollbar mt-3">
		{data?.datasets && data?.datasets[0]?.data?.some(d => d !== 0) && (
			<div className=" flexRow  sm-scrollbar">
		
			{!Boolean(Object.keys(responseChart).length)?data?.labelsData.map((item,index) => {
				return (
				<div className={`${!chartZoom ? 'chart-label-bar-chart col-lg-3' : 'zoom-container'}`} key={index}>
					<ul className={`${index === 0 || 'chart-label-seperator'}`}>
					<li className='dashboard-bullet' style={{color:item.color}} >
					<div className="chart-heading-pie">{item.name}<br></br>{item.value}</div>
					</li>
					</ul>
				</div>
				)
			}):responseChart?.labelsData.map((item,index) => {
				return (
				<div className={`${!chartZoom ? 'chart-label-bar-chart col-lg-3' : 'zoom-container'}`} key={index}>
					<ul className={`${index === 0 || 'chart-label-seperator'}`}>
					<li className='dashboard-bullet' style={{color:item.color}} >
					<div className="chart-heading-pie">{item.name}<br></br>{item.value}</div>
					</li>
					</ul>
				</div>
				)
			})}
 		 <span className="icon-enlarge">
             {!chartZoom &&<img alt='zoom' src={zoom} onClick={()=>setView()} />}
          </span>
		
		</div>
	)}
	 </div>

	
	</>

	);

}