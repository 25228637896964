import React,{useEffect} from "react";
import { getDateFromTimezone } from "./Cards";

const CountTable = (props) => {
  return (
    <div className="flexCol">
      <table className="sub-table">
        <thead>
          <tr style={{backgroundColor:'#1d3e4e',color:'#fff'}}>
            <th>Economy</th>
            <th>Premium Economy</th>
            <th>Business Class</th>
            <th>First Class</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{props.status?.ecyPax ??"NA"}</td>
            <td>{props.status?.pcPax ??"NA"}</td>
            <td>{props.status?.bcPax ??"NA"}</td>
            <td>{props.status?.fcPax ??"NA"}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export const CateringSummary = (props) => {
  const { group, timezone,status,defaultTimezone,getCorrectedTailNo} = props


  return (
    <div className="flexCol cntrl-calender-summary oy-auto">
<table>
          <tbody>
          <tr>
            <td style={{backgroundColor:'#1d3e4e',color:'#fff'}}>Flight Date</td>
            <td>{getDateFromTimezone(group.date, timezone,defaultTimezone).format("YYYY-MM-DD")}</td>
          </tr>
          <tr>
            <td style={{backgroundColor:'#1d3e4e',color:'#fff'}}>STD</td>
            <td>{getDateFromTimezone(group.startTime, timezone,defaultTimezone).format("YYYY-MM-DD HH:mm")}</td>
          </tr>
          <tr>
            <td style={{backgroundColor:'#1d3e4e',color:'#fff'}}>ETD</td>
            <td>{getDateFromTimezone(group.endTime, timezone,defaultTimezone).format("YYYY-MM-DD HH:mm")}</td>
          </tr>
          <tr>
            <td style={{backgroundColor:'#1d3e4e',color:'#fff'}}>Tail Number</td>
            <td>{group.sectors.length > 0 ?getCorrectedTailNo(group.sectors[0].tailNumber) : ''}</td>
          </tr>
          <tr>
            <td style={{backgroundColor:'#1d3e4e',color:'#fff'}}>Pax Summary</td>
            <td>
              <CountTable status={status} />
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{textAlign:'center',fontWeight:'bold'}}>Sectors</td>
          </tr>
          {
            group.sectors.map((sector, index) => {
              return (
                <tr key={index}>
                  <td style={{backgroundColor:'#1d3e4e',color:'#fff'}}>{sector.origin} {">"} {sector.destination}</td>
                  <td>
                    <CountTable />
                  </td>
                </tr>
              )
            })
          }
          </tbody>
        </table>
    </div>
  )
}