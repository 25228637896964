import React from 'react'
import moment from 'moment';
import { DataGrid,Modal} from '../../../../common';
import { IoArrowForwardOutline } from "react-icons/io5";
import { getCultureFromLs ,formatDateByTimezone} from '../../../../utils/commonFunctions';


const SalesSummary=(props)=> {
const {state}=props

  return (
    <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
   <Modal
        className='cash-deposit-summary'
        title={``}
        TitleComponent={props.sectorTitle}
        onSubmit={props.handleModalCancelEvent}
        onClose={props.handleModalCancelEvent}>
          <div className='flexCol full-flex'>
          <div className='fitcontent justifyContentSpaceBetween display wrapper-sector-dynamic'>
              <div className='flexRow sector-banner display justifyContentSpaceBetween'>
                <div className='flexRow justifyContentFlexStart'>
                   <div className='sector-number fontMedium'>{state.selected2.flightDetails?.flightNumber}</div>
                   <div className='flexCol justifyContentCenter alignItemsCenter'>
                <div className='flexRow sector-tag'>{state.selected2.flightDetails?.origin}</div>
                <div className='flexRow fontMedium'>{state.selected2.flightDetails?.originName}</div>
                </div>
                </div>

                <div className='flexCol justifyContentCenter alignItemsCenter'>
                <div className='flexRow'> <IoArrowForwardOutline size={17} color={'#F06548'}/></div>
                <div className='flexRow fontMedium'>{moment.parseZone(formatDateByTimezone(state.selected2.flightDetails?.flightDate)).format('DD-MM-YYYY')}</div>
                </div>
               <div className='flexCol justifyContentCenter alignItemsCenter'>
                <div className='flexRow sector-tag'>{state.selected2.flightDetails?.destination}</div>
                <div className='flexRow fontMedium'>{state.selected2.flightDetails?.destinationName}</div>
                </div>
              </div>
              </div>




              <div className='flexRow fitContent margBottom8'>
                  <div style={{float:'left'}} className="flexCol width50 justifyContentFlexStart">

                  <div className='margBottom10'>
                  <label className='fontExtrabold'>Number of Orders</label>
                  <div>{state.selected2.data.totalNumberOfOrders}</div>
                </div>

                <div className='margBottom10'>
                  <label className='fontExtrabold'>Total Amount</label>
                  <div>{getCultureFromLs().currency.code} {state.selected2.data.totalAmount}</div>

                </div>

              </div>
              </div>
           <br/>

                <h6>Payment Type Distribution</h6>
             <DataGrid
            rowData={state.selected2.data?.paymentTypeDistribution}
            columnResize
            className='full-flex'
            showDefaultFilters={false}
            enableGridSearch={false}
            noResultsMessage={ 'No Data' }
            columns={props.paymentTypeDist}
            rowModelType='clientSide'
            />
           <br/>
<h6>Sale Type Distribution</h6>
  <DataGrid
            rowData={state.selected2.data?.saleTypeDistribution}
            columnResize
            noResultsMessage={ 'No Data' }
            className='full-flex'
            showDefaultFilters={false}
            enableGridSearch={false}
            columns={props.saleTypeDist}
            rowModelType='clientSide'
            />

<br/>


<h6>Currency Distribution</h6>

  <DataGrid
            rowData={state.selected2.data?.currencyDistribution}
            columnResize
            className='full-flex'
            showDefaultFilters={false}
            enableGridSearch={false}
            columns={props.currencyDist}
            noResultsMessage={ 'No Data' }
            rowModelType='clientSide'
            />



          </div>

      </Modal>


    </div>
  )
}

export default SalesSummary