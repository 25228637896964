/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from 'react'
import { DFPAPIEndpoint,APIEndpoint, DFPAPIEndpointGalley } from '../../../utils/axios'
import { monthNames } from '../../../utils/commonFunctions';
import '../../../assets/styles/styles.scss'
import './menu.scss'
import editIcon from "../../../assets/edit.svg";
import deleteIcon from '../../../assets/delete.svg'
import View from '../../../assets/view.svg'
import '../../../assets/styles/inner/table.scss'
import moment from "moment";
import Select from 'react-select'
import {NGODatePicker} from '../../../common';
import MenuCalendar from './renderers/MenuCalendar';
import { menuSettingPal,sectorTypesList,customSelSearch } from '../../../utils/configs'
import {translationLength } from '../../../utils/commonFunctions';
import {
  EditModal,
  DeleteConf
} from '../../../common'
import { PlansStatusSelectList } from '../../../utils/configs'
import { useTranslation } from 'react-i18next'
import { AddMenuPlanner } from './addMenuPlanner'
import usePermissions from '../../../utils/userPermissions';


export const MenuPlanner = props => {
  const { t } = useTranslation()
  const modelStyle = { maxWidth: '80%' };
  const [searchKey, setSearchKey] = useState('')
  const [state, setState] = useState({sectors:[],aircrafts:[],sectorTypes:sectorTypesList,isOpenMenu:false,mealMenu:[], sectSel:[{key: 0, label:`Select sector`,label2:'Select sector', value: ''}],resetDataSource: false })
  const [data,setData]=useState({})
  const [, setActionItems] = useState([])
  const [modalTitle,setModalTitle]=useState('');
  const [editFormFields,setEditFormFields]=useState([])
  const [modalCustomButtons,setModalCustomButtons]=useState([])
  const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' })
  const [perPageLimit, setPerPageLimit] = useState(10)
  const tableRef = useRef()
  const [ plansStatusSelected, setPlansStatusSelected ] = useState('');
const sType = localStorage.getItem('sType');
const { hasPermission: createMenuPlanner } = usePermissions('create', "configuration.fsc.menu-planner");
const { hasPermission: editMenuPlanner } = usePermissions('edit', "configuration.fsc.menu-planner");
const { hasPermission: deleteMenuPlanner } = usePermissions('delete', "configuration.fsc.menu-planner");
const { hasPermission: viewDetailsMenuPlanner } = usePermissions('view', "configuration.fsc.menu-planner");

  useEffect(() => {
    setActionItems([
      {
        title: 'Actions',
        attribute: 'view',
        minWidth:100,
        width:120
      },
      {
        title: '',
        attribute: 'edit',
        minWidth:100,
        width:120
      },
      {
        title: '',
        attribute: 'delete',
        minWidth:100,
        width:120
      },

    ])
    let months = monthNames.reduce(
      (acc, item,index) =>[
      ...acc,
      { key: index + 1, label: item, value: index },

      ],
      []
      );
      setState((prev)=>{return {...prev,months}});
      let options=[]

    for(let i=2015;i<=moment().year()+10;i++){
      options.push( { key: i+1, label: i, value: i })
    }

    if(options.length){
      setState((prev)=>{return {...prev,year:options}})
    }
    getCurrentTimeLine();
    fetchWarehouse();
    fetchCaterers();
    fetchConfig();
    getSectors(null,null);
    fetchAirtcraftsType(null);
    fetChMealmenuConfig();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

   useEffect( () => {
    if(plansStatusSelected||(state.sectSel.length>0&&state.sectSel[0].value)||(state.fromDate&&state.toDate)||(!state.fromDate&&!state.toDate)){
      fetchConfig();
     }
   }, [ plansStatusSelected,state.sectSel ,state?.fromDate, state?.toDate]);


  useEffect(() => {
    if(state&&state?.monthSel?.length>0&&state?.yearSel?.length>0)
    setState((prev) => ({
      ...prev,
      defaultDate:state.yearSel.length>0 && state.monthSel.length>0
      ? new Date(state.yearSel[0].value, state.monthSel[0].value, 1, 23, 59, 59)
     : new Date(),
    }));
  }, [state.monthSel,state.yearSel]);



const getCurrentTimeLine=()=>{
  const curDate = new Date();
const curYear = curDate.getFullYear();
const curMonth = monthNames[curDate.getMonth()];

if(curYear&&curMonth){
  setState((prev) => ({
    ...prev,
    monthSel:[{
      key: curDate.getMonth()+1,
      label: curMonth.toString(),
      value: curDate.getMonth()
  }],
  yearSel:[{
    key: curYear+1,
    label: curYear,
    value: curYear
}],
  }));
}
}
  const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    fliters: [],
    limit: 5000,
    page: 1,
    searchParam: ''
  };

const  getSectors=async(src=null,dst=null)=>{
  try{
const res=await  DFPAPIEndpoint.get('stores');
if(res.success){
  setData(prevState => ({
    ...prevState,
    sectors:res.result}))
  const options = [{key: 0, label:`Select sector`,label2:'Select sector', value: ''}];
  res.result.filter( (fieldValue, index) => {
    options.push({key: index+1, label:`${fieldValue.value}`,label2:fieldValue.originName, value: fieldValue.origin});
    return fieldValue;
  })
  setState(prevState => ({
        ...prevState,
        sectors:options}))
  // const locationMapping = res.result.reduce((acc, item) => {
  //   acc[item.origin] = item.originName;
  //   return acc;
  // }, {});
  // if(src&&dst){
  //   return `${locationMapping[src]} ⇀ ${locationMapping[dst]}`
  // }
}
if(res.success&&!src&&!dst){
  setData(prevState => ({
    ...prevState,
    sectors:res.result}))
}
  }
  catch(err){

  }
  }
  const  fetChMealmenuConfig=async()=>{
    try{
  const [res,resItemMaster] = await Promise.all([
    DFPAPIEndpointGalley.get('galley/mealMenuPlan/configs'),
    DFPAPIEndpoint.post('master/process',filterData),
  ]);
  if(res.data&&resItemMaster.items){
    const {items}=resItemMaster
    const newListMeals = res.data.mealTypes.list.slice();
    const newObject = {id:'beverages',label:'Beverages',brands:[]};
    newListMeals.push(newObject);

    const modResponses = newListMeals.map(mlt => {
      switch (mlt.id) {
        case 'appetizer':
          mlt.brands = items?.filter((fl) => (fl.category === 'Appetizers'))
          .map((fl) => ({ value: fl.itemName, id: fl.itemId }));
          if(!mlt.brands.length){
            mlt.brands = items?.filter((fl) => (fl.category === 'Appetizers'))
            .map((fl) => ({ value: fl.itemName, id: fl.itemId }));
          }
          break;
        case 'main':
          mlt.brands =items?.filter((fl) =>(fl.category === 'Gourmet')|| (fl.category === 'Food')|| (fl.category === 'Refreshments'))
          .map((fl) => ({ value: fl.itemName, id: fl.itemId }));
          if(!mlt.brands.length){
            mlt.brands = items?.filter((fl) => ((fl.category !== 'Liquor')))
            .map((fl) => ({ value: fl.itemName, id: fl.itemId }));
          }
          break;
        case 'dessert':
          mlt.brands = items?.filter((fl) => (fl.category === 'Dessert'))
          .map((fl) => ({ value: fl.itemName, id: fl.itemId }));
          if(!mlt.brands.length){
            mlt.brands = items?.filter((fl) => ((fl.category === 'Dessert')))
            .map((fl) => ({ value: fl.itemName, id: fl.itemId }));
          }
          break;
          case 'beverages':
            mlt.brands = items?.filter((fl) => fl.category === 'Beverage')
            .map((fl) => ({ value: fl.itemName, id: fl.itemId }));
            if(!mlt.brands.length){
              mlt.brands = items?.filter((fl) => ((fl.category === 'Beverage')))
              .map((fl) => ({ value: fl.itemName, id: fl.itemId }));
            }
            break;
          default:
            break;
      }
      return mlt;
    });
    const options = [];
    res.data.classCodes?.list?.map( (fieldValue, index) => {
      options.push({key: index+1, label: fieldValue.label, value: fieldValue.systemCode});
      return fieldValue;
    })
    const optionsMt = [];
    res.data.mealTypeGroups.list?.map( (fieldValue, index) => {
      optionsMt.push({key: index+1, label: fieldValue.label, value: fieldValue.id});
      return fieldValue;
    })
    const optionsSct = [];
    res.data.sectorTypes.list?.map( (fieldValue, index) => {
      optionsSct.push({key: index+1, label: fieldValue.label, value: fieldValue.id});
      return fieldValue;
    })
    const renamedData = { menuSettings: modResponses?.map(item => renameKeys(item, keyMap)).map(data=>{
      return{
      mealTypeId: data.mealTypeId,
      mealType: data.mealType,
      items:(data.mealTypeId==='appetizer'||data.mealTypeId==='dessert'||data.mealTypeId==='main'||data.mealTypeId==='beverages')?data.items.map(item => ({
          itemName: item.value,
          itemId: item.id
      })):data.items.map(item => ({
        itemName: item.label,
        itemId: item.value
    }))}})};

      if (renamedData.menuSettings) {
        const { menuSettings } = renamedData;
        const mealTypeMapping = {
            "continentalBreakfast": ["appetizer", "main", "dessert","beverages"],
            "breakfast": ["appetizer", "main", "dessert"],
            "morningSnack": ["main", "dessert"],
            "lunch": ["appetizer", "main", "dessert"],
            "eveningSnack": ["main", "dessert"],
            "dinner": ["appetizer", "main", "dessert"],
            "supper": ["main", "dessert"],
            "alcoholicBeverages": ["whisky", "brandy", "vodka", "cognac", "whiteRum", "darkRum", "beer", "redWine", "whiteWine", "sparklingWine", "fortifiedWine", "liqueurs"],
            "nonAlcoholic": ["freshJuice", "cannedJuice", "cannedSmoothies", "energyDrinks", "softDrinks", "infusedWater", "sparklingWater", "water"],
        };

        menuSettingPal.forEach(category => {
            const mealTypeIdsToAdd = mealTypeMapping[category.mealCategoryId];
            if (mealTypeIdsToAdd) {
                mealTypeIdsToAdd.forEach(mealTypeId => {
                    const mealTypeObject = menuSettings.find(type => type.mealTypeId === mealTypeId);
                    if (mealTypeObject) {
                        category.mealsSelection.push(mealTypeObject);
                    }
                });
            }
        });
    }
      setState(prevState => ({
        ...prevState,
        mealMenuClass:options,
        mealMenuCatergory: res.data.mealCategory,
        menuSettings:menuSettingPal,
        mealTypesGroup:optionsMt,
        sectorTypes:optionsSct,
      }));
  }
    }
    catch(err){

    }
    }
    const fetchAirtcraftsType = async (airId=null) => {
      filterData.searchParam = searchKey;
      filterData.filters =  [
        {
          aircraftFor: sType === 'dfp' || sType === 'regular' ? 'pal' : 'akasa'
        }
      ];
    try {
            const res = await DFPAPIEndpointGalley.get('galley/aircraftTypes', JSON.stringify(filterData));
          if(res.success&&res.result){
            setData(prevState => ({
              ...prevState,
              aircraft: sType === ("dfp") || sType===("regular") ? res.result.filter(act=>act.aircraftFor&&act.aircraftFor==='pal') :
                  res.result.filter(act=>act.aircraftFor&&act.aircraftFor==='akasa').map(act=>{return{...act,value:act.value.slice(0,6)}})
              })
            );

            const options = [];
            if ((sType==="dfp") || (sType==="regular")) {
              res.result.filter(act=>act.aircraftFor&&act.aircraftFor==='pal').filter( (fieldValue, index) => {
              options.push({key: index+1, label: fieldValue.value, value: fieldValue.id});
              return fieldValue;
              });
            }

            if (sType==='akasa') {
              res.result.filter(act=>act.aircraftFor&&act.aircraftFor==='akasa').filter( (fieldValue, index) => {
                options.push({key: index+1, label: fieldValue.value.slice(0,6), value: fieldValue.id});
                return fieldValue;
              });
            }

          setState(prevState => ({
            ...prevState,
            aircrafts: options
          }));

          if(airId){
            const airCraft=res.result.filter(act=>act.aircraftFor&&(act.aircraftFor==='pal'||act.aircraftFor==='akasa')).find(aId=>aId.id===airId)?.value;
            if(sType==='pal'||sType==='regular'){
              return airCraft;
            }
            else{
              return airCraft.slice(0,6);
            }

          }
        }
    } catch (error) {

    }

  }

const fetchCaterers = async (page, perPage,catId=null,catStn=null) => {
if(catId&&catStn){
filterData.page = page;
filterData.limit =  perPage;
filterData.searchParam = null;
filterData.filters=[{warehouseId: parseInt(catStn)}];
try{
    const res = await DFPAPIEndpoint.post('vendorMappings', JSON.stringify(filterData));
    if(res.count &&!catId){
    setData(prevState => ({
    ...prevState,
    caterers:res.items}))
    const options=[]
    res.items.map( (fieldValue, index) => {
    options.push({key: index+1, label: `${fieldValue.name} ${fieldValue.name2}`, value: fieldValue.code});
    return fieldValue;
    })
    setState(prevState => ({
    ...prevState,
    caterers:options
    }));
    }

    // if(catStn&&catId&&res.count){
    //   const catObj=res.items.find(cat=>cat.vendorId===catId);
    //   if(Object.keys(catObj).length){
    //     return `${catObj.vendorName} ${catObj.vendorCode}`
    //   }
    // }
 }
 catch(err){
 }
  }
}
const fetchWarehouse = async() => {
  try {
     const resp = await APIEndpoint.post(`warehouses/process`,filterData);
     if(resp.success){
      setData(prevState => ({
        ...prevState,
        warehouse:resp.items}));

      const options=[]
      resp.items.filter( (fieldValue, index) => {
        options.push({key: index+1, label: fieldValue.warehouseName, value: fieldValue.cluster?.clusterName});
        return fieldValue;
      })
      setState(prevState => ({
      ...prevState,
      warehouses:options
    }));
  }
     }
      catch (err) {

      }
}


  const preCompile = {
    save: t('buttons.save'),
    uploadLayout: t('pages.pagesContent.aircrafts.uploadLayout')
  }

  function renameKeys(obj, keyMap) {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [keyMap[key] || key, value])
    );
  }

  const keyMap = {
    "id": "mealTypeId",
    "label": "mealType",
    "brands": "items",
  };

  const handleFormSubmit = async (e, updated) => {
    if (updated) {
      const body = {
        model: updated.model,
        variant: updated.variant,
        capacity: parseInt(updated.capacity)
      }
      try {
        const resp = await DFPAPIEndpoint.post(`aircraftTypes`, body)
        if (resp.success) {
          setModalFormStatusObj({ text: resp.message })
        }
      } catch (resp) {
        setModalFormStatusObj({ text: resp.message })
      }
    }
  }

  const onClose = () => {
    setState(_prev => {
      return { ..._prev, selected: false,resetDataSource: !state.resetDataSource }
    })
    setModalFormStatusObj({ text: '' })
    fetchConfig();
  }

  const onAddItemHandler = () => {
    setState(_prev => {
      return {
        ..._prev,
        selected: { action: 'createMenuPlan' },
        resetDataSource: !state.resetDataSource
      }
    })
  }
  const onPerLimitChange = e => {
    setPerPageLimit(Number(e.target.value))
  }

  const onPlanStatusChange = e => {
    setPlansStatusSelected(e.target.value)
  }

  const handleYear=(e)=>{
    setState(_prev => {
      return {
        ..._prev,
        yearSel:[e],

      }
    })
  }

  const handleMonth=(e)=>{
    setState(_prev => {
      return {
        ..._prev,
        monthSel:[e],

      }
    })
  }
const handleSectors=(e)=>{
  setState(_prev => {
    return {
      ..._prev,
      sectSel:[e],

    }
  })
}
  const onCloseDelete = () => {
    setState(_prev => {
      return { ..._prev,resetDataSource: !state.resetDataSource, delete: false }
    })
    setModalFormStatusObj({ text: '' })
    fetchConfig();
  }

  const onContinue = async () => {
    setState(_prev => {
      return { ..._prev, deleteProg: true }
    })
    try {
      const res = await DFPAPIEndpointGalley.delete(
        `galley/mealMenuPlan/${state.selected._id}`
      )
      if (res.success) {
        setState(_prev => {
          return { ..._prev, deleteProg: false,resetDataSource: !state.resetDataSource }
        })
        setModalFormStatusObj({ text: res.message })
        fetchConfig();
      }
    } catch (err) {
      const errorMsg=JSON.parse(err?.message);
      setModalFormStatusObj({ text: errorMsg.data?.message||"Something went wrong!" })
    }
    let timerId = setTimeout(() => {
      clearTimeout(timerId)
      setModalFormStatusObj({ text: ''})
      setState((_) => ({ ..._, delete: false,selected:false }))
    }, 1500)
  }

  var myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('user_id', localStorage.getItem('user_id'))
  myHeaders.append('x-csrf-token', localStorage.getItem('token'))
  myHeaders.append('X-Bearer-Token', localStorage.getItem('bearer_token'))


  function actionsMenuPlanner(props){
    return (
      props.data?
      <>
        <div className='icon-aircraft-container'>
        <img className='aircraft-actions' alt='view' onClick={(e) => setSector(props.data, 'viewMenuPlan')} src={View}/>
        <span className="tooltip-action">View</span>
        </div>
        <div className='icon-aircraft-container'>
        <img className='aircraft-actions' alt='edit' onClick={(e) => setSector(props.data, 'editMenuPlan')} src={editIcon}/>
        <span className="tooltip-action">Edit</span>
        </div>
        <div className='icon-aircraft-container'>
        <img  style={{width:'25.5px',height:'25.5px'}} className='aircraft-actions' alt='delete' onClick={(e) => setSector(props.data, 'deleteMenuPlan')} src={deleteIcon}/>
        <span className="tooltip-action">Delete</span>
        </div>
      </> : <></>
    );
  }


  const setSector = (data,type) => {
    if(type==='viewMenuPlan'){
      let updatedEditFields=[]
      setModalTitle(`Menu - ${data.status === 'expired' ? `${data.menuPlanName} (Expired)` : data.menuPlanName}`);
      updatedEditFields = [{ label: 'View Menu Planner', type: 'viewMenuPlanner', attribute: 'items'}];
      updatedEditFields = updatedEditFields.filter( row => {
          if (row.type === 'viewMenuPlanner') {
            row.value = row;

          }
          return row;
      });


      setModalCustomButtons([
        { label: 'Close', attribute: 'button', type: 'button', disabled: false, className: 'close-button', action: (e) => {

                onClose();
                return false;
            }
        }
      ]);

      setEditFormFields( [ ...updatedEditFields ] );

      setState((_prev) => {
        return { ..._prev, selected: { ...data, action: type} };
      });

    }
    else if(type==='editMenuPlan' && editMenuPlanner){
      setState(_ => {
        return {
          ..._,
          resetDataSource: !state.resetDataSource,
          selected: {...data,action:'editMenuPlan'},
          view: true,
          deleteProg: false
        }
      })
    }
   else if(type==='deleteMenuPlan' && deleteMenuPlanner){
    setState(_prev => {
      return { ..._prev, delete: true, resetDataSource: !state.resetDataSource,
        selected: data,
        view: true,
        deleteProg: false }
    })
   }
   else{
    setState( (_prev) => {
      return { ..._prev, selected: {...data ,action: type},resetDataSource:!state.resetDataSource}
    });
   }
  };

  // const updateCalendar = () => {
  //   const defaultDate = props.data.yearSel && props.data.monthSel
  //     ? new Date(props.data.yearSel.value, props.data.monthSel.value, 1, 23, 59, 59)
  //     : new Date();

  //   setState((prev) => ({
  //     ...prev,
  //     defaultDate,
  //   }));
  // };


  const fetchConfig = async () => {

    try {
      let payload = {
        searchParam: "", page: 1, limit: 1000, action: 4,
        filters: [
        ]
    };
      if ( plansStatusSelected ) {
        payload.status = plansStatusSelected;
      }
      if (searchKey) {
        payload.searchKey = searchKey;
      }
try {
   const resp = await DFPAPIEndpointGalley.post(`galley/mealMenuPlan/list`, payload);
      if(resp.success && !state.sectSel[0]?.value && !Boolean(state.fromDate)&& Boolean(state.toDate)) {
        setState(prevState => ({
          ...prevState,
          mealMenu: resp.items,
        }));

        resp.items = resp.items.filter( (obj, key) => ( key <= 15 ) );

        return { ...resp, items: resp.items };

      }
      else{
        setState(prevState => ({
          ...prevState,
          mealMenu: resp.items.filter(fl=>fl.sectorDetails?.value===state.sectSel[0]?.label),
        }));
      }

      if (resp.success && Boolean(state.fromDate)&& Boolean(state.toDate)) {

        const dateRange = {
          from: moment(state.fromDate).format('YYYY-MM-DD'),
          to: moment(state.toDate).format('YYYY-MM-DD'),
        };

        if(dateRange){
          setState(_ => {
            return { ..._, mealMenu: state.mealMenu.filter(fl=>fl.fromDate.includes(dateRange.from)),defaultDate:new Date(Number(dateRange.from.slice(0,4)), Number(dateRange.from.split("-")[1])-1, 1, 23, 59, 59),resetDataSource: !state.resetDataSource }
          });
        }
      }
      else{
        setState(_ => {
          return { ..._,defaultDate:undefined,resetDataSource: !state.resetDataSource }
        });
      }


      if(resp.success&&state.sectSel.length>0&&state.sectSel[0].value){
        setState(_ => {
          return { ..._, mealMenu: state.mealMenu.filter(fl=>fl.sectorDetails?.value===state.sectSel[0]?.label),resetDataSource: !state.resetDataSource }
        });
      }else{
        setState(prevState => ({
          ...prevState,
          mealMenu: resp.items,
          resetDataSource: !state.resetDataSource
        }));
      }
} catch (error) {

}



    }

    catch (err) {
      throw err;
    }
  }


  const delProps = {
    onContinue,
    onCloseDelete,
  }
  const handleMenuOpen=(isOPen)=>{
    setState(prevState => ({
      ...prevState,
      isOpenMenu: isOPen,
    }));
  }

  let onChangeInputFromDate = e => {
    setState((_)=>{return {..._,fromDate:e.target.value}})
  }

  let onChangeInputToDate = e => {
    setState((_)=>{return {..._,toDate:e.target.value}})
  }

  const onSearch = e => {
    setSearchKey(e.target.value)
  }
  return (
    <div className='op-aircraft-container flexCol full-flex ag-theme-alpine-dark'>
      {
        <>
<div className='flexRow justifyContentSpaceBetween' style={{marginLeft:'0.5rem'}}>

<div className="flexRow row justifyContentFlexStart m-2">
  <>
    <div className="flexRow justifyContentFlexStart" style={{padding:'0px'}}>
    <div className="status-button margRight11" title="Select filter by status">
    <select
      className="offBlueBg"
      onChange={onPlanStatusChange}
      value={plansStatusSelected}
      id="status-select-list"
    >
      {PlansStatusSelectList.map((_) => (
        <option key={_.value} value={_.value}>
          {_.label}
        </option>
      ))}
    </select>
  </div>
{ state && state.sectSel? (<div className='felxCol margRight11'>
    <label className="req margBottom10">Sector</label>
      <Select
        options={state?.sectors}
        defaultValue={state?.sectSel[0]?.label ? state?.sectSel[0]?.label : ''}
        value={state?.sectors.filter(function (option) {
          return (
            state?.sectSel.length &&
            state?.sectSel.find((sc) => sc.label === option.label)
          );
        })}
        onChange={handleSectors}
        onMenuOpen={() => handleMenuOpen(true)}
      onMenuClose={() => handleMenuOpen(false)}
      styles={{
        ...customSelSearch, 
        control: (base) => ({
          ...base,
          width: '160px',
          backgroundColor: '#162c3b',
          marginTop: '5px',
        }),
      }}
        placeholder="Select Sector"
        isSearchable={true}
        isMulti={false}
        className=""
      />
  </div>):<></>}

  <div className='flexRow'>
  <div className='felxCol'>
    <label className='flexRow'>From Date</label>

    <NGODatePicker
    value={state.fromDate}
    selectsStart={true}
    style={{border:'1.3px solid #fff',borderRadius:'4px',marginTop:'0.82rem',height:'2.37rem',width:'9rem'}}
    dateFormat="dd/MM/yyy"
    name='fromDate'
    placeholder={'Select From Date'}
    className='margRight'
    isOpen={state.isOpenMenu}
    isClearable={true}
    onCalendarOpen={() => handleMenuOpen(true)}
    onCalendarClose={() => handleMenuOpen(false)}
    onChange={onChangeInputFromDate}
    />
    </div>

    <div className='felxCol'>
    <label className='flexRow'>To Date</label>

    <NGODatePicker
    value={state.toDate}
    selectsEnd={true}
    style={{border:'1.3px solid #fff',borderRadius:'4px',marginTop:'0.82rem',height:'2.37rem',width:'9rem'}}
    placeholder={'Select To Date'}
    dateFormat="dd/MM/yyy"
    name='toDate'
    className=''
    isClearable={true}
    isOpen={state.isOpenMenu}
    onCalendarOpen={() => handleMenuOpen(true)}
    onCalendarClose={() => handleMenuOpen(false)}
    onChange={onChangeInputToDate}
    />
    </div>
    </div>


      &nbsp;&nbsp;
      {state && state.monthSel && state.monthSel.length > 0 ? (
    <>
 <div className='felxCol'>
        <label className="req margBottom10">Month</label>
        <Select
          defaultValue={state?.monthSel[0]?.label ? state?.monthSel[0]?.label : ''}
          options={state?.months}
          placeholder="Search/Select Month"
          value={state?.months.filter(function (option) {
            return (
              state?.monthSel.length &&
              state?.monthSel.find((sc) => sc.label === option.label)
            );
          })}
          onChange={handleMonth}
          onMenuOpen={() => handleMenuOpen(true)}
          onMenuClose={() => handleMenuOpen(false)}
          styles={{
            ...customSelSearch, 
            control: (base) => ({
              ...base,
              width: '130px',
              backgroundColor: '#162c3b',
              marginTop: '5px',
            }),
          }}
          isSearchable={true}
          isMulti={false}
          className=""
        />
        </div>
        &nbsp;&nbsp;

    </>
  ) : (
    <></>
  )}
{state && state.yearSel && state.yearSel.length > 0 ? (
    <>
     <div className='flexCol'>
        <label className="req margBottom10">Year</label>
        <Select
          defaultValue={state?.yearSel[0]?.label ? state?.yearSel[0]?.label : ''}
          options={state?.year}
          placeholder="Select Year"
          value={state?.year.filter(function (option) {
            return (
              state?.yearSel.length &&
              state?.yearSel.find((sc) => sc.label === option.label)
            );
          })}
          styles={{
            ...customSelSearch, 
            control: (base) => ({
              ...base,
              width: '100px',
              backgroundColor: '#162c3b',
              marginTop: '5px'
            }),
          }}
          onMenuOpen={() => handleMenuOpen(true)}
          onMenuClose={() => handleMenuOpen(false)}
          onChange={handleYear}
          isSearchable={true}
          isMulti={false}
          className=""
        />
        </div>
        &nbsp;&nbsp;

    </>
  ) : (
    <></>
  )}


    </div>
  </>





</div>

{createMenuPlanner?<div className="flexRow justifyContentFlexEnd alignItemsBaseline margTop36 margRight11">
    <button
      disabled={!Boolean(Object.keys(state).length > 6)}
      className="add-item-button"
      onClick={onAddItemHandler}
    >
      {t('buttons.create')}
    </button>
  </div>:<></>}
</div>


        {state &&(!state.selected?.action)?
        <MenuCalendar data={state} actions={setSector}/>:<></>
        }
        </>

      }
       {
        state && Boolean(state.selected) &&
          ( state.selected?.action === 'viewMenuPlan' && viewDetailsMenuPlanner) ?
          (
            <EditModal
                className='salesPageModal'
                title={ modalTitle }
                columnViewType={true}
                customModalStyle={ modelStyle }
                onClose={onClose}
                // onSubmit={onSubmit}
                modalFormStatusObj={modalFormStatusObj}
                actionsMenuView={setSector}
                modalCustomButtons={modalCustomButtons}
                fields={ editFormFields.map( (elt) => {

                        if ( state.selected.action === 'viewMenuPlan' ) {
                            return {...elt, onSelectAction:setSector,initialValue: state.selected ?? '' }
                        }
                        else {
                            return {...elt, initialValue: '' }
                        }
                  })
                }
                showFieldsInColumn={true}
            />
        )
        : null
      }
      {state&&state?.delete && <DeleteConf   {...delProps} modalFormStatusObj={modalFormStatusObj} description={`Are you sure you want to delete "${state?.selected?.menuPlanName}" meal Plan ?`} />}


      {state &&
        Boolean(state.selected) &&
        (state.selected?.action === 'createMenuPlan'|| state.selected?.action === 'editMenuPlan') && (
          <AddMenuPlanner
            key={state.selected?.action}
            options={state}
            setState={setState}
            data={data}
            title={state.selected.action==='createMenuPlan'?"Create Menu":`Edit Menu - ${state.selected.menuPlanName}`}
            buttonLabel={preCompile.save}
            onSubmit={handleFormSubmit}
            setModalFormStatusObj={setModalFormStatusObj}
            onClose={onClose}
          />
        )}
        {
        t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
      }
    </div>
  )
}