import React from 'react'
import { IoCalendarOutline } from 'react-icons/io5'
import { getCultureFromLs } from '../utils/commonFunctions'
import moment from 'moment'

const TimeZoneInfo = ({mode}) => {
  if(mode === "collapsed"){
    return null;
  }
  return (
    <div style={{gap:0}} className='flexCol footer-timezone margBottom5'>
        <div className='date'>
            <IoCalendarOutline/>
            {moment(new Date()).format("YYYY-MM-DD")}
        </div>
        <span>{getCultureFromLs()?.timezone.formattedName.split(" ")[1]}</span>
    </div>
  )
}

export default TimeZoneInfo