import React, { useEffect,  useState } from 'react';
import { FilterBodyTemplate } from "../../../utils/configs";
import { APIEndpoint } from "../../../utils/axios";
import {translationLength} from '../../../utils/commonFunctions';
import { DataGrid,IconButton, Modal, NGODatePicker, Input,  Loading } from "../../../common";
import { useTranslation } from 'react-i18next';
// import Edit2 from "../../../assets/edit2.svg";
import moment from "moment";

const Columns = [
  { field: 'code', headerName: 'DFP Code', resizable:true,flex:1 },
  { field: 'startDateStr', headerName: 'Start Date', resizable:true,flex:1 },
  { field: 'createdAtStr', headerName: 'Created At', resizable:true,flex:1 },
  { field: 'createdByStr', headerName: 'Created By', resizable:true,flex:1 },
  { field: 'dfp', headerName: 'DFP', resizable:true,flex:1 },
  { field: 'state', headerName: 'State', resizable:true,flex:1 }
]

export const EstimationRunsPage = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({ filterDate: new Date(), generateDSForm: {} });

  useEffect(() => {
    fetchEnumerate()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchEnumerate = async () => {
    const res = await APIEndpoint.get('items/enumerate');
    const options = (res.result || []).map((fieldValue, index) => {
      return { key: index, label: fieldValue.value, value: fieldValue.id };
    });
    const placeholder = { label: t('pages.pagesContent.estimationRuns.enumaratedPlaceholder'), value: "", key: 'place' }
    setState((_) => ({ ..._, enumerates: [placeholder, ...options] }))
  }

  const fetchEstimationRuns = async (iPageNo = 1, iPageLimit = 10, iSearchText = '') => {
    const filterData = JSON.parse(JSON.stringify(FilterBodyTemplate));
    filterData.page = iPageNo;
    filterData.limit = iPageLimit;
    filterData.searchParam = iSearchText;
    if (state.filterDate) {
      let flightDate = moment(state.filterDate).startOf('day').format();
      filterData.filters = [{ targetDate: flightDate }]
    }
    const res = await APIEndpoint.post('estimation/getEstimationRuns', filterData);
    if (res.items) {
      res.items = res.items.map((_) => {
        return {
          ..._,
          startDateStr: moment(_.startDate).format('DD-MM-YYYY hh:mm A'),
          createdAtStr: moment(_.createdAt).format('DD-MM-YYYY hh:mm A'),
          createdByStr: `${_.createdBy.employeeName}(${_.createdBy.employeeCode})`
        }
      })
    }
    return res;
  }

  const handleFilterDateChange = (e) => {
    setState((_) => ({ ..._, filterDate: e.target.value, resetDataSource: true }));
    let timerId = setTimeout(() => {
      clearTimeout(timerId);
      setState((_) => {
        return { ..._, resetDataSource: false };
      })
    }, 500)
  }
  const handleEstimationTargetDateChange = async (e) => {
    let targetDate = e.target.value
    setState((_) => ({ ..._, targetDate: targetDate, targetDateEst: 'loading', estimationRun: null }))
    const res = await APIEndpoint.post('dfp/getActiveDFP', { targetDate })
    setState((_) => ({ ..._, targetDateEst: res.dfp || 'NotExist' }))
  }
  const handleEstimationRun = async () => {
    const msg = {}
    setState((_) => ({ ..._, estimationRun: 'loading' }))
    try {
      const res = await APIEndpoint.post('estimation/estimationRun', { targetDate: state.targetDate })
      msg.success = true;
      msg.message = res.message
    } catch (error) {
      msg.success = false;
      msg.message = error.data.message
    }
    setState((_) => ({ ..._, estimationRun: msg }))
  }
  return (
    <div className="dfp-draft op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      <div className="flexRow justifyContentFlexEnd alignItemsCenter margRight margBot10">
        <IconButton
          className='btn btn-primary'
          label={t('pages.estimationsContent.estimationRuns.runEstimationBtn')}
          onClick={() => setState((_) => ({ ..._, showModal: true, targetDate: null,  targetDateEst: null, estimationRun: null }))} />
      </div>
      <DataGrid
        columnResize
        showDefaultFilters
        className='full-flex'
        columns={Columns}
        getRows={fetchEstimationRuns}
        resetDataSource={state.resetDataSource}
        actions={[{ attribute: 'EDIT/DELETE' }, { attribute: 'VALIDATE' }]}
        RightComponent={(props) => {
          return <NGODatePicker value={state.filterDate} onChange={handleFilterDateChange} />
        }}
      />
      {
        state.showModal &&
        <Modal
          showDone={false}
          showFieldsInColumn
          className='run-est'
          onClose={() => setState((_) => ({ ..._, showModal: false }))}
          title={t('pages.estimationsContent.estimationRuns.runEstimationBtn')}>
          <div className='flexCol'>
            <div className='alert alert-warning small-body-text-regular-subscript'>
              {t('pages.estimationsContent.estimationRuns.runEstimationWarning')}
            </div>
            <Input
              required
              label='Target Date'

              type='date'
              value={state.targetDate || ''}
              placeholder='Estimation target date'
              minDate={moment().toDate()}
              maxDate={moment().add(1, "days").toDate()}
              onChange={handleEstimationTargetDateChange}
            />
            {
              Boolean(state.targetDateEst) &&
              <>
                {
                  state.targetDateEst === 'loading' ?
                    <Loading />
                    :
                    <div className='alert alert-info'>
                      {
                        state.targetDateEst === 'NotExist' ?
                          <span>{t('pages.estimationsContent.estimationRuns.runEstimationNoExist')}</span>
                          :
                          <>
                            <span>{t('pages.estimationsContent.dfpDraft.dfp')}</span>
                            <span className='dfp-code'>{state.targetDateEst.code}</span>
                            <span>{t(`pages.estimationsContent.estimationRuns.${state.targetDateEst.deleted ? 'runEstimationDelete' : 'runEstimationActive'}`)}</span>
                          </>
                      }
                    </div>
                }
              </>
            }
            {
              Boolean(state.estimationRun) &&
              <>
                {
                  state.estimationRun === 'loading' ?
                    <Loading />
                    :
                    <div className={`alert alert-${state.estimationRun.success ? 'success' : 'danger'}`}>
                      {state.estimationRun.message}
                    </div>
                }
              </>
            }
          </div>

          <div className='flexRow justifyContentFlexEnd width100'>
            <IconButton
              className='btn btn-success'
              onClick={handleEstimationRun}
              label='Run'
              loading={state.estimationRun === 'loading'} />
          </div>
        </Modal>
      }
       {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }
    </div>
  )
}
