import React, { useEffect, useState } from 'react'
import ReactSpreadsheet from "react-spreadsheet";
import Currency from "../assets/json/currency.json"
import { FaRegCheckSquare, FaSave } from 'react-icons/fa';
import { BiSolidSelectMultiple } from 'react-icons/bi';
import { RiDeleteBin6Line } from 'react-icons/ri';

const defaultCellFieldData = {
    id: "id",
    value: "",
    defaultValue: "",
    className: "cellClass",
    width: "200px",
    readOnly: false,
    type: "string",
    required: true,
    showPopup: false,
  };

const CustomSpreadSheet = (props) => {
  const {columns,darkMode,colDefs,type,showTitle,insideModal,saveData,initialData,onClose} = props
  const [sheetData, setSheetData] = useState([])
  const [activeCell, setActiveCell] = useState(null)
  const [selectAll, setSelectAll] = useState(false)
  const [selectedCellsArr, setSelectedCellsArr] = useState([])
  const [showDropDown, setShowDropDown] = useState({
    state:false,
    options:[]
  })
  const [errors, setErrors] = useState({})
  const [message, setMessage] = useState(null)

  useEffect(() => {
    if(errors.errorArr?.length > 0){
      const updatedSheetData = sheetData.map((row,rowIndex)=>{
        const err = errors.errorArr.find((err)=>err.row === rowIndex);
        return row.map((cell,colIndex)=>{
          if(err && colIndex === 0){
            const elem = document.querySelector(`.cellClass_${rowIndex}_${colIndex}`)
            const errorMsgElm = document.createElement("span");
            errorMsgElm.classList.add("sheetCellErrMsg")
            errorMsgElm.textContent = err.message;
            elem.appendChild(errorMsgElm)
            return {...cell,className: `sheetErrCell cellClass cellClass_${rowIndex}_${colIndex}`}
          }
          return {...cell}
        })
      })
      setSheetData([...updatedSheetData])
    }
  }, [errors])
  
  useEffect(()=>{
    performSelectAll()
  },[selectAll])
  useEffect(() => {
    addRowsToSheet()
  }, [])
  useEffect(() => {
    if(showDropDown.state && activeCell){
        showPopUp()
    }
  }, [showDropDown.state,activeCell])
  

  useEffect(() => {
    if(activeCell?.column || activeCell?.column === 0){
        if(!colDefs[activeCell.column].options) return;
        const val = sheetData[activeCell.row][activeCell.column].value;
        const filteredDropDown = colDefs[activeCell.column].options.filter((data)=>{
            return data.toLocaleLowerCase().startsWith(val?.toLocaleLowerCase())
        })
        setShowDropDown(prev=>({...prev,options:[...filteredDropDown]}))
    }
  }, [sheetData])

  useEffect(()=>{
    if(initialData?.length > 0){
      const row = []
      initialData.forEach((d, i) => {
        row.push([
          {
            ...defaultCellFieldData,
            id: "currencyCode",
            className: "cellClass cellClass_" + i + "_0",
            showPopup: true,
            value: d.currencyCode,
            options:Currency.map(c=>c.code)
          },
          {
            ...defaultCellFieldData,
            id: "currencyName",
            className: "cellClass cellClass_" + i + "_1",
            showPopup: true,
            value: d.currencyName,
            options:Currency.map(c=>c.currency)
          },
          {
            ...defaultCellFieldData,
            id: "amount",
            className: "cellClass cellClass_" + i + "_2",
            value: d.amount,
          },
        ]);
      });
      setSheetData(row)
      addMoreRows(10,initialData.length)
    }
  },[initialData])

  function performSelectAll() {
    let jsonSpreadsheetData = JSON.stringify(sheetData);
    let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData);
    if(selectAll){
      const data = updatedSpreadsheetData.map((row,rowIndex)=>{
        return row.map((col,colIndex)=>{
          return {...col,className:col.className+" selectcell-class"}
        })
      })
      setSheetData([...data])
    }else{
      const data = updatedSpreadsheetData.map((row,rowIndex)=>{
        return row.map((col,colIndex)=>{
          return {...col,className:`cellClass cellClass_${rowIndex}_${colIndex}`}
        })
      })
      setSheetData([...data])
    }
  }
  
  function handleCellChange(data){
    setSheetData([...data])
  }
  function addRowsToSheet(){
    const rows = [];
    for (let i = 0; i < 10; i++) {
        const columns = []
        colDefs.forEach((def,j) => {
            columns.push({...defaultCellFieldData,...def,className:`cellClass cellClass_${i}_${j}`})
        });
        rows.push(columns)
    }
    setSheetData([...rows])
  }
  function handleActiveCellChange(activeCell){
    console.log(activeCell)
    hideDropDown()
    setActiveCell(activeCell)
    const selectedCell = sheetData[activeCell.row][activeCell.column];
    if(selectedCell.showPopup){
        setShowDropDown((prev)=>({...prev,state:true,options:[...selectedCell.options]}))
    }
  }
  function hideDropDown() {
    setShowDropDown({state:false,options:[]})
  }
  function showPopUp() {
    if(selectedCellsArr.length > 1){
      hideDropDown();
      return;
    }
    const cellElem = document.querySelector(`.cellClass_${activeCell.row}_${activeCell.column}`)
    if(!cellElem) return;
    const dropDown = document.querySelector(".customSpreadSheetDropDown")
    const position = cellElem.getBoundingClientRect();
    dropDown.style.top = `${position.top + window.scrollY+30}px`;
    if(insideModal){
      dropDown.style.top = `${position.top-60}px`;
    }
    dropDown.style.left = `${position.left + window.scrollX}px`;
    dropDown.style.width = `${position.width}px`;
  }
  function setItemToSheet(data) {
    if (activeCell) {
      const item = Currency.find(c=>c.code.toLocaleLowerCase().startsWith(data.toLocaleLowerCase()) || c.currency.toLocaleLowerCase().startsWith(data.toLocaleLowerCase()))
      let jsonSpreadsheetData = JSON.stringify(sheetData);
      let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData);
      updatedSpreadsheetData.map((sheetRow, i) => {
        if (i === activeCell.row) {
          sheetRow[0].value = item.code;
          sheetRow[1].value = item.currency;
        }
        return sheetRow;
      });
      
      setSheetData([...updatedSpreadsheetData]);
      hideDropDown();
    }
  }

  function addMoreRows(val,currentL) {
    const rows = [];
    for (let i = currentL; i < val+currentL; i++) {
        const columns = []
        colDefs.forEach((def,j) => {
            columns.push({...defaultCellFieldData,...def,className:`cellClass cellClass_${i}_${j}`})
        });
        rows.push(columns)
    }
    setSheetData(prev=>[...prev,...rows])
  }

  async function handleSave() {
    setErrors({})
    setMessage(null)
    try {
      const res = await saveData(sheetData)
      setMessage(res.message)
      setTimeout(() => {
        onClose()
      }, 2000);
    } catch (error) {
      const errorObj = JSON.parse(error.message)
      console.log(errorObj)
      setErrors({
        message:errorObj?.data?.errors?.message,
        errorArr:errorObj?.data?.errors?.errors
      })
    }
  }
  function handleSelectAllCells() {
    setSelectAll(prev=>!prev)
  }
  function handleRemove() {
    if(selectAll){
      addRowsToSheet()
    }else{
      let jsonSpreadsheetData = JSON.stringify(sheetData);
      let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData);
      updatedSpreadsheetData.splice(activeCell?.row,1)
      const reArrangedData = updatedSpreadsheetData.map((row,rowIndex)=>{
        return row.map((col,colIndex)=>{
          return {...col,className:`cellClass cellClass_${rowIndex}_${colIndex}`}
        })
      })
      setSheetData([...reArrangedData])
    }
  }

  function handleCellSelect(selectedCell) {
    setSelectedCellsArr(selectedCell)
  }
  
  return (
    <>
        <div className='flexRow alignItemsCenter justifyContentSpaceBetween width100'>
          <div className='flexRow'>
            <button onClick={handleSave} className='add-item-button m-2 flexRow alignItemsCenter justifyContentCenter'>
              <FaSave style={{marginRight:'5px'}}/>
              Save
            </button>
            <button onClick={handleSelectAllCells} className='add-item-button-all m-2 flexRow alignItemsCenter justifyContentCenter'>
              {selectAll?<BiSolidSelectMultiple  style={{marginRight:'5px'}} />:<FaRegCheckSquare  style={{marginRight:'5px'}} />}
              Select All
            </button>
            <button onClick={handleRemove} className='remove-button m-2 flexRow alignItemsCenter justifyContentCenter'>
              <RiDeleteBin6Line style={{marginRight:'5px'}} />
              Delete
            </button>
          </div>
          <div>
          {errors?.message && (
          <div style={{marginBottom:0}} className={`customSpreadsheetErr padding4 alert alert-${errors?.message.includes('successfully') ? 'success' : 'danger'}`}>
            <p className='mb-0'>{errors?.message}</p>
            {/* {errors?.errorArr?.length > 0 && (
              <ul>
                {errors?.errorArr.map(errInfo=>(
                  <li>{`${errInfo?.message} at line number ${errInfo?.row+1}`}</li>
                ))}
              </ul>
            )} */}
          </div>
        )}
        {message && (
          <div style={{marginBottom:0}} className={`customSpreadsheetSuccess padding4 alert alert-${message.includes('successfully') ? 'success' : 'danger'}`}>
            <p className='mb-0'>{message}</p>
          </div>
        )}
          </div>
        </div>
        {showTitle && (
          <div>Title</div>
        )}
        
        <div className='sheet-container'>
          <ReactSpreadsheet
              className='spread-sheet'
              data={sheetData}
              columnLabels={columns}
              darkMode={darkMode || true}
              onChange={handleCellChange}
              onActivate={handleActiveCellChange}
              onSelect={handleCellSelect}
          />
        </div>
        <button title='Add 10 rows at bottom' className='add-item-button' onClick={()=>addMoreRows(10,sheetData.length)}>+ 10 Rows</button>
            <div style={{height:showDropDown.state ? "auto":"0px",transition:"all 0.2s"}} className="customSpreadSheetDropDown sm-scrollbar">
              <ul>
                {showDropDown.options.length > 0 ? showDropDown.options.map((data)=>(
                    <li onClick={()=>setItemToSheet(data)}>{data}</li>
                )):(
                    <li className=''>No match found!</li>
                )}
              </ul>
            </div>
    </>
  )
}

export default CustomSpreadSheet