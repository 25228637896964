import React, { useState } from 'react';
import { APIEndpoint } from "../../../utils/axios";
import {TooltipLabel} from '../../../common/TooltipLabel';
import { toolTipInfo } from '../../../utils/itemSel';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
export const ConfigurationPage = (props) => {
  const { t,ready } = useTranslation();
  const preCompile = {
    title: t('pages.configurationContent.title'),
    save:t('buttons.save')
  }

  const [configResp, setConfigResp] = useState({});
  const [configSet,setConfigSet]=useState(null);
  const [updated,setUpdated]=useState(false);
  const [modalhtmlFormStatusObj, setModalhtmlFormStatusObj] = useState({ text: '' });
  const [show, setShow] = useState(false);
  const [state, setState] = useState({mswipeEnabled:false,mandatoryBillPrintPrompt:false,mandatoryScheduleService:false,autoConfirmUplift:false,ensureWarehouseStockDuringUplift:false,msg91Enabled:false})

  React.useEffect(() => {
    fetchConfig();
  }, []);
  React.useEffect(() => {
    setInputConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configResp]);

  React.useEffect(() => {
    if(show){
        const timeId = setTimeout(() => {
        setShow(false);
      setModalhtmlFormStatusObj({ text: '' });
      }, 3000)
  return () => {
        clearTimeout(timeId)
      }
    }
    }, [show]);

    let isError = false;
    let updateStatusText = '';

    if (modalhtmlFormStatusObj) {
      if (('text' in modalhtmlFormStatusObj)) {
        updateStatusText = modalhtmlFormStatusObj.text;
      }
      if (('error' in modalhtmlFormStatusObj)) {
        isError = modalhtmlFormStatusObj.error;
      }
    };
  const setInputConfig=()=>{
    setConfigSet(configResp);
    if(configResp){
      setState((_prev) => {
        return { ..._prev,msg91:{authKey:configResp.msg91?.authKey?configResp.msg91?.authKey:'',templateId:configResp.msg91?.templateId?configResp.msg91?.templateId:''},tin:configResp.tin?configResp.tin:'',mswipeEnabled:configResp.mswipeEnabled,mandatoryBillPrintPrompt:configResp.mandatoryBillPrintPrompt,mandatoryScheduleService:configResp.mandatoryScheduleService,autoConfirmUplift:configResp.autoConfirmUplift,ensureWarehouseStockDuringUplift:configResp.ensureWarehouseStockDuringUplift,msg91Enabled:configResp.msg91Enabled };
    });
    }
  }
  const fetchConfig = async() => {
        try {
           const resp = await APIEndpoint.get(`customer/app/config`);

           if(resp.success){
            const {result}=resp;
            setConfigSet(result);
            setConfigResp(result);
       }
           }
            catch (resp) {
              console.log(resp.msg)
            }
     }

  const onChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "mswipeEnabled":
        setState((_prev) => {
          return { ..._prev, mswipeEnabled:!state.mswipeEnabled };
      });

        break;
        case "mandatoryBillPrintPrompt":
          setState((_prev) => {
            return { ..._prev, mandatoryBillPrintPrompt:!state.mandatoryBillPrintPrompt };
        });
        break;
        case "mandatoryScheduleService":
          setState((_prev) => {
            return { ..._prev, mandatoryScheduleService:!state.mandatoryScheduleService };
        });
        break;
        case "autoConfirmUplift":
          setState((_prev) => {
            return { ..._prev, autoConfirmUplift:!state.autoConfirmUplift };
        });
        break;
        case "ensureWarehouseStockDuringUplift":
          setState((_prev) => {
            return { ..._prev, ensureWarehouseStockDuringUplift:!state.ensureWarehouseStockDuringUplift };
        });
        break;
        case "msg91Enabled":
          setState((_prev) => {
            return { ..._prev, msg91Enabled:!state.msg91Enabled };
        });
        break;
        case "authKey":
          case "templateId":
            setState((_prev) => {
              return {
                ..._prev,
                msg91: { ..._prev.msg91, [name]: value },
              };
            });
        break;
      default:
        setState((_prev) => {
          return { ..._prev };
      });
        break;
    }
    let newUpdates = configSet ? { ...configSet } : {}

    if (name === "authKey" || name === "templateId") {
      newUpdates.msg91 = { ...newUpdates.msg91, [name]: value };
    } else {
      newUpdates[name] = value;
    }
    setUpdated(true);
    setConfigSet({ ...newUpdates });

  }

  const getDate = () => {
    return moment().subtract(1, 'days').format('YYYY-MM-DD') + "T18:30:00.000Z";
  }
const onEditSubmit=async()=>{
  const date={startdate:getDate(),endDate:getDate()};
  let body
  if(!updated){
body={...configResp,...state,...date}
  }
  else{
    body={...state,...configSet,...date}
  }
try {
  const resp = await APIEndpoint.post(`customer/app/config`,body);
  if(resp.success){
    setModalhtmlFormStatusObj({ text: resp.message });
    setShow(true);
    setUpdated(false);
  }
} catch (error) {
  setModalhtmlFormStatusObj({ text: error.resp.message });
    setShow(true);
}
  }
  return (
<div role="dialog" style={{height:'80vh',overflowY:'auto'}}>
<div >
<h2 className="dialog__title subheading-text-medium fontMedium textAlignCenter">{preCompile.title}</h2>
<hr />
<div style={{display:'flex'}} className="flexRow justifyContentSpaceBetween flexWrap">
<div className='width40 margLeft margLeft20'>
<div className="flexCol justifyContentSpaceEvenly ">
<div className=" dialog__container_item input-container margTop24">
<div className="dialog__description_config margBottom38 margBottom41">
<label>Account Alias :<span><TooltipLabel content={toolTipInfo.accountAlias} direction="top">
      <span className="info-label" role="img">
      <div className="bg">
      <button className="info-button">
  <span className="info">i</span>
</button>
      </div>
      </span>
    </TooltipLabel>
    </span> &nbsp;
    </label>

</div>
</div>
</div>

<div className="flexCol justifyContentSpaceEvenly ">
<div className=" dialog__container_item input-container">
<div className="dialog__description_config margBottom41">
  <label >Carrier Code :</label>

</div>
</div>
</div>




<div className="flexCol justifyContentSpaceEvenly ">
<div className=" dialog__container_item input-container">
<div className="dialog__description_config margBottom38 margBottom41">
  <label >mSwipe :</label>

</div>
</div>
</div>

<div className="flexCol justifyContentSpaceEvenly ">
<div className=" dialog__container_item input-container">
<div className="dialog__description_config margBottom38">
  <label >GSTIN :</label>

</div>
</div>
</div>

<div className="flexCol justifyContentSpaceEvenly ">
<div className=" dialog__container_item input-container">
<div className="dialog__description_config margBottom28">

    <label>Mandatory Bill Print Prompt : <span><TooltipLabel content={toolTipInfo.mandBillPrompt} direction="top">
  <span className="info-label" role="img">
      <div className="bg">
      <button className="info-button">
  <span className="info">i</span>
</button>
          </div>
      </span>
    </TooltipLabel></span>
    </label>

</div>
</div>
</div>

<div className="flexCol justifyContentSpaceEvenly ">
<div className=" dialog__container_item input-container">
<div className="dialog__description_config margBottom28">
    <label>Schedule service mandatory :<span><TooltipLabel content={toolTipInfo.schServMand} direction="top">
  <span className="info-label" role="img">
      <div className="bg">
      <button className="info-button">
  <span className="info">i</span>
</button>
          </div>
      </span>
    </TooltipLabel></span>
    </label>
</div>
</div>
</div>

<div className="flexCol justifyContentSpaceEvenly ">
<div className=" dialog__container_item input-container">
<div className="dialog__description_config margBottom28">
    <label>Auto-Confirm Uplift : <span><TooltipLabel content={toolTipInfo.autoConfUplift} direction="top">
  <span className="info-label" role="img">
      <div className="bg">
      <button className="info-button">
  <span className="info">i</span>
</button>
          </div>
      </span>
    </TooltipLabel></span>
    </label>
</div>
</div>
</div>

<div className="flexCol justifyContentSpaceEvenly ">
<div className=" dialog__container_item input-container">
<div className="dialog__description_config margBottom28">
<>
    <label>Ensure Stock In Warehouse During Uplift : <span><TooltipLabel content={toolTipInfo.ensStockDuringUplift} direction="top">
      <span className="info-label" role="img">
      <div className="bg">
      <button className="info-button">
  <span className="info">i</span>
</button>
          </div>
      </span>
    </TooltipLabel></span>
    </label>
  </>
</div>
</div>
</div>

<div className="flexCol justifyContentSpaceEvenly ">
<div className=" dialog__container_item input-container">
<div className="dialog__description_config margBottom28">
  <label >MSG91 :</label>

</div>
</div>
</div>
<div className="flexCol justifyContentSpaceEvenly ">
<div className=" dialog__container_item input-container">
<div className="dialog__description_config margBottom38 margBottom41">
<label>Auth Key  :<span><TooltipLabel content={toolTipInfo.authKey} direction="top">
      <span className="info-label" role="img">
      <div className="bg">
      <button className="info-button">
  <span className="info">i</span>
</button>
      </div>
      </span>
    </TooltipLabel>
    </span> &nbsp;
    </label>

</div>
</div>
</div>

<div className="flexCol justifyContentSpaceEvenly ">
<div className=" dialog__container_item input-container">
<div className="dialog__description_config margBottom38">
<label>Template ID  :<span><TooltipLabel content={toolTipInfo.tempId} direction="top">
      <span className="info-label" role="img">
      <div className="bg">
      <button className="info-button">
  <span className="info">i</span>
</button>
      </div>
      </span>
    </TooltipLabel>
    </span> &nbsp;
    </label>

</div>
</div>
</div>

</div>
<div className='width10'></div>
<div className='width40 floatRight'>



<div className="flexCol justifyContentSpaceEvenly ">
<div className=" dialog__container_item input-container">
<div className="dialog__description_config margBottom18">
  <input
    id={''}
    name="accountAlias"
    onChange={(e)=>onChange(e)}
    value={configSet?configSet.accountAlias:""}
    type={'text'}
    className='edit-input-galley1'
    />
</div>
</div>
</div>

<div className="flexCol justifyContentSpaceEvenly ">
<div className=" dialog__container_item input-container">
<div className="dialog__description_config margBottom18">

  <input
    id={''}
    name='carrierCode'
    onChange={(e)=>onChange(e)}
    value={configSet?configSet.carrierCode:""}
    type={'text'}
    className='edit-input-galley1'
    />
</div>
</div>
</div>




<div className="flexCol justifyContentSpaceEvenly ">
<div className=" dialog__container_item input-container">
<div className="dialog__description_config margBottom18">

  <>
  <div className={`dialog__description__radio`}>
  <label className="control control-radio" htmlFor="1">
  <input  id="1" type="radio"
    name="mswipeEnabled"
    value= {state.mswipeEnabled}
    checked={state.mswipeEnabled}
  onChange={onChange} />
  <div className="control-indicator"></div>
  </label>
  <span className='margRight80'>Enable</span>

  <label className="control control-radio" htmlFor="2">
  <input  id="2" type="radio"
  name="mswipeEnabled"
  value={!state.mswipeEnabled}
  checked={!state.mswipeEnabled}
  onChange={onChange} />
  <div className="control-indicator"></div>
  </label>
  <span>Disable</span>

  </div>
  </>
</div>
</div>
</div>

<div className="flexCol justifyContentSpaceEvenly ">
<div className=" dialog__container_item input-container">
<div className="dialog__description_config margBottom18">
  <input  id={''}
    name='tin'
    onChange={(e)=>onChange(e)}
    value={configSet?configSet.tin:""}
    placeholder='GSTIN'
    type={'text'}
    className='edit-input-galley1' />
</div>
</div>
</div>

<div className="flexCol justifyContentSpaceEvenly ">
<div className=" dialog__container_item input-container">
<div className="dialog__description_config margBottom18">

  <>
  <div className={`dialog__description__radio`}>
  <label  className="control control-radio" htmlFor="3">
  <input  id="3" type="radio"
    name="mandatoryBillPrintPrompt"
    value= {state.mandatoryBillPrintPrompt}
    checked={state.mandatoryBillPrintPrompt}
  onChange={onChange} />
  <div className="control-indicator"></div>
  </label>
  <span className="margRight80">Enable</span>

  <label  className="control control-radio" htmlFor="4">
  <input  id="4" type="radio"
  name="mandatoryBillPrintPrompt"
  value={!state.mandatoryBillPrintPrompt}
  checked={!state.mandatoryBillPrintPrompt}
  onChange={onChange} />
  <div className="control-indicator"></div>
  </label>
  <span>Disable</span>

  </div>
  </>
</div>
</div>
</div>

<div className="flexCol justifyContentSpaceEvenly ">
<div className=" dialog__container_item input-container">
<div className="dialog__description_config margBottom18">

  <>
  <div className={`dialog__description__radio`}>
  <label  className="control control-radio" htmlFor="5">
  <input  id="5" type="radio"
    name="mandatoryScheduleService"
    value= {state.mandatoryScheduleService}
    checked={state.mandatoryScheduleService}
  onChange={onChange} />
  <div className="control-indicator"></div>
  </label>
  <span className="margRight80">Enable</span>

  <label  className="control control-radio" htmlFor="6">
  <input  id="6" type="radio"
  name="mandatoryScheduleService"
  value={!state.mandatoryScheduleService}
  checked={!state.mandatoryScheduleService}
  onChange={onChange} />
  <div className="control-indicator"></div>
  </label>
  <span>Disable</span>

  </div>
  </>
</div>
</div>
</div>

<div className="flexCol justifyContentSpaceEvenly ">
<div className=" dialog__container_item input-container">
<div className="dialog__description_config margBottom18">

  <>
  <div className={`dialog__description__radio`}>
  <label  className="control control-radio" htmlFor="7">
  <input  id="7" type="radio"
    name="autoConfirmUplift"
    value= {state.autoConfirmUplift}
    checked={state.autoConfirmUplift}
  onChange={onChange} />
  <div className="control-indicator"></div>
  </label>
  <span className="margRight80">Enable</span>

  <label  className="control control-radio" htmlFor="8">
  <input  id="8" type="radio"
  name="autoConfirmUplift"
  value={!state.autoConfirmUplift}
  checked={!state.autoConfirmUplift}
  onChange={onChange} />
  <div className="control-indicator"></div>
  </label>
  <span>Disable</span>
  </div>
  </>
</div>
</div>
</div>

<div className="flexCol justifyContentSpaceEvenly ">
<div className=" dialog__container_item input-container">
<div className="dialog__description_config margBottom18">

  <>
  <div className={`dialog__description__radio`}>
  <label  className="control control-radio" htmlFor="9">
  <input  id="9" type="radio"
    name="ensureWarehouseStockDuringUplift"
    value= {state.ensureWarehouseStockDuringUplift}
    checked={state.ensureWarehouseStockDuringUplift}
  onChange={onChange} />
  <div className="control-indicator"></div>
  </label>
  <span className="margRight80">Enable</span>

  <label  className="control control-radio" htmlFor="10">
  <input  id="10" type="radio"
  name="ensureWarehouseStockDuringUplift"
  value={!state.ensureWarehouseStockDuringUplift}
  checked={!state.ensureWarehouseStockDuringUplift}
  onChange={onChange} />
  <div className="control-indicator"></div>
  </label>
  <span>Disable</span>

  </div>
  </>
</div>
</div>
</div>

<div className="flexCol justifyContentSpaceEvenly ">
<div className=" dialog__container_item input-container">
<div className="dialog__description_config margBottom18">
  <>
  <div className={`dialog__description__radio`}>
    <label  className="control control-radio" htmlFor="11">
    <input  id="11" type="radio"
    name="msg91Enabled"
    value= {state.msg91Enabled}
    checked={state.msg91Enabled}
    onChange={onChange} />
  <div className="control-indicator"></div>
    </label>
    <span className="margRight80">Enable</span>

    <label  className="control control-radio" htmlFor="12">
    <input  id="12" type="radio"
    name="msg91Enabled"
    value={!state.msg91Enabled}
    checked={!state.msg91Enabled}
    onChange={onChange} />
  <div className="control-indicator"></div>
    </label>
    <span>Disable</span>
  </div>
  </>
</div>
</div>
</div>

<div className="flexCol justifyContentSpaceEvenly ">
<div className=" dialog__container_item input-container">
<div className="dialog__description_config margBottom18">
  <input
    id={''}
    name="authKey"
    placeholder='Auth Key'
    onChange={(e)=>onChange(e)}
    value={configSet?configSet.msg91?.authKey:""}
    type={'text'}
    className='edit-input-galley1'
    />
</div>
</div>
</div>

<div className="flexCol justifyContentSpaceEvenly ">
<div className=" dialog__container_item input-container">
<div className="dialog__description_config margBottom18">
  <input
    id={''}
    name="templateId"
    placeholder='Template ID'
    onChange={(e)=>onChange(e)}
    value={configSet?configSet.msg91?.templateId:""}
    type={'text'}
    className='edit-input-galley1'
    />
</div>
</div>
</div>

</div>
</div>
{updateStatusText&&show ?
<>
<div className={ "confirmation_popup " + (isError ? 'alert_error' : 'alert_success') }>
{updateStatusText}
</div>
</> : null }
</div>
<div className="dialog__footer flexCol alignItemsCenter margTop8">
<button className="done-button" onClick={onEditSubmit}>{props.title === 'Edit Item' ? 'Update' : 'Save'}</button>
</div>
{
!ready &&<div className='overlay_hide sub'></div>
}
</div>
);
}