import React,{useEffect, useState} from 'react'
import { ORDER_SALE_TYPE_LIST_DYNAMIC,ORDER_STATUS_DYNAMIC,ORDER_PAYMENT_TYPE_LIST } from '../../../../utils/configs';
import { formatDateByTimezone } from "../../../../utils/commonFunctions";
import CustomSelect from "../../../../common/CustomSelect";
import moment from 'moment';
import { TbReload } from "react-icons/tb";
import { IoArrowForwardOutline } from "react-icons/io5";
import deleteIcon from '../../../../assets/icons/delete.svg';
import editIcon from "../../../../assets/edit.svg";
import { useTranslation } from "react-i18next";


const OrderListing=(props)=> {
    const {dataOrder,flightDetails,inventoryDetails}=props;

    const { t,ready } = useTranslation();
    const [collapsedRows, setCollapsedRows] = useState(null);
    const [orderLists, setOrderLists] = useState({});

    const toggleCollapse = (rowId) => {
      setCollapsedRows(prevIndex => (prevIndex === rowId ? null : rowId));
    }
useEffect(() => {
  if(dataOrder){
    setOrderLists(dataOrder)
  }
}, [dataOrder])

const getOrderItems=(item)=>{
    const res=  item.map((it,index)=>
   <div key ={index} className='justifyContentSpaceBetween margBottom10 flexRow width100'>
    <div style={{width:'80%'}}className='justifyContentFlexStart'>{it.itemName}<span className='margLeft10'>{it.quantity}x{it.itemPrice}</span></div>
    <div className='flexRow'>{it.totalAmount}</div>
    </div>
     )
      return res;
}
const onStatusChange=(e)=>{
e?props.setStatus(e.value):props.setStatus("")
}
const onSaleTypeChange=(e)=>{
  e?props.setSaleType(e.value):props.setSaleType("")


}
const onPaymentTypeChange=(e)=>{
  e?props.setPaymentType(e.value):props.setPaymentType("")

}
const onSearch=(e)=>{
    props.setSearchKeyOrders(e.target.value)
}

  return (
    <>
    <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
    {
            <>
  <div className='fitcontent justifyContentSpaceBetween display wrapper-sector-dynamic'>
              <div className='flexRow sector-banner justifyContentSpaceBetween'>
                <div className='flexRow justifyContentFlexStart'>
                   <div className='sector-number fontMedium'>{flightDetails?.flightNumber??orderLists?.flightDetails.flightNumber}</div>
                   <div className='flexCol justifyContentCenter alignItemsCenter'>
                <div className='flexRow sector-tag'>{flightDetails?.origin??orderLists?.flightDetails.origin}</div>
                <div className='flexRow fontMedium'>{flightDetails?.originName??orderLists?.flightDetails.originName}</div>
                </div>
                </div>

                <div className='flexCol justifyContentCenter alignItemsCenter'>
                <div className='flexRow'> <IoArrowForwardOutline size={17} color={'#F06548'}/></div>
                <div className='flexRow fontMedium'>{moment.parseZone(formatDateByTimezone(flightDetails.flightDate?flightDetails?.flightDate:orderLists?.flightDetails.flightDate)).format('DD-MM-YYYY')}</div>
                </div>
               <div className='flexCol justifyContentCenter alignItemsCenter'>
                <div className='flexRow sector-tag'>{flightDetails?.destination??orderLists?.flightDetails.destination}</div>
                <div className='flexRow fontMedium'>{flightDetails?.destinationName??orderLists?.flightDetails.destinationName}</div>
                </div>
              </div>
              </div>
                <div className='flexRow width100 margBot10 margTop8 justifyContentSpaceBetween alignItemsCenter tableFilterBlocks'>
                    <div className="flexRow justifyContentFlexStart alignItemsCenter leftBlock">
                        <div className="flexRow justifyContentFlexEnd alignItemsBaseline">
                              <button className='saleType-div m-2'>
                              <CustomSelect
                              options={ORDER_STATUS_DYNAMIC}
                              width={'13rem'}
                              placeHolder={'Order Status'}
                              onChange={onStatusChange}
                              />
                              </button>

                        <button className='saleType-div m-2'>
                              <CustomSelect
                              options={ORDER_SALE_TYPE_LIST_DYNAMIC}
                              width={'13rem'}
                              placeHolder={'Sale Type'}
                              onChange={onSaleTypeChange}
                              />
                              </button>

                            <button className='saleType-div m-2'>
                              <CustomSelect
                              options={ORDER_PAYMENT_TYPE_LIST}
                              width={'13rem'}
                              placeHolder={'Payment Type'}
                              onChange={onPaymentTypeChange}
                              />
                              </button>

                        </div>
                    </div>
                    <div className="flexRow justifyContentFlexEnd alignItemsCenter rightBlock">
                        <div className="underline margRight10">
                            <span><input className="search" onChange={onSearch} value={props.searchKeyOrders} type="search" placeholder="Search Order no / seat no" /></span>
                        </div>




                    </div>
                </div>
                <div className='table-header'></div>

                <table style={{borderRadius:'5px'}}>
      <thead style={{backgroundColor:'#1b3a48'}}>
        <tr  className='sticky'>
          <th>Order No</th>
          <th style={{width:"15%"}}>Seat No</th>
          <th>Sale Type</th>
          <th style={{ whiteSpace: 'nowrap' }}>Pay Mode Status</th>
         <th> Status</th>
          <th>Total</th>

        </tr>
      </thead>
     {orderLists?.data && orderLists.data?.length? <tbody>
        {orderLists?.data.map((row, index) => (
          <React.Fragment key={index}>
            <tr className='pointer' style={{ backgroundColor: collapsedRows === index ? '#1d3e4e' : '' }} onClick={() => toggleCollapse(index)}>
              <td style={{ whiteSpace: 'nowrap' }}>{row.orderNo}</td>
              <td>{row.customerInfo?.seatNumber}</td>
              <td style={{ whiteSpace: 'nowrap' }}>{ORDER_SALE_TYPE_LIST_DYNAMIC.find(ord=>ord.value===row.saleType)?.label}</td>
              <td style={{ whiteSpace: 'nowrap' }}>{row.paymentType==='none'?"SSR":row.paymentType.charAt(0).toUpperCase()+row.paymentType.slice(1)}</td>
              <td style={{ whiteSpace: 'nowrap' }}>{row.status.charAt(0).toUpperCase()+row.status.slice(1)==='Accepted'?<span className='status-consolidate' style={{backgroundColor:'#47d26c'}}>{row.status.charAt(0).toUpperCase()+row.status.slice(1)}</span>:<span className='status-consolidate' style={{backgroundColor:'#f03630'}}>{row.status.charAt(0).toUpperCase()+row.status.slice(1)}</span>}</td>
              <td style={{ whiteSpace: 'nowrap' }}>{`${row.currencyInfo?.currencyCode} ${row.totalAmount} (${row.items.length}) ${row.items.length!==1?"Items":'Item'}`}</td>

            </tr>
            {collapsedRows===index && (
              <tr>
                <td style={{backgroundColor:'#1d3e4e'}} colSpan="6">

            <div className='width100 justifyContentSpaceBetween display'>
{row.status==='accepted'&& <div className='flexRow justifyContentFlexStart'>
            <span onClick={()=>inventoryDetails(row,null,'editOrder',null,null)} className='status-consolidate-orders' style={{marginLeft:0,backgroundColor:'#03A5FC'}}><img  className="icon-size" style={{margin:'4px'}} alt='uploadItem' src={editIcon} />Edit Order</span>
            <span  onClick={()=>inventoryDetails(row,null,"cancelOrder",null,null)} className='status-consolidate-orders' style={{backgroundColor:'#F03630'}}><img  className="icon-size" style={{margin:'4px'}} alt='uploadItem' src={deleteIcon} />Cancel Order</span>

</div>}
{row.status==='cancelled'&& <div className='flexRow justifyContentFlexStart'>
            <span  onClick={()=>inventoryDetails(row,null,"undoCancelOrder",null,null)} className='status-consolidate-orders' style={{backgroundColor:'#4c5c81',padding:'10px',marginLeft:0}}><TbReload/> &nbsp;Undo Cancel Order</span>

</div>}
              <div className='flexRow'>
                  <div style={{float:'left'}} className="margTop8 flexCol width50 justifyContentFlexStart">
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Date and Time</label>
                  <div>{moment(row.createdAt).format('DD-MM-YYYY hh:mm A')}</div>
                </div>

                <div className='margBottom10'>
                  <label className='fontExtrabold'>Taken By</label>
                  <div className='flexCol'>
                  <div className='flexRow'>
                  {row.createdBy.employeeName}
                    </div>
                    <div className='flexRow'>
                    {row.createdBy.employeeCode}
                    </div>
                    </div>
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Ordered Using</label>
                  <div className='flexCol'>
                  <div className='flexRow'>
                  {row.userAgent.appName}@ {row.userAgent.appVersion}
                    </div>
                    <div className='flexRow'>
                    {row.userAgent.deviceModel},MAC ID: {row.userAgent.macId}
                    </div>
                    </div>
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Customer Info</label>
                  <div className='flexCol'>
                  <div className='flexRow'>
                  {row.customerInfo.customerName}
                    </div>
                    <div className='flexRow'>
                    Seat No: {row.customerInfo.seatNumber}
                    </div>
                    <div className='flexRow'>
                    PNR: {row.customerInfo.pnr}
                    </div>
                    </div>
                </div>


              </div>


              <div  className="flexCol width50">

                <div className='flexCol margBottom10 justifyContentSpaceBetween'>
                  <label className='fontExtrabold'>Items</label>
                { getOrderItems(row.items)}
                <div className='flexCol justifyContentFlexEnd'>
                    <div className='fontExtrabold flexRow justifyContentFlexEnd margBottom10'>
                    {`Gross ${row.currencyInfo?.currencyCode}`} {row.netAmountInUsedCurrency}
                    </div>
                    <div className='fontExtrabold flexRow justifyContentFlexEnd margBottom10'>
                    {`GST ${row.currencyInfo?.currencyCode}`} {row.gstAmountInUsedCurrency}
                    </div>
                    <div className='fontExtrabold flexRow justifyContentFlexEnd margBottom10'>
                    {`Grand Total ${row.currencyInfo?.currencyCode}`} {row.totalAmountInUsedCurrency}
                    </div>

                </div>

                </div>

              </div>


              </div>
            </div>

                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>:
      <tr>
      <td colSpan="6">
      <div className='flexRow w-100 h-100 justifyContentCenter alignItemsCenter'>No Data</div>
      </td>
      </tr>
      }
    </table>
            </>
        }

        {
          !ready &&<div className='overlay_hide sub'></div>
        }
    </div>
</>
  )
}

export default OrderListing;