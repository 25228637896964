import React, {useState } from 'react';
import { FilterBodyTemplate } from "../../../utils/configs";
import { APIEndpoint } from "../../../utils/axios";
import { DataGrid,UploadButton, EditModal, UploadModal } from "../../../common";
import { onDownloadXlsFile ,translationLength} from '../../../utils/commonFunctions';
import { useTranslation } from 'react-i18next';
import Edit2 from "../../../assets/edit2.svg";
// import moment from "moment";

const Columns = [
  { field: 'perishableCode', headerName: 'Perishable Code', suppressSizeToFit:true,flex:1, resizable:true, },
  { field: 'type', headerName: 'Type', suppressSizeToFit:true, resizable:true,flex:1, },
  { field: 'segment', headerName: 'Segment', suppressSizeToFit:true, resizable:true,flex:1, },
  { field: 'sellingType', headerName: 'Timing', suppressSizeToFit:true, resizable:true, flex:1,},
  { field: 'percentageText', headerName: 'Percentage', suppressSizeToFit:true, resizable:true, flex:1,},
];

const FormFields = [
  { label: 'Perishable Code', attribute: 'perishableCode', type: 'text', required: true, disabled: true },
  { label: 'Segment', attribute: 'segment', type: 'text', disabled: true },
  { label: 'Uplift Percentage', attribute: 'percentage', type: 'text' }
]

export const PerishableCodes = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({});

  const fetchPerishableCodes = async (iPageNo = 1, iPageLimit = 10, iSearchText = '') => {
    const filterData = JSON.parse(JSON.stringify(FilterBodyTemplate));
    filterData.page = iPageNo;
    filterData.limit = iPageLimit;
    filterData.searchParam = iSearchText;
    const res = await APIEndpoint.post('estimation/perishablecodes/process', filterData);
    if (res.items) {
      res.items = res.items.map((_) => {
        return {
          ..._,
          percentageText: `${_.percentage}%`
        }
      })
    }
    return res;
  }
  const handleAction = async (e, action, selectedRowData) => {
    try {
      const res = await APIEndpoint.get('estimation/perishablecodes/' + selectedRowData.perishableCode)
      if (!res.success) {
        alert(res.msg || 'Unable to fetch Perisable Code')
      }
      setState((_) => {
        return { ..._, editRow: res.result }
      })
    } catch (error) {
      alert('Unable to fetch Perisable Code')
    }
  };
  const handleReset = (e, reset) => {
    setState((_) => {
      return { ..._, resetDataSource: reset ? true : state.resetDataSource, editRow: null };
    })
    if (reset) {
      let timerId = setTimeout(() => {
        clearTimeout(timerId);
        setState((_) => {
          return { ..._, resetDataSource: false };
        })
      }, 500)
    }
  }
  const handleFormSubmit = async (e, values) => {
    if (Object.keys(values).length) {
      try {
        values = { ...state.editRow, ...values };
        const res = await APIEndpoint.post('estimation/perishablecodes/update/' + state.editRow.perishableCode, values);
        if (res.success) {
          handleReset(null, true)
        }
        alert(res.msg)
      } catch (error) {
        alert('Error While updating Standard Item')
      }
    }
  }
  const onDownloadSampleFile = async (e) => {
    onDownloadXlsFile('samples/downloads/Perishable_Codes_Upload_Sample', 'Perishable_Codes_Upload_Sample')
  }
  const handleUploadCodes = async (e) => {
    const formData = new FormData()
    formData.append('file', e.target.files[0]);
    return APIEndpoint.post('estimation/perishablecodes/upload', formData, { 'Content-Type': "multipart/form-data" })
  }
  return (
    <div className="device-page op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      <div className="flexRow justifyContentFlexEnd alignItemsCenter margRight margBot10">
        <UploadButton
          onClick={() => setState((_) => ({ ..._, showUpload: true }))}
          label={t('pages.estimationsContent.perishablecodes.uploadItems')} />
      </div>
      <DataGrid
        columnResize
        showDefaultFilters
        enableGridSearch={false}
        className='full-flex'
        columns={Columns}
        getRows={fetchPerishableCodes}
        resetDataSource={state.resetDataSource}
        actions={[
          { detail: [{ src: Edit2, value: "edit" ,title:'Edit'}] }
        ]}
        onAction={handleAction}
      />
      {
        Boolean(state.editRow) && (
          <EditModal
            title={t('pages.estimationsContent.perishablecodes.editModalTitle')}
            onClose={handleReset}
            onSubmit={handleFormSubmit}
            fields={
              FormFields.map((elt) => {
                const isEdit = Boolean(state.editRow)
                return { ...elt, initialValue: isEdit ? state.editRow[elt.attribute] : '' }
              })
            }
          />
        )
      }
      {
        state.showUpload &&
        <UploadModal
          uploadAPI={handleUploadCodes}
          modelTitle={t('pages.estimationsContent.perishablecodes.uploadItems')}
          onUploadClose={() => setState((_) => ({ ..._, showUpload: false }))}
          onDownloadSampleFile={onDownloadSampleFile}
        />
      }
       {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }
    </div>
  )
}
