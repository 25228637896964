import React, { useRef, useState } from 'react';
import { APIEndpoint } from "../../../utils/axios";
import { DataGrid, NGODatePicker } from "../../../common";
import {translationLength} from '../../../utils/commonFunctions';
import { useTranslation } from 'react-i18next';
import moment from "moment";

const Columns = [
  { field: 'aircraftNumber', headerName: 'Tail Number' },
  { field: 'startDate', headerName: 'Date' },
  { field: 'latestSession.destination', headerName: 'Last Known Sector' },
  { field: 'state', headerName: 'State' },
]

export const FlightAircraftsPage = (props) => {
  const stateListOptions = useRef(null);
  const { t } = useTranslation();
  const [state, setState] = useState({ isListReady: false, filterPrice: '', resetDataSource: false, })

  React.useEffect(() => {
    fetchStateList();
  });

  const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 10,
    page: 1,
    searchParam: ''
  };

  const fetchAircrafts = async (iPageNo = 1, iPageLimit = 10, iSearchText = '') => {
    filterData.page = iPageNo;
    filterData.limit = iPageLimit;
    filterData.searchParam = iSearchText;
    if (state.filterDate) {
      let flightDate = moment(state.filterDate).startOf('day').toString();
      filterData.filters = [{ targetDate: flightDate }]
    }
    if (state.storeId) {
      filterData.filters = [{ state: state.storeId }]
    }
    const res = await APIEndpoint.post('aircraftStates/process', filterData);
    console.log(res)
    return res;
  }

  const fetchStateList = async () => {
    const routeList = [
      {label:t('pages.flightsContent.aircrafts.firstLogin'),status:"created"},
      {label:t('pages.flightsContent.aircrafts.loggedIn'),status:"open"},
      {label:t('pages.flightsContent.aircrafts.inProgress'),status:"sessionSynced"},
      {label:t('pages.flightsContent.aircrafts.awaitingCrew'),status:"crewChangeInitiated"},
      {label:t('pages.flightsContent.aircrafts.dayEnded'),status:"closed"},
    ];
    stateListOptions.current = routeList.map((_pl) => {
      return { value: _pl.status, label: _pl.label}
    })
    setState((_) => {
      return { ..._, isListReady: true }
    })
  };

  const handleSelectRouteList = (e) => {
    setState((_) => {
      return { ..._, storeId: e.target.value, resetDataSource: true, showAddForm: false, editRow: null }
    });
    let timeoutId = setTimeout(() => {
      clearTimeout(timeoutId)
      setState((_) => {
        return { ..._, resetDataSource: false }
      });
    }, 100)
  }

  const handleFilterDateChange = (e) => {
    setState((_) => ({ ..._, filterDate: e.target.value, resetDataSource: true }));
    let timerId = setTimeout(() => {
      clearTimeout(timerId);
      setState((_) => {
        return { ..._, resetDataSource: false };
      })
    }, 500)
  }

  return (
    <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      {
        Boolean(state.isListReady) &&
        <>
          <div className="flexRow justifyContentFlexEnd alignItemsCenter margRight margBot10">
          </div>
          <DataGrid
            columnResize
            resetDataSource={state.resetDataSource}
            showDefaultFilters
            className='full-flex'
            columns={Columns}
            getRows={fetchAircrafts}
            RightComponent={() => {
              return (
                <div className='flexRow alignItemsCenter'>
                  <select className='select-item' onChange={handleSelectRouteList} value={state.storeId} id="item-type">
                    <option value="">{t('pages.flightsContent.aircrafts.selectState')}</option>
                    {stateListOptions.current.map((item, index) => (
                      <option key={index} value={item.value}>{item.label}</option>
                    ))
                    }
                  </select>
                  <NGODatePicker
                  style={{marginLeft: '30px'}}
                  value={state.filterDate}
                  name='filterDate'
                  onChange={handleFilterDateChange} />
                </div>
              )
            }}
          />
        </>
      }
       {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }
    </div>
  )
}
