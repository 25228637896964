/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { FilterBodyTemplate } from "../../../utils/configs";
import { APIEndpoint } from "../../../utils/axios";
import { onDownloadXlsFile,translationLength } from '../../../utils/commonFunctions';
import { DataGrid,UploadButton, DownloadButton, Modal, NGODatePicker, IconButton, UploadModal, EditModal } from "../../../common";
import { useTranslation } from 'react-i18next';
import Edit2 from "../../../assets/edit2.svg";
import LoadingGIF from "../../../assets/loadingIn.gif";
import moment from "moment";

const Columns = [
  { field: 'code', headerName: 'DFP Code', resizable:true,flex:1},
  { field: 'startDateStr', headerName: 'Start Date', resizable:true,flex:1},
  { field: 'createdAtStr', headerName: 'Created At', resizable:true,flex:1},
  { field: 'createdByStr', headerName: 'Created By', resizable:true,flex:1},
]
const ItemColumns = [
  { field: 'groupIdentifier', headerName: 'Gp No.',resizable:true,flex:1 },
  { field: 'serialNumber', headerName: 'Sl No.',resizable:true,flex:1 },
  { field: 'flightNumber', headerName: 'Flt No.',resizable:true,flex:1 },
  { field: 'origin', headerName: 'From',resizable:true,flex:1 },
  { field: 'destination', headerName: 'To',resizable:true,flex:1 },
  { field: 'aircraftNumber', headerName: 'Tail No.',resizable:true,flex:1 },
  { field: 'uplift', headerName: 'Uplift',resizable:true,flex:1 },
  { field: 'location', headerName: 'Location',resizable:true,flex:1 },
  { field: 'lookAheadText', headerName: 'Look Ahead',resizable:true,flex:1 },
  { field: 'statinaryItems', headerName: 'Stationary Items',resizable:true,flex:1 },
  { field: 'topup', headerName: 'Topup',resizable:true,flex:1 },
  { field: 'status', headerName: 'Status',resizable:true,flex:1 }
]
const FlightScheduleColumns = [
  { field: 'serialNumber', headerName: 'Line',resizable:true,flex:1 },
  { field: 'aircraftNumber', headerName: 'Tail No.',resizable:true,flex:1 },
  { field: 'flightNumber', headerName: 'Flt No.',resizable:true,flex:1 },
  { field: 'origin', headerName: 'From',resizable:true,flex:1 },
  { field: 'destination', headerName: 'To',resizable:true,flex:1 },
  { field: 'date', headerName: 'Date',resizable:true,flex:1 },
  { field: 'std', headerName: 'STD',resizable:true,flex:1 },
  { field: 'atd', headerName: 'ATD',resizable:true,flex:1 },
  { field: 'status', headerName: 'Flight Status',resizable:true,flex:1 },
  { field: 'syncStatus', headerName: 'Status',resizable:true,flex:1 },
]

const UploadFormFields = [
  { label: 'Start Date', attribute: 'startDate', type: 'date', required: true }
]
const DownloadFormFields = [
  { label: 'Report Type', attribute: 'reportType', type: 'select', required: true },
  { label: 'Start Date', attribute: 'startDate', type: 'date', required: false },
  { label: 'End Date', attribute: 'endDate', type: 'date', required: false },
]

export const DiningSchedulePage = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    resetDataSource: false, detailsRow: null, detailsRowData: null,
    filterDate: new Date(), checkSchedule: null, dialogType: null
  });
  useEffect(() => {
    if (Boolean(state.detailsRow) && state.detailsRowData === null) {
      fetchItems();
    }
  }, [state])
  const fetchDiningSchedules = async (iPageNo = 1, iPageLimit = 10, iSearchText = '') => {
    const filterData = JSON.parse(JSON.stringify(FilterBodyTemplate));
    filterData.page = iPageNo;
    filterData.limit = iPageLimit;
    filterData.searchParam = iSearchText;
    if (state.filterDate) {
      let flightDate = moment(state.filterDate).startOf('day').toString();
      filterData.filters = [{ flightDate: flightDate }]
    }
    const res = await APIEndpoint.post('dfp/process', filterData);
    if (res.items) {
      res.items = res.items.map((_) => {
        return {
          ..._,
          startDateStr: moment(_.startDate).format('DD-MM-YYYY hh:mm A'),
          createdAtStr: moment(_.createdAt).format('DD-MM-YYYY hh:mm A'),
          createdByStr: `${_.createdBy.employeeName}(${_.createdBy.employeeCode})`
        }
      })
    }
    return res;
  }
  const fetchItems = async (iPageNo = 1, iPageLimit = 10, iSearchText = '') => {
    const res = await APIEndpoint.get(`dfp/${state.detailsRow.id}/items`);
    if (res.items) {
      res.items = res.items.map((_) => {
        return {
          ..._,
          location: _.upliftAirport ? _.upliftAirport.locationCode : '',
          lookAheadText: _.lookAhead ? 'Yes' : 'No',
          topup: _.isTopupUplift ? "Yes" : 'No',
          uplift: _.isTopupUplift ? "Yes" : 'No',
          statinaryItems: _.upliftStationaryItems ? "Yes" : 'No',
        }
      })
    }
    setState((_) => {
      return { ..._, detailsRowData: res.items }
    })
  }
  const handleAction = (e, action, row) => {
    setState((_) => {
      return { ..._, detailsRow: row }
    })
  }
  const onClose = () => {
    setState((_) => {
      return { ..._, detailsRow: null, detailsRowData: null, showFlightSchedule: false, generatedSchedules: null }
    })
  }
  const handleFilterDateChange = (date) => {
    setState((_) => ({ ..._, filterDate: date, resetDataSource: true }));
    let timerId = setTimeout(() => {
      clearTimeout(timerId);
      setState((_) => {
        return { ..._, resetDataSource: false };
      })
    }, 500)
  }
  const handleCheckSchedule = async () => {
    if (state.filterDate) {
      let generatedSchedules = 'Error'
      setState((_) => ({ ..._, showFlightSchedule: true }));
      let targetDate = moment(state.filterDate).startOf('day').toString();
      const res = await APIEndpoint.post('schedule/generate', { targetDate: targetDate });
      if (res.success) {
        generatedSchedules = res.dfp.items.map((_) => {
          let actualDepartureDateTime = moment(_.actualDepartureDateTime);
          return {
            ..._,
            date: actualDepartureDateTime.format('DD-MM-YYYY'),
            std: moment(_.departureDateTime).format('hh:mm A'),
            atd: actualDepartureDateTime.format('hh:mm A'),
          }
        });
      }
      setState((_) => ({ ..._, generatedSchedules: generatedSchedules }))
      if (!res.success && res.message) {
        alert(res.message);
      }
    }
  }
  const handleDiningSchedule = async (e, updated) => {
    const formData = new FormData()
    formData.append('file', e.target.files[0]);
    Object.keys(updated).forEach((key) => {
      formData.append(key, updated[key]);
    })
    return APIEndpoint.post('distributors/master/upload', formData, { 'Content-Type': "multipart/form-data" })
  }
  const onDownloadSampleFile = async (e) => {
    onDownloadXlsFile('samples/downloads/DFP_Upload_Sample', 'DFP_Upload_Sample')
  }
  const handleDownloadReport = async () => {
    const res = await APIEndpoint.get("reports/dfpReportTypes")
    const list = (res.result || []).map((_) => ({ value: _.id, label: _.value }));
    list.unshift({ value: '', label: t('pages.estimationsContent.diningSchedule.reportTypeSelectPlaceholder') })
    const downloadFormData = { reportType: '', startDate: new Date(), endDate: new Date() }
    setState((_) => ({ ..._, dialogType: 'download', dftReportTypes: list, downloadFormData: downloadFormData }))
  }
  const handleDownloadForm = async (e, updated) => {
    setState((_) => ({ ..._, downloadFormData: { ..._.downloadFormData, ...updated } }))
  }
  return (
    <div className="device-page op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      <div className="flexRow justifyContentFlexEnd alignItemsCenter margRight margBot10">
        <IconButton
          onClick={handleCheckSchedule}
          label={t('pages.estimationsContent.diningSchedule.checkSchedule')} />
        <UploadButton
          onClick={() => setState((_) => ({ ..._, dialogType: 'upload' }))}
          label={t('pages.estimationsContent.diningSchedule.uploadBtn')}
        />
        <DownloadButton
          onClick={handleDownloadReport}
          label={t('pages.estimationsContent.diningSchedule.downloadBtn')} />
      </div>
      <DataGrid
        columnResize
        showDefaultFilters
        className='full-flex'
        columns={Columns}
        getRows={fetchDiningSchedules}
        resetDataSource={state.resetDataSource}
        actions={[
          { detail: [{ src: Edit2, value: "edit" }] }
        ]}
        onAction={handleAction}
        RightComponent={(props) => {
          return <NGODatePicker value={state.filterDate} onChange={handleFilterDateChange} />
        }}
      />
      {
        Boolean(state.detailsRow) &&
        <Modal onClose={onClose} title={state.detailsRow.code} showDone={false}>
          <div className='flexCol' style={{ height: '60vh', width: '100%' }}>
            <DataGrid
              columnResize
              rowModelType='clientSide'
              showDefaultFilters={false}
              className='full-flex'
              columns={ItemColumns}
              rowData={state.detailsRowData || []}
            />
          </div>
        </Modal>
      }
      {
        Boolean(state.showFlightSchedule) &&
        <Modal
          onClose={onClose}
          title={`${t('pages.estimationsContent.diningSchedule.flightSchedule')} - ${moment(state.filterDate).format("DD-MM-YYYY")}`}
          showDone={false}>
          <div className='flexCol' style={{ height: '60vh', width: '100%' }}>
            {
              Boolean(state.generatedSchedules) ?
                <>
                  {
                    state.generatedSchedules === 'Error' ?
                      <div className='flexCol full-flex justifyContentCenter alignItemsCenter'>
                        <div>Error</div>
                      </div>
                      :
                      <DataGrid
                        columnResize
                        rowModelType='clientSide'
                        showDefaultFilters={false}
                        className='full-flex'
                        columns={FlightScheduleColumns}
                        rowData={state.generatedSchedules || []}
                      />
                  }
                </>
                :
                <div className='flexRow full-flex justifyContentCenter alignItemsCenter'>
                  <img src={LoadingGIF} className='cell-loading' alt='cell loading' />
                  <div>{t('pages.estimationsContent.diningSchedule.flightSchedulePlaceholder')}</div>
                </div>
            }
          </div>
        </Modal>
      }
      {
        state.dialogType === 'upload' &&
        <UploadModal
          uploadAPI={handleDiningSchedule}
          inputFields={
            UploadFormFields.map((_) => ({ ..._, initialValue: '', minDate: new Date() }))
          }
          modelTitle={t('pages.estimationsContent.diningSchedule.uploadBtn')}
          onUploadClose={() => setState((_) => ({ ..._, dialogType: '' }))}
          onDownloadSampleFile={onDownloadSampleFile}
        />
      }
      {
        state.dialogType === 'download' &&
        <EditModal
          showDone={false}
          showFieldsInColumn
          fields={
            DownloadFormFields.map((_) => {
              return {
                ..._, initialValue: state.downloadFormData[_.attribute], options: state.dftReportTypes,
                ...(_.attribute === 'endDate' && state.downloadFormData.startDate ? { minDate: new Date(state.downloadFormData.startDate) } : {})
              }
            })
          }
          onChange={handleDownloadForm}
          title={t('pages.estimationsContent.diningSchedule.downloadBtn')}
          onClose={() => setState((_) => ({ ..._, dialogType: '' }))} >
          <div className="dialog__footer flexCol alignItemsCenter">
            <DownloadButton
              downloadLink='dfp/download'
              className="done-button"
              payload={state.downloadFormData}
              disabled={!(state.downloadFormData && state.downloadFormData.reportType)}
              label={t('buttons.download')} />
          </div>
        </EditModal>
      }
       {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }
    </div>
  )
}
