/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState,useEffect } from 'react';
import { APIEndpoint } from "../../../utils/axios";
import {Input} from "../../../common";
import { useTranslation } from 'react-i18next';
import { passwEx } from '../../../utils/commonFunctions';
import moment from 'moment';

const EditFormField = [
    { label: 'Password', attribute: 'password', type: 'password', required: true, disabled: false },
    { label: 'Confirm Password', attribute: 'confirmPassword', type: 'password',required: true, disabled: false },
]

export const UpdatePOSLogin = (props) => {
  const { t,ready } = useTranslation();
  const [pwdLenNotify, setPwdLenNotify] = useState("");
  const [confPwdLenNotify, setConfPwdLenNotify] = useState("");
  const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
  const [show, setShow] = useState(false);
  const [updated, setUpdated] = useState(null)
  const modelStyle = { maxWidth: '50%' };
  const preCompile = {
    title: t('pages.employeesSub.updatePOSLogin'),
    update:t('buttons.update'),
    passwordReqWarning:t('warningTexts.passwordReq'),
    passwordLengthCheck:t('warningTexts.passwordLengthCheck'),
    passwordMatchCheck:t('warningTexts.passwordMatchCheck'),
  }
useEffect(() => {
if(updated!==null&&!updated?.password&&!updated?.confirmPassword){
  setConfPwdLenNotify(t('warningTexts.passwordReq'));
  setPwdLenNotify(t('warningTexts.passwordReq'));
buttonStatus()
}
}, [updated])

React.useEffect(() => {
  if(show){
      const timeId = setTimeout(() => {
      setShow(false);
    setModalFormStatusObj({ text: '' });
    if(modalFormStatusObj.text){

    }
    }, 3000)
return () => {
      clearTimeout(timeId)
    }
  }
  }, [show]);

useEffect(() => {
buttonStatus();
  }, [])
  let isError = false;
  let updateStatusText = '';

  if (modalFormStatusObj) {
    if (('text' in modalFormStatusObj)) {
      updateStatusText = modalFormStatusObj.text;
    }
    if (('error' in modalFormStatusObj)) {
      isError = modalFormStatusObj.error;
    }
  };

const getDateBody=()=>{
  let localTime = moment().format('YYYY-MM-DD');
  return localTime + "T18:30:00.000Z";
}
  const onSubmit =async() => {
    const {password,confirmPassword}=updated;
   if(!pwdLenNotify&&!confPwdLenNotify){
    const body={
      "startDate": getDateBody(),
      "endDate": getDateBody(),
      "password": password,
      "confirmPassword": confirmPassword
  }
    try {
      const res = await APIEndpoint.post(`emloyee/login/pos`,body);
     if(res.success){
    setModalFormStatusObj({ text: res.message });
     }
   }
   catch (error) {
    setModalFormStatusObj({ error: error.resp.message });
   }
  }
  }
const buttonStatus=()=>{
  if(pwdLenNotify.length&&confPwdLenNotify.length){
    return true
  }
  if(!pwdLenNotify.length&&confPwdLenNotify.length){
    return true
  }
  if(pwdLenNotify.length&&!confPwdLenNotify.length){
    return true
  }
if(updated){
  if(updated.password&&updated.confirmPassword){
    return false
  }
  else{
    return true
  }
}
else{
  return true
}
}
const validatePassword=(pwd)=>{
    if(pwd.password!==undefined && !pwd?.password?.match(passwEx)){
      setPwdLenNotify(t('warningTexts.passwordLengthCheck'));
    }
    if(pwd.confirmPassword!==undefined && !pwd?.confirmPassword?.match(passwEx)){
      setConfPwdLenNotify(t('warningTexts.passwordLengthCheck'));;
    }
    if((pwd?.password!==undefined && pwd?.confirmPassword!==undefined) && (pwd?.password?.match(passwEx) && pwd.confirmPassword?.match(passwEx))){
      if(pwd.password!==pwd.confirmPassword){
        setConfPwdLenNotify(t('warningTexts.passwordMatchCheck'));
      }
    }
    if(pwd.password!==undefined && pwd?.password?.match(passwEx)){
      setPwdLenNotify("");
    }
    if(pwd.confirmPassword!==undefined && pwd?.confirmPassword?.match(passwEx)&&pwd.password===pwd.confirmPassword){
      setConfPwdLenNotify("");
    }
    if((pwd?.password!==undefined||pwd?.confirmPassword!==undefined) && pwd?.password?.match(passwEx) && pwd.password===pwd.confirmPassword){
      setConfPwdLenNotify("");
    }
}

const onChange=async(e)=>{
  const {name,value}=e.target;
  let newUpdates = updated ? { ...updated } : {}
  newUpdates[name] = value;
  setUpdated({ ...newUpdates });
    validatePassword({ ...newUpdates });
}
  return (
    <div className="flexRow justifyContentCenter alignItemsCenter" role="dialog">
    <div className="update-password" style={modelStyle}>
        <h2 className="dialog__title subheading-text-medium fontMedium textAlignCenter">{preCompile.title}</h2>
        <hr />
        <div className="dialog__body_container  justifyContentCenter flexCol">
        {
      EditFormField.map((_,index) => {
        const { initialValue, ...rest } = _;
        return (
          <React.Fragment key={index}>
          <div className={(pwdLenNotify&&_.attribute==="password")||(confPwdLenNotify&&_.attribute==="confirmPassword")?'passwordReset_body-input':'passwordReset_body'}>
              <Input
                key={_.attribute}
                id={_.attribute}
                name={_.attribute}
                value={updated ? updated[_.attribute] : initialValue !== undefined ? initialValue : ''}
                data={initialValue}
                onChange={onChange}
                {...rest}
              />
            {pwdLenNotify&&_.attribute==="password"? <h6 style={{position:'absolute',flexWrap:'wrap',maxWidth:'16.5rem' }} className="small-body-text-regular-label-upload text-danger">{pwdLenNotify}</h6>:<></>}
            {confPwdLenNotify&&_.attribute==="confirmPassword"? <h6 style={{position:'absolute',flexWrap:'wrap',maxWidth:'16.5rem'}} className="small-body-text-regular-label-upload text-danger">{confPwdLenNotify}</h6>:<></>}
          </div>
        </React.Fragment>
        )
      })
    }
        </div>
        <>
        {updateStatusText&&show ?
        <>
          <div className={ "confirmation_popup" + (isError ? 'alert_error' : 'alert_success') }>
            {updateStatusText}
          </div>
        </> : null }
        </>
        <div className="dialog__footer flexRow justifyContentCenter margTop36">
            <button disabled={buttonStatus()} className="done-button" onClick={onSubmit}>{preCompile.update}</button>
          </div>
      </div>
      {
      !ready &&<div className='overlay_hide sub'></div>
      }
      </div>
  )
}
