import React, { useEffect, useRef, useState } from 'react';
import { DataGrid, EditModal, UploadModal} from "../../../common";
import { APIEndpoint } from "../../../utils/axios";
import uploadItemIcon from '../../../assets/icons/upload-items.svg';
import { useTranslation } from 'react-i18next';
import usePermissions from '../../../utils/userPermissions';
import downloadMasterIcon from '../../../assets/icons/download-master.svg';
import searchIcon from '../../../assets/icons/search.svg';
import { onDownloadXlsFile} from '../../../utils/commonFunctions';
import LoadingGIF from '../../../assets/loadingIn.gif';

  export const AircraftMaster = (props) => {

  const tableRef = useRef();
  const { t } = useTranslation();
  const [perPageLimit, setPerPageLimit] = useState(10);
  const [searchKey, setSearchKey] = useState('');
  const [showUpload, setShowUpload] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [state,setState] = useState({})
  const [aircraftItems]=useState([]);
  const [uploadedFile, setUploadedFile ] = useState([])
  const [uploadFileStatusBlock, setUploadFileStatusBlock ] = useState('')
  const [aircraftTypes, setAircraftTypes] = useState([])
  const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
  const [resetDataSource, setResetDataSource] = useState(true)

  const { hasPermission: viewAircraftmaster } = usePermissions('viewDetails', "configuration.fsc.aircrafts");
  const { hasPermission: downloadAircraftmaster } = usePermissions('download',"configuration.fsc.aircrafts");
  const { hasPermission: uploadAircraftmaster } = usePermissions('upload',"configuration.fsc.aircrafts");

  useEffect(() => {
    if(state.selected){
      fetchAircraftTypes()
    }
  }, [state])

  async function fetchAircraftTypes() {
    try {
      const res = await APIEndpoint.get("aircraftTypes")
      if(res.success){
        const options = res.result.map(type=>{
          return {key:type.id,label:type.value,value:type.id}
        })
        setAircraftTypes([...options])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 10,
    page: 1,
    searchParam: ''
  }

  const AircraftTypeRenderer = (params)=>{
    if (params.data&&params.data.aircraftType) {
      return params.data.aircraftType.key ;
   }
   return (
    <button onClick={()=>setState(_=>({..._,selected:params.data}))} className="grid-download-btnStyle1">Set Type</button>
   );
  }

  const Columns = [
    { field: 'aircraftNumber', headerName: 'Aircraft Number',resizable:true,  suppressSizeToFit:true,flex:1 },
    { field: 'name', headerName: 'Aircraft Name', resizable:true,suppressSizeToFit:true,flex:1,tooltipField: 'name',valueGetter: (params) => {
      if (params.data&&params.data.name) {
         return params.data.name.length>21? params.data.name.slice(0,22)+"...": params.data.name ;
      }
      return null;
    } },
    { field: 'typeCode', headerName: 'Type Code',resizable:true,  suppressSizeToFit:true,flex:1 },
    { field: 'aircraftType', headerName: 'Type',resizable:true,  suppressSizeToFit:true,flex:1 ,tooltipField: 'aircraftType.key',
      cellRenderer: AircraftTypeRenderer
    },
  ];

  const AddFormFields = [
    {label: 'Aircraft Type', attribute: 'aircrafttype', type: 'selectSearch2', disabled: false, options:aircraftTypes, required: true },
  ];

  const fetchAircraftMaster = async (page=1, perPage=10) => {
    if(!viewAircraftmaster){
      return {count:0,items:[]};
    }
    filterData.page = page;
    filterData.limit = perPage;
    filterData.searchParam = searchKey;
    const res = await APIEndpoint.post('aircrafts/process', filterData);
    return res;
  };

  const onSearch = (e) => {
    setSearchKey(e.target.value);
  };

  const onUploadTaxMaster=()=>{
    setShowUpload(true)
  }

  const onDownloadItemMaster = async () => {
    let header = {
      'Accept': 'text/csv'
    }
   try {
      const res = await APIEndpoint.post('aircrafts/operationStats', {}, header)

      if (res.status === 'scheduled') {
        setIsDownloading(true);

        const intervalId = setInterval(async () => {
          const dowId = await APIEndpoint.get(`downloads/status/${res.id}`)

          if (dowId.status === 'completed') {
            clearInterval(intervalId);
            setIsDownloading(false);

            const ItemMasterDwnld = await APIEndpoint.get(`downloads/analytics/${dowId.fileName}${dowId.fileExtension}`)
            const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'Aircraft_Operation_Stats.csv')
            document.body.appendChild(link)
            link.click()
          } else if (dowId.status === 'failed') {
            clearInterval(intervalId)
            setIsDownloading(false)
            console.error('Download failed:', dowId.message)
          }
        }, 1000)
      }
    }  catch (error) {
      setState((_) => {
        return { ..._,message:{text:error?.data?.message||"Something went wrong!"},}
      })
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          message: null,
        }))
      }, 5000);
       setIsDownloading(false)
     }
  }

  const onPerLimitChange = (e) => {
      setPerPageLimit(Number(e.target.value));
  }

  const onUploadClose = () => {
      setShowUpload(false);
      setUploadedFile([]);
      setUploadFileStatusBlock('');
  }

  const onDownloadSampleFile = () => {
    onDownloadXlsFile('samples/downloads/Aircrafts_Upload_Sample','Aircraft_Upload_Sample')
  }


  const onUploadFileChangeHandler = async (e) => {
    try {
      const file = e.target.files[0];
      setUploadedFile(e.target.files[0]);
      setUploadFileStatusBlock('<p>Please wait while we process your data...</p>');

      const formData = new FormData();
      formData.append('aircarft', aircraftItems || [])
      formData.append('file', file);

      await APIEndpoint.post('aircrafts/upload', formData)

      setUploadedFile([]);
      setUploadFileStatusBlock('<p>File Imported Successfully.</p>')
      setTimeout(() => {
        setUploadedFile(false);
        onUploadClose()
    }, 2500);
    } catch (error) {
      let { status, data } = JSON.parse( error.message );
      let msgHeading = data?.Error || data?.message || error.message;

      let msgContent = "";
      if (status === 400 && data?.errors) {
        data.errors.forEach( _ => {
        const lineNum = _.lineNumber;
        const errorMsgArr = [];
        _.errors.forEach(erObj => {
          errorMsgArr.push( erObj.field?erObj.field:"" + ' | ' + erObj.message + ' \n<br>' );
        });

        msgContent += '<br>Line ' + lineNum + ': \n<br>' + errorMsgArr.join(' ');
    });
  } else if(status===0 && !data){
    msgContent += `Error occurred while uploading Aircraft sheet!<br>Please try again.`;
}
else if(status === 400 && data && data?.Error){
    msgHeading = data?.Error;
    msgContent += `Error occurred while uploading Aircraft sheet!<br>Please try again.`;
}
  else {
    msgContent += `Error occurred while uploading Aircraft sheet!<br>Please fix the errors and re-upload.`;
  }


      setUploadFileStatusBlock("<p class='listErrorMessagesCls'>"+ msgHeading + "</p>" +
                                "<p class='listErrorMessagesCls sm-scrollbar border p-2 pt-1'>" + msgContent +"</p>"
                              );
    }
  }

  function onClose(){
    setModalFormStatusObj({text:""})
    setState(_=>({..._,selected:null}))
  }

  async function onSubmitAircraftType(e, updated){
    try {
      if(updated?.aircrafttype){
        const res = await APIEndpoint.put(`aircrafts/${state.selected.id}/aircraftType`,{
          aircraftTypeId:updated.aircrafttype
        })
        if(res.success){
          setModalFormStatusObj({ text: res.message });
          setResetDataSource(prev=>!prev)
          setTimeout(() => {
            onClose()
          }, 2000);
        }
      }else{
        setModalFormStatusObj({  error: "Please select aircraft type!" });
        return;
      }
    } catch (error) {
      console.log("Error updating aircraft type!")
    }
  }

  return (
    <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      {
        <>
          <div className='flexRow width100 margBot10 justifyContentFlexEnd'>


            <div className="margLeft flexRow justifyContentFlexEnd alignItemsBaseline">
               {uploadAircraftmaster?<button className='upload dropdownStyle1-TextMedium' onClick={() => onUploadTaxMaster(true)}> <span><img  className="icon-size" alt='uploadItem' src={uploadItemIcon} /><u>{t('pages.pagesContent.aircraftMaster.uploadButtonTitle')}</u></span></button>:null}
             {  downloadAircraftmaster?<button className='download dropdownStyle1-TextMedium' onClick={onDownloadItemMaster}><span>{isDownloading?<img src={LoadingGIF} alt='loading' className='cell-loading' />:<img className="icon-size mt-1" alt='downloadMaster' src={downloadMasterIcon} />}<u className={`download__banner_${isDownloading ? "active" : ""}`}>{isDownloading?t('filters.downloadingMaster'):t('pages.pagesContent.aircraftMaster.downloadButtonTitle')}</u></span></button>:null}
            </div>
          </div>
          <div className=' flexRow justifyContentCenter  margBot10 margTop8  alignItemsCenter'>
          <div className="margLeft margBot10 underline">
              <span><img  className="icon-size" alt={t('filters.searchText')} src={searchIcon} /></span>
              <span><input className="search" onChange={onSearch} type="search" placeholder={t('filters.searchText')} value={searchKey}/></span>
            </div>
          </div>

          <DataGrid
            ref={tableRef}
            className='full-flex'
            resetDataSource={resetDataSource}
            columns={[...Columns]}
            initialPageLimit={perPageLimit}
            showDefaultFilters={false}
            searchKey={searchKey}
            getRows={fetchAircraftMaster} />
        </>
      }

     {
        showUpload &&
        <UploadModal
          modelTitle={t('pages.pagesContent.aircraftMaster.uploadButtonTitle')}
          onUploadClose={onUploadClose}
          onDownloadSampleFile={onDownloadSampleFile}

            fields={aircraftItems}
            uploadedFile = { uploadedFile }
            onUploadFileChangeHandler = { (e) => onUploadFileChangeHandler(e)}
            uploadFileStatusBlock = { uploadFileStatusBlock }
            />
          }
          {state?.selected && <EditModal
            key={state.selected.action}
            title={"Add Aircarft Type"}
            onClose={onClose}
            showFieldsInColumn={true}
            buttonLabel="Submit"
            onSubmit={onSubmitAircraftType}
            modalFormStatusObj={modalFormStatusObj}
            fields={
              AddFormFields.map( (elt) => {
                return {...elt, initialValue: state.selected}
              })
            }
            />
          }
        </div>
  )
}