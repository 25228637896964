/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */

import React, {useEffect,useState } from 'react'
import { APIEndpoint, DFPAPIEndpoint } from '../../../utils/axios';
import { NGODatePicker,Modal} from '../../../common';
import closeChart from '../../../images/icons/closeChart.png'
import {errorFormatter } from '../../../utils/commonFunctions';
import closeIcon from '../../../images/icons/Icon2.png';
import LoadingIn from '../../../assets/loadingIn.gif';
import plus from '../../../images/icons/plus.png';
import bar from '../../../assets/icons/bar.svg';
import { crewInc,sectSales } from '../../../utils/configs';
import moment from 'moment';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import AllMultiSelect from "../../../common/AllSelectCheckbox.jsx";
import downloadMasterIcon from '../../../assets/icons/download-master.svg';
import monitor from '../../../assets/icons/monitor.svg'
import { components } from "react-select";
import pieChartIcon from '../../../assets/icons/pi-chart-sales.svg';
import totalOrders from '../../../assets/icons/total-orders.svg';
import totalSales from '../../../assets/icons/total-sales.svg';
import CustomSelect from '../../../common/CustomSelect.jsx';




export const PerformancePage = (props) => {

  const specialSaleTypes=[{key:1,label:'# Seat upgrades Done',value:'noSeatUpgrades'},
    {key:2,label:'Merchandise sales',value:'merchandiseSales'}
  ]
  const images = [pieChartIcon,bar,totalOrders,totalSales,monitor];
  const randomImage = images[Math.floor(Math.random() * images.length)];

  const { t,ready } = useTranslation();
  const [state, setState] = useState({emptInst:false,resetDataSource:false,disabled:false,showDetails:false,addSalesCards:false,addedSalesCards:false,addnSalesCards:[]});
const [optionSelectedCrew,setOptionSelectedCrew]=useState(null);
const [optionSelectedSector,setOptionSelectedSector]=useState(null);
  const [errorMessage,SetErrorMessage]=useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showDownload,setShowDownload]=useState(false);
  const [reportsType, setReportTypes] = useState([]);
  const [selectedSales, setSelectedSales] = useState([]);
  const [addnSales]=useState(specialSaleTypes)
  const [reportDownloadErr, setReportDownloadErr] = useState(null);
  const [reptType,setReptType]=useState("");
  const [crewMembers,setCrewMembers]=useState([]);
  const [sectors,setSectors]=useState([]);
  const [dashboardCards,setDashBoardCards]=useState([]);
  const [crewName,setCrewName]=useState([]);
  const [currentDate1, setCurrentDate1] = useState(moment().subtract(1, 'month'));
  const [currentDate2, setCurrentDate2] = useState(moment().add(1, 'month'));
const [reportDate,setReportDate]=useState({startDate:new Date(moment()),endDate:new Date(moment())});
  const [isDownloading, setIsDownloading] = useState(false);
  const sType = localStorage.getItem('sType');
  const DOMAIN = (sType === 'dfp' ? DFPAPIEndpoint : APIEndpoint);

  useEffect(() => {
    getCrewMembers();
    getCrewIncentiveAndSales();

  }, [])


  useEffect(() => {
    if(!reptType){
      getCrewIncentiveAndSales();
    }
  }, [reportDate,crewName])

  useEffect(() => {
    const updateDisabledState = () => {
      if (!reptType) return true;
    };

    setState((_prev) => ({
      ..._prev,
      disabled: updateDisabledState(),
    }));
    if(optionSelectedCrew && optionSelectedCrew?.length){
      if( optionSelectedCrew.filter(opt=>opt.value==='*').length){
        setOptionSelectedCrew([{key:null,label:`All ${crewMembers.length-1} selected`,value:crewMembers?.map((item)=>item.key.toString()).filter(item=>item!=='0')}])
      }
     }
     if(optionSelectedSector && optionSelectedSector?.length){
      if( optionSelectedSector.filter(opt=>opt.value==='*').length){
        setOptionSelectedSector([{key:null,label:`All ${optionSelectedSector.length-1} selected`,value:sectors?.map((item)=>item.value.replace(/\s+/g, '')).filter(item=>item!=="")}])
      }
     }
  }, [reptType, crewName, optionSelectedCrew, optionSelectedSector]);

const getCrewMembers=async()=>{
    try {
    const resp2 = await DOMAIN.get('sandboxing/employees?includeAdmin=no');
      if (resp2.result.length > 0) {
        const options = [{key:0,label:'Select Crew',value:""}];
        resp2.result.filter( (fieldValue, index) => {
          options.push({key: fieldValue.employeeId, label: `${fieldValue.employeeName} (${fieldValue.employeeCode}) ${fieldValue.employeeId} ${index}`, value: `${fieldValue.employeeCode}`});
          return fieldValue;
        });
        setCrewMembers(options.filter((item,index,self)=>index===self.findIndex(i=>(i.key===item.key && i.value===item.value))));
    }
    } catch (error) {

    }


}

const getOptions=(cMemb)=>{

    return cMemb.filter(a=>a.value);


}

const handleMenuOpen = () => {
  setIsDropdownOpen(true);
};

const handleMenuClose = () => {
  setIsDropdownOpen(false);
};

const Option = props => {
  return (
  <div>
  <components.Option {...props}>
  <input
  type="checkbox"
  checked={props.isSelected}
  onChange={() => null}
  />{" "}
  <label>{props.label}</label>
  </components.Option>
  </div>
  );
  };

const MultiValue = props => (
  <components.MultiValue {...props}>
  <span>{props.data.label}</span>
  </components.MultiValue>
  );

const newCustomStyles = {
  input: (defaultStyles) => ({
    ...defaultStyles,
    color: '#fff',
    }),
    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#162E3E",
      padding: "2px",
      width:'17rem',
      marginBottom: '1rem',
      border: "0.4px solid #FFFFFF",
      boxShadow: "none",
      PointerEvent: 'none',
    })
};
const handleSalesUpdates=(e)=>{

  setSelectedSales((prevSales) => {
    const prevSaleExist = Array.isArray(prevSales) ? prevSales : [];

    if (e === null) {
      return prevSaleExist.slice(0, prevSaleExist.length - 1);
    } else {
      if(!prevSaleExist.some(item=>item.label===e.label)){
        return [...prevSaleExist, e];
      }
      return prevSaleExist;

    }
  });

}

const getCrewIncentiveAndSales=async()=>{
  setIsDownloading(true)
 const body= {
    startDate: moment(reportDate.startDate, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ").format(),
    endDate:moment(reportDate.endDate, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ").format()
}
try {
  const resp1 = await DOMAIN.post('sales/getCrewIncentiveSummary',body);
  const resp2 = await DOMAIN.post('sales/getSectorWiseSales',body);

if (resp1&&resp2) {
  const randId=Math.floor(Math.random() * (10 - 1 + 1)) + 1;
  setDashBoardCards([{crewIncentive:crewInc.find(cr=>cr.id===randId),sectorWiseSale:sectSales.find(sc=>sc.id===randId)}]);
  setIsDownloading(false)
}else{
  setIsDownloading(false)
}
} catch (error) {
  const err=JSON.parse(error.message)

  if(err.data&&err.data?.errors){
    let errorMessageFormatted= errorFormatter(err.data.errors[0]?.message)
    SetErrorMessage(errorMessageFormatted||"Error in fetching data!");
    setTimeout(() => {
      SetErrorMessage("")
      }, 4000);
   }else{
    SetErrorMessage("Error in fetching data!");
    setTimeout(() => {
      SetErrorMessage("")
      }, 4000);
   }


}
}

const onCrewChange=(e)=>{
  setCrewName([e])
  setReportDownloadErr(null)
}
const onCrewFilterChange=(e)=>{
setCrewName([e])
}
const handleModalCancel=()=>{
  setState((_)=>({..._,addSalesCards:!state.addSalesCards}))
}

const handleModalSubmit=()=>{
  setState((_)=>({..._,addedSalesCards:true,addSalesCards:!state.addSalesCards,addnSalesCards:selectedSales}))

}

  const handlePrevMonth = () => {
    setCurrentDate1(prevDate => prevDate.clone().subtract(1, 'month'));
    setCurrentDate2(prevDate => prevDate.clone().subtract(1, 'month'));
    setReportDate((prev)=>({...prev,startDate:moment().startOf('month').subtract(1, 'month').toDate(),endDate:moment().startOf('month').subtract(1, 'month').toDate()}))

  };

  const handleNextMonth = () => {
    setCurrentDate1(prevDate => prevDate.clone().add(1, 'month'));
    setCurrentDate2(prevDate => prevDate.clone().add(1, 'month'));
    setReportDate((prev)=>({...prev,startDate:moment().startOf('month').add(1, 'month').toDate(),endDate:moment().startOf('month').add(1, 'month').toDate()}))

  };

  const onDownloadActivityReport=async()=>{

    try {
      const resp = await DOMAIN.get('reports/crewReportTypes');
      if (resp.result.length > 0) {
          const options = [{key: 0, label: "Select Report Type", value: ''}];
          resp.result.filter( (fieldValue, index) => {
            options.push({key: index+1, label: `${fieldValue.value}`, value: `${fieldValue.id}`});
            return fieldValue;
          });
          setReportTypes(options);
      }
      else{
          console.log("error")
      }

        const resp2 = await DOMAIN.get('sandboxing/employees?includeAdmin=no');
        if (resp2.result.length > 0) {
            const options = [{key: 0, label: "Select Crew", value: ''}];
            resp2.result.filter( (fieldValue, index) => {
              options.push({key: fieldValue.employeeId, label: `${fieldValue.employeeName} (${fieldValue.employeeCode}) ${index}`, value: `${fieldValue.employeeCode}`});
              return fieldValue;
            });
            setCrewMembers(options);
        }
        const resp3 = await DOMAIN.get('stores');
        if (resp3.result.length > 0) {
            const options = [{key: 0, label: "Select Sectors", value: ''}];
            resp3.result.filter( (fieldValue, index) => {
              options.push({key: fieldValue.id, label: `${fieldValue.flightNumber} (${fieldValue.originName}-${fieldValue.destinationName}) ${fieldValue.id}`, value: `${fieldValue.value}`});
              return fieldValue;
            });
            setSectors(options.filter((item,index,self)=>index===self.findIndex(i=>(i.key===item.key && i.value===item.value))));
        }


setShowDownload(true);
  }
   catch (error) {

  }

    }

    function getCrewName(crew){
      const [crewOrg] = crew
      if(crewOrg?.value){
        return [crewOrg?.value]
      }else{
        return [crewOrg]
      }
    }

    const onDownloadReportFinal = async () => {
      let body = {
        startDate: reportDate.startDate,
        endDate: reportDate.endDate,
        reportType: reptType,
      };

      switch (reptType) {
        case 'crewCommission':
        case 'crewVariance':
        case 'crewReconVariance':
          body.crewCode = getCrewName(crewName);
          break;
        case 'customerInteractionsReport':
          body.crewCode = optionSelectedCrew[0]?.key && optionSelectedCrew[0].key===null?optionSelectedCrew[0].value:optionSelectedCrew?.map(crew => crew.value);
          body.sectors = optionSelectedSector[0]?.key && optionSelectedSector[0].key===null?optionSelectedSector[0].value:optionSelectedSector?.map(sect => sect.value);
          break;
        case 'allCrewCommission':
          break;
        default:
          break;
      }


      let header = {
        Accept: "text/csv",
      };
      if(body && reptType){
           try {
        setIsDownloading(true);
        const res = await DOMAIN.post(`crew/download/${reptType}`, body, header);
        setReportDownloadErr()
        if (res.status === "scheduled") {
          setTimeout(async () => {
            try {
              setIsDownloading(true);
              const dowId = await DOMAIN.get(`downloads/status/${res.id}`);
              const ItemMasterDwnld = await DOMAIN.get(
                `downloads/analytics/${dowId.fileName}${dowId.fileExtension}`,
              );
              const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute(
                "download",
                `${dowId.fileName}.${dowId.fileExtension}`,
              );
              document.body.appendChild(link);
              link.click();
              setIsDownloading(false);
            } catch (error) {
              setIsDownloading(false);
              const err = JSON.parse(error.message);
              const errMsg = err?.data?.error?.title || err?.data?.message;
              setReportDownloadErr(errMsg);
            }
          }, 5000);
        }
      } catch (error) {
        setIsDownloading(false);
      }
      }

    };



  const onReportTypeChange = (e) => {
    setReptType(e.target.value);
    setCrewName([]);
    setOptionSelectedCrew([]);
    setOptionSelectedSector([]);
    setReportDownloadErr(null);
}


  const handleChange = (selected) => {
    setOptionSelectedCrew(selected)
  };

  const handleChangeSector = (selected) => {
    setOptionSelectedSector(selected)
  };

const onDateChangeRep=(e)=>{
const {name,value}=e.target;
if(name==='startDate'){
    setReportDate((prev)=>({...prev,startDate:value}))
}
else{
    setReportDate((prev)=>({...prev,endDate:value}))
}
}
const addMoreSales=()=>{
  setState((_)=>({..._,addSalesCards:!state.addSalesCards}))

}

const showsalesDetails=(type)=>{
setState((_)=>({..._,type:type,showDetails:!state.showDetails}))
}
const ShowsalesDetails=()=>{
return(<div className={`overlay_dashboard ${props.isSubModal ? 'sub' : ''}`} role="dialog">
  <div className="dashboard_enlarge sales_main_desciption sm-scrollbar">
    <div id="salesCard" className={`dialog__content_salescard ${props.className || ''}`}>
      <img className="modal__close_icon" alt='Close' src={closeChart} onClick={()=>setState((_)=>({..._,showDetails:!state.showDetails}))
      }/>
      <h1 className="subheading-text-medium h2">{state.type}</h1>
      <hr/>
      <div className='rowDetail show' style={{height:'auto'}}>
                      <table id="groupedItemTbl">
                        <thead>
                          <tr>
                          <th style={{width:"20%"}}>Total Sales Done</th>
                          <th style={{width:"20%"}}>Commision Earned</th>
                          <th style={{width:"60%"}}>Deductions</th>


                          </tr>
                        </thead>
                        <tbody>
                        <tr style={{ borderBottom: "1px solid red" }}>
                          <td>782</td>
                          <td>11240</td>
                          <td>
                            <div className='margBottom10'>Inventory Variance: 12314</div>
                            <div  className='margBottom10'>Cash Variance: 5264</div>
                            <div>Total: 18398</div>
                          </td>
                        </tr>
                      </tbody>
                      </table>
                    </div>

      </div>
  </div>
</div>)
}

  const onUploadClose = () => {
    setShowDownload(false);
    setCrewName([]);
    setOptionSelectedCrew([]);
    setOptionSelectedSector([]);
    setReptType("");
    setReportDownloadErr("");
  }

const salesSpecial=()=>{
  return state.addnSalesCards.map((item)=>(
    <div className="col-lg-3 card-container-sales-header card-sales"  style={{backgroundColor:"#1d3f4f",padding:'10px',marginRight:'0.6rem',borderRadius:'5px'}} key={1 + '-1fincial-year-sales'}>
    <>
    <div className="card-content2" style={{cursor:'pointer'}} onClick={()=>showsalesDetails('Total Commission')}>
      <div className="h6">
          {item.label}
      </div>
      { isDownloading?<div className='h4 flexRow justifyContentCenter alignItemsCenter'><img src={LoadingIn}style={{width:'25px',height:'25px'}} alt="Loading" /></div>:   <div className="h4">{t('pages.dashboardContent.salesOverview.rs')} {Math.floor(Math.random() * (2560 - 340 + 1)) + 340}</div>                }

  </div>
  <div className='dashboard-header-footer'>
      <img className="dashboard-header-main-icon floatRight pb-1" alt='Current Month Avg Orders' src={randomImage} />
  </div>
    </>


</div>

  )

  )

}
  const salesAndOrders=(items)=>{
  if(items&&items.length){

         return(
       <>
       <div className='margTop10'></div>

            <div className="col-lg-3 card-container-sales-header card-sales"  style={{backgroundColor:"#1d3f4f",padding:'10px',marginRight:'0.6rem',borderRadius:'5px'}} key={1 + '-1fincial-year-sales'}>
              <>
              <div className="card-content2" style={{cursor:'pointer'}} onClick={()=>showsalesDetails('Total Commission')}>
                <div className="h6">
                    Total Commission
                </div>
                { isDownloading?<div className='h4 flexRow justifyContentCenter alignItemsCenter'><img src={LoadingIn}style={{width:'25px',height:'25px'}} alt="Loading" /></div>:   <div className="h4">{t('pages.dashboardContent.salesOverview.rs')} {items[0]?.crewIncentive?.salesCommission}</div>                }

            </div>
            <div className='dashboard-header-footer'>
                <img className="dashboard-header-main-icon floatRight pb-1" alt='Current Month Avg Orders' src={totalSales} />
            </div>
              </>


        </div>
        <div className="col-lg-3 card-container-sales-header card-sales"  style={{backgroundColor:"#1d3f4f",padding:'10px',marginRight:'0.6rem',borderRadius:'5px'}} key={2 + '-1fincial-year-sales'}>
            <div className="card-content2">
                <div className="h6">
                    Total BoB sales
                </div>
                { isDownloading?<div className='h4 flexRow justifyContentCenter alignItemsCenter'><img src={LoadingIn}style={{width:'25px',height:'25px'}} alt="Loading" /></div>:   <div className="h4">{t('pages.dashboardContent.salesOverview.rs')}{items[0]?.sectorWiseSale?.totalBoBSales}</div>                }


            </div>

            <div className='flexRow justifyContentSpaceBetween dashboard-header-footer'>
            <div >Crew Share:&nbsp;{t('pages.dashboardContent.salesOverview.rs')}{items[0].sectorWiseSale?.crewShare}</div>

                <img className="dashboard-header-main-icon floatRight pb-1" alt='Current Month Avg Orders' src={monitor} />
            </div>


        </div>
        <div className="col-lg-3 card-container-sales-header card-sales"  style={{backgroundColor:"#1d3f4f",padding:'10px',marginRight:'0.6rem',borderRadius:'5px'}} key={3 + '-1fincial-year-sales'}>
            <div className="card-content2">
                <div className="h6">
                    Cash sales
                </div>
                { isDownloading?<div className='h4 flexRow justifyContentCenter alignItemsCenter'><img src={LoadingIn}style={{width:'25px',height:'25px'}} alt="Loading" /></div>:   <div className="h4">{t('pages.dashboardContent.salesOverview.rs')} {items[0].sectorWiseSale?.totalCashSales}</div>                }


            </div>

            <div className='dashboard-header-footer'>
                <img className="dashboard-header-main-icon floatRight pb-1" alt='Current Month Avg Orders' src={totalOrders} />
            </div>




        </div>
        <div className="col-lg-3 card-container-sales-header card-sales"  style={{backgroundColor:"#1d3f4f",padding:'10px',marginRight:'0.6rem',borderRadius:'5px'}} key={4 + '-1fincial-year-sales'}>
            <div className="card-content2">
                <div className="h6">
                    Card sales
                </div>
                { isDownloading?<div className='h4 flexRow justifyContentCenter alignItemsCenter'><img src={LoadingIn}style={{width:'25px',height:'25px'}} alt="Loading" /></div>:   <div className="h4">{t('pages.dashboardContent.salesOverview.rs')}  {items[0].sectorWiseSale?.totalCardSales}</div>                }
            </div>
            <div className='dashboard-header-footer'>
                <img className="dashboard-header-main-icon floatRight pb-1" alt='Current Month Avg Orders' src={pieChartIcon} />
            </div>
        </div>
        <div className="col-lg-3 card-container-sales-header card-sales"  style={{backgroundColor:"#1d3f4f",padding:'10px',borderRadius:'5px'}} key={5 + '-1fincial-year-sales'}>
            <div className="card-content2">
              <div className='flexRow justifyContentSpaceBetween'>
              <div className="h6">
                    Special sales
                </div>
                <img src={plus}style={{width:'25px',height:'25px',cursor:'pointer'}} onClick={addMoreSales} alt="Loading" />
              </div>

                { isDownloading?<div className='h4 flexRow justifyContentCenter alignItemsCenter'><img src={LoadingIn}style={{width:'25px',height:'25px'}} alt="Loading" /></div>:   <div className="h4">{t('pages.dashboardContent.salesOverview.rs')}  {items[0].sectorWiseSale?.specialSales}</div>                }
            </div>

            <div className='flexRow justifyContentSpaceBetween dashboard-header-footer'>
            <div >Duty Sales:&nbsp;{t('pages.dashboardContent.salesOverview.rs')}{items[0].sectorWiseSale?.dutySales}</div>

                <img className="dashboard-header-main-icon floatRight pb-1" alt='Current Month Avg Orders' src={bar} />
            </div>
        </div>
  </>

  )

  }
  else{
    return(<></>)
  }
    // eslint-disable-next-line array-callback-return




}


  return (
      <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      {
        <>
          <div className="flexRow justifyContentFlexEnd alignItemsCenter margRight margBot10">
              <button className='download dropdownStyle1-TextMedium' onClick={onDownloadActivityReport}><span><img className="icon-size mt-1" alt='downloadMaster' src={downloadMasterIcon} /><u className={`download__banner_${isDownloading ? "active" : ""}`}>Download Reports</u></span></button>

          </div>
          <div className="flexRow justifyContentFlexEnd alignItemsCenter margRight margBot10">
          {
          Boolean(state.message) &&
          <div className={`validationMsgDiv error`}>
            {state.message.text}
          </div>
        }
          </div>
          <div className='flexRow width100 margBot10 margTop8 justifyContentSpaceBetween'>
            <button className="saleType-div m-2">
               {/* <select
                  className={!crewName?"select-item darkBlue-bg":"select-item widthSelect darkBlue-bg"}
                  onChange={onCrewChange}
                  value={crewName}
                  id="saleTypeId"
                  style={{width:280}}
                >
                  {crewMembers.map((_) => {
                    return (
                      <option key={_.value} value={_.value}>
                        {_.label}
                      </option>
                    );
                  })}
                </select> */}

            <CustomSelect
             onChange={onCrewFilterChange}
             options={crewMembers}
             placeHolder={"Search Crew"}
             width={"17rem"}
             value={crewMembers?.filter(function (option) {
              return (
                crewName.length && crewName[0]&&
                crewName.find((sc) => sc.label === option.label)
              );
            })}
            />


              </button>
              <div className='flexRow justifyContentFlexEnd'>
              <button onClick={handlePrevMonth} style={{height:'2.4rem'}} className="margTop9-5 add-item-button">
              {currentDate1.format('MMMM YYYY')}
                  </button><div className='flexRow justifyContentSpacebetween'>
                     <NGODatePicker
                      style={{width: '12rem'}}
                      value={reportDate.startDate}
                      className="margLeft5"
                      name="startDate"
                      maxDate={new Date()}
                      isclearable={true}
                      placeholder={t('Start Date')}
                      placeholdertext={t('datePickerPlaceholder')}
                      onChange={onDateChangeRep} />
                      <NGODatePicker
                      style={{width: '12rem'}}
                      value={reportDate.endDate}
                      className="margLeft5"
                      name="endDate"
                      maxDate={new Date()}
                      isclearable={true}
                      placeholder={t('Start Date')}
                      placeholdertext={t('datePickerPlaceholder')}
                      onChange={onDateChangeRep} />
                  </div>



                      <button onClick={handleNextMonth} style={{height:'2.4rem'}} className="margLeft5 margTop9-5 add-item-button">
                      {currentDate2.format('MMMM YYYY')}

                  </button>
            </div>
         </div>
         {(errorMessage.length) ?
        <>
          <div  style={{display:'flex',width:'100%',margin:'1rem',marginLeft:'0rem',padding:'0.3rem'}} className={ "confirmation_popup alert_error"}>
        {errorMessage[0]+errorMessage[1].toUpperCase()+errorMessage.slice(2)}
        </div>
        </> : null }
         <div className='scroll-container-perf sm-scrollbar'>
                    { salesAndOrders(dashboardCards)}
                    </div>

                {state.addnSalesCards.length ?<div className='scroll-container-perf sm-scrollbar' style={{marginTop:'1rem'}}>
                    { salesSpecial()}
                    </div>:null}

        </>
      }




     {showDownload &&
        <div className="overlay mt-4" role="dialog">
          <div className="dialog sm-scrollbar">
            <div className="dialog__content">
              <img className="modal__close_icon" alt='Close' src={closeIcon} onClick={onUploadClose} />
              <h2 className="dialog__title subheading-text-medium fontMedium">Download reports</h2>
              <hr />

                <div className="dialog__body_container flexRow mt-4 justifyContentCenter flexWrap">
                <div className={`dialog__container_item input-container`} style={{height:`${isDropdownOpen?'55vh':""}`}}>
                 <div style={{alignItems:"center"}} className={`dialog__description`}>
                <div className='flexCol'>
                <label className={`fontRegular req mt-2`}>Report Type</label>
                  <select className='select-input-galley1'
                  onChange={onReportTypeChange}
                  >
                    {reportsType.map((_) => {
                      return (
                        <option value={_.value} key={_.value}>{_.label}</option>
                      )
                    })}
                  </select>
                </div>
                <div className='flexCol'>
                { reptType==="crewCommission"||reptType==="crewVariance" ||reptType==="crewReconVariance"?  <>
            <label  className={`sub-heading-title-req margBottom10`}>Select Crew</label>
              <Select
              options={crewMembers}
              value={crewMembers?.filter(function (option) {
                return (
                  crewName.length && crewName[0]&&
                  crewName.find((sc) => sc.label === option.label)
                );
              })}
              onMenuOpen={handleMenuOpen}
              onMenuClose={handleMenuClose}
              onChange={onCrewChange}
              styles={newCustomStyles}
              placeholder="Search Crew"
              isSearchable={true}
              isClearable={true}
              className=""
            /></> :<></> }
                </div>



     {   reptType==='customerInteractionsReport'?<div className={`dialog__container_item input-container`}>
         <div className={`dialog__description`}>
            <label  className={`sub-heading-title-req margBottom10`}>Select Crew</label>
        <AllMultiSelect
        options={getOptions(crewMembers)}
        defaultValue={[]}
        isMulti
        styles={ {
          input: provided => ({
            ...provided,
            color: '#fff'
          })
        ,
        control: (defaultStyles) => ({
          ...defaultStyles,
          backgroundColor: "#162E3E",
          width: '17rem',
          boxShadow: "none",
        }), multiValue: provided => ({
          ...provided,

        }),
        multiValueLabel: (provided,state) => ({
          ...provided,
          width: 'auto',
          whiteSpace: 'nowrap',
          overflow: 'visible',
          title: state.data?.label,
        }),
         menu: provided => ({
          ...provided,
          backgroundColor: "#162E3E",
          width: 'auto',
          minWidth: '25rem',
          maxWidth: '30rem',
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isFocused ? '#1d3e4e' : 'transparent',
          color: state.isFocused ? '#fff' : '#fff',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: '#1d3e4e',
            color: '#fff',
          },
        }),

      }
      }
      onMenuOpen={handleMenuOpen}
      onMenuClose={handleMenuClose}
        isSearchable
        isClearable
        components={{ Option, MultiValue }}
        onChange={handleChange}
        allowSelectAll={true}
        value={optionSelectedCrew}
        placeholder={`Select Crew`}

      />
      </div>
      </div>
:<></>}

                <div className={`dialog__container_item input-container`}>
                <div style={{alignItems:"center"}} className={`dialog__description`}>
                  <div> <label className={`fontRegular req`}>Start Date</label>
                    <NGODatePicker
                      style={{width: '17rem'}}
                      value={reportDate.startDate}
                      name="startDate"
                      maxDate={new Date()}
                      isclearable={true}
                      placeholder={t('Start Date')}
                      placeholdertext={t('datePickerPlaceholder')}
                      onChange={onDateChangeRep} />
                  </div>
                  <div> <label className={`fontRegular req`}>End Date</label>
                    <NGODatePicker
                      style={{width: '17rem'}}
                      value={reportDate.endDate}
                      name="endDate"
                      maxDate={new Date()}
                      isclearable={true}
                      placeholder={t('End Date')}
                      placeholdertext={t('datePickerPlaceholder')}
                      onChange={onDateChangeRep} />
                  </div>


                </div>


                </div>
                {   reptType==='customerInteractionsReport'?<div className={`dialog__container_item input-container`}>
         <div className={`dialog__description`}>
            <label  className={`sub-heading-title-req margBottom10`}>Select Sector</label>
        <AllMultiSelect
        options={getOptions(sectors)}
        defaultValue={[]}
        isMulti
        styles={ {
          input: provided => ({
            ...provided,
            color: '#fff'
          })
        ,
        control: (defaultStyles) => ({
          ...defaultStyles,
          backgroundColor: "#162E3E",
          width: '17rem',
          boxShadow: "none",
        }), multiValue: provided => ({
          ...provided,

        }),
        multiValueLabel: (provided,state) => ({
          ...provided,
          width: 'auto',
          whiteSpace: 'nowrap',
          overflow: 'visible',
          title: state.data.label,
        }),
         menu: provided => ({
          ...provided,
          backgroundColor: "#162E3E",
          width: 'auto',
          minWidth: '25rem',
          maxWidth: '30rem',
        }),
        option: (provided, state) => ({
          ...provided,
          textAlign: 'left',
          backgroundColor: state.isFocused ? '#1d3e4e' : 'transparent',
          color: state.isFocused ? '#fff' : '#fff',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: '#1d3e4e',
            color: '#fff',
          },
        })
      }
      }
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        isSearchable
        components={{ Option, MultiValue }}
        onChange={handleChangeSector}
        allowSelectAll={true}
        value={optionSelectedSector}
        placeholder={`Select Sector`}

      />
      </div>
      </div>
:<></>}
                      {reportDownloadErr && (
                  <div className='flexRow justifyContentCenter alignItemsCenter' style={{marginTop:"1.5rem",width:"100%"}}>
                    <p style={{width:"100%"}} className='listErrorMessagesCls'>{reportDownloadErr}</p>
                  </div>
                )}

                <button type="button" onClick={onDownloadReportFinal} disabled={state.disabled} className="btn btn-info mt-4" style={{ color: "white",width:"100%" }}>

                  { isDownloading?<div className='flexRow justifyContentCenter alignItemsCenter'><img src={LoadingIn}style={{marginRight:'5px',width:'35px',height:'35px'}} alt="Loading" /> Processing...</div>:<span><span><img className="icon-size mt-1" alt='downloadMaster' src={downloadMasterIcon} /></span> Download</span>}
                </button>

              </div>
</div>
                </div>
            </div>
          </div>
        </div>
      }
{state.showDetails ?  <ShowsalesDetails/>:null}

{
       state.addSalesCards &&
        <Modal
        className='cash-deposit-summary'
        title={`Add Special Sales`}
        onSubmit={handleModalSubmit}
        onClose={handleModalCancel}
        >
         <div className='width100 flexRow alignItemsCenter justifyContentCenter'>

      <div className={`dialog__description`} style={{marginBottom:'0',minHeight:'42px'}}>
              <label htmlFor={2} className={`'req' :Boolean(info) ? 'info':''}`}>Sales Category</label>
              <CreatableSelect
                onChange={handleSalesUpdates}
                styles={ {
                  input: provided => ({
                  ...provided,
                  color: '#fff',
                  width:'17rem',
                  })
                  }}
                options={addnSales}
                isSearchable
                isClearable
                className='selectMenu-meals-master'
                placeholder="Create/Search special sales"
                />
              </div>

              </div>
        </Modal>
      }


      {
      !ready &&<div className='overlay_hide sub'></div>
      }
    </div>
  )
}