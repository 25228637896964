import React,{useState,useEffect} from 'react'
import moment from 'moment';
import { IoPerson } from "react-icons/io5";
import { MdAddBox } from "react-icons/md";
import { NGODatePicker} from '../../../../common';
import LoadingIn from '../../../../assets/loadingIn.gif';
import { FaFilter } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import searchIcon from '../../../../assets/icons/search.svg';
import Select from 'react-select';


const SectorListing=(props)=> {

    const { t,ready } = useTranslation();
    const [showLoading, setShowLoading] = useState(null);

    useEffect(() => {
      setShowLoading('Loading...')
      const timer = setTimeout(() => {
        if(!props.sectorListing?.length){
          setShowLoading('No Data');
        }
      }, 7000);
      return () => clearTimeout(timer);
    }, [props.sectorListing]);

const attachSectorList=(list)=>{
props.attachSector(list.id)
}
const onSectorChange=(e)=>{
props.setSectors([e])
}
const onEmployeeChange=(e)=>{
props.setEmployee([e])

}
const onFlightDateChange=(e)=>{
props.setFilghts(e.target.value)
}
const onSearch=(e)=>{
    props.setSearchKeyOrders(e.target.value)
}
const customStyles = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    position: "relative",
    zIndex: state.isOpenMenu ? 9999 : null,
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999, // Set a high z-index for the portal
  }),

};

const eventLogView=(sec)=>{

    const listItems = sec?.map((list,index) =>
    {
      return(
      <React.Fragment key={index}>
        <div>
          <li className="list-group-item">
            <div className='row'>
              <div  style={{width:'100%',padding:'15px'}}>
                <div  className='flexRow justifyContentSpaceBetween'style={{padding:'5px'}}>
                <div className='flexRow justifyContentFlexStart'><strong>{list?.name}</strong>{list?.isolateAffinity?.name?<span className='code'>{list?.isolateAffinity?.name}</span>:<></>} </div>  <div className='flexRow justifyContentFlexEnd'><IoPerson size={17} style={{ marginRight: '3px' }} /><span style={{ paddingTop:'3px'}}>{list.createdBy.employeeName} @ {moment(list.createdAt).format('DD-MM-YYYY  hh:mm A')}</span>&nbsp;<div title='Attach this sector' onClick={()=>attachSectorList(list)}><MdAddBox size={20} color='green'/></div></div>

                </div>
              </div>
            </div>
          </li>
        </div>
      </React.Fragment>

      )

    }
     );
     return listItems
      }

  return (
    <>
    <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
    {
            <>
                <div className='flexRow width100 margBot10 margTop8 justifyContentSpaceBetween alignItemsCenter tableFilterBlocks'>
                    <div className="flexRow justifyContentFlexStart alignItemsCenter leftBlock">
                        <div className="flexRow justifyContentFlexEnd alignItemsBaseline">
                          <div className=' item-button margRight10'><Select
                                options={props.stores}
                                value={props.stores?.filter(function (option) {
                                  return (
                                    props.sectors.length && props.sectors[0]&&
                                    props.sectors.find((sc) => sc.label === option.label)
                                  );
                                })}
                                onChange={onSectorChange}
                                styles={customStyles}
                                placeholder="Search Sectors"
                                isSearchable={true}
                                isClearable={true}
                                className="selectMenu-meals"
                              /></div>
                        <div className=' item-button margRight10'><Select
                                options={props.employees}
                                value={props.employees?.filter(function (option) {
                                  return (
                                    props.employee.length && props.employee[0]&&
                                    props.employee.find((sc) => sc.label === option.label)
                                  );
                                })}
                                onChange={onEmployeeChange}
                                styles={customStyles}
                                placeholder="Search Employee"
                                isSearchable={true}
                                isClearable={true}
                                className="selectMenu-meals"
                              /></div>


                        <> <div className='flexCol'>

                    <NGODatePicker
                      style={{width: '12rem'}}
                      value={props.flights}
                      className="input-margin"
                      name="startDate"
                      isclearable={true}
                      placeholder={t('Flight Date')}
                      placeholdertext={t('datePickerPlaceholder')}
                       onChange={onFlightDateChange}
                      />
                        </div>

                  </>
                 <div title="Apply filter" className='filter-set' onClick={()=>props.setFilterSectors(!props.filterSectors)}><FaFilter size={20} color='white' /></div>
                        </div>
                    </div>
                    <div className="flexRow justifyContentFlexEnd rightBlock underline margLeft10">
                    <span><img className='icon-size margTop5' src={searchIcon} alt={t('filters.searchText')} /></span>
                    <span><input className="search-layout"  onChange={onSearch} value={props.searchKeyOrders} type="search" placeholder="Search" /></span>
                    </div>
                </div>


                <div className={props?.data?"width100 dialog__body_container flexRow justifyContentSpaceBetween flexWrap sm-scrollbar":"dialog__body_container flexRow justifyContentSpaceBetween flexWrap sm-scrollbar"} style={{overflowY:'scroll',height:'65vh'}}>
           {props?.sectorListing.length? <ul className='list-group'>
                {eventLogView(props?.sectorListing)}
                </ul>:<div className="flexRow w-100 h-100 justifyContentCenter alignItemsCenter">{showLoading && showLoading.includes('Loading...')?<img src={LoadingIn}  className="loading-img" alt="Loading" />:showLoading}</div>}
          </div>
            </>
        }

        {
          !ready &&<div className='overlay_hide sub'></div>
        }
    </div>
</>
  )
}

export default SectorListing;