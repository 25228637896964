import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DEFAULT_SHEET_ROWS_PLIFERAGE } from "../../../utils/configs";
import currencyData from "../../../assets/json/currency.json";
import { useTranslation } from "react-i18next";
import { FaRegCheckSquare, FaSave } from "react-icons/fa";
import ReactSpreadsheet from "react-spreadsheet";
import * as ReactDOM from "react-dom";
import close from "../../../images/icons/Icon2.png";
import { APIEndpoint } from "../../../utils/axios";
import { RiDeleteBin6Line } from "react-icons/ri";
import Currency from "../../../assets/json/currency.json"
import { BiSolidSelectMultiple } from "react-icons/bi";

const iIndexes = {
  C_CODE: 0,
  C_NAME: 1,
  AMOUNT: 2,
};
const sheetLabels = ["Currency Code", "Currency Name", "Amount"];
const colDef = [
  { label: 'Currency Code', type: 'select', labelProp: 'code', attribute: 'currencyCode', required: true,options:Currency },
  { label: 'Currency Name', type: 'select', labelProp: 'currency', attribute: 'currencyName', required: true,options:Currency },
  { label: 'Value', type: 'number', attribute: 'amount', required: true },
]

const defaultCellFieldData = {
  id: "id",
  value: "",
  defaultValue: "",
  className: "cellClass",
  width: "200px",
  readOnly: false,
  type: "string",
  required: true,
  showPopup: false,
};

const createEmptySheetRows = (
  addtionalRows = 0,
  noOfRows = DEFAULT_SHEET_ROWS_PLIFERAGE,
) => {
  const rows = [];
  if (addtionalRows > 0) {
    i = 0;
    while (i < 10) {
      rows.push([
        {
          ...defaultCellFieldData,
          id: "currencyCode",
          className:
            "cellClass cellClass_" + addtionalRows + "_" + iIndexes.C_CODE,
          showPopup: true,
        },
        {
          ...defaultCellFieldData,
          id: "currencyName",
          className:
            "cellClass cellClass_" + addtionalRows + "_" + iIndexes.C_NAME,
          showPopup: true,
        },
        {
          ...defaultCellFieldData,
          id: "amount",
          className:
            "cellClass cellClass_" + addtionalRows + "_" + iIndexes.AMOUNT,
          width: "400px",
          required: true,
        },
      ]);
      i++;
      addtionalRows++;
    }
  } else {
    for (var i = 0; i < noOfRows; i++) {
      rows.push([
        {
          ...defaultCellFieldData,
          id: "currencyCode",
          className: "cellClass cellClass_" + i + "_" + iIndexes.C_CODE,
          showPopup: true,
        },
        {
          ...defaultCellFieldData,
          id: "currencyName",
          className: "cellClass cellClass_" + i + "_" + iIndexes.C_NAME,
          showPopup: true,
        },
        {
          ...defaultCellFieldData,
          id: "amount",
          className: "cellClass cellClass_" + i + "_" + iIndexes.AMOUNT,
          width: "400px",
          required: true,
        },
      ]);
    }
  }

  return rows;
};

const initialData = createEmptySheetRows(null, 10);

export const BankTransferCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [activeCell, setActiveCell] = useState({});
  const [count, setCount] = useState(1);
  const [sheetData, setSheetData] = useState(initialData);
  const [spreadsheetErrors, setSpreadsheetErrors] = useState([]);
  const [message, setMessage] = useState(null);
  const [selectedCellsArr, setSelectedCellsArr] = useState([]);
  const [currencyState, setCurrencyState] = useState([...currencyData]);

  useEffect(() => {
    if(message){
      setTimeout(() => {
        setMessage(null)
      }, 2000);
    }
  }, [message])
  

  useEffect(() => {
    if(activeCell.column || activeCell.column === 0){
      const val = sheetData[activeCell.row][activeCell.column].value;
      if(activeCell.column === iIndexes.C_CODE){
        const currFiltered = currencyData.filter((data)=>{
          return data.code.toLocaleLowerCase().startsWith(val?.toLocaleLowerCase())
        })
        setCurrencyState([...currFiltered])
      }else if(activeCell.column === iIndexes.C_NAME){
        const currFiltered = currencyData.filter((data)=>{
          return data.currency.toLocaleLowerCase().startsWith(val?.toLocaleLowerCase())
        })
        setCurrencyState([...currFiltered])
      }
    }
  }, [sheetData])

  const addRowsToSheet = (rowsToAdd = 10) => {
    const newRows = createEmptySheetRows(sheetData.length, rowsToAdd);
    setSheetData([...sheetData, ...newRows]);
  };

  useEffect(() => {
    if (state.rows && state.rows.length > 0) {
      setDataToSheet(state.rows);
    }
  }, [state]);

  useEffect(() => {
    let timeOut = setTimeout(() => {
      if (spreadsheetErrors && spreadsheetErrors.length > 0) {
        setSpreadsheetErrors([]);
      }
    }, 3000);
    return () => {
      clearTimeout(timeOut);
    };
  }, [spreadsheetErrors]);

  function setDataToSheet(data) {
    const row = [];
    data.forEach((d, i) => {
      row.push([
        {
          ...defaultCellFieldData,
          id: "currencyCode",
          className: "cellClass cellClass_" + i + "_" + iIndexes.C_CODE,
          showPopup: true,
          value: d.currencyCode,
        },
        {
          ...defaultCellFieldData,
          id: "currencyName",
          className: "cellClass cellClass_" + i + "_" + iIndexes.C_NAME,
          showPopup: true,
          value: d.currencyName,
        },
        {
          ...defaultCellFieldData,
          id: "amount",
          className: "cellClass cellClass_" + i + "_" + iIndexes.AMOUNT,
          value: d.amount,
        },
      ]);
    });
    const newRows = createEmptySheetRows(row.length, 10);
    setSheetData([...row, ...newRows]);
  }

  const handleCellSelect = (selectedCell) => {
    if (selectedCell.length !== 0) {
      setSelectedCellsArr([...selectedCell]);
    }
  };

  const goToModuleHome = () => {
    navigate("/cashRegister/bankTransfers");
  };

  const setItemToSheet = (item) => {
    if (Object.keys(item).length === 0) {
      return 0;
    }

    let jsonSpreadsheetData = JSON.stringify(sheetData);
    let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData);

    updatedSpreadsheetData.map((sheetRow, i) => {
      if (i === activeCell.row) {
        sheetRow[iIndexes.C_CODE].value = item.code;
        sheetRow[iIndexes.C_NAME].value = item.currency;
      }

      return sheetRow;
    });

    ReactDOM.flushSync(() => {
      let newRows = createEmptySheetRows(null, 10);
      // setSheetData( [...sheetData,...newRows.slice(sheetData.length)]);

      setSheetData([
        ...updatedSpreadsheetData,
        ...newRows.slice(sheetData.length),
      ]);
    });
    hidePopUps()
    setActiveCell({});
  };

  const onClickPopupListItem = (e, row, type = "reason") => {
    setItemToSheet(row);
  };

  function hidePopUps(type=""){
    setTimeout(() => {
      setCurrencyState([...currencyData])
    }, 200);
    const code = document.querySelector(".cellSelectionBlockCurrencyCodes");
    const name = document.querySelector(".cellSelectionBlockCurrencyNames");
    if(type === "name"){
      name.style.display = "none"
    }
    if(type === "code"){
      code.style.display = "none"
    }
    if(!type){
      name.style.display = "none"
      code.style.display = "none"
    }
  }

  const getTransferRow = (rowData) => {
    let row = [];
    colDef.forEach(({ attribute, readOnly }, i) => {
      let cellVal = { readOnly: readOnly || false, value: '',...rowData[i] }
      if (Array.isArray(rowData)) {
        cellVal.value = rowData[i].value;
        cellVal.error = rowData[i].error;
        if (!rowData[i].updated) {
          if (attribute === 'currencyCode' && rowData[1].value !== '') {
            let cellval = Currency.find((_) => _.currency === rowData[1].value)?.code;
            if(cellval){
              cellVal.value = cellval
              cellVal.error = false
            }else{
              cellVal.value = ""
              cellVal.error = false
            }
          }
          else if (attribute === 'currencyName' && rowData[0].value !== '') {
            let cellval = Currency.find((_) => _.code === rowData[0].value)?.currency
            if(cellval){
              cellVal.value = cellval
              cellVal.error = false
            }else{
              cellVal.value = ""
              cellVal.error = false
            }
          }
        }
      } else {
        cellVal.value = rowData[attribute] || ''
      }
      row.push(cellVal)
    });
    return row;
  }

  const handleSaveTransfer = async () => {
    setMessage(null)
    try {
      const rowsFormatted = sheetData
        .map((row, i) => {
          return {
            currencyCode: row[0]["value"],
            currencyName: row[1]["value"],
            amount: +row[2]["value"],
            index: i,
          };
        })
        .filter((d) => d.currencyCode && d.currencyName);
        
        if (rowsFormatted.some((d) => !d.amount)) {
          const error = {
            text: "Please fill all the required fields ",
          };
          setSpreadsheetErrors([error]);
          return;
        }
      if (rowsFormatted.length === 0) {
        const error = {
          text: "We did not find anything to save!",
        };
        setSpreadsheetErrors([error]);
        return;
      }
      const body = { items: rowsFormatted };
      if (state.newtransfer) {
        body.attachment = state.newtransfer.attachment;
        body.cashDrawerId = state.newtransfer.cashDrawerId;
        body.targetDate = state.newtransfer.targetDate;
      } else {
        body.bankTransferId = state.bankTransferId;
      }
      const res = await APIEndpoint.post(
        "cashRegister/bankTransfers/save",
        body,
      );
      if(!state.newtransfer){
      setMessage({ type: "success", text: 'Bank transfer edited successfully.' });

      }else{
        setMessage({ type: "success", text: res.message });
      }
      setTimeout(() => {
        navigate("/cashRegister/bankTransfers")
      }, 2000);
    } catch (error) {
      const errorObj = JSON.parse(error.message);
      const err = {
        text: errorObj.data.message || errorObj.data?.errors.message,
      };
      setMessage({ type: "failed", text: err.text ||'Something went wrong. Please try again after some time.' });
      let errArray = []
      if(errorObj.data?.errors?.errors.length > 0){
        errorObj.data.errors.errors.forEach(error=>{
          errArray.push({text:`${error.message} in row ${+error.row+1}`})
        })
      }
      setSpreadsheetErrors([err,...errArray]);
    }
  };

  const selectAllCells = (e) => {
    setCount((prev)=>prev+1)
    let updatedSheetData=[]
    if(count%2!==0){
        updatedSheetData = sheetData.map((row, rowIndex) => {
            return row.map((cell, colIndex) => {
                return {
                    ...cell,
                    className: `selectcell-class cellClass cellClass_${rowIndex}_${colIndex}`
                };
            });
        });
    }else{
        updatedSheetData = sheetData.map((row, rowIndex) => {
            return row.map((cell, colIndex) => {
                return {
                    ...cell,
                    className: `cellClass cellClass_${rowIndex}_${colIndex}`
                };
            });
        });
    }

    setSheetData(updatedSheetData);

};

  function removeRow() {
    let jsonSpreadsheetData = JSON.stringify(sheetData);
    let inSheetData = JSON.parse(jsonSpreadsheetData);
    if (Object.keys(activeCell).length === 0) {
      inSheetData.pop();
      setSheetData([...inSheetData]);
    } else {
      let selectedRowIndex = activeCell.row;
      if (selectedRowIndex || selectedRowIndex === 0) {
        inSheetData.splice(selectedRowIndex, 1);
        setSheetData([...inSheetData]);
      }
    }
  }

  const handleCellChange = (updated) => {
    let updatedFormat = updated.map((row) => {
      return getTransferRow(row)
    })
    setSheetData([...updatedFormat])
  }

  function handleActiveCellChange(activeCell){
    setActiveCell(activeCell)
    hidePopUps()
    const {row,column} = activeCell;
    if(sheetData[row][column]?.showPopup){
      const sourseElem = document.getElementsByClassName('cellClass_' + row + '_' + column)[0]
      const position = sourseElem.getBoundingClientRect();
      const code = document.querySelector(".cellSelectionBlockCurrencyCodes");
      const name = document.querySelector(".cellSelectionBlockCurrencyNames");
      if(column === iIndexes.C_CODE){
        hidePopUps("name")
        code.style.display = "block"
        code.style.top = (position.top+30)+"px";
        code.style.left = position.left+"px";
        code.style.width = position.width+"px";
      }else if(column === iIndexes.C_NAME){
        hidePopUps("code")
        name.style.display = "block"
        name.style.top = (position.top+30)+"px";
        name.style.left = position.left+"px";
        name.style.width = position.width+"px";
      }else{
        hidePopUps()
      }
    }else{
      hidePopUps()
    }
  }

  return (
    <>
      <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
        <div className="sheet-container sm-scrollbar">
          <div className="header mb-2 flexRow justifyContentSpaceBetween">
            <div className="leftBlock alignItemsCenter flexRow">
              <button
                onClick={(e) => handleSaveTransfer()}
                className="add-item-button mr-2 margRight10"
              >
                <FaSave style={{ marginRight: "5px",marginBottom:"3px" }} />
                {t("buttons.save")}
              </button>
              <button onClick={ (e) => selectAllCells(e) }
                                className="alignItemsCenter margRight10 flexRow add-item-button-all ">{count%2===0?<BiSolidSelectMultiple  style={{marginRight:'5px'}} />:<FaRegCheckSquare  style={{marginRight:'5px'}} />}Select All</button>
              <button
                onClick={(e) => removeRow(e)}
                className="remove-button mr-2"
              >
                <RiDeleteBin6Line style={{ marginRight: "5px" ,marginBottom:"3px" }} />
                {t("buttons.deleteRows")}
              </button>
            </div>
            <div style={{ color: "#E70000" }} className="rightBlock alignItemsCenter">
              {spreadsheetErrors.length === 1
                ? ( <div className="padding4 margTop9-5 alert alert-danger"> {spreadsheetErrors[0].text} </div>)
                : null}
              {spreadsheetErrors.length > 1 &&(
                <div className="flexCol">
                  {spreadsheetErrors.map((error) => {
                return <span style={{ color: "#E70000" }}>{error.text}</span>;
              })}
                </div>
              )}
              {message && spreadsheetErrors.length === 0 && (
                <div className={`padding4 alert alert-${message.type==='success' ? 'success' : 'danger'}`}>
               {message.text}
              </div>
              )}
            </div>
            <button onClick={goToModuleHome}>
              <img src={close} alt="close" />
            </button>
          </div>
          <div className="se-titlebar">{<span>{state.title}</span>}</div>

          <ReactSpreadsheet
            className='spread-sheet'
            data={sheetData}
            columnLabels={sheetLabels}
            darkMode={true}
            onActivate={handleActiveCellChange}
            onChange={handleCellChange}
            // DataEditor={(p) => <SpreadsheetEditorC colDef={colDef[p.column]} {...p} />}
            onSelect={handleCellSelect}
            cellClassName="custom-cell"
          />
          <div className="flexRow alignItemsCenter margTop8">
            <button
              onClick={() => addRowsToSheet()}
              className="add-item-button"
              title={t("spreadsheet.addmoreRows")}
            >
              {t("buttons.addRows")}
            </button>
          </div>
        </div>
        <div className="cellSelectionBlockCurrencyCodes sm-scrollbar">
        {currencyState.length ? (
            <ul>
              {currencyState.length &&
                currencyState.map((row, i) => (
                  <li
                    key={i}
                    onClick={(e) => onClickPopupListItem(e, row, "item")}
                  >
                    {row.code}
                  </li>
                ))}
            </ul>
          ) : (
            <ul>
              <li>No Match Found!</li>
            </ul>
          )}
        </div>
        <div className="cellSelectionBlockCurrencyNames sm-scrollbar">
        {currencyState.length ? (
            <ul>
              {currencyState.length &&
                currencyState.map((row, i) => (
                  <li
                    key={i}
                    onClick={(e) => onClickPopupListItem(e, row, "item")}
                  >
                    {row.currency}
                  </li>
                ))}
            </ul>
          ) : (
            <ul>
              <li>No Match Found!</li>
            </ul>
          )}
        </div>
      </div>
    </>
  );
};