import React, { useEffect, useState } from 'react';
// import { newEnforcer } from 'casbin';
import { useNavigate, useLocation } from "react-router-dom";
import "../assets/styles/inner/leftmenu.scss";
import { HOST_SERVER } from '../utils/configs';
import MenuItems from "../assets/json/menuAkasa.json";
import MenuItemsSec from "../assets/json/menuSandbox.json";
import MenuItemsPeach from '../assets/json/menuSandbox2.json';
import { useTranslation } from 'react-i18next';
import {ConfirmModal, Footer} from '../common';
import {Authorizer} from 'casbin.js';
import TimeZoneInfo from './TimeZoneInfo';

// import back from '../assets/back.svg'

// const MENU_STATE_EXPAND = 'expanded';
const MENU_STATE_COLLAPSE = 'collapsed';

const MenuLink = (props) => {

  const { t } = useTranslation();

  const { item, icon, subItems,menuState } = props;
  const location = useLocation();
  const active = location.pathname.indexOf(item.path) === 0;

  const noSubMenu = (!subItems || subItems.length === 0) ? true : false;

  let cls = '';
  if (noSubMenu) {
    cls = active ? "current" : "";
  }


  const onClick = (e) => {
    e.preventDefault();
    props.onMenuClick(e);

    if (noSubMenu) {
      props.onNavigate();
    }

  }
  // return (
  //   <a className={`menu ${active ? 'active' : ""}`} onClick={onClick}>
  //     <span>
  //       {t(`pages.${item.key}`)}
  //     </span>
  //   </a>
  // )
  return (
    <button className={ `flexRow width100 hor-ctr space-btn menu-item-head ${cls}`} onClick={onClick}>
      <div className='flexRow width100 hor-ctr'>
        <i className={`sidebar-icon ${icon}`} title={menuState==='collapsed'?item.title:''}/>
        <div className='title'>
          {t(`pages.${item.key}`)}
        </div>
      </div>
      {noSubMenu ? <i className={`caret icon-caret-right`} /> : <i className='caret icon-caret-down' /> }
    </button>
  );

  // return (
  //   <a className='flexRow width100 hor-ctr space-btn menu-item-head' onClick={onClickMenu}>
  //     <div className='flexRow width100 hor-ctr'>
  //       <i className={`sidebar-icon ${icon}`} />
  //       <div className='title'>
  //         {t(`pages.${item.key}`)}
  //       </div>
  //     </div>
  //     <i className='caret icon-caret-down' />
  //   </a>
  // );

}
const SubMenu = (props) => {
  const { t } = useTranslation();

  const { item, subItem } = props;
  const location = useLocation();
  const active = location.pathname.indexOf(subItem.path) === 0;
  const onClick = (e) => {
    e.preventDefault()
    props.onNavigate()
  }
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a className={`sub-menu ${active ? 'active' : ""}`} onClick={onClick}>
      <span>
        {t(`pages.${item.key}Sub.${subItem.key}`)}
      </span>
    </a>
  )
}

const MenuItem = (props) => {

  // const { t } = useTranslation();
  const { item, selected, menuState, current } = props;
  const { subItems, title, icon } = item;
  const isActive = selected.title === title;
  const onClickMenu = (e) => {
    e.preventDefault();
    props.onClick && props.onClick(isActive ? '' : item)
  }
  let maxHeight = isActive && Array.isArray(subItems) && subItems.length > 0 ? subItems.length * 35 : 0;
  maxHeight = maxHeight ? maxHeight + 35 : maxHeight;
  maxHeight = menuState === MENU_STATE_COLLAPSE ? 0 : maxHeight;
  return (
    <div className={`flexCol menu-item ${isActive ? 'active' : ''} ${ current ? 'current' : ''}`}>
      {
        (!subItems || subItems.length === 0) ?
        <>
          <MenuLink item={item} icon={icon} key={item.key} menuState={props.menuState}
            onNavigate={ () => {
              props.onNavigate(item);
            } }
            onMenuClick={ (e) => onClickMenu(e) }
          />
        </>
        :
        <>
           {/* <a className='flexRow width100 hor-ctr space-btn menu-item-head' onClick={onClickMenu}>
              <div className='flexRow width100 hor-ctr'>
                <i className={`sidebar-icon ${icon}`} />
                <div className='title'>
                  {t(`pages.${item.key}`)}
                </div>
              </div>
              <i className='caret icon-caret-down' />
            </a> */}

            <MenuLink item={item} subItems={subItems} icon={icon} key={item.key} menuState={props.menuState}
              onMenuClick={ (e) => onClickMenu(e) }
            />

        </>
      }

      {/*  Sub menu items */}
      {
        Array.isArray(subItems) && subItems.length > 0 &&
        <div className='flexCol sub-item-cont width100' style={{ maxHeight: maxHeight }}>
          <div className='flexCol container width100'>
            {
              subItems.map((subItem, i) => {
                // if(subItem.key==='deviceStats'||subItem.key==='topPerformanceRoutes'||subItem.key==='datewiseSales'||subItem.key==='topItemsMore'){
                //   return (
                //     <SubMenu item={item} subItem={subItem} key={i} onNavigate={() => {}} />
                //   )
                // }
                // else{
                  return (
                    <SubMenu item={item} subItem={subItem} key={i} onNavigate={() => props.onNavigate(item, subItem)} />

                  )
                // }

              })
            }
          </div>
        </div>
      }
    </div>
  )
}

export const getCurrentMenuItem = (location) => {
  let menuItem, subMenuItem, subMenuItem2,subMenuItem3, subMenuItem4,menuIndex,
    isFound = () => {
      return Boolean(menuItem && subMenuItem)
    };

  let urlArr = null;
  if (location) {
    urlArr = location.pathname.split('/');
  }
  MenuItems.every((menu, index) => {

    if (urlArr[1] === menu.key ) {

      menuItem = menu;
      menuIndex = index;

      if (Array.isArray(menu.subItems) && menu.subItems.length > 0) {
        menu.subItems.every((subMenu) => {

          if ( '/' + urlArr[1] + '/' + urlArr[2] ===  subMenu.path ) {
            subMenuItem = subMenu;

            if (Array.isArray(subMenu.subItems2)) {
              subMenu.subItems2.map((subItem2) => {

                const searchPattern = new RegExp(subItem2.path, "g");
                if ( subItem2.path && location.pathname.search(searchPattern) >= 0 ) {
                  subMenuItem2 = subItem2;
                }
                return subItem2;
              })
            }
            if (Array.isArray(subMenu.subItems3)) {
              subMenu.subItems3.map((subItem3) => {
                const searchPattern = new RegExp(subItem3.path, "g");
                if ( subItem3.path && location.pathname.search(searchPattern) >= 0 ) {
                  subMenuItem3 = subItem3;
                }
                return subItem3;
              })
            }

            if (Array.isArray(subMenu.subItems4)) {
              subMenu.subItems4.map((subItem4) => {
                const searchPattern = new RegExp(subItem4.path, "g");
                if ( subItem4.path && location.pathname.search(searchPattern) >= 0 ) {
                  subMenuItem4 = subItem4;
                }
                return subItem4;
              })
            }
          }
          return !isFound();
        });

      }

    }

    return !isFound();
  });

  if (isFound()) {
    const a = { title: menuItem, id: menuIndex, subTitle: subMenuItem, subMenuItem2: subMenuItem2, subMenuItem3: subMenuItem3,subMenuItem4: subMenuItem4 };
    return a;
  }

  return null;
}

export const SidebarComponent = (props) => {

  const [state, setState] = useState({ selected: ''});
  const [MenuItemsList, setMenuItemsList] = useState([]);
  const [permissionObj, setPermissionObj] = useState({});
  const [activeMenu, setActiveMenu] = useState(null);
  const [authorizer] = useState(new Authorizer('manual'));
  const hostType = localStorage.getItem('sType');
  const userRole = localStorage.getItem('userRole')
  const permissions=localStorage.getItem("userPermissions");

  const navigate = useNavigate();
  const location = useLocation();

  const  convertPath=(originalPath)=> {
    if(originalPath){
      const parts = originalPath.split('.');
    const lastPart = parts[parts.length - 1];
    if(lastPart!=='alerts'){
      return `/${lastPart}`;
    }else{
      return `/alertsWarnings`;
    }
    }

}

  useEffect(() => {
    setActiveMenu(getCurrentMenuItem(location));
  }, [location]);
;
  useEffect(() => {
    if(hostType && userRole){

      if (permissions) {
      const permissionsObject = JSON.parse(permissions);
      authorizer.setPermission(permissionsObject[userRole]);
    }
    }
  }, [props,hostType,userRole,permissions]);

  useEffect(() => {
    if(authorizer&& authorizer?.permission){
      setPermissionObj(authorizer?.permission)
    }
  }, [props,hostType]);


  useEffect(() => {
    if (permissionObj) {
      const { actObjData } = permissionObj;
      let ngoMenu = HOST_SERVER === process.env?.REACT_APP_API_SERVER ? MenuItems : MenuItemsSec;
      if (ngoMenu) {
        setMenuItemsList(
          ngoMenu.map(ml => {
            if (actObjData && actObjData.has('view')) {
              const urls = actObjData.get('view');
              if (Array.isArray(urls)) {
                if (urls.some(p => p === ml.path)) {

                  const filteredSubItems = ml.subItems ? ml.subItems.filter(sI => urls.some(s => s === sI.pathPermission)) : [];

                  if (filteredSubItems.length === 0 && (ml.key==='reports'|| ml.key==='dashboard' || ml.key==='loyalty' || ml.key==='alertsWarnings')) {
                    return {
                      ...ml,
                      path: convertPath(ml.path),
                      subItems: []
                    };
                  }

                  return {
                    ...ml,
                    subItems: filteredSubItems
                  };
                }
              }
            }
            return null;
          }).filter(item => item !== null)
        );
      }
    }
  }, [permissionObj, hostType]);

  const onNavigate = (item, subItem=null) => {
    let path = '';

    if (subItem && subItem.path) {
      path = subItem.path;
    }
    else {
      path = item.path;
    }

    navigate(path, {
      state: {
        ...location.state,
        title: item,
        subTitle: subItem
      }
    });
  }
  return (
    <>
    <div style={{position:"relative"}} className={`flexCol darkMelon-bg side-menu-container sm-scrollbar ${props.menuState}`}>

      <div className='menu-list sm-scrollbar'>
        {
         MenuItemsList?.map((item, i) => {

            return (
              <MenuItem
                key={i}
                item={item}
                onClick={(_c) => {
                  setState((_) => ({ ..._, selected: _c }))
                  props.onMenu(_c);
                }}
                current={activeMenu && (activeMenu.id === i)}
                onNavigate={onNavigate}
                menuState={props.menuState}
                selected={state.selected || ''} />
            )
          })
        }
      </div>
      <TimeZoneInfo mode={props.menuState} />
     <Footer mode={props.menuState}/>
    </div>
     </>
  );
}