/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import '../../../assets/styles/modal.scss'
import closeIcon from '../../../images/icons/Icon2.png';
import Select from 'react-select';
import plus from '../../../images/icons/plus.png';
import minus from '../../../images/icons/minusgaly.png';
import CreatableSelect from 'react-select/creatable';
import mealMasterData from "../../../assets/json/meal_master.json";



export const CreateMealmaster = (props) => {
  const {fields,menuSelections}=props;
  const [mealmasterExtraReq, setMealMasterExtraReq] = useState([{ id: 1 }]);
  const [inputChanged,setInputChanged]=useState(false);

  const handleMealOptions=(type)=>{

    let options=[]

  if(menuSelections&&menuSelections?.mealMasterOptions){
    const {mealMasterOptions}=menuSelections;
    mealMasterOptions.forEach((item,index)=>{
      if(item.mealTypeId.includes(type)){
        item.items.forEach(it=>{
          options.push({key:index,label:it.itemName,value:it.itemId})
        })
      }else{
        item.items.forEach(it=>{
          options.push({key:index,label:it.itemName,value:it.itemId})
        })
      }
    })
  }
  return options
  }

  const newCustomStyles = {
    input: (defaultStyles) => ({
      ...defaultStyles,
      color: '#fff',
      }),
      control: (defaultStyles) => ({
        ...defaultStyles,
        backgroundColor: "#162E3E",
        padding: "3px",
        marginBottom: '1rem',
        border: "0.4px solid #FFFFFF",
        boxShadow: "none",
        PointerEvent: 'none',
      })
  };
  const handleAddTypes = (mealCatergory,edit=null) => {
    setInputChanged(true);
      let newId;
          newId = mealmasterExtraReq[mealmasterExtraReq.length-1];
          setMealMasterExtraReq([...mealmasterExtraReq, { id: newId['id']+1 }]);
    };
    const handleRemoveTypes = (mId) => {
      setInputChanged(true);
        const updatedMealReq = mealmasterExtraReq.filter(set => set.id !== mId);
        setMealMasterExtraReq(updatedMealReq);
      };

const generateAddntnMeals = (mealSetId) => (
    <div key={mealSetId} className={'flexRow justifyContentSpaceBetween'}>
      <div className='width100 flexRow alignItemsCenter margTop8'>
            <div className='width100 flexRow' style={{margin:'0% 3% 0% 0%'}}>
      <div className={`dialog__description flexCol margTop11`} style={{marginBottom:'0',minHeight:'42px',margin: '0.9rem 1rem 0rem 0rem'}}>
              <label htmlFor={2} className={`'req' :Boolean(info) ? 'info':''}`}>Meal Content</label>
              <CreatableSelect
                // onChange={handleSelectDept}
                // value={selectSearchOptionsDepartment.filter(function(option) {
                // return option.value === section?.department;
                // })}
                styles={newCustomStyles}
                options={menuSelections.mealContent}
                isSearchable
                isClearable
                className='selectMenu-meals-master text-wrap'
                placeholder="Create/Search Meal content"
                />


              </div>


              <div className={`dialog__description flexCol margTop11`} style={{marginBottom:'0',minHeight:'42px'}}>
                 <label htmlFor={2} className={`'req' :Boolean(info) ? 'info':''}`}>Select Content(s)</label>
                      <Select
            // defaultValue={state[stateKey+mealSetId] ? state[stateKey+mealSetId] : []}
            options={handleMealOptions("all")}
            // placeholder={`Select ${mealType}`}
            // value={handleMealOptions(preCompile.contBreakFast, preCompile[mealType]).filter(function (option) {
            //   return state[stateKey+mealSetId] && state[stateKey+mealSetId].find(sc => sc.label === option.label)
            // })}
            // onChange={(selectedOption)=>selectHandler(selectedOption,stateKey+mealSetId)}
            styles={newCustomStyles}
            isSearchable={true}
            isMulti={true}
            className='selectMenu-meals-master'
          />
</div>

            <div className={`dialog__container_item input-container `} style={{margin:'0% 1% 0% 0%'}}>
              <div className={`dialog__description flexCol margTop11`} style={{marginBottom:'0',minHeight:'42px'}}>
              <label htmlFor={2} className={`'req' :Boolean(info) ? 'info':''}`}>Weight</label>

                <input
                  type={'number'}
                  className='edit-input'
                  placeholder={'Weight (kg)'}
                  style={{minHeight:"44px"}}
                  // required={ required ? true : false }
                  // {...rest}
                />
              </div>

            </div>
            <div className={`dialog__container_item input-container `} style={{margin:'0% 3% 0% 0%'}}>
              <div className={`dialog__description flexCol margTop11`} style={{marginBottom:'0',minHeight:'42px'}}>
              <label htmlFor={2} className={`'req' :Boolean(info) ? 'info':''}`}>Calories</label>

                <input
                  type={'number'}
                  className='edit-input'
                  placeholder={'Calories (kcal)'}
                  style={{minHeight:"44px"}}
                  // required={ required ? true : false }
                  // {...rest}
                />
              </div>

            </div>

       <div className={`dialog__container_item input-container `} style={{margin:'0% 3% 0% 0%'}}>
            <div className={`dialog__description`} style={{marginBottom:'0',minHeight:'42px'}}>
            <div className={`dialog__description flexCol margTop11`}>
            <label htmlFor={2} className={`'req' :Boolean(info) ? 'info':''}`}>Allergens</label>
            <textarea
              className={`edit-input-meals`}
              placeholder='Allergens...'
              required={ true }

            />
          </div>
            </div>
       </div>

      {mealSetId===1 && (
      <img src={plus} className="menuplanner-icons" style={{marginTop:'5%'}} alt="add" onClick={handleAddTypes} />
      )}
      {(mealSetId !== 1) && (
      <img src={minus} className="menuplanner-icons" style={{marginTop:'5%'}} alt="remove" onClick={()=>handleRemoveTypes(mealSetId)} />
      )}
            </div>


      </div>

    </div>
  );

  return (
    <div className='overlay'>
<div className="sm-scrollbar dialogMenu-caterer">
    <div style={{margin: '3% 5% 3% 5%',maxWidth: '100%',width:'90%',whiteSpace:'nowrap'}}>
          <div className="dialog__content">
            <div className='flexRow justifyContentSpaceBetween'>
            <h2 className="dialog__title subheading-text-medium fontMedium" style={{marginLeft:'40%'}}>{props.title}</h2>
            <img className="modal__close_icon" alt='Close'  style={{height:'15px',cursor:'pointer'}}src={closeIcon} onClick={props.onClose} />
            </div>

    <hr />
<div className='flexMenu justifyContentSpaceBetween'>
  <div className='width30Menu'>
  <div className={`dialog__container_item input-container `} style={{margin:'0% 3% 0% 0%'}}>
            <div className={`dialog__description flexCol margTop16`} style={{marginBottom:'0',minHeight:'42px'}}>
            <label className="req">Meal Name</label>
              <input
                type={'text'}
                className='edit-input'
                placeholder={'Meal Name'}
                // required={ required ? true : false }
                // {...rest}
              />
            </div>
       </div>
       <div className={`dialog__container_item input-container `} style={{margin:'0% 3% 0% 0%'}}>
            <div className={`dialog__description flexCol margTop16`} style={{marginBottom:'0',minHeight:'42px'}}>
            <label htmlFor="upload-img" className="subheading-text-small-title fontRegular req">Cabin</label>
            <Select
          // defaultValue={state[stateKey+mealSetId] ? state[stateKey+mealSetId] : []}
        //   options={handleMealOptions("custom")}
          placeholder={`Select cabin`}
          // value={handleMealOptions(preCompile.contBreakFast, preCompile[mealType]).filter(function (option) {
          //   return state[stateKey+mealSetId] && state[stateKey+mealSetId].find(sc => sc.label === option.label)
          // })}
          // onChange={(selectedOption)=>selectHandler(selectedOption,stateKey+mealSetId)}
          styles={newCustomStyles}
          isSearchable={true}
          isMulti={true}
          className='selectMenu-meals'
        />
            </div>
       </div>

  </div>

  <div className='width30Menu'>

  <div className={`dialog__container_item input-container `} style={{margin:'0% 3% 0% 0%'}}>
            <div className={`dialog__description flexCol margTop16`} style={{marginBottom:'0',minHeight:'42px'}}>
            <label className={`req`}>Meal Type</label>
              <select
                //   id={id || ''}
                  className='select-input'
                //   style={rest.style}
                //   required={ required ? true : false }
                //   {...rest}
              >
              {
                mealMasterData.map((_, index) => {

                    return (
                      <option value={_.mealType} key={index}>{_.mealType==='High Fibre Meal'?'Select Meal Type':_.mealType}</option>
                    )
                })
              }
            </select>
            </div>
        </div>


       <div className={`dialog__container_item input-container `} style={{margin:'0% 3% 0% 0%'}}>
       <div className={`dialog__description flexCol margTop16`} style={{marginBottom:'0',minHeight:'42px'}}>
            <label className="req">Tags</label>
              <input
                type={'text'}
                className='edit-input'
                placeholder={'Tags'}
                // required={ required ? true : false }
                // {...rest}
              />
            </div>
       </div>

  </div>

  <div className='width30Menu'>

  <div className={`dialog__container_item input-container `} style={{margin:'0% 3% 0% 0%'}}>
            <div className={`dialog__description flexCol margTop16`} style={{marginBottom:'0',minHeight:'42px'}}>
            <label className={`req`}>Diet Type</label>
              <select
                //   id={id || ''}
                  className='select-input'
                //   style={rest.style}
                //   required={ required ? true : false }
                //   {...rest}
              >
              {
                fields[2].options.map((_, index) => {
                    return (
                      <option value={!_.value && _.value!==""?_.label:_.value} key={index}>{_.label}</option>
                    )
                })
              }
            </select>
            </div>
        </div>
        <div className={`dialog__container_item input-container `} style={{margin:'0% 3% 0% 0%'}}>
            <div className={`dialog__description flexCol margTop16`} style={{marginBottom:'0',minHeight:'42px'}}>
            <label className={`req`}>Meal Status</label>
              <select
                //   id={id || ''}
                  className='select-input'
                //   style={rest.style}
                //   required={ required ? true : false }
                //   {...rest}
              >
              {
                menuSelections.mealStatus.map((_, index) => {
                    return (
                      <option value={!_.value && _.value!==""?_.label:_.value} key={index}>{_.label}</option>
                    )
                })
              }
            </select>
            </div>
        </div>



  </div>

  <div className='width30Menu'>
  <div className='dialog__container_item input-container margBottom48'>
    <div className='dialog__description flexCol'>
      <label htmlFor="upload-img" className="subheading-text-small-title fontRegular req">Image</label>
      <input
        type="file"
        id="upload-img"
        name="img"
        accept="image/png"
        style={{ margin: 0 }}
        //   onChange={onSelectFile}
      />
    </div>
  </div>
</div>


</div>

<div className='flexRow justifyContentSpaceBetween width100'>

<div className={`dialog__container_item input-container `} style={{margin:'0% 3% 0% 0%'}}>
            <div className={`dialog__description flexCol margTop16`} style={{marginBottom:'0',minHeight:'42px'}}>
            <label htmlFor={2} className={`'req' :Boolean(info) ? 'info':''}`}>Description</label>
            <textarea
              className={`edit-input-meals-desc`}
              required={ true }
              placeholder={'Description...'}
/>
            </div>
       </div>

<div className={`dialog__container_item input-container `} style={{margin:'0% 3% 0% 0%'}}>
            <div className={`dialog__description`} style={{marginRight:'5.3rem',minHeight:'42px'}}>
            <div className={`dialog__description flexCol margTop16`}>
            <label htmlFor={2} className={`'req' :Boolean(info) ? 'info':''}`}>Preparation Instructions</label>
            <textarea
              className={`edit-input-meals-desc`}
              required={ true }
              placeholder={'Preparation Instructions...'}
            />
          </div>
            </div>
       </div>


</div>

      <hr style={{marginTop:'2%',width:'100%'}}/>
      <br />

<div className='flexCol justifyContentSpaceBetween width100' >
    {mealmasterExtraReq.map((mealSet) => (
        <div key={mealSet.id}>
        {generateAddntnMeals(mealSet.id)}
        </div>
      ))}</div>

  </div>
    </div>

    <div className="dialog__footer flexCol alignItemsCenter" style={{top:'90%',position:'sticky'}}>
    <button  disabled={false} className="done-button" onClick={props.onSubmit}>{props.title === 'Edit Item' ? 'Update' : 'Save'}</button>
  </div>
   </div>
    </div>

  );
}

export const CreateMealmastr = (props) => {


  const onSubmit = async () => {

    removeContainerOverlay();
  }

  const onChange = (e, updated) => {
    typeof (props.onChange) === 'function' && props.onChange(e, updated)
  }

  const onClose = (e) => {
    removeContainerOverlay();
    props.onClose();
  }

  const removeContainerOverlay = () => {
    /**
     * Remove overlay to parent class
     */
    (document.querySelectorAll('.content-container')[0]).classList.remove('modal-overlay-bg2');
  };

  return (
    <CreateMealmaster
      type={props.type}
      title={props.title}
      fields={props.fields}
      menuSelections={props.menuItems}
      onClose={onClose}
      onSubmit={onSubmit}>
    </CreateMealmaster>
  )
}