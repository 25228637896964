import React, { useState ,useEffect} from 'react'
import '../assets/styles/modal.scss'
import closeIcon from '../images/icons/Icon2.png'
import downloadMasterIcon from '../assets/icons/download-master.svg';
import AllMultiSelect from "../common/AllSelectCheckbox.jsx";
import CustomSelect from "../common/CustomSelect";
import { components } from "react-select";
import { NGODatePicker } from "./DatePicker";
import { useTranslation } from 'react-i18next';
import { Loading } from "./Loading";
import LoadingIn from '../assets/loadingIn.gif';
import { Input } from "./Input";


export const Upload = (props) => {

  const [selected, setSelected] = useState(null);
  const [uploadProgress,setUploadProgress]=useState({status:"Uploading",percentage:40});
  const [fileSize,setFileSize]=useState(0);
const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [optionSelected,setOptionSelected]=useState(null);
  const [error, setError] = useState(true);
  const [form] = useState([{ name: null, type: null, date: null }]);
  const [date, setDate] = useState(null);
  const { t } = useTranslation();
  const [dateValue, setDateValue] = useState(null);
  const [state, setState] = useState({ loading: false, message: null, inputFieldsForm: {} });

  useEffect(() => {
    if(!props.errorObj) return;
    setState(prev=>{
      return {...prev,message:{message:props.errorObj?.message,errors:props.errorObj?.errors}}
    })
  }, [props.errorObj])

  const preCompile = {
    chooseFilePlaceholder: t('filters.chooseXlsFile'),
    browse: t('filters.browse'),
    sampleFile: t('filters.sampleFile')
  }
  let fileName = preCompile.chooseFilePlaceholder;
  if (props.uploadedFile) {
    if (Array.isArray(props.uploadedFile)) {
      if (props.uploadedFile.length === 0) {
      }
    } else {
      fileName = props.uploadedFile.name;
    }
  }

  useEffect(() => {
    if(props.uploadedFile && props.uploadedFile?.size>0){
       let fileSizeUpload=props.uploadedFile?.size/(1024*1024);
       setFileSize(parseFloat((fileSizeUpload * uploadProgress.percentage)/100).toFixed(4));
    }
  }, [uploadProgress.percentage,props.uploadedFile])

  useEffect(() => {
    let interval;

    if (props.uploadFileStatusBlock && props.uploadedFile?.size>0) {
        const statusBlock = props.uploadFileStatusBlock;
        const parser = new DOMParser();
        const doc = parser.parseFromString(statusBlock, 'text/html');

        const errorElementsWithClass = doc.querySelectorAll('.listErrorMessagesCls');
        const errorMessagesWithClass = Array.from(errorElementsWithClass).map(p => p.textContent.trim());

        const errorElementsWithoutClass = doc.querySelectorAll('p:not([class])');
        const errorMessagesWithoutClass = Array.from(errorElementsWithoutClass).map(p => p.textContent.trim());
        const allErrorMessages = [...errorMessagesWithClass, ...errorMessagesWithoutClass];
        if (doc.querySelectorAll('.listErrorMessagesCls').length) {
            setUploadProgress({ status: "Error", percentage: 100 });
            if (interval) clearInterval(interval);
            return;
        }

        if (allErrorMessages.some(message => message.includes('File Imported Successfully'))) {
            setUploadProgress({ status: "Success", percentage: 100 });
            if (interval) clearInterval(interval);
            return;
        }

        if (allErrorMessages.some(message => message.includes('Please wait while we process your data...'))) {
            if (interval) clearInterval(interval);
            setUploadProgress({status:"Uploading",percentage:40})
            interval = setInterval(() => {
                setUploadProgress(prev => {
                    if (prev.percentage < 85) {
                        return { ...prev, percentage: prev.percentage + Math.floor(Math.random() * (15 - 10 + 1)) + 10 };
                    } else {
                        clearInterval(interval);
                        return prev;
                    }
                });
            }, 3500);

        }
    }

    return () => {
        if (interval) clearInterval(interval);
    };
}, [props.uploadFileStatusBlock,props.uploadedFile]);


const handleMenuOpen = () => {
  setIsDropdownOpen(true);
};

const handleMenuClose = () => {
  setIsDropdownOpen(false);
};

const Option = props => {
  return (
  <div>
  <components.Option {...props}>
  <input
  type="checkbox"
  checked={props.isSelected}
  onChange={() => null}
  />{" "}
  <label>{props.label}</label>
  </components.Option>
  </div>
  );
  };

const MultiValue = props => (
  <components.MultiValue {...props}>
  <span>{props.data.label}</span>
  </components.MultiValue>
  );

  const onValueChange = (event) => {
let type="",value="";
if(typeof event === 'object' && !event?.target){
   type = event?.value;
   value = event?.value;
  form[0].type = value
  setSelected(value)
}else{
   type = event.target.type;
   value = event.target.value; 
}

    if (type === 'text') {
      form[0].name = value
    } else if (type === 'radio') {
      form[0].type = value
      setSelected(value)
    } else if (type === undefined) {
      form[0].date = value
      setDate(value)
    }
    if( form[0].name && form[0].type && form[0].date && props.setFormDetails){
      props.setFormDetails(form)
    }
    form[0].name && form[0].type && form[0].date ? setError(false) : setError(true)
  }
  const handleInputFieldChange = (e) => {
    const inputFieldsForm = state.inputFieldsForm
    const { name, value } = e.target;
    inputFieldsForm[name] = value;
    setState((_) => ({ ..._, inputFieldsForm: { ...inputFieldsForm }, message: null }))
  }

  const onWarehouseSelect = (e) => {
    if(props.modelTitle==='Upload Warehouse Mappings'){
      setOptionSelected(e)
    }
    (props.modelTitle==='Upload Warehouse Mappings')? props.setWarehouseUpload(e): props.setWarehouseUpload([e])
  }
  const onDateChange = (e) => {
    setDateValue(e.target.value)
    props.setDateChange(e.target.value);
  }

  const onPriceDateChange = (e) => {
    props.setDateChange(e.target.value);
  }

  const warehouseSelect = () => {
    if (props.warehouse && props.dateChange === undefined) {
      return (<>
        <div className={`flexCol alignItemsCenter dialog__container_item input-container`} style={{minHeight:`${isDropdownOpen?'40vh':""}`}} >
        <div style={{width:'17rem'}} className={`flexCol dialog__description`}>
        <label className={`fontRegular req`}>{props.modelTitle!=='Upload Vendor Mappings'?'Warehouses':'Vendors'}</label>
     {props.modelTitle==='Upload Vendor Mappings'? <button className='saleType-div m-2'>
              <CustomSelect
                  options={props.warehouse}
                  width={'17rem'}
                  onMenuOpen={handleMenuOpen} 
                  onMenuClose={handleMenuClose} 
                  placeHolder={'Select vendors'}
                  onChange={onWarehouseSelect}
              />
              </button>:  <div className={`margTop8`}> <AllMultiSelect
                options={props.warehouse.filter(fl=>fl.value!=="")}
                defaultValue={[]}
                onMenuOpen={handleMenuOpen} 
                onMenuClose={handleMenuClose} 
                isMulti
                styles={ {
                  input: provided => ({
                    ...provided,
                    color: '#fff'
                  })
                ,
                control: (defaultStyles) => ({
                  ...defaultStyles,
                  backgroundColor: "#162E3E",
                  width: '17rem',
                  boxShadow: "none",
                }),
                  option: (defaultStyles) => ({
                  ...defaultStyles,
                  textAlign: 'left',
                }),
                placeholder: (defaultStyles) => ({
                  ...defaultStyles,
                  textAlign: 'left',
                })
              }
              }
                isSearchable
                components={{ Option, MultiValue }}
                onChange={onWarehouseSelect}
                allowSelectAll={true}
                value={optionSelected}
                placeholder={`Select warehouse`}

              /></div>}
          </div>
        </div>
      </>)
    }
    if (props.warehouse && props.dateChange !== undefined) {
      return (<>
        <div style={{height:`${isDropdownOpen?'40vh':""}`}} className={`dialog__container_item input-container width100 flexCol alignItemsCenter`} >

          <div  className={`flexCol dialog__description`}>
          <label className={`fontRegular req`}>Warehouses</label>
            <button className='saleType-div m-2'>
                                <CustomSelect
                                    options={props.warehouse}
                                    width={'17rem'}
                                    onMenuOpen={handleMenuOpen} 
                                    onMenuClose={handleMenuClose} 
                                    placeHolder="Select Warehouse"
                                    onChange={onWarehouseSelect}
                                />
                                </button>

          </div>
          <div className={`dialog__description m-2`}>
          <label className={`fontRegular req`}>Effective Date</label>
              <NGODatePicker
                style={{width: '17rem'}}
                isclearable={false}
                value={dateValue}
                placeholder={t('effectiveDate')}
                placeholdertext={t('datePickerPlaceholder')}
                onChange={onDateChange} />
          </div>
        </div>
      </>)
    }
    else if(props.modelTitle!=='Upload Items'&&props.warehouse){
      return (<>
        <div className={`dialog__container_item input-container`}>
          <label className={`fontRegular req`}>Warehouses</label>
          <div className={`dialog__description`}>
            <select className='select-input-galley1' onChange={onWarehouseSelect}>
              {[].map((_) => {
                return (
                  <option value={null} key={null}>{null}</option>
                )
              })}
            </select>
          </div>
        </div>
      </>)
    }
   else if(props.modelTitle!=='Upload Items'&&props.dateChange !== undefined){
    return (<>
      <div className={`dialog__container_item input-container seatprice-upload`} >
       
        <div className={`dialog__description`}>
        <div className={`fontRegular req`}>Target Sector Type</div>
          <select className='select-input-galley1'
           onChange={(e) => props.setPriceSector(e.target.value)}
           value={props.priceSector}
           style={{ marginBottom:"1.5rem"}}
          >
            <option value="">Select Sector</option>
           <option value="dom">Domestic</option>
           <option value="int">International</option>
          </select>
        </div>

        
        <div className={`dialog__description`}>
          <> <label className={`fontRegular req`}>Effective Date</label>
            <NGODatePicker
              style={{width: '17rem'}}
              isclearable={false}
              value={props.dateChange}
              placeholder={t('effectiveDate')}
              minDate={new Date()}
              placeholdertext={t('datePickerPlaceholder')}
              onChange={onPriceDateChange} />
          </>
        </div>
      </div>
    </>)}
    else{
      return(<></>)
    }
  }
  const handleUploadFile = async (e) => {
    if (typeof props.uploadAPI === 'function') {
      const inputFieldsForm = state.inputFieldsForm
      let message = { success: true, message: '', }
      if (Array.isArray(props.inputFields)) {
        let valid = props.inputFields.every((_) => {
          if (!inputFieldsForm[_.attribute] && _.required) {
            message.success = false;
            message.message = `${_.label} is required`
            return false
          }
          return true
        });
        if (!valid) {
          document.getElementById('file-upload').value = ''
          return setState((_) => ({ ..._, message: message }))
        }
      }
      setState((_) => ({ ..._, loading: true, message: null }))
      try {
        const res = await props.uploadAPI(e, inputFieldsForm);
        message.success = true;
        message.message = res.message || 'Upload completed.';
      } catch (error) {
        let {data} = JSON.parse(error.message)
        message.success = false;
        message.message = data?.Error || data?.message || error.message;
        message.errors = data?.errors
      }
      if(document.getElementById('file-upload')){
        document.getElementById('file-upload').value = ''
      }
      setState((_) => ({ ..._, loading: false, message: message }))
    } else if (typeof props.onUploadFileChangeHandler === "function") {
      props.onUploadFileChangeHandler(e)
    }
  }

  const getStringMIMEType = (allowedExtensions) => {
    if (allowedExtensions) {
      if ( typeof allowedExtensions === 'string' ) {
        return allowedExtensions;
      }
      else if ( Array.isArray(allowedExtensions) ) {
        return allowedExtensions.join(',');
      }
      else if ( typeof allowedExtensions === 'object' ) {
        console.log('Log: Allowed extension type object:', allowedExtensions)
      }

    }
    return '';
  }

  return (
    <div className="overlay" role="dialog">
      <div className="dialog sm-scrollbar">
        <div className="dialog__content">
          <img className="modal__close_icon" alt='Close' src={closeIcon} onClick={props.onUploadClose} />
          <h2 className="dialog__title subheading-text-medium fontMedium">{props.modelTitle}</h2>
          <hr />
          {warehouseSelect()}
          {
            Boolean(props.inputFields) &&
            <>
              {
                props.inputFields === 'manage_products' ?
                  <div className="dialog__body_container flexRow flexWrap">
                    <div class="file-upload input-group col-md-8 col-xs-12">
                      <div className="dialog__container_item input-container upload-align">
                        <div className={`dialog__description`}>
                          <label className='req'>Price List Name</label>
                          <input type='text' className='edit-input' onChange={onValueChange} />
                        </div>
                        <div className={`dialog__description my-4`}>
                          <label className='req'>Target Sector Type</label><br></br>                
                          <CustomSelect
                            options={[
                            { key:0,label: 'Select Sector Type', value: '' },
                            { key:1,label: 'Domestic', value: 'dom' },
                            { key:2,label: 'International', value: 'int' }
                            ]}
                            width={'12.5rem'}
                            placeHolder={'Select Type'}
                            onChange={onValueChange}
                           />

                        </div>
                        <div className={`dialog__description`}>
                          <label className='req'>Effective Date</label>
                          <NGODatePicker id='effectiveDate' value={date} minDate={new Date()} onChange={onValueChange} />
                        </div>
                      </div>
                    </div>
                  </div>

                  : Array.isArray(props.inputFields) ?
                    <div className="dialog__body_container flexRow flexWrap button-align ">
                      {
                        props.inputFields.map((_) => {
                          const { initialValue, ...rest } = _;
                          return (
                            <Input
                              key={_.attribute}
                              name={_.attribute}
                              value={state.inputFieldsForm ? state.inputFieldsForm[_.attribute] : initialValue}
                              data={initialValue}
                              onChange={handleInputFieldChange}
                              {...rest}
                            />
                          )
                        })
                      }
                    </div>
                    : null
              }
            </>
          }
          {
            Boolean(props.warehouse?.length && props.warehouseUpload?.length && !props.warehouseUpload.includes(null) && props.dateChange === undefined) && !props.inputFields &&
            <div className="dialog__body_container flexRow mt-4 button-align justifyContentFlexEnd flexWrap">
              <div className="file-upload input-group col-md-8 col-xs-12 content-tabs">
                <span className="btn btn-primary btn-file">
                  <label htmlFor="file-upload">{preCompile.browse}</label>
                  <input id="file-upload" type="file" name="file" data-url="/api/inventory/upload" onChange={props.onUploadFileChangeHandler}  accept={ getStringMIMEType(props.allowedExtensions) } />
                </span>
                <input type="text" className="form-control" placeholder={fileName} readOnly=""></input>
              </div>

              <div className="col-md-4 col-xs-12">
                <button type="button" onClick={props.onDownloadSampleFile} className="btn btn-info" style={{ color: "white" }}>
                  <span><img className="icon-size" alt='downloadMaster' src={downloadMasterIcon} /></span>
                  {preCompile.sampleFile}
                </button>
              </div>
            </div>
          }
              {
            Boolean(props.warehouse?.length && props.warehouseUpload?.length && !props.warehouseUpload.includes(null) && props.dateChange !== undefined && dateValue) && !props.inputFields &&
            <div className="dialog__body_container flexRow mt-4 button-align justifyContentFlexEnd flexWrap">
              <div className="file-upload ng-grid-right input-group col-md-8 col-xs-12 content-tabs">
                <span className="btn btn-primary btn-file">
                  <label htmlFor="file-upload">{preCompile.browse}</label>
                  <input id="file-upload" type="file" name="file" data-url="/api/inventory/upload" onChange={props.onUploadFileChangeHandler} accept={ getStringMIMEType(props.allowedExtensions) } />
                </span>
                <input type="text" className="form-control" placeholder={fileName} readOnly=""></input>
              </div>

              <div className="col-md-4 col-xs-12">
                <button type="button" disabled={props.isDownloading} onClick={props.onDownloadSampleFile} className="btn btn-info" style={{ color: "white" }}>
                  <span><img className="icon-size" alt='downloadMaster' src={downloadMasterIcon} /></span>
                  {preCompile.sampleFile}
                </button>
              </div>
            </div>
          }
          {
            Boolean( props.dateChange !== undefined && props.priceSector) &&
            <div className="dialog__body_container flexRow mt-4 button-align justifyContentFlexEnd flexWrap">
              <div className="file-upload ng-grid-right input-group col-md-8 col-xs-12 content-tabs">
                <span className="btn btn-primary btn-file">
                  <label htmlFor="file-upload">{preCompile.browse}</label>
                  <input id="file-upload" type="file" name="file" data-url="/api/inventory/upload" onChange={props.onUploadFileChangeHandler} accept={ getStringMIMEType(props.allowedExtensions) } />
                </span>
                <input type="text" className="form-control" placeholder={fileName} readOnly=""></input>
              </div>

              <div className="col-md-4 col-xs-12">
                <button type="button" disabled={props.isDownloading} onClick={props.onDownloadSampleFile} className="btn btn-info" style={{ color: "white" }}>
                  <span><img className="icon-size" alt='downloadMaster' src={downloadMasterIcon} /></span>
                  {preCompile.sampleFile}
                </button>
              </div>
            </div>
          }
              {
            Boolean(!props.warehouse?.length && !props.warehouseUpload?.length && props.dateChange === undefined) && !props.inputFields &&
            <div className="dialog__body_container  flexRow button-align flexWrap justifyContentSpaceBetween">
          <div className="file-upload input-group col-md-8 col-xs-12 content-tabs">
      <span className={`btn btn-primary btn-file`}>
        <label htmlFor="file-upload">{preCompile.browse}</label>
        <input id="file-upload" type="file" name="file" data-url="/api/inventory/upload" onChange={props.onUploadFileChangeHandler} accept={ getStringMIMEType(props.allowedExtensions) } />
      </span>
      <input
        type="text"
        className="form-control"
        placeholder={fileName}
        readOnly
      />
    </div>

              <div className="col-md-4 col-xs-12">
                <button type="button" onClick={props.onDownloadSampleFile} className="btn btn-info" style={{ color: "white" }}>
                  <span><img className="mt-1 icon-size" alt='downloadMaster' src={downloadMasterIcon} /></span>
                  {preCompile.sampleFile}
                </button>
              </div>
            </div>
          }
          {
            props.inputFields && props.inputFields ==='manage_products' &&
            <div className="dialog__body_container flexRow button-align flexWrap justifyContentSpaceBetween">
          <div className="file-upload input-group col-md-8 col-xs-12 content-tabs">
      <span className={`btn btn-primary btn-file ${error ? 'disabled' : ''}`}>
        <label htmlFor="file-upload">{preCompile.browse}</label>
        <input id="file-upload" type="file" name="file" data-url="/api/inventory/upload"  disabled={error} onChange={props.onUploadFileChangeHandler} accept={ getStringMIMEType(props.allowedExtensions) } />
      </span>
      <input
        type="text"
        className="form-control"
        placeholder={fileName}
        readOnly
      />
    </div>

              <div className="col-md-4 col-xs-12">
                <button type="button" onClick={props.onDownloadSampleFile} className="btn btn-info" style={{ color: "white" }}>
                  <span><img className="mt-1 icon-size" alt='downloadMaster' src={downloadMasterIcon} /></span>
                  {preCompile.sampleFile}
                </button>
              </div>
            </div>
          }
          {
            props.uploadFileStatusBlock && props.uploadedFile && props.uploadedFile.size>0?
              <div className="dialog__footer">
                <div className={uploadProgress.status !== 'Error'?"meter animate":"meter red"}>
                <span style={{ width: `${uploadProgress.percentage}%` }}>
                <span
                className='flexRow justifyContentCenter alignItemsCenter'
                style={{ color: uploadProgress.status !== 'Error' ? "#fff" : '#c10e0e' }}
                >
                {uploadProgress.status !== 'Error' ? (
                    uploadProgress.status !== 'Success' ? (
                        `${uploadProgress.status} ${fileSize}MB of ${parseFloat(props.uploadedFile?.size / (1024 * 1024)).toFixed(2)}MB ${uploadProgress.percentage}%`
                    ) : (
                        uploadProgress.status
                    )
                ) : (
                    uploadProgress.status
                )}
                </span>
                </span>
                </div>
                <div className='flexRow justifyContentCenter alignItemsCenter'>
  {uploadProgress.status === 'Uploading' ? (<> <img src={LoadingIn} className="loading-img" alt="Loading" /><div className='margTop18' dangerouslySetInnerHTML={{ __html: props.uploadFileStatusBlock }}></div></>
  ) : null}
</div>

{uploadProgress.status !== 'Uploading' ?<div className='margTop18' dangerouslySetInnerHTML={{ __html: props.uploadFileStatusBlock }}></div>:null
}
              </div>
              :
              <>
                {
                  Boolean(state.loading) &&
                  <Loading />
                }
                {
                  Boolean(state.message) &&
                  <div className='upload-msg col-md-8 col-xs-12 flexCol button-align'>
                    <div className={`alert alert-${state.message.success ? 'success' : 'danger'}`} style={{ width: '75%' }}>
                      <div className='w-100 flexCol'>{state.message.message}</div>
                      {
                        Boolean(state.message.errors) &&
                        state.message.errors.map((er, i) => {
                          return (
                            <div key={i} className="flexRow">
                              {er.lineNumber && <div className='key'>Line Number: {er.lineNumber}</div>}
                              <div className='flexCol'>
                                {
                                  er.errors.map((_, i) => {
                                    return (<span key={i}>{_.message}</span>)
                                  })
                                }
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                }
              </>
          }
          {
            props.uploadFileStatusBlock && props.uploadedFile.length === 0 &&  (
              <div className='fileStatusBlock' style={{
                backgroundColor:props.uploadFileStatusBlock.includes("Success") ? "#377569":"#2b414ff0"
              }} dangerouslySetInnerHTML={{ __html: props.uploadFileStatusBlock }}></div>
            )
          }
        </div>
      </div>
    </div>
  );
}
export const UploadModal = (props) => {
  const onUploadClose = (e) => {
    removeContainerOverlay();
    props.onUploadClose();
  }
  const onDownloadSampleFile = (e) => {
    removeContainerOverlay();
    props.onDownloadSampleFile();
  }

  const removeContainerOverlay = () => {
    /**
     * Remove overlay to parent class
     */
    (document.querySelectorAll('.content-container')[0]).classList.remove('modal-overlay-bg2');
  };

  return (
    <Upload
      modelTitle={props.modelTitle ? props.modelTitle : 'Upload Items'}
      onUploadClose={onUploadClose}
      onDownloadSampleFile={onDownloadSampleFile}
      warehouse={props.warehouse}
      isDownloading={props.isDownloading}
      dateChange={props.dateChange}
      setFormDetails={props.setFormDetails}
      setDateChange={props.setDateChange}
      warehouseUpload={props.warehouseUpload}
      setWarehouseUpload={props.setWarehouseUpload}
      uploadedFile={props.uploadedFile}
      inputFields={props.inputFields}
      onUploadFileChangeHandler={(e) => {
        typeof props.onUploadFileChangeHandler === 'function' && props.onUploadFileChangeHandler(e)
      }}
      uploadAPI={props.uploadAPI}
      uploadFileStatusBlock={props.uploadFileStatusBlock}
      allowedExtensions={ props.allowedExtensions }
      errorObj={props.errorObj}
      priceSector={props.priceSector}
      setPriceSector={props.setPriceSector}
      />
  )
}