/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState,useRef } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import editIcon from "../../../assets/edit.svg";
import deleteIcon from "../../../assets/delete.svg";
import searchIcon from '../../../assets/icons/search.svg';
import confirmIcon from "../../../assets/icons/confirm.svg";
import viewIcon from "../../../assets/view.svg";
import LoadingGIF from '../../../assets/loadingIn.gif';
import DOWNLOAD_ICON from "../../../assets/icons/download-master.svg";
import { BsBorderStyle } from "react-icons/bs";
import { FaCopy } from "react-icons/fa6";
import { FaLock } from "react-icons/fa";

import {
    LetterOfIndendRenderSel,
   } from "../../sales/inner-components/renderers";
import { DataGrid, EditModal,NGODatePicker } from "../../../common"; //, UploadModal
import { APIEndpoint,DFPAPIEndpoint } from "../../../utils/axios";
import usePermissions from "../../../utils/userPermissions";
import { FaPause } from "react-icons/fa";
import { getRemarks, updateButtonTxtStatus,  fileUploadStatusShow, fileUploadStatusHide, translationLength, copyToClipBoard} from "../../../utils/commonFunctions";
import {  UPLOADED_LOI_ATTACHMENT_KEY,HOST_SERVER } from "../../../utils/configs";
import { useNavigate } from "react-router-dom";
import * as ReactDOM from 'react-dom';
import CustomSelect from "../../../common/CustomSelect";

const SectorsCellRenderer= ({value}) => {

if(value && typeof value === "string" && !Array.isArray(value) && typeof value !== "function"){
    const parts = value.split("(");
    if (parts.length === 2) {
        return (
            <div className="sector-container">
             <span className="sector-label">{parts[0] + " (" + parts[1]}</span>
            </div>
          );
    } else {
      return <div className="sector-container">
      <span className="sector-label">{value}</span>
     </div>;
    }

     }
     else if(value && typeof value === "object" && !Array.isArray(value) && typeof value !== "function" && !Object.keys(value).includes('attachment')){
        const {employeeName}=value

        return (
            <div className="sector-container">
             <span className="sector-label">{employeeName}</span>
            </div>
          );
     }
     else if(value && typeof value === "object" && !Array.isArray(value) && typeof value !== "function" && Object.keys(value).includes('attachment')){
        const {agreementNumber,attachment}=value

        return (
            <>
              {attachment ? <u className="loi-attachment-downld" onClick={()=>getLOIattachmentFile(attachment)}>{agreementNumber}</u> : <>{agreementNumber}</>}
            </>
          );

     }

     else{
return <></>
     }
  };


const getLOIattachmentFile = (file) => {
    const {name } = file;
    if(name){
        const link = document.createElement('a');
        link.href = `${HOST_SERVER}/loi/downloads/attachments/${name}`;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

};

  const LoiCodeRenderer = (props)=>{
    if(props.data&&props.data?.code){
    return (
      <div className="orderNo-renderer">
           <span>{props.data?.code}</span>
           <button title='Copy to clipboard' onClick={(e)=>copyToClipBoard(e,props.data?.code)} className='margLeft5'>
             <FaCopy/>
           </button>
       </div>
   );
  }else
  return (
     null
  );
  }



export const LetterOfIntents = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
  const [message, setMessage] = useState('No Data')

  const [isDownloading,setIsDownloading]=useState(false);
  const [buttonId,setButtonId]=useState(null);
    const [ uploadedLOIAttachment, setUploadedLOIAttachment] = useState(null);
    const sType = localStorage.getItem('sType');
    const DOMAIN = (sType === 'dfp' ? DFPAPIEndpoint : APIEndpoint);

    const { hasPermission: createLoi } = usePermissions('create', "procurement.letter-of-intents");
    const { hasPermission: editLoi } = usePermissions('edit', "procurement.letter-of-intents");
    const { hasPermission: downloadLoi } = usePermissions('download', "procurement.letter-of-intents");
    const { hasPermission: deleteLoi } = usePermissions('delete', "procurement.letter-of-intents");
    const { hasPermission: viewDetailsLoi } = usePermissions('viewDetails', "procurement.letter-of-intents");
    const { hasPermission: approveLoi } = usePermissions('approve', "procurement.letter-of-intents");


    const EditFormFields = [

        /**
         * The options array should contain objects.
         * Required keys are "name" and "value" but you can have and use any number of key/value pairs.
         */
        {key: 1, label: 'Distributor Name', name: 'distributorName', id: 'distributorName', attribute: 'distributorName', type: 'selectSearch'
            , defaultValue: ''
            , value: ''
            , className: 'select-search-cls'
            , options: []
            // , getOptions: () => {
            //     return
            // }
            /**
             * multiple(false): true/false
             * search(false): true/false
             * closeOnSelect(true): true/false
             * debounce(0): Number of ms to wait until calling get options when searching.
             * autoComplete(off): Options are on/off
             * autoFocus(false): true/false
             */
            , multiple: false
            , search: true
            // , closeOnSelect: false
            // , debounce: 1000
            , autoComplete: 'on'
            , autoFocus: false
            , disabled: false, placeholder: 'Start typing...',required: true

        },

        {key: 2, label: 'Distributor Code', name: 'distributorId', id: 'distributorId', attribute: 'distributorId',
            type: 'text', required: true, disabled: true, value: '' },

        { key: 3, label: 'Agreement Number', name: 'agreementNumber', id: 'agreementNumber', attribute: 'agreementNumber', type: 'text', required: true, disabled: false,
            placeholder: t('pages.procurementSub.purchaseOrdersSub.agreementNumber'),
            className: 'text-input' },

        { key: 4, label: 'Expiry Date', name: 'expiryDate', id: 'expiryDate', attribute: 'expiryDate', type: 'date', required: true, disabled: false,
            placeholder: t('datePickerPlaceholder'),minDate:new Date(),
            className: 'date-input',
            // startDate: moment().format()
        },

        { key: 5, label: 'Attachment', name: 'attachment', id: 'attachment', attribute: 'attachment', type: 'file', required: false, disabled: false,
            placeholder: t('selectButtons.selAgreement'),
            onChange: (e) => {
                fileUploadStatusShow(e, 'Uploading...'); // , "data-ext": 'xlsx,xls,doc,docx,pdf'
                if (!e.target.files || e.target.files.length === 0) {
                    fileUploadStatusHide(e);
                    return false;
                }

                const files = e.target.files;

                const payload = new FormData();
                payload.append('scheduleLater', false);
                payload.append('listName', null); // listName:undefined
                var sectorType = '';
                payload.append('targetSectorType', sectorType); // targetSectorType:undefined
                payload.append('stores', []);
                payload.append('warehouses', []);
                payload.append('file', files[0]);
                if (sectorType) {
                    payload.append('effectiveDate', '');
                }

                APIEndpoint.post('attachments/upload', payload)
                .then( (resp) => {
                    console.log('Successfully uploaded attachment.');

                    setUploadedLOIAttachment(resp.attachment);
                    fileUploadStatusHide(e, 'Uploaded attachment.');
                })
                .catch( (error) => {
                    console.log('Error occured while uploading attatchment:', error.message);
                    fileUploadStatusShow(e, 'Error occured while uploading attatchment:', error.message);
                });

            },
            className: 'date-input'},

        { key: 6, label: 'Remarks', name: 'remarks', id: 'remarks', attribute: 'remarks', type: 'textarea', required: true, rows: 4, cols: 42, disabled: false
            , placeholder: t('selectButtons.commentsOrRemarks'), className: 'wide-textarea-input' }
    ];

    const [state, setState] = useState({resetDataSource:false,disabled:false,message:null});
    const [editFormFields, setEditFormFields] = useState( null );
    const perPageLimit = 10;
    const [searchKey, setSearchKey] = useState('');

    const [distributors, setDistributors] = useState([]);
    const [EditFieldsCess, setEditFieldsCess] = useState([]);
    const [updatedFormFieldValues, setUpdatedFormFieldValues] = useState({});
    const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
    const [modalCustomButtons, setModalCustomButtons] = useState(null);
    const modelStyle = { maxWidth: '80%' };
    const [ modalTitle, setModalTitle ] = useState('View Purchase Order Items');
    const [modalFormData, setModalFormData] = useState({});
    const stateRef = useRef(state);

    useEffect(() => {
        if (state.code || state.expiryDate || state.status || state.distributor ||
            state.code === "" || state.expiryDate === "" || state.status === "" || state.distributor === "") {
            fetchLetterOfIntentRows();
        }
        if(state.message && state.message.text){
            setTimeout(()=>{
                setState((prev)=>({...prev,message:null}))
                setButtonId(null);
            },3500)
        }
    stateRef.current = state;
    }, [state]);


    useEffect( () => {

        localStorage.removeItem( UPLOADED_LOI_ATTACHMENT_KEY );

        // setEditFormFields( EditFormFields );

        fetchDistributorList();

    }, [] );

    useEffect( () => {
        if (updatedFormFieldValues) {

            if ( state.selected?.action === 'editItem' || state.selected?.action === 'AddNewItem') {
                const extraFields = {};
                if (updatedFormFieldValues.distributorId) {
                    const selectedDistributor = distributors.find( item => item.value === updatedFormFieldValues.distributorId );
                    extraFields.distributorName = selectedDistributor.label;
                }

                setState((_prev) => {
                    return { ..._prev, selected: { ..._prev.selected, ...updatedFormFieldValues, ...extraFields } };
                });
            }

        }
    }, [updatedFormFieldValues] );


    const downloadLOI = async (id) => {
        if (isDownloading) return;

        const header = {
            'Accept': 'application/pdf'
        };

        try {
            setIsDownloading(true);
            const res = await DOMAIN.post(`downloads/loi`, { id }, header);

            if (res.status === 'scheduled') {
                setTimeout(async () => {
                    try {
                        const dowId = await DOMAIN.get(`downloads/status/${res.id}`);


                        if (dowId.status === "completed") {
                            const ItemMasterDwnld = await DOMAIN.get(`downloads/analytics/${dowId.fileName}${dowId.fileExtension}`);
                            const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', dowId.fileName + '.csv');
                            document.body.appendChild(link);
                            link.click();
                            setIsDownloading(false);
                        }
                    } catch (error) {
                        let {status,data}=JSON.parse(error.message)
                        console.error('Error fetching download status or analytics:', data);
                        setState((_) => ({
                            ..._,
                            message: { text:data?.message},
                        }));
                        setIsDownloading(false);
                    }
                }, 8000);
            }
        } catch (error) {
            let { data } = JSON.parse(error?.message);
            setState((_) => ({
                ..._,
                message: { text:data?.message },
            }));
            setTimeout(() => {
                setState((prevState) => ({
                    ...prevState,
                    message: null,
                }));
                setIsDownloading(false);
            }, 5000);
        }
    };



    const handleOnReset = () => {
        setState((_prev) => {
            return { ..._prev,resetDataSource:!state.resetDataSource ,disabled:false};
        });
        setTimeout(() => {
          onClose();
        }, 3500);
      };

    const onEnableDisableAction=async ()=>{
        const delData = stateRef.current;
        const {selected}=delData;

        if(selected){
            setState((_prev) => {
                return { ..._prev,disabled:true };
            });
          try {
            const res = await DOMAIN.post(`letterOfIndents/${selected?.action==='deleteLOI'?'delete':'approve'}`, {
                "loiId": selected?.id
            });
        if(res){
          setModalFormStatusObj({text:res.message||res.msg||`Success`})
        handleOnReset();
        }
          } catch (error) {
            const errData=JSON.parse(error.message)
            if(errData.data?.message){
                setState((_prev) => {
                    return { ..._prev,disabled:false };
                });
          setModalFormStatusObj({error:errData.data?.message||`Failed.`})
            }
          }
        }

        }


    const onActionInner = (action, row) => {

        const {id}=row;
        setButtonId(id)
        let updatedEditFields = [];

        if (action.value === 'Suspend' && deleteLoi) {
            setModalTitle('Suspend Letter of Indent - ' + row.code);

            updatedEditFields = [
                {
                    label: 'You will not be able to update this LOI once you suspend it. Are you sure you want to continue?',
                    attribute: 'alertMessage', type: 'alertMessage'
                },
                { label: 'Remarks', attribute: 'remarks', type: 'textarea', required: true, rows: 10, cols: 50, disabled: false, placeholder: 'Please enter suspend remarks', className: 'wide-textarea-input' }
            ];
            setModalCustomButtons([

                { label: 'Suspend', attribute: 'button', type: 'button', disabled: false, className: 'submit-button', action: 'onSubmit'
                    // , action: (e) => {  onSubmit(e); return false; }
                }
            ]);

        }
        else if(action.value==='deleteLOI' && deleteLoi){
            setModalTitle('Delete letter of intent -' +'('+row.code+")");
        updatedEditFields = [
          {
              label: 'This action is irreversible. Are you sure you want to continue?',
              attribute: 'alertMessage', type: 'alertMessageRemove'
          }
      ];
      setModalCustomButtons([{label:'Delete',attribute:'button',type:"button",disabled:state.disabled,className:'submit-button',action:()=>onEnableDisableAction()}])

         setState((prev)=>({...prev,disabled:false,selected: {...row,action: 'deleteLOI' }}))

      setEditFieldsCess(updatedEditFields);

        }
        else if(action.value==='approveLOI' && approveLoi){
            setModalTitle('Approve letter of intent -' +'('+row.code+")");
        updatedEditFields = [
          {
              label: 'You will not be able to update this letter of intent once you approve it.Are you sure you want to continue?',
              attribute: 'alertMessage', type: 'alertMessageRemove'
          }
      ];
      setModalCustomButtons([{label:'Approve',attribute:'button',type:"button",disabled:state.disabled,className:'submit-button',action:()=>onEnableDisableAction()}])

         setState((prev)=>({...prev,disabled:false,selected: {...row,action: 'approveLOI' }}))

      setEditFieldsCess(updatedEditFields);

        }
        else if (action.value === 'edit' && editLoi) {
            navigate('/procurement/letter-of-intents'
                    + '/loi-items/edit'
                    + '/' + row.id +'/'+row.code
                );
        }
        else if (action.value === 'finalize' && approveLoi) {
            setModalTitle('Finalize Purchase Order - ' + row.code);

            updatedEditFields = [
                {
                    label: 'You will not be able to update this letter of intent once you finalize it. <br />Are you sure you want to continue?',
                    attribute: 'alertMessage', type: 'alertMessage'
                },
            ];

            setModalCustomButtons([

                { label: 'Finalize', attribute: 'button', type: 'button', disabled: state.disabled, className: 'submit-button', action: 'onSubmit'
                    // , action: (e) => {  onSubmit(e); return false; }
                }
            ]);
        }
        else if (action.value === 'purchaseOrders') {
            navigate('/procurement/purchase-orders/loi/' + row.id + '/' + row.code);
        }
        else if(action.value==='Download' && downloadLoi){
            downloadLOI(row.id);
        }
        else {
            setModalTitle('Letter of Intent - ' + row.code);

            updatedEditFields = [
                { label: 'Letter of Intent', type: 'viewLetterOfIntentItems', attribute: 'items' }
            ];

            // updatedEditFields = EditFormFields.filter( field => {
            //     if (field.type === 'viewLetterOfIntentItems') {
            //         setModalTitle('Letter of Intent - ' + row.code );
            //         field.value = row;
            //     }
            //     return field;
            // });

            setModalCustomButtons([[]]);
        }
        setEditFormFields( updatedEditFields );

        setState((_prev) => {
            return { ..._prev, selected: { ...row, action: action.value!=='Download'?action.value:""} };
        });
    };
const handleStatusChange=(e)=>{
    setState((_prev) => {
        return { ..._prev, status: e.target.value,resetDataSource:!state.resetDataSource };
    });
};

    const onAddItemHandler = async () => {


        setModalTitle('Create Letter of Intent');

        ReactDOM.flushSync( () => {
            setState( (_prev) => {
                delete _prev.selected;
                return { ..._prev, selected: { action: 'AddNewItem' } };
            });

            setUpdatedFormFieldValues({});



            setModalCustomButtons([

                { label: 'Save', attribute: 'button', type: 'submit', disabled: state.disabled, className: 'submit-button', action: 'onSubmit'
                    // , action: (e) => {  onSubmit(e); return false; }
                }
            ]);

            fetchDistributorList();

            /** Here, Resetting default input fields to array then updating */
            const updatedEditFields = EditFormFields.filter( (field) => {

                if (distributors.length > 1 && field.attribute === 'distributorName') {
                    field.disabled = false;
                    field.options = distributors;
                    field.getOptions = () => {
                        return distributors;
                    }
                    setModalFormStatusObj({ text: '' });
                }

                return field;
            });

            setEditFormFields( updatedEditFields );

        });

    }

    const onModalFormInputChange = (e, newFormData) => {
        setModalFormStatusObj({text:''})
        const { name, value } = e.target;

        let newUpdates = modalFormData ? { ...modalFormData } : {}
        newUpdates[name] = value;

        setModalFormData( { ...newFormData } );
    }
    const updateEditFormValues = async (e, updatedFormObj) => {
        setModalFormStatusObj({text:''})
        ReactDOM.flushSync( () => {
            if (e) {
                const selectedDistributor = distributors.find( item => item.value === e);

                /** Set to respective fields */
                const updatedEditFields = editFormFields.filter( (field) => {

                    if (selectedDistributor ) {
                        if ( field.attribute === 'distributorId' ) {
                            field.value = selectedDistributor.label;
                        }
                        else if ( field.attribute === 'distributorName' ) {
                            field.value = selectedDistributor.value;
                        }
                    }

                    return field;

                });

                setEditFormFields( _prev => {
                    return [ ...updatedEditFields ] ;
                });
            }
        })


    };

    const actionsLOI=(props)=>{
        return (
            props.data ? (
              <>
                {isDownloading && buttonId === props.data.id ? (
                  <img
                    src={LoadingGIF}
                    className="icon-size"
                    style={{ width: '25px', height: '25px' }}
                    alt="Loading"
                  />
                ) : (
                  (props.data.status === 'approved' || props.data.status === 'finalized') && (
                    <button
                      id={props.data.id}
                      disabled={isDownloading}
                      className="margRight"
                      style={{ cursor: 'pointer' }}
                      title={"Download"}
                      onClick={() => onActionInner({ value: 'Download' }, props.data)}
                    >
                      <img src={DOWNLOAD_ICON} className="node-icon svg" alt="" />
                    </button>
                  )
                )}

                {props.data.status === "approved" ? (
                  <>
                    <span
                      title={"View Items"}
                      onClick={() => onActionInner({ value: 'viewItems' }, props.data)}
                    >
                      <img className="aircraft-actions" alt="view" src={viewIcon} />
                    </span>
                    &nbsp;&nbsp;
                    <span
                      title={"Purchase Orders"}
                      onClick={() => onActionInner({ value: 'purchaseOrders' }, props.data)}
                      className="grid-LOI-stylebutton"
                    >
                      <BsBorderStyle size={15} style={{ marginRight: '4px' }} />PO
                    </span>
                    &nbsp;&nbsp;
                    <span
                    title={"Suspend"}
                    onClick={() => onActionInner({ value: 'Suspend' }, props.data)}
                    className="grid-LOI-stylebutton-rem margLeft8"
                  >
                    <FaPause size={15} style={{ marginRight: '4px' }} /> Suspend
                  </span>
                  </>
                ) : null}


                {props.data.status === "finalized" ? (
                  <>
                    <span
                      title={"View Items"}
                      onClick={() => onActionInner({ value: 'viewItems' }, props.data)}
                    >
                      <img className="aircraft-actions" alt="view" src={viewIcon} />
                    </span>
                    &nbsp;&nbsp;
                    <span
                      title={"Approve"}
                      onClick={() => onActionInner({ value: 'approveLOI' }, props.data)}
                    >
                       <img className="aircraft-actions" alt="view" src={confirmIcon} />
                    </span>
                  </>
                ) : null}


                {props.data.status === "created" ? (
                  <>
                    <span
                      title={"Edit Items"}
                      onClick={() => onActionInner({ value: 'edit' }, props.data)}
                    >
                      <img className="aircraft-actions" alt="edit" src={editIcon} />
                    </span>
                    &nbsp;&nbsp;
                    <span
                      title={"Finalize"}
                      onClick={() => onActionInner({ value: 'finalize' }, props.data)}
                      className="grid-LOI-stylebutton-fin"
                    >
                      <FaLock color={'#be6b58'} size={15} /> Finalize
                    </span>
                    &nbsp;&nbsp;
                    <span
                      title={"Delete Items"}
                      onClick={() => onActionInner({ value: 'deleteLOI' }, props.data)}
                    >
                      <img className="aircraft-actions LOI-actions" alt="delete" src={deleteIcon} />
                    </span>
                  </>
                ) : null}


              </>
            ) : null
          );



    }

    const Columns = [
        { field: 'code', headerName: 'LOI Code',resizable:true, minWidth: 164,
            tooltipField: 'code',pinned:'left',
            autoHeight: true,
            cellRendererSelector: (params) => {
                return {
                    component: LoiCodeRenderer,
                };
            },
        },
        { field: 'distributorCode', headerName: 'Distributor',resizable:true, minWidth: 230,
        cellRendererFramework: SectorsCellRenderer,
            valueGetter: params => params.data ? params.data.distributorCode + "(" + params.data.distributorName + ")" : '',
            tooltipField: 'distributorName',pinned:'left',
            autoHeight: true
        },

        { field: 'agreementNumber', headerName: 'Agreement Info', resizable:true, filter: false,
            width: 180,
            minWidth: 200,
            tooltipField: 'agreementNumber',
        cellRendererFramework: SectorsCellRenderer,
            valueGetter: params => params.data ? params.data : '',
            autoHeight: true
        },
        { field: 'status', headerName: 'Status',
        minWidth: 150,
        cellRendererSelector: (params) => {
            return {
              component: LetterOfIndendRenderSel,
            };
          },
        valueGetter: params => {
            return params.data ?
                params.data.status[0].toUpperCase() + params.data.status.substring(1) : '';
        },
        autoHeight: true
    },

        { field: 'createdAt', headerName: 'Created At',
            minWidth: 176,flex:1, suppressSizeToFit:true,
            valueGetter: params => {
                return params.data?.createdAt ? moment(params.data?.createdAt).format('DD-MM-YYYY hh:mm A') : '';
            },
            autoHeight: true
        },
        { field: 'createdBy', headerName: 'Created By',resizable:true,
            minWidth: 130,cellRendererFramework: SectorsCellRenderer,flex:1, suppressSizeToFit:true,
            autoHeight: true
        },
        { field: 'expiryDate', headerName: 'Expiry Date',
        minWidth: 176,flex:1, suppressSizeToFit:true,
        valueGetter: params => {
            return params.data?.expiryDate ? moment(params.data?.expiryDate).format('DD-MM-YYYY') : '';
        },
        autoHeight: true
    },
    { field: 'finalizedAt', headerName: 'Finalized At',
    minWidth: 176,flex:1, suppressSizeToFit:true,
    valueGetter: params => {
        return params.data?.finalizedAt ? moment(params.data?.finalizedAt).format('DD-MM-YYYY hh:mm A') : '';
    },
    autoHeight: true
    },

    { field: 'finalizedBy', headerName: 'Finalized By',resizable:true,
    minWidth: 150,cellRendererFramework: SectorsCellRenderer,flex:1, suppressSizeToFit:true,
    autoHeight: true
    },

    { field: 'approvedAt', headerName: 'Approved At',resizable:true,
    minWidth: 176,flex:1, suppressSizeToFit:true,
    valueGetter: params => {
        return params.data?.approvedAt ? moment(params.data?.approvedAt).format('DD-MM-YYYY hh:mm A') : '';
    },
    autoHeight: true
    },

    { field: 'approvedBy', headerName: 'Approved By',resizable:true,
    minWidth: 150,cellRendererFramework: SectorsCellRenderer,flex:1, suppressSizeToFit:true,
    autoHeight: true
    },
        // { field: '', headerName: '', sortable: false, filter: false,
        //     refName: 'LOIAgreementDownload',
        //     minWidth: 200, maxWidth: 600,
        //     tooltipField: 'agreementNumber',
        //     renderAlways: true, cellType: 'button',
        //     className: 'grid-download-btnStyle1',
        //     buttonTextKey: 'Download Agreement',
        //     // valueGetter: params => params.data ? params.data.agreementNumber : '',
        //     onClick: async (e, fieldName, item) => {
        //         updateButtonTxtStatus(true, e, 'Downloading...', true);

        //         // let acceptType = '';
        //         // if (MIMETypes[item.attachment.type]) {
        //         //     acceptType = MIMETypes[item.attachment.type];
        //         // }
        //         // else {
        //         //     acceptType = MIMETypes.excel;
        //         // }
        //         // console.log('Log: item attachment details:', item.attachment);

        //         if (item.attachment.extension === 'xlsx') {
        //             await onDownloadXlsFile('downloads/attachments/' + item.attachment.name, 'loi-Attachment-' + moment()).then( () => {
        //                 updateButtonTxtStatus(true, e, 'Downloaded', false, { defaultButtonText: 'Download' });
        //             });
        //         }
        //         else if (item.attachment.extension === 'pdf') {
        //             await onDownloadPDFFile('downloads/attachments/' + item.attachment.name, 'loi-Attachment-' + moment()).then( () => {
        //                 updateButtonTxtStatus(true, e, 'Downloaded', false, { defaultButtonText: 'Download' });
        //             });
        //         }

        //         /**
        //          * Method:-1
        //          */
        //         // var att = item.attachment;
        //         // var originalName = att.originalName;
        //         // var a = document.createElement("a");
        //         // document.body.appendChild(a);

        //         /**
        //          * Method:-2
        //          */
        //         // APIEndpoint.get('downloads/attachments/' + item.attachment.name, { Accept: acceptType, responseType: 'arraybuffer' }).then( resp => {
        //         //     // var file = new Blob([resp.data], { type: att.type });
        //         //     // var fileUrl = window.URL.createObjectURL(file);
        //         //     // a.href = fileUrl;
        //         //     // a.download = originalName;
        //         //     // a.click();
        //         //     onDownloadXlsFile('downloads/attachments/' + item.attachment.name);
        //         // })
        //         // .catch( error => {
        //         //     console.log('Log: DownloadLOI error:', error);
        //         //     updateButtonTxtStatus(false, e, 'Download failed', false, { defaultButtonText: 'Download Agreement', className: 'failedCls' });
        //         // });
        //     },
        //     autoHeight: true
        // },


       { field: 'remarks', headerName: 'Remarks',resizable:true,
            width: 200,
            minWidth: 200,flex:1, suppressSizeToFit:true,
            // resizable: true,
            tooltipField: 'remarks',
            valueGetter: params => params.data ? getRemarks( params.data.comment, params.data.remarks) : '',
            autoHeight: true
        },
        { field: 'items', headerName: 'Summary',
            minWidth: 150,flex:1, suppressSizeToFit:true,
            valueGetter: params => params.data ? params.data?.items?.length + ' items' : '',
            autoHeight: true
        },
        {
            headerName:'Actions',
            pinned:'right',
             autoResize:true,
             minWidth:240,
              width:255,
              flex:1,
              resizable: false,
              suppressSizeToFit:true,
              cellRenderer: actionsLOI,
              valueGetter: function(params) {
                return params;
              }
            }
    ];
    const statusState=[{id:1,label:'Approved',value:'approved'},{id:2,label:'Draft',value:'created'},{id:3,label:'Finalized',value:'finalized'},{id:4,label:'Suspended',value:'suspended'}]

    const onClose = () => {
        setState((_prev) => {
            return { ..._prev, selected: false };
        });
        setModalFormStatusObj({ text: '' });
    };

    const validateFormInputs = (body, e) => {
        setModalFormStatusObj({ text: '' });
        if (!body) {
            setModalFormStatusObj({ error: 'Input validation failed!' });
            return false;
        }

        if ( state.selected?.action === 'AddNewItem' ) {
            if (!body.distributorId && !body.distributorName) {
                setModalFormStatusObj({ error: 'Search distributor by distributor name!'});
                return false;
            }
            if (!body.agreementNumber) {
                setModalFormStatusObj({ error: 'Please enter agreement number' });
                return false;
            }

            if (!body.expiryDate) {
                setModalFormStatusObj({ error: 'Please select expiry date', });
                return false;
            }
            else if ( moment(body.expiryDate).endOf('day').isBefore( moment().endOf('day') ) ) {
                setModalFormStatusObj({ error: 'Expiry date cannot be earlier than today' });
                return false;
            }

            if ( !body.remarks ) {

                setModalFormStatusObj({ error: 'Please enter remarks!' });
                return false;
            }

        } else if ( state.selected?.action === 'Suspend' ) {
            if ( !body.remarks ) {
                setModalFormStatusObj({ error: 'Please enter suspend remarks!' });
                return false;
            }
        } else if ( state.selected?.action === 'finalize' ) {
            // validation not applicable
        }
        return true;
    };

    // const onFormSubmitHandle = async (e) => {
    //     console.log('onFormSubmitHandle:', e);
    //     return false;
    // }

    const onEditSubmit = async (e, updated) => {
        console.log("fre",e,updated)
        try {
            setState((_prev) => {
                return { ..._prev,disabled:true };
            });
            if ( state.selected?.action === 'Suspend') {
                setModalFormStatusObj({ text: 'Submitting...' });
                const body = {
                    ...updated,
                    indentId: state.selected.id,
                    // warehouseId: state.selected.warehouseId,
                };
                if (!validateFormInputs(body, e) ) {
                    return false;
                }
                APIEndpoint.post('indents/suspendLOI', body)
                .then(resp => {
                    if (resp.success) {
                        setState((_prev) => {
                            return { ..._prev,resetDataSource:!state.resetDataSource };
                        });
                        setModalFormStatusObj({ text: resp.message });
                    }
                    else {
                        setState((_prev) => {
                            return { ..._prev,disabled:false };
                        });
                        setModalFormStatusObj({ error: JSON.stringify(resp) });
                    }

                }).catch(error => {
                    let {data}=JSON.parse(error?.message);
                    setModalFormStatusObj({ error: data.msg,  });
                    setState((_prev) => {
                        return { ..._prev,disabled:false };
                    });
                });

            }
            else if ( state.selected?.action === 'viewItems' ) {
                setState((_prev) => {
                    return { ..._prev, selected: false };
                });
            }
            else if ( state.selected?.action === 'AddNewItem') {
                setModalFormStatusObj({ text: 'Submitting...' });
                const body = {
                    ...updated,
                    // warehouseId: state.selected.warehouseId,
                };
                if (!validateFormInputs(body, e) ) {
                    return false;
                }

                // const navigationData = {
                //     state: {
                //         ...location.state,
                //         invoicePayload: body
                //     }
                // }

                if (uploadedLOIAttachment) {
                    localStorage.setItem(UPLOADED_LOI_ATTACHMENT_KEY, JSON.stringify(uploadedLOIAttachment) );
                }

                navigate('/procurement/letter-of-intents'
                    + '/loi-items/create'
                    + '/' + JSON.stringify(body)
                );
            }
            else if ( state.selected?.action === 'finalize' ) {
                const btnText = 'Finalize';
                updateButtonTxtStatus( false, e, 'Finalizing...', true, { defaultButtonText: btnText });
                const body = {
                    ...updated,
                    loiId: state.selected.id,
                    // warehouseId: state.selected.warehouseId,
                };
                if (!validateFormInputs(body, e) ) {
                    updateButtonTxtStatus(false, e, 'Finalize failed', false, { defaultButtonText: btnText });
                    return false;
                }
                APIEndpoint.post('letterOfIndents/finalize', body)
                .then(resp => {
                    if (resp.success) {
                        updateButtonTxtStatus( true, e, btnText, false, { defaultButtonText: btnText });
                        setState((_prev) => {
                            return { ..._prev,resetDataSource:!state.resetDataSource };
                        });
                        fetchLetterOfIntentRows();
                        setModalFormStatusObj({ text: resp.message });
                        handleOnReset();
                    }
                    else {
                        updateButtonTxtStatus( false, e, 'Finalize failed', false, { defaultButtonText: btnText });
                        setModalFormStatusObj({ error: JSON.stringify(resp)});
                    }

                }).catch(error => {
                    updateButtonTxtStatus( false, e, 'Finalize failed', false, { defaultButtonText: btnText });
                    let {data}=JSON.parse(error?.message);
                    setModalFormStatusObj({ error: data.msg });
                    handleOnReset();
                });
            }
        }
        catch(err) {
            if ( err.hasOwnProperty('data') ) {
                setModalFormStatusObj({ error: 'Error: ' + err.data.msg,  });
            }
            else {
                setModalFormStatusObj({ error: 'Error: ' + err.message,  });
            }
        }
    };

    const onDateChange=(e)=>{
        // const dateObject = new Date(e.target.value);
        // const formattedDate = dateObject.toISOString();

        setState((_prev) => {
            return { ..._prev, expiryDate:e.target.value,resetDataSource:!state.resetDataSource};
        });
    }

    // const resetModal = () => {
    //     setState( (_prev) => {
    //         delete _prev.selected;
    //         return { ..._prev, selected: { action: 'AddNewItem' } };
    //     });
    //     // setUpdatedFormFieldValues({});
    //     setEditFormFields( _prev => {
    //         return [ ...EditFormFields ] ;
    //     });
    // }

    const filterData = {
        action: 4,
        collection: '',
        filterOperation: 'or',
        filters: [],
        limit: 10,
        page: 1,
        searchParam: ''
    };


    const fetchLetterOfIntentRows = async (page=1, perPage=10, iSearchText = '') => {
        filterData.page = page;
        filterData.limit = perPage;
        filterData.searchParam = iSearchText;
        filterData.sort = [];
        filterData.sort.push({ field: "createdAt", order: "desc" });

        if (state) {
            let filters = [];
            const filterConditions = [
              { condition: state.code, filter: { code: state.code } },
              { condition: state.status, filter: { status: state.status } },
              { condition: state.expiryDate, filter: { expiryDate: state.expiryDate } },
              { condition: state.distributor, filter: { distributor: state.distributor } },
            ];


            filterConditions.forEach(({ condition, filter }) => {
              if (condition) {
                const existingFilterIndex = filters.findIndex(existingFilter => {
                  return Object.keys(existingFilter).every(key => filter.hasOwnProperty(key));
                });

                if (existingFilterIndex !== -1) {
                  const existingFilter = filters[existingFilterIndex];
                  filters[existingFilterIndex] = { ...existingFilter, ...filter };
                } else {
                  filters.push(filter);
                }
              }
            });
            filterData.filters = filters;
          }
        try {
            const res = await APIEndpoint.post('letterOfIndents/process', filterData);
                if(res && res.items.length){
                return res;
                }else{
                    if(page===1){
                         return {
                            items:[],count:0
                         }
                    }
                }
        } catch (error) {

        }


    };

    const onDistributorChange=(e)=>{
setState((_)=>({..._,distributor:e?.value,resetDataSource:!state.resetDataSource}));
    }

    const onSearch = (e) => {
        setState((_)=>({..._,code:e.target.value,resetDataSource:!state.resetDataSource}));
    };
    const fetchDistributorList = async () => {
        console.log('Fetching distributor list...');
        if (distributors.length > 1) {
            return false;
        }

        const filterData = {
            action: 4,
            collection: '',
            filterOperation: 'or',
            filters: [],
            limit: 1000,
            page: 1,
            searchParam: ''
        };
        setModalFormStatusObj({ error: 'Please wait, Loading distributors...' });
        /**
         * POST distributors/process
         */
        try {
         const itemsRes = await APIEndpoint.get('warehouses/distributors/enumerate', filterData);
        const distributorList = [];

        if (itemsRes) {
            itemsRes.items.filter( row => {
                distributorList.push({ key: row.id, label: row.code, name: row.name, value: row.id });
                return row;
            });
            setDistributors(distributorList);
            setModalFormStatusObj({ text: '' });
        }
        } catch (error) {

        }

    };

    // const updateEditFormInputs = async (distributors=null) => {
    //     const updatedEditFields = await editFormFields.filter( (field) => {
    //         if (distributors && field.attribute === 'distributorId') {
    //             field.disabled = false;
    //             field.options = distributors;
    //             field.getOptions = () => {
    //                 return distributors;
    //             }
    //             setModalFormStatusObj({ text: '' });
    //         }
    //         return field;
    //     });
    //     setEditFormFields( updatedEditFields );
    // };

    return (
        <>
            <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
                {
                    <>



<div className="flexRow width100 justifyContentSpaceBetween">

<div className="flexRow justifyContentFlexStart alignItemsCenter">
    <button className="sector-div m-2 margLeft">

                <CustomSelect
                options={distributors}
                placeHolder=" Select Distributor"
                onChange={onDistributorChange}
              />
              </button>



              <button className="orderStatus-div m-2">
                <select
                  className="select-item darkBlue-bg"
                  onChange={handleStatusChange}
                  value={state.status}
                  id="orderStatusId"
                >
                  <option key={""} value={""}>
                    Select Status
                  </option>
                  {statusState.map((_) => {
                    return (
                      <option key={_.key} value={_.value}>
                        {_.label}
                      </option>
                    );
                  })}
                </select>
              </button>



              <button className="orderStatus-div m-2">
            <>
                    <NGODatePicker
                      style={{width: '13rem'}}
                      value={state.expiryDate}
                      dateFormat="dd/MM/yyy"
                      name='expiryDate'
                      className="input-margin"
                      isclearable={true}
                      placeholder={t('Expiry Date')}
                      placeholdertext={t('datePickerPlaceholder')}
                     onChange={onDateChange}
                       />
                  </>
            </button>
            </div>

               <div className="flexRow margLeft jusitfyContentCenter alignItemsCenter margBot10 margTop30 margRight10 underline">
                  <span><img  className="icon-size" alt={t('filters.searchText')} src={searchIcon} /></span>
                  <span><input className="search" onChange={onSearch} type="search" placeholder="Search LOI Code" value={state.loiCode}/></span>
              </div>

          { createLoi? <div className="flexRow justifyContentFlexEnd margTop28">
                    <button className="add-item-button" onClick={ onAddItemHandler }>
                        {t('buttons.createLetterOfIntent')}
                    </button>
             </div>:<></>}

            </div>

          {  Boolean(state.message)?<div className="flexRow justifyContentFlexEnd alignItemsCenter margRight margBot10">

          <div className={`validationMsgDiv error`}>
            {state.message.text}
          </div></div>:null
        }


                        <DataGrid
                            className="full-flex"
                            columns={Columns}
                            initialPageLimit={perPageLimit}
                            showDefaultFilters={false}
                            searchKey={searchKey}
                            getRows={ fetchLetterOfIntentRows }
                            rowSelection="multiple"
                            noResultsMessage={message}
                            // actions={actionItems}
                            // getActionCellIcons={getActionCellIcons}
                            // onAction={ (e, action, data) => { return onAction(e, action, data) } }
                            onAddItemHandler={() => onAddItemHandler()}
                            columnResize={true}
                            resetDataSource={state.resetDataSource}

                        />

                    </>
                }
                {
                    state && Boolean(state.selected) && Boolean(state.selected.action) && state.selected.action!=='deleteLOI' && state.selected.action!=='approveLOI'? (
                        <EditModal
                            title={ modalTitle }
                            customModalStyle={ modelStyle }
                            onClose={onClose}
                            onSubmit={onEditSubmit}
                            // onFormSubmitHandle={onFormSubmitHandle}
                            modalFormStatusObj={modalFormStatusObj}
                            modalCustomButtons={modalCustomButtons}
                            fields={ editFormFields.map( (elt) => {
                                    if (state.selected.action === 'viewItems' && viewDetailsLoi) {
                                        return {...elt, initialValue: state.selected[elt.attribute] || ''}
                                    }
                                    else {
                                        return {...elt, initialValue: '' }
                                    }
                                })
                            }
                            showFieldsInColumn={true}
                            onChange={ (e, updated) => {
                                setUpdatedFormFieldValues(updated);

                                if (typeof e === 'string') { // Search select
                                    updateEditFormValues(e, updated);
                                }
                                else {
                                    onModalFormInputChange(e, updated);
                                }

                            }
                        }
                        />
                    )
                    : null
                }

{
         Boolean(state?.selected) && (state?.selected?.action==='deleteLOI' ||state?.selected?.action==='approveLOI') ? (
        <div className='flexRow width100'>
          <EditModal
            key={state.selected.action}
            title={ modalTitle }
            mainContentCenter
            onClose={onClose}
            modalFormStatusObj={modalFormStatusObj}
            onSubmit={onEnableDisableAction}
            modalCustomButtons={modalCustomButtons}
            fields={EditFieldsCess.map( (elt) => {
              return { ...elt, initialValue: state.selected[elt.attribute] }
              })
            }
          />
          </div>
        )
        : null
      }

 {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }
            </div>
        </>
    );
}