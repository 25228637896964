import React, { useEffect, useState,useMemo } from "react";
import { EditModal, Input } from "../../../common";
import { getDateFromTimezone } from "./Cards";
import { APIEndpoint, DFPAPIEndpoint } from "../../../utils/axios";
import { formatDateByTimezone, objectToQueryString, truncateString } from "../../../utils/commonFunctions";
import usePermissions from '../../../utils/userPermissions';
import viewIcon from "../../../assets/view.svg"
import IrTopCards from "./renderers/IrTopCards";
import ExpandableTable from "../../../common/ExpandableTable";
// import CustomSelect from "../../../common/CustomSelect";
// import varianceData from "../../../assets/json/varianceData.json"
// import moment from "moment";

const filters = {
  action: 4,
  collection: '',
  filterOperation: 'or',
  filters: [],
  limit: 5000,
  page: 1,
  searchParam: ''
};

const itemTableHead = [
  { field: "productCat", headerName: "Category",minWidth:150,flex:1, },
  { field: "estimatedMeal", headerName: "Est.Meal",minWidth:150,flex:1, },
  { field: "uplift", headerName: "Uplift",minWidth:150,flex:1, },
  { field: "sales", headerName: "Sales",minWidth:150,flex:1, },
  { field: "wastage", headerName: "Wastage",minWidth:150,flex:1, },
  { field: "offload", headerName: "Offload",minWidth:150,flex:1, },
  { field: "crewHandover", headerName: "Crew Handover",minWidth:150,flex:1, },
  { field: "retrieval", headerName: "Retrievel",minWidth:150,flex:1, },
  { field: "variance", headerName: "Variance",minWidth:150,flex:1, },
];

export const InventoryReconciliation = () => {
  const sType = localStorage.getItem('sType');
  const DOMAIN = (sType === 'dfp' ? DFPAPIEndpoint : APIEndpoint);
  const [filterData, setFilterData] = useState({
    startDate: (()=>{
      const today = new Date();
      const pastDate = new Date(today).setDate(today.getDate()-60)
      return new Date(pastDate)
    })(),
    endDate: new Date(),
  });
  const [state, setState] = useState({ showModal: false,isOpenMenu:false,selectedCrew:null,selectedCat:null });
  const [tailNumber,setTailNumbers]=useState([]);
  const [actualSectors, setActualSectors] = useState([])
  const [storesList,setStoresList]=useState([]);
  const [sectorList, setSectorsList] = useState([]);
  const [sectorPageObj, setSectorPageObj] = useState({
    page: 1,
    size: 50,
    q: "",
  });
  const [rowData, setRowData] = useState([])
  const [vData, setVData] = useState([])
  const [itemMaster, setItemMaster] = useState([])
  const [selectedItems, setSelectedItems] = useState([])
  const [varianceData, setVarianceData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const { hasPermission: viewInvReconDetails } = usePermissions('viewDetails', "inventory-reconciliation");


  useEffect(() => {
    fetchDataFilters()
    fetchAirports()
    getSectorList()
    fetchVariance()
    fetchItemMaster()
  }, [])

  useEffect(() => {
    if(itemMaster.length === 0 && varianceData.length === 0) return;
    formatVarianceJson()
  }, [varianceData])

  useEffect(() => {
    if(!state.selectedCrew || !state.selectedCat) return;
    const data = vData.find(data=>data.crewId === state.selectedCrew)
    if(data.groupDetails){
      const items = data.groupDetails.filter(item=>item.productCat === state.selectedCat)
      setSelectedItems([...items])
    }
  }, [state.selectedCrew,state.selectedCat])

  useEffect(() => {
    fetchVariance()
  }, [filterData])



  async function fetchItemMaster() {
    try {
      const res = await APIEndpoint.post("master/process",JSON.stringify(filters))
      setItemMaster(res.items)
    } catch (error) {
      console.log(error)
    }
  }

  async function fetchVariance(){
    try {
      setIsLoading(true)
      const body = {...filterData}
      body.startDate = formatDateByTimezone(filterData.startDate)
      body.endDate = formatDateByTimezone(filterData.endDate)
      const res = await APIEndpoint.post("groups/variance",body)
      setIsLoading(false)
      setVarianceData(res.groups)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  function formatVarianceJson() {
    const formatted = varianceData.map(((group)=>{
      const data = {
        aoc:"",
        flightDate:getFlightData(group.sectors,"flightDate"),
        flightNumber:getFlightData(group.sectors,"flightNum"),
        sectorPairing:getFlightData(group.sectors,"flightSector"),
        crewId:group.key,
        groupDetails:formatItems([...group.items])
      }
      return data
    }))
    const formatted2 = varianceData.map(((group)=>{
      const data = {
        aoc:"",
        flightDate:getFlightData(group.sectors,"flightDate"),
        flightNumber:getFlightData(group.sectors,"flightNum"),
        sectorPairing:getFlightData(group.sectors,"flightSector"),
        crewId:group.key,
        groupDetails:formatItems([...group.items],true)
      }
      return data
    }))
    setVData([...formatted2])
    setRowData([...formatted])
  }

  function formatItems(items,groupBy=false){
    const itemsModified = items.map(item=>{
      const master = itemMaster.find(master=>master?.itemId==item?.itemId)
      return {...item,productCat:master?.category}
    })
    if(groupBy){
      return [...itemsModified]
    }
    let groupedData = {};
    itemsModified.forEach(item => {
      const { productCat, uplift, sales, wastage, offload, retrieval, variance, estimatedMeal, crewHandover } = item;
      if (!groupedData[productCat]) {
        groupedData[productCat] = {itemCategory: productCat,estCount: 0,upliftCount: 0,salesCount: 0,wastageCount: 0,offloadCount: 0,crewHCount: 0,retrievelCount: 0,varianceCount: 0};
      }
      groupedData[productCat].upliftCount += uplift;
      groupedData[productCat].salesCount += sales;
      groupedData[productCat].wastageCount += wastage;
      groupedData[productCat].offloadCount += offload;
      groupedData[productCat].retrievelCount += retrieval;
      groupedData[productCat].varianceCount += variance;
      groupedData[productCat].estCount += estimatedMeal;
      groupedData[productCat].crewHCount += crewHandover;
    });
    const result = Object.values(groupedData);
    return result;
  }

  function getFlightData(sectors,dataType){
    if(dataType === "flightDate"){
      const flightDates = sectors.map(sector=>sector.flightDate).join(" | ");
      return flightDates;
    }else if(dataType === "flightNum"){
      const flightNumbers = sectors.map(sector=>sector.flightNumber).join(" | ");
      return flightNumbers;
    }else if(dataType === "flightSector"){
      const sectorPairs = sectors.map(sector=>`${sector.flightNumber}-${sector.origin}-${sector.destination}`).join(" | ");
      return truncateString(sectorPairs,30,true);
    }
  }


  function getSectorList(){
    try {
      APIEndpoint.get("stores/byPage?" + objectToQueryString(sectorPageObj)).then(
  (resp) => {


    setSectorPageObj({ ...sectorPageObj, page: sectorPageObj.page + 1 });
    const list = [];
    if (resp.result.length > 0) {
      setActualSectors([...resp.result])
      resp.result.map((_) => {
        list.push({ value: _.id, id: _.id, label: _.value });
      });

      setSectorsList(list);
    }
  }
);
} catch (error) {

}
  }

  async function fetchDataFilters(){
    try {
      const [resTailNo,respStores] = await Promise.all([
        DOMAIN.get('sandboxing/aircrafts'),
        APIEndpoint.get('stores'),
      ]);
      if (resTailNo.result.length ) {
        const options1 = [];
        resTailNo.result.filter( (fieldValue, index) => {
          options1.push({key: index+1, label: `${fieldValue.value}`, value: `${fieldValue.value}`});
          return fieldValue;
        });
      setTailNumbers(options1)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const fetchAirports = async () => {
    try {
       const resp = sType === 'dfp' ? await DFPAPIEndpoint.get('airports') : await APIEndpoint.get('airports');
    if (resp.items.length > 0) {
      const options = [];
      resp.items.filter( (fieldValue, index) => {
        options.push({key: fieldValue.id, label: fieldValue.locationCode, value: fieldValue.locationCode});
        return fieldValue;
      });
      setStoresList(options);

    } else {
      console.log('Log: Error while fetching warehouses');
    }
    } catch (error) {

    }

  };
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if (name === "startDate") {
      let dataVal = getDateFromTimezone(value).toDate();
      setFilterData((prev) => {
        return { ...prev, startDate: dataVal };
      });
    }
    if (name === "endDate") {
      let dataVal = getDateFromTimezone(value).toDate();
      setFilterData((prev) => {
        return { ...prev, endDate: dataVal };
      });
    }
    setFilterData((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const handleMenuOpen=(isOPen)=>{
    setState(prevState => ({
      ...prevState,
      isOpenMenu: isOPen,
    }));
  }
  const onClose = () => {
    setState(prev=>({...prev,selectedCrew:null}))
  };
  function handletailNumberSel(e){
    setFilterData(prev=>{
      return {...prev,tailNumber:e?.value||""}
    })
  }
  function handleBaseStationSel(e){
    setFilterData(prev=>{
      return {...prev,baseStation:e?.value || ""}
    })
  }
  function onSectorChange(e){
    if(!e?.id) return;
    const sectorObject = actualSectors.find(sector=>sector.id === e?.id)
    const sectorObj = {
      flightNumber:sectorObject?.flightNumber,
      origin:sectorObject?.origin,
      destination:sectorObject?.destination,
    }
    setFilterData(prev=>{
      return {...prev,sector:{...sectorObj}}
    })
  }
  const Columns = [
    { field: "aoc",headerName: "AOC",minWidth: 50,},
    { field: "flightDate", headerName: "Flight Date",minWidth:150,flex:1, },
    { field: "flightNumber", headerName: "Flight Number",minWidth:150,flex:1, },
    { field: "sectorPairing", headerName: "Sector Pairing",minWidth:300,flex:1, },
    { field: "crewId", headerName: "Crew ID",minWidth:150,flex:1, },
    // { field: "actions", headerName: "Actions",minWidth:150,flex:1 },
  ];

  const getActionCellIcons = (cellDef, data) => {
    if (cellDef.attribute === "viewItems") {
      return [{ src: viewIcon, value: "viewItems", title: "View" }];
    }

    return [];
  };
  return (
    <>
      <div className="flexCol full-flex ag-theme-alpine-dark">
      <div
          className="flex flexRow alignItemsCenter"
        >
          {/* <div className='felxCol m-2'>
          <label className="req margBottom10">Airport</label>
            <div style={{marginBottom:"7px"}}>
              <CustomSelect
                options={storesList}
                placeHolder="Search Base Station"
                onChange={handleBaseStationSel}
              />
            </div>
          </div> */}
          <div className='felxCol m-2'>
            <Input
              label="Start Date"
              type="date"
              value={filterData.startDate}
              name="startDate"
              onChange={handleInputChange}
            />
          </div>
          <div className='felxCol m-2'>
            <Input
              label="End Date"
              type="date"
              value={filterData.endDate}
              name="endDate"
              onChange={handleInputChange}
            />
          </div>
          {/* <div className="felxCol m-2">
            <label className="req margBottom10">Tail Number</label>
            <div style={{marginBottom:"7px"}}>
              <CustomSelect
                options={tailNumber}
                placeHolder="Search Tail Number"
                onChange={handletailNumberSel}
              />
            </div>
          </div>
          <div className='felxCol'>
            <label htmlFor="sector" className="req margBottom10">Sector</label>
            <div style={{marginBottom:"7px"}}>
              <CustomSelect
                  options={sectorList}
                  placeHolder="Search Sectors"
                  onChange={onSectorChange}
                />
            </div>
          </div> */}
        </div>
        <div style={{height:"70vh",overflowY:"auto"}}>
          <div className="cardViewToggler">
            <IrTopCards/>
          </div>
          <div style={{position:"relative"}}>
            
              <ExpandableTable
                columns={Columns}
                data={rowData}
                childTableHeadings={itemTableHead}
                setState={setState}
                isLoading={isLoading}
              />
            {/* )} */}
          </div>
        </div>
      </div>
      { state.selectedCrew && viewInvReconDetails &&(
        <EditModal showDone={false} onClose={onClose} title={`Item Details - ${state.selectedCrew} - ${state.selectedCat}`}>
          <div className="sm-scrollbar width100" style={{height:"40vh"}}>
            <table id="groupedItemTbl">
              <thead>
                <tr>
                  <th style={{width:"20%"}}>Item Name</th>
                  <th style={{width:"10%"}}>Est.Meal</th>
                  <th style={{width:"10%"}}>Crew.Verif</th>
                  <th style={{width:"10%"}}>Uplift</th>
                  <th style={{width:"10%"}}>Sales</th>
                  <th style={{width:"10%"}}>Wastage</th>
                  <th style={{width:"10%"}}>Offload</th>
                  <th style={{width:"10%"}}>Retrieval</th>
                  <th style={{width:"10%"}}>Variance</th>
                </tr>
              </thead>
              <tbody>
                {selectedItems.map((item,i)=>{
                  return (
                    <tr>
                      <td>{truncateString(item.itemName,18,true)}</td>
                      <td>{item.estimatedMeal}</td>
                      <td>{item.crewVerification}</td>
                      <td>{item.uplift}</td>
                      <td>{item.sales}</td>
                      <td>{item.wastage}</td>
                      <td>{item.offload}</td>
                      <td>{item.retrieval}</td>
                      <td>{item.variance}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </EditModal>
      )}
    </>
  );
};