/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect ,useRef} from 'react'
import '../../../assets/styles/modal.scss'
import ReactPdfViewer from '../../../utils/pdfViewer';
import closeIcon from '../../../images/icons/Icon2.png';
import CustomSelect from "../../../common/CustomSelect";
import moment from 'moment';
import { NGODatePicker } from "../../../common/DatePicker";
import { FaChevronCircleLeft ,FaChevronCircleRight} from "react-icons/fa";
import { FaFileUpload } from "react-icons/fa";

export const CreatecatrInv = (props) => {
  const {fields}=props;
  const [formFields, setFormFields] = React.useState(null);
  const fileInput = useRef(null)
  const [dateValue,setDateValue]=useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedPdf,setSelectedPdf]=useState(null)


useEffect(()=>{
if(fields.length){
  const groupedFields=Object.groupBy(fields,(item)=>item.attribute)
  setFormFields(groupedFields)
}
},[])
const handlePdfUpload=(e)=>{
  setSelectedPdf(e.currentTarget.files[0])
}
const decrementPageNo = () => {
  if (pageNumber > 1) {
    setPageNumber(pageNumber - 1);
  }
};
const onDateChange = (e) => {
  setDateValue(e.target.value)
}
const incrementPageNo = () => {
  if (pageNumber < numPages) {
    setPageNumber(pageNumber + 1);
  }
};
const onSectorChange=()=>{

}

  return (
    <div className='overlay' role='dialog'>
    <div className='sm-scrollbar dialogMenu-caterer' style={{overflowX:'scroll',flexWrap:'wrap'}}>
        <>
        <div style={{margin: '3% 5% 3% 5%',maxWidth: '100%',width:'90%',whiteSpace:'nowrap'}} role="dialog">
        {/* <div className="dialog sm-scrollbar" style={props.customModalStyle}>
        <div className={`dialog__content ${props.className || ''}`}> */}
      <div className='width100 flexRow justifyContentSpaceBetween'>
        <h2 className='dialog__title subheading-text-medium justifyContentCenter alignItemsCenter fontMedium' style={{marginLeft:'40%'}}>
      {props.title}
        </h2>
      <img className="modal__close_icon" alt='Close' src={closeIcon} style={{cursor:'pointer',width:'1rem',height:'1rem'}} onClick={props.onClose} />
      </div>
      <hr style={{margin:'0 auto',width:'95%'}} />
      <br />
      <div className='flex justifyContentFlexStart margLeft23 width100'>
        <div className='flexCol width30 margRight10'>

        <label className='reqm flexRow'>{formFields?.warehouse[0].label}</label>

<button className='saleType-div m-2'>
           <CustomSelect
                options={formFields?.warehouse[0].options}
                width={'12.5rem'}
                placeHolder="Select Warehouse"
                onChange={onSectorChange}
              /></button>


  <div className='flexColMenu width30'>
      <label className='reqm flexRow'>{formFields?.catererName[0].label}</label>
      <input
          // id={id || ''}
          type={formFields?.catererName[0].type}
          className='edit-input'
          placeholder={"Caterer Name"}
          required={formFields?.catererName[0].required ? true : false }
          // {...rest}
        />
    </div>

  <div className='flexColMenu width30'>
  <div className=''>
        <label className='reqm flexRow'>{formFields?.address[0].label}</label>
        <textarea
        className={`edit-input`}
        placeholder={"Address"}
        required={formFields?.address[0].required ? true : false }

      />

      </div>
  </div>





<div className='flexColMenu width30'>
<div className=''>
              <label className='reqm flexRow'>{formFields?.gstIn[0].label}</label>
              <input
             type={formFields?.gstIn[0].type}
             className='edit-input'
             placeholder={"GSTIN"}
              required={formFields?.gstIn[0].required ? true : false }

            />

            </div>
</div>

<div className='flexColMenu width30'>
<div className=''>
              <label className='reqm flexRow'>{formFields?.invoiceNumber[0].label}</label>
              <input
               type={formFields?.invoiceNumber[0].type}
               className='edit-input'
               placeholder={"Invoice Number"}
              required={formFields?.invoiceNumber[0].required ? true : false }

            />

            </div>
</div>

<div className='flexColMenu width30'>
<div className=''>
              <label className='reqm flexRow'>{formFields?.value[0].label}</label>
              <input
             type={formFields?.value[0].type}
             className='edit-input'
             placeholder={"Value"}
              required={formFields?.value[0].required ? true : false }

            />

            </div>
</div>

<div className='flexColMenu width30'>
<div className=''>
              <label className='reqm flexRow'>{formFields?.paymentTerms[0].label}</label>
              <input
             type={formFields?.paymentTerms[0].type}
             className='edit-input'
             placeholder={"Payment Terms"}
              required={formFields?.paymentTerms[0].required ? true : false }

            />

            </div>
</div>


          <br />


        </div>
        <div className='flexCol width30 margRight10' style={{whiteSpace:'nowrap',minWidth:'200px'}}>
        <div className='flexColMenu width30 '>
         <label className={`req`}>{formFields?.date[0].label}</label>
            <NGODatePicker
                      dateFormat="dd/MM/yyy"
                      name='flightDate'
                      isClearable={true}
                      value={dateValue}
                      placeholder={`Select ${formFields?.date[0].label}`}
                      placeholdertext={formFields?.date[0].label}
                      onChange={onDateChange} />
        </div>

<div className='flexColMenu width30'>
<div className=''>
              <label className='reqm flexRow'>{formFields?.cateringGroup[0].label}</label>

            <button className='saleType-div m-2'>
           <CustomSelect
                options={formFields?.cateringGroup[0].options}
                width={'12.5rem'}
                placeHolder="Select Catering Group"
                onChange={onSectorChange}
              /></button>

            </div>
</div>

<div className='flexColMenu width30'>
<div className=''>
              <label className='reqm flexRow'>{formFields?.sector[0].label}</label>

            <button className='saleType-div m-2'>
           <CustomSelect
                options={ formFields?.sector[0].options}
                width={'12.5rem'}
                placeHolder="Select Sectors"
                onChange={onSectorChange}
              /></button>


            </div>
</div>

<div className='flexColMenu width30'>
<div className=''>
              <label className='reqm flexRow'>{formFields?.typeofMeal[0].label}</label>
            <button className='saleType-div m-2'>
           <CustomSelect
                options={  formFields?.typeofMeal[0].options}
                width={'12.5rem'}
                placeHolder="Select Type Of Meal"
                onChange={onSectorChange}
              /></button>

            </div>
</div>

<div className='flexColMenu width30'>
<div className=''>
              <label className='reqm flexRow'>{formFields?.quantity[0].label}</label>
              <input
             type={formFields?.quantity[0].type}
             className='edit-input'
             placeholder={formFields?.quantity[0].label}
              required={formFields?.quantity[0].required ? true : false }

            />

            </div>
</div>

<div className='flexColMenu width30'>
<div className=''>
              <label className='reqm flexRow'>{formFields?.subTotal[0].label}</label>
              <input
             type={formFields?.subTotal[0].type}
             className='edit-input'
             placeholder={formFields?.subTotal[0].label}
              required={formFields?.subTotal[0].required ? true : false }

            />

            </div>
</div>

<div className='flexColMenu width30'>
<div className=''>
              <label className='reqm flexRow'>{formFields?.total[0].label}</label>
              <input
             type={formFields?.total[0].type}
             className='edit-input'
             placeholder={formFields?.total[0].label}
              required={formFields?.total[0].required ? true : false }

            />

            </div>
</div>

        </div>



        <div className={`flexColMenu justifyContentFlexEnd ${!selectedPdf?"pdfContainer width30Menu":"pdfContainer-onload width100"}`}>
      <div className='justifyContentCenter alignitemsCenter'>
      <>
      <input
        ref={fileInput}
        onChange={handlePdfUpload}
        type="file"
        accept={".pdf"}
        className='input_display'
        />

      {!selectedPdf?<div className='pdf-styles flexCol'><FaFileUpload size={45} style={{margin:'5px'}} onClick={(e) => fileInput.current.click()} />&nbsp;Upload Pdf file</div>: <></> }
      {selectedPdf?<ReactPdfViewer fileHolder={selectedPdf} numPages={numPages} pageNumber={pageNumber} setNumPages={setNumPages} setPageNumber={setPageNumber} />:<></>}
      {selectedPdf?<><div className='flexRow justifyContentSpaceBetween margTop10 margLeft23 margRight11'><span className='pointer'>{pageNumber!==1?<FaChevronCircleLeft size={20} onClick={decrementPageNo}/>:<></>}&nbsp;Page {pageNumber} of {numPages}&nbsp;{pageNumber!==numPages?<FaChevronCircleRight onClick={incrementPageNo} size={20}/>:<></>}</span><span className='justifyContentFlexEnd'> <img style={{cursor:'pointer'}} alt='Close' src={closeIcon} onClick={()=>setSelectedPdf(null)} /></span></div><span className='flexRow margTop10 margLeft23'><strong>Modified on</strong>:&nbsp;{selectedPdf.lastModifiedDate?moment(selectedPdf.lastModifiedDate).format("ddd MMM DD YYYY HH:mm:ss"):""}</span></>:<></>}
      </>
      </div>
        </div>
      </div>


      <div className={selectedPdf?"flexRow width90Menu":"flexRow justifyContentCenter alignItemsCenter width90Menu"}>


        <div className='width30Menu saveBtnMenu'>
          <button

            className='done-button'
            onClick={props.onClose}
            style={{ position: 'fixed', bottom: '2rem' }}
          >
            {'Done'}
          </button>{' '}
        </div>
        <div className='width30Menu'></div>
      </div>




 {/* </div>
 </div> */}
 </div>
  </>
    </div>
    </div>
  );
}

export const CreatecatererInvoice = (props) => {


  const onSubmit = async () => {

    removeContainerOverlay();
  }


  const onClose = (e) => {
    removeContainerOverlay();
    props.onClose();
  }

  const removeContainerOverlay = () => {
    /**
     * Remove overlay to parent class
     */
    (document.querySelectorAll('.content-container')[0]).classList.remove('modal-overlay-bg2');
  };

  return (
    <CreatecatrInv
      type={props.type}
      title={props.title}
      fields={props.fields}
      onClose={onClose}
      onSubmit={onSubmit}>
    </CreatecatrInv>
  )
}