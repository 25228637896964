import React, { useEffect,  useState } from 'react';
import { FilterBodyTemplate } from "../../../utils/configs";
import { APIEndpoint } from "../../../utils/axios";
import {translationLength} from '../../../utils/commonFunctions';
import { DataGrid, Modal, NGODatePicker, Input } from "../../../common";
import { useTranslation } from 'react-i18next';
import Edit2 from "../../../assets/edit2.svg";
import moment from "moment";

const Columns = [
  { field: 'code', headerName: 'DFP Code', resizable:true,flex:1 },
  { field: 'startDateStr', headerName: 'Start Date', resizable:true,flex:1 },
  { field: 'createdAtStr', headerName: 'Created At', resizable:true,flex:1 },
  { field: 'createdByStr', headerName: 'Created By', resizable:true,flex:1 },
  { field: 'state', headerName: 'State', resizable:true,flex:1 }
]
const GenerateDSColumns = [
  { field: 'serialNumber', headerName: 'SL No',resizable:true,flex:1},
  { field: 'aircraftNumber', headerName: 'Tail No.',resizable:true,flex:1},
  { field: 'sector', headerName: 'Sector',resizable:true,flex:1},
  { field: 'departureDate', headerName: 'Date',resizable:true,flex:1},
  { field: 'departureTime', headerName: 'STD',resizable:true,flex:1},
  { field: 'hasUpliftText', headerName: 'Uplift',resizable:true,flex:1},
  { field: 'location', headerName: 'Location',resizable:true,flex:1},
  { field: 'lookAheadText', headerName: 'Look Ahead',resizable:true,flex:1},
  { field: 'upliftStationary', headerName: 'Stationary',resizable:true,flex:1},
]

const DFPGroupRow = (props) => {
  const { t } = useTranslation();
  return (
    <div className='flexCol full justifyContentCenter alignItemsCenter group-row'>
      <span>{t('pages.estimationsContent.dfpDraft.groupNo', { groupNo: props.data.groupIdentifier })}</span>
    </div>
  )
}
const isGroudHeadRow = (data) => {
  return data.rowNode.data.groupRow;
}

export const DFPDraftPage = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({ filterDate: new Date(), generateDSForm: {} });

  useEffect(() => {
    fetchWarehouses()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchWarehouses = async () => {
    try {
      const res = await APIEndpoint.get('warehouses');
    const options = (res.result || []).map((fieldValue, index) => {
      return { key: index, label: fieldValue.value, value: fieldValue.id };
    });
    const placeholder = { label: t('pages.pagesContent.warehouse.selectPlaceholder'), value: "", key: 'place' }
    setState((_) => ({ ..._, warehouses: [placeholder, ...options] }))
    } catch (error) {

    }

  }

  const fetchDrafts = async (iPageNo = 1, iPageLimit = 10, iSearchText = '') => {
    const filterData = JSON.parse(JSON.stringify(FilterBodyTemplate));
    filterData.page = iPageNo;
    filterData.limit = iPageLimit;
    filterData.searchParam = iSearchText;
    if (state.filterDate) {
      let flightDate = moment(state.filterDate).startOf('day').toString();
      filterData.filters = [{ flightDate: flightDate }]
    }
    const res = await APIEndpoint.post('draftDfp/process', filterData);
    if (res.items) {
      res.items = res.items.map((_) => {
        return {
          ..._,
          startDateStr: moment(_.startDate).format('DD-MM-YYYY hh:mm A'),
          createdAtStr: moment(_.createdAt).format('DD-MM-YYYY hh:mm A'),
          createdByStr: `${_.createdBy.employeeName}(${_.createdBy.employeeCode})`
        }
      })
    }
    return res;
  }
  const handleFilterDateChange = (e) => {
    setState((_) => ({ ..._, filterDate: e.target.value, resetDataSource: true }));
    let timerId = setTimeout(() => {
      clearTimeout(timerId);
      setState((_) => {
        return { ..._, resetDataSource: false };
      })
    }, 500)
  }
  const getActionCellIcons = (colDef, data) => {
    if (data && data.state === 'draft') {
      if (colDef.attribute === 'EDIT/DELETE') {
        return [{ src: Edit2, value: "edit", title: "Edit" }, { src: Edit2, value: "delete", title: "Delete" }]
      } else if (colDef.attribute === 'VALIDATE') {
        return [{ src: Edit2, value: "validate", title: "Validate" }]
      }
    }
    return []
  }
  const handleAction = (e, action, selectedRowData) => {
    // console.log('e, action, selectedRowData', action, selectedRowData);
  }
  const handleClose = (e) => {
    setState((_) => {
      return { ..._, showGenerateDialog: false, generateDSItems: null }
    })
  }
  const handleGenerateDSField = (e) => {
    const { name, value } = e.target;
    setState((_) => {
      return { ..._, generateDSForm: { ..._.generateDSForm, [name]: value } }
    })
  }
  const handlGenerateFormSubmit = async (e) => {
    e.preventDefault();
    let generateDSItems = null, items;
    setState((_) => ({ ..._, generateDSItems: 'wait' }))
    const body = { ...state.generateDSForm };
    body.warehouseId = Number(body.warehouseId)
    const res = await APIEndpoint.post('dfp/generate', body);
    if (res.success) {
      generateDSItems = [];
      items = res.dfp.items || [];
      items.forEach((_, i) => {
        if (i === 0 || (i !== 0 && items[i - 1].groupIdentifier < items[i].groupIdentifier)) {
          generateDSItems.push({ groupIdentifier: items[i].groupIdentifier, groupRow: true })
        }
        generateDSItems.push({
          ..._,
          sector: `${_.flightNumber}-${_.origin}-${_.destination}`,
          departureDate: moment(_.departureDateTime).format("DD-MM-YYYY"),
          departureTime: moment(_.departureDateTime).format("HH:mm"),
          hasUpliftText: _.hasUplift ? t("yes") : t("no"),
          lookAheadText: _.lookAhead ? t("yes") : t("no"),
          upliftStationary: _.upliftStationaryItems ? t("yes") : t("no"),
          location: _.upliftAirport ? _.upliftAirport.locationCode : '',
        })
      })
    } else {
      generateDSItems = 'error';
    }
    setState((_) => ({ ..._, generateDSItems: generateDSItems }))
  }
  const handleConfirmGeneratedDS = async () => {
    let body = {
      targetDate: state.generateDSForm.targetDate,
      warehouseId: state.generateDSForm.warehouseId,
      items: state.generateDSItems.filter((_) => !_.groupRow)
    }
    try {
      const res = await APIEndpoint.post('dfp/createDraftDfp', body);
      if (res.success) {
        handleClose();
      }
    } catch (error) {
      console.log('error', error);
      alert(error.data.message)
    }
  }

  return (
    <div className="dfp-draft op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      <div className="flexRow justifyContentFlexEnd alignItemsCenter margRight margBot10">
      </div>
      <DataGrid
        columnResize
        showDefaultFilters
        className='full-flex'
        columns={Columns}
        getRows={fetchDrafts}
        resetDataSource={state.resetDataSource}
        actions={[{ attribute: 'EDIT/DELETE' }, { attribute: 'VALIDATE' }]}
        RightComponent={(props) => {
          return (
            <div className='flexRow alignItemsCenter'>
             <button className="add-item-button margRight"
             onClick={() => {
              fetchWarehouses()
              setState((_) => ({
                ..._, showGenerateDialog: true,
                generateDSForm: { targetDate: state.filterDate, warehouse: '' }
              }))
            }}>
                {t('pages.estimationsContent.dfpDraft.generateDiningSchedule')}
             </button>
              <NGODatePicker
                value={state.filterDate}
                onChange={handleFilterDateChange}
              />
            </div>
          )
        }}
        getActionCellIcons={getActionCellIcons}
        onAction={handleAction}
      />
      {
        Boolean(state.showGenerateDialog) &&
        <Modal
          onClose={handleClose}
          className='hello'
          showDone={false}
          buttonLabel={t('confirm')}
          title={`${t('pages.estimationsContent.dfpDraft.dfp')} ${moment(state.filterDate).format("DD-MM-YYYY")}`}>
          <div className='flexCol' style={{ height: '60vh', width: '100%' }}>
            <div className='flexCol' style={{ width: '90%' }}>
              <form className='flexRow gdt-form' onSubmit={handlGenerateFormSubmit}>
                <Input
                  required
                  containerClassName='date-input-label'
                  label='Target Date'
                  type="date"
                  name='targetName'
                  isClearable={false}
                  value={state.generateDSForm.targetDate}
                  onChange={handleGenerateDSField}
                />
                <Input
                  required
                  containerClassName='warehouse-select'
                  label='Warehouse'
                  type="select"
                  name='warehouseId'
                  value={state.generateDSForm.warehouseId || ''}
                  options={(state.warehouses || [])}
                  onChange={handleGenerateDSField}
                />
                <div className='flexCol justifyContentCenter btn-generate'>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    disabled={
                      !Boolean(state.generateDSForm.targetDate && state.generateDSForm.warehouseId)
                    }>
                    <span>{t('generate')}</span>
                  </button>
                </div>
              </form>
            </div>
            {
              state.generateDSItems === 'wait' ?
                <div className='flexCol full-flex justifyContentCenter alignItemsCenter'>
                  <span>{t('pages.estimationsContent.dfpDraft.waitGeneratingDiningSchedule')}</span>
                </div>
                : state.generateDSItems === 'error' ?
                  <div className='flexCol full-flex justifyContentCenter alignItemsCenter'>
                    <span>{t('pages.estimationsContent.dfpDraft.errorGeneratingDiningSchedule')}</span>
                  </div>
                  : Array.isArray(state.generateDSItems) ?
                    <>
                      <div className='flexRow full-flex' style={{ marginTop: '24px' }}>
                        <DataGrid
                          columnResize
                          showDefaultFilters={false}
                          className='flexCol draft-grid'
                          rowModelType='clientSide'
                          columns={GenerateDSColumns}
                          rowData={state.generateDSItems || []}
                          isFullWidthRow={isGroudHeadRow}
                          fullWidthCellRenderer={DFPGroupRow}
                        />
                        <div className='flexCol subheading-text-small note-cont full-flex'>
                          <div className='flexCol  note justifyContentCenter alignItemsCenter'>
                            <span>{t("pages.estimationsContent.dfpDraft.noteAddMissingAndConfirm")}</span>
                          </div>
                        </div>
                      </div>
                      <div className='flexRow justifyContentFlexEnd'>
                        <button
                          type='button'
                          onClick={handleConfirmGeneratedDS}
                          className='btn btn-success'>
                          <span>{t('confirm')}</span>
                        </button>
                      </div>
                    </>
                    : null
            }
          </div>
        </Modal>
      }
       {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }
    </div>
  )
}
