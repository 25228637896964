import React,{useEffect,useRef,useState} from 'react'
import moment from 'moment';
import {getCultureFromLs} from '../../../../utils/commonFunctions'


export const  GeneralContainer=(props) =>{
  const containerRef = useRef();
  const [timeZone,setTimeZone]=useState("");

  useEffect(() => {
    if(!timeZone){
      fetchCulureApi();
    }
    const updateHeight = () => {
      const availableHeight = window.innerHeight * 0.55;
      if (containerRef.current) {
        containerRef.current.style.maxHeight = `${availableHeight}px`;
      }
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  async function fetchCulureApi() {
    try {
      const res = getCultureFromLs();
      if(res && res.timezone){
        setTimeZone(res.timezone.formattedName)
      }
    } catch (error) {
      console.log(error);
    }
  }


  const { instance,
    sectorIsolateData,inventoryDetails}=props.data;

    const getSectorStatus = (sec) => {
      switch (sec) {
        case 'open':
          return <div className="status-dynamic" style={{ backgroundColor: '#03a5fc' }}>Active</div>;
        case 'locked':
          return <div className="status-dynamic" style={{ backgroundColor: '#f03630' }}>Locked</div>;
          case 'cancelled':
          return <div className="status-dynamic" style={{ backgroundColor: '#f03630' }}>Cancelled</div>;
          case 'unknown':
          return <div className="status-dynamic" style={{ backgroundColor: '#1d3f4f' }}>Unknown</div>;
        case 'committed':
          return <div className="status-dynamic" style={{ backgroundColor: '#09ab16' }}>Committed</div>;
          case 'completed':
            return <div className="status-dynamic" style={{ backgroundColor: '#09ab16' }}>Completed</div>;
        case 'commitScheduled':
          return <div className="status-dynamic" style={{ backgroundColor: '#f28016' }}>Commit Scheduled</div>;
        default:
          return "";
      }
    };

  return (
<>
<div  ref={containerRef} className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark" style={{overflowY:'scroll' }}>
{instance&&sectorIsolateData?<div style={{float:'left'}} className="flexCol justifyContentFlexStart">
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Instance ID</label>
                  <div>{instance.name}</div>
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Time Zone</label>
                  <div>{timeZone}</div>
                </div>

                <div className='margBottom10'>
                  <label className='fontExtrabold'>Aircraft</label>
                  <div className='flexCol'>
                  <div className='flexRow'>
                  {instance?.aircraft?.aircraftNumber}
                    </div>
                    <div className='flexRow'>
                    {instance?.aircraft?.name}
                    </div>
                    </div>
                </div>


                <div className='margBottom10'>
                  <label className='fontExtrabold'>Start Date</label>
                  <div>{instance?.startDate?moment(instance?.startDate).format('DD-MM-YYYY'):'Not available'}</div>
                </div>


                {/* <div className='margBottom10'>
                  <label className='fontExtrabold'>Created Using</label>
                  <div className='flexCol'>
                  <div className='flexRow'>
                  {instance.userAgent.appName}@ {instance.userAgent.appVersion}
                    </div>
                    <div className='flexRow'>
                    {instance.userAgent.deviceModel},MAC ID:{instance.userAgent.macId}
                    </div>
                    </div>
                </div> */}
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Group Identifier</label>
                  <div>{sectorIsolateData.groupIdentifier??'None'}</div>
                </div>

                <div className='margBottom10'>
                  <label className='fontExtrabold'>Status</label>
                  <div>{getSectorStatus(instance.state)}</div>
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Created At</label>
                  <div>{sectorIsolateData.createdAt?moment(sectorIsolateData.createdAt).format('DD-MM-YYYY hh:mm A'):'Not available'}</div>
                </div>

                <div className='flexCol margBottom10'>
                  <label className='fontExtrabold'>Created By</label>
                  <div>{sectorIsolateData.createdBy?.employeeName}</div>
                  <div>{sectorIsolateData.createdBy?.employeeCode}</div>

                </div>

                <div className='margBottom10'>
                  <label className='fontExtrabold'>Created Using</label>
                  <div className='flexCol'>
                  <div className='flexRow'>
                  {sectorIsolateData.userAgent?.appName}@{sectorIsolateData.userAgent?.appVersion}
                    </div>
                    <div className='flexRow'>
                    {sectorIsolateData.userAgent?.deviceModel}@{sectorIsolateData.userAgent?.deviceName}
                    </div>
                    </div>
                </div>

                <div className='flexCol margBottom10'>
                  <label className='fontExtrabold'>Latest Inventory Transfer</label>
                  { sectorIsolateData&& sectorIsolateData?.transferReport?  <button  style={{ backgroundColor: '#03a5fc' }} className="status-dynamic dynamic-act" onClick={()=>inventoryDetails(sectorIsolateData,null,'transferOut',null,sectorIsolateData.transferReport?.sector)}>{sectorIsolateData?.transferReport?.sector?.name}</button>:<>None</>}

                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Locked At</label>
                  <div>{sectorIsolateData.lock?.createdAt?moment(sectorIsolateData.lock.createdAt).format('DD-MM-YYYY hh:mm A'):"None"}</div>

                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Locked By</label>
                  <div>{sectorIsolateData.lock?sectorIsolateData.lock.createdBy.employeeName:"None"}</div>

                </div>


              </div>:<></>}
</div>

</>
  )
}