import React from 'react';

function HeaderSelectAll(props) {
    const { selectAll, setSelectAll } = props;

    const onChangeSelectAll = (e) => {
        setSelectAll((_) => ({
            ..._,
            isChecked: !_.isChecked
        }));
    };

    return (
        < >

            <div className="ag-header-select-all">
            <div class="ag-header-component"/>
                <div className="selectAll-checkbox-size">
                        <input
                            id="checkboxMembers"
                            type="checkbox"
                            className="ag-checkbox-input"
                            onChange={onChangeSelectAll}
                            checked={selectAll.isChecked}
                        />
                    <label htmlFor={"checkboxMembers"} className='ag-header-cell-text margLeft8'>{selectAll?.label}</label>
                </div>
                </div>
            </>
    );
}

export default HeaderSelectAll;
