/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import searchIcon from '../../../assets/icons/search.svg';
import { DataGrid ,NGODatePicker} from "../../../common";
import { APIEndpoint } from "../../../utils/axios";
import { PageSizes } from "../../../utils/configs";
import moment from "moment";
const Columns = [
    {field: 'code', headerName: 'PO Code', minWidth: 165},
    {field: 'warehouses', headerName: 'Warehouse', minWidth: 219,
        tooltipField: 'warehouses'
    },
    {field: 'vendor', headerName: 'Vendor', minWidth: 165},
    {field: 'createdAt',valueGetter: (params) => {
        return moment(params.data?.createdAt).format('YYYY-MM-DD hh:mm A');
      }, headerName: 'Created At'},
    {field: 'sector',headerName: 'Sector', minWidth: 115},
    {field: 'unit',headerName: 'Unit', minWidth: 125},
    { field: 'items', headerName: 'Summary',
    minWidth: 150,
    valueGetter: params => params.data ? params.data?.items?.length + ' items' : ''
},

];

export const PerishablePurchaseOrders = (props) => {
  const { t } = useTranslation();
    const [warehouses, setWarehouses] = useState([]);
    const [wareHouseArr,setWareHouseArr]=useState([]);
    const [pageLimit, setPageLimit] = useState(10);
    const [searchKey, setSearchKey] = useState('');
    const [selWarehouse, setSelWarehouse] = useState('');
    const [selFiltersMessage, setSelFiltersMessage] = useState('');

    const preCompile = {
        warehouse: t('selectButtons.selWarehouse')
    }

    React.useEffect(() => {
        getWarehouses();
	}, []);

    React.useEffect(() => {
        getWarehousesDetails();
	}, [selWarehouse]);

    React.useEffect(() => {
        setSelFiltersMessage('Please select a warehouse')
        if( selWarehouse.length > 0) {
            setSelFiltersMessage('');
            fetchPerishablePurchaseOrderRows();
        }
	}, [selWarehouse]);



    const getWarehouses = async () => {
        try {
           const resp = await APIEndpoint.get('warehouses');
        if (resp.result.length > 0) {
            setWareHouseArr(resp.result);
            const options = [{key: 0, label: preCompile.warehouse, value: ''}];
            resp.result.filter( (fieldValue, index) => {
              options.push({key: index+1, label: `${fieldValue.value}`, value: `${fieldValue.value}`});
              return fieldValue;
            });
            setWarehouses(options);
        }
        } catch (error) {

        }

    }


    const filterData = {
        action: 4,
        collection: '',
        filterOperation: 'or',
        filters: [],
        limit: 10,
        page: 1,
        searchParam: ''
    };
    const fetchPerishablePurchaseOrderRows = async (page=1, perPage=10) => {
        if( selWarehouse.length > 0){
            filterData.page = page;
            filterData.limit = perPage;
            filterData.searchParam = searchKey;
            filterData.filters=[{ warehouseId: getWarehousesDetails()}]
            try {
            const res = await APIEndpoint.post('perishablePurchaseOrders/process', JSON.stringify(filterData));
            return res;
            } catch (error) {

            }


        }
        else{
            return {}
        }
    };

    const onPerLimitChange = (e) => {
        setPageLimit(Number(e.target.value));
    };

    const onSearch = (e) => {
        setSearchKey(e.target.value);
    };

    const onItemTypeChange = (e) => {
        setSelWarehouse(e.target.value);
    }

    const getWarehousesDetails = () =>{
        if(selWarehouse.length>0){
            const wareHs = wareHouseArr.find(w=>w.value===selWarehouse)
            return wareHs?.id;
        }
    };

    return (
        <>
            <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
                {
                    <>
                        <div className='flexRow width100 margBot10 margTop8 justifyContentSpaceBetween'>
                            <button className='page-button'>
                                <select className="darkBlue-bg" onChange={onPerLimitChange} value={pageLimit} id="page-size">
                                {
                                    PageSizes.map((_) => {
                                        return (
                                        <option key={_.value} value={_.value}>{_.label}</option>
                                        )
                                    })
                                }
                                </select>
                            </button>

                            <div className="flexRow  alignItemsBaseline">
                                <button className='item-button'>
                                <select className='select-input' onChange={onItemTypeChange} value={selWarehouse} id="item-type">
                                    {warehouses.map( (item, index) => (
                                        <option key={index} value={item.value}>{item.label}</option>
                                    ))
                                    }
                                </select>
                                </button>
                            </div>



                            <div className="margLeft margBot10 underline">
                                <span><img  className="icon-size" alt={t('filters.searchText')} src={searchIcon} /></span>
                                <span><input className="search" onChange={onSearch} type="search" placeholder={t('filters.searchText')} value={props.searchKey}/></span>
                            </div>
                        </div>

                        {selFiltersMessage ?
                        <>
                            <div className="selectFiltersBlock flexRow justifyContentSpaceEvenly alignItemsCenter">
                                <p className="alert_error">{selFiltersMessage}</p>
                            </div>
                        </> :
                        <>
                            <DataGrid
                                className="full-flex"
                                columns={Columns}
                                initialPageLimit={pageLimit}
                                showDefaultFilters={false}
                                searchKey={searchKey}
                                selWarehouse={selWarehouse}
                                getRows={fetchPerishablePurchaseOrderRows}
                                noResultsMessage={ "No indents found" }
                                rowSelection="multiple"
                                columnResize={true}
                            />
                        </>}

                    </>
                }
            </div>
        </>
    );
}