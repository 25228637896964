import React, { useEffect,useState } from "react";
import { useLocation, useNavigate,useParams } from "react-router-dom";
import { DEFAULT_SHEET_ROWS_PLIFERAGE } from "../../../utils/configs";
import currencyData from "../../../assets/json/currency.json";
import { useTranslation } from "react-i18next";
import { FaRegCheckSquare ,FaSave} from "react-icons/fa";
import { BiSolidSelectMultiple } from "react-icons/bi";
import ReactSpreadsheet from "react-spreadsheet";
import * as ReactDOM from "react-dom";
import close from "../../../images/icons/Icon2.png";
import { APIEndpoint } from "../../../utils/axios";
import { RiDeleteBin6Line } from "react-icons/ri";
import Currency from "../../../assets/json/currency.json"

const iIndexes = {
  C_CODE: 0,
  C_NAME: 1,
  C_DENOM: 2,
   VALUE:3,
  NET_AMOUNT:4,
};
const sheetLabels = ["Currency Code", "Currency Name", "Denomination","Value","Net Amount"];

const sheetColDefs = [
  { label: 'Currency Code', type: 'select', labelProp: 'code', attribute: 'currencyCode', required: true,options:Currency },
  { label: 'Currency Name', type: 'select', labelProp: 'currency', attribute: 'currencyName', required: true,options:Currency },
  { label: 'Denomination', type: 'number', labelProp: 'denomination', attribute: 'denomination', required: true },
  { label: 'Value', type: 'number', attribute: 'amount', required: true },
  { label: 'Net Amount', type: 'number', attribute: 'netAmount', required: true },
]

const defaultCellFieldData = {
  id: "id",
  value: "",
  defaultValue: "",
  className: "cellClass",
  width: "200px",
  readOnly: false,
  type: "string",
  required: true,
  showPopup: false,
};

const createEmptySheetRows = (
  addtionalRows = 0,
  noOfRows = DEFAULT_SHEET_ROWS_PLIFERAGE,
) => {
  const rows = [];
  if (addtionalRows > 0) {
    let i = 0;
    while (i < 10) {
      rows.push([
        {
          ...defaultCellFieldData,
          id: "currencyCode",
          className:
            "cellClass cellClass_" + addtionalRows + "_" + iIndexes.C_CODE,
          showPopup: true,
        },
        {
          ...defaultCellFieldData,
          id: "currencyName",
          className:
            "cellClass cellClass_" + addtionalRows + "_" + iIndexes.C_NAME,
          showPopup: true,
        },
        {
          ...defaultCellFieldData,
          id: "denomination",
          className:
            "cellClass cellClass_" + addtionalRows + "_" + iIndexes.C_DENOM,
        },
        {
          ...defaultCellFieldData,
          id: "value",
          className:
            "cellClass cellClass_" + addtionalRows + "_" + iIndexes.VALUE,
        },
        {
          ...defaultCellFieldData,
          id: "netAmount",
          className:
            "cellClass cellClass_" + addtionalRows + "_" + iIndexes.NET_AMOUNT,
          width: "400px",
          required: true,
        },
      ]);
      i++;
      addtionalRows++;
    }
  } else {
    for (var i = 0; i < noOfRows; i++) {
      rows.push([
        {
          ...defaultCellFieldData,
          id: "currencyCode",
          className:
            "cellClass cellClass_" + i + "_" + iIndexes.C_CODE,
          showPopup: true,
        },
        {
          ...defaultCellFieldData,
          id: "currencyName",
          className:
            "cellClass cellClass_" + i + "_" + iIndexes.C_NAME,
          showPopup: true,
        },
        {
          ...defaultCellFieldData,
          id: "denomination",
          className:
            "cellClass cellClass_" + i + "_" + iIndexes.C_DENOM,
        },
        {
          ...defaultCellFieldData,
          id: "value",
          className:
            "cellClass cellClass_" + i + "_" + iIndexes.VALUE,
        },
        {
          ...defaultCellFieldData,
          id: "netAmount",
          className:
            "cellClass cellClass_" + i + "_" + iIndexes.NET_AMOUNT,
          width: "400px",
        },
      ]);
    }
  }

  return rows;
};

const initialData = createEmptySheetRows(null, 10);

export const CashDepositCreate = () => {
  const { t } = useTranslation();
  const urlParams = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [activeCell, setActiveCell] = useState({});
  const [count, setCount] = useState(1);
  const [sheetData, setSheetData] = useState(initialData);
  const [spreadsheetErrors, setSpreadsheetErrors] = useState([]);
  const [message, setMessage] = useState(null);
  const [selectedCellsArr, setSelectedCellsArr] = useState([]);
  const [currencyState, setCurrencyState] = useState([...currencyData]);

  console.log(spreadsheetErrors)


  useEffect(()=>{
    if(selectedCellsArr.length > 1){
      hidePopUps()
    }
    if(selectedCellsArr.length == 0){
      hidePopUps()
    }
  },[selectedCellsArr])

  const addRowsToSheet = (rowsToAdd = 10) => {
    const newRows = createEmptySheetRows(sheetData.length, rowsToAdd);
    setSheetData([...sheetData, ...newRows]);
  };

  useEffect(() => {
    if(activeCell.column || activeCell.column === 0){
      const val = sheetData[activeCell.row][activeCell.column].value;
      if(activeCell.column === iIndexes.C_CODE){
        const currFiltered = currencyData.filter((data)=>{
          return data.code.toLocaleLowerCase().startsWith(val?.toLocaleLowerCase())
        })
        setCurrencyState([...currFiltered])
      }else if(activeCell.column === iIndexes.C_NAME){
        const currFiltered = currencyData.filter((data)=>{
          return data.currency.toLocaleLowerCase().startsWith(val?.toLocaleLowerCase())
        })
        setCurrencyState([...currFiltered])
      }
    }
  }, [sheetData])
  

  useEffect(() => {
    if (state.deposit && state.deposit.denominations.length>0) {
      setDataToSheet(state.deposit.denominations);
    }
  }, [state]);

  useEffect(() => {
    let timeOut = setTimeout(() => {
      if (spreadsheetErrors && spreadsheetErrors.length > 0) {
        setSpreadsheetErrors([]);
      }
    }, 5000);
    return () => {
      clearTimeout(timeOut);
    };
  }, [spreadsheetErrors]);

  const getTransferRow = (rowData) => {
    let row = [];
    sheetColDefs.forEach(({ attribute, readOnly }, i) => {
      let cellVal = { readOnly: readOnly || false, value: '',...rowData[i] }
      if (Array.isArray(rowData)) {
        cellVal.value = rowData[i].value;
        cellVal.error = rowData[i].error;
        if (!rowData[i].updated) {
          if (attribute === 'currencyCode' && rowData[1].value !== '') {
            let cellval = Currency.find((_) => _.currency === rowData[1].value)?.code;
            if(cellval){
              cellVal.value = cellval
              cellVal.error = false
            }else{
              cellVal.value = ""
              cellVal.error = false
            }
          }
          else if (attribute === 'currencyName' && rowData[0].value !== '') {
            let cellval = Currency.find((_) => _.code === rowData[0].value)?.currency
            if(cellval){
              cellVal.value = cellval
              cellVal.error = false
            }else{
              cellVal.value = ""
              cellVal.error = false
            }
          }
        }
      } else {
        cellVal.value = rowData[attribute] || ''
      }
      row.push(cellVal)
    });
    return row;
  }

  function calcTotal(data){
    let jsonSpreadsheetData = JSON.stringify( data );
    let SpreadsheetData = JSON.parse(jsonSpreadsheetData);
    const updatedSpreadsheetData = SpreadsheetData.map( (sheetRow, i) => {
        // if (i === activeCell.row) {
            sheetRow[iIndexes.NET_AMOUNT].value = Number(sheetRow[2].value)*Number(sheetRow[3].value)>0?Number(sheetRow[2].value)*Number(sheetRow[3].value):null
        // }
        return sheetRow;
    } );

    let updatedFormat = updatedSpreadsheetData.map((row) => {
      return getTransferRow(row)
    })
    setSheetData([...updatedFormat])
}

  function setDataToSheet(data) {

    const row = [];
    data.forEach((d, i) => {
      row.push([
        {
          ...defaultCellFieldData,
          id: "currencyCode",
          className:
            "cellClass cellClass_" + i + "_" + iIndexes.C_CODE,
          value:d.currencyCode,
          showPopup: true,
        },
        {
          ...defaultCellFieldData,
          id: "currencyName",
          value:d.currencyName,
          className:
            "cellClass cellClass_" + i + "_" + iIndexes.C_NAME,
          showPopup: true,
        },
        {
          ...defaultCellFieldData,
          id: "denomination",
          value:d.denomination,
          className:
            "cellClass cellClass_" + i + "_" + iIndexes.C_DENOM,
          required:true
        },
        {
          ...defaultCellFieldData,
          id: "value",
          value:d.value,
          className:
            "cellClass cellClass_" + i + "_" + iIndexes.VALUE,
          required:true
        },
        {
          ...defaultCellFieldData,
          id: "netAmount",
          value:d.netAmount,
          className:
            "cellClass cellClass_" + i + "_" + iIndexes.NET_AMOUNT,
          width: "400px",
          readOnly:true,
          required: true,
        },
      ]);
    });
    const newRows = createEmptySheetRows(row.length, 10);
    setSheetData([...row, ...newRows]);
  }

  function hidePopUps(type=""){
    setTimeout(() => {
      setCurrencyState([...currencyData])
    }, 200);
    const code = document.querySelector(".cellSelectionBlockCurrencyCodes");
    const name = document.querySelector(".cellSelectionBlockCurrencyNames");
    if(type === "name"){
      name.style.display = "none"
    }
    if(type === "code"){
      code.style.display = "none"
    }
    if(!type){
      name.style.display = "none"
      code.style.display = "none"
    }
  }

  const handleActiveCellChange = (activeCell) => {
    setActiveCell(activeCell);
    hidePopUps()
    const {row,column} = activeCell;
    if(sheetData[row][column]?.showPopup){
      if(selectedCellsArr.length > 1) return;
      const sourseElem = document.getElementsByClassName('cellClass_' + row + '_' + column)[0]
      const position = sourseElem.getBoundingClientRect();
      const code = document.querySelector(".cellSelectionBlockCurrencyCodes");
      const name = document.querySelector(".cellSelectionBlockCurrencyNames");
      if(column === iIndexes.C_CODE){
        hidePopUps("name")
        code.style.display = "block"
        code.style.top = (position.top+30)+"px";
        code.style.left = position.left+"px";
        code.style.width = position.width+"px";
      }else if(column === iIndexes.C_NAME){
        hidePopUps("code")
        name.style.display = "block"
        name.style.top = (position.top+30)+"px";
        name.style.left = position.left+"px";
        name.style.width = position.width+"px";
      }else{
        hidePopUps()
      }
    }else{
      hidePopUps()
    }
  };

  const handleCellSelect = (selectedCell) => {
    if (selectedCell.length !== 0) {
      setSelectedCellsArr([...selectedCell]);
    }
  };
  const checkSheetData=()=>{
    let data=false;
    sheetData?.map(upl=>{
        upl?.forEach((val,ind)=>{
            if(val&&val?.value){
                data=true
            }
        })

    })
    return data
}
  const goToModuleHome = () => {
    navigate("/cashRegister/cashDeposits",{state:{depositId:state.deposit.id}});
  };

  const setItemToSheet = (item) => {
    if (Object.keys(item).length === 0) {
      return 0;
    }

    let jsonSpreadsheetData = JSON.stringify(sheetData);
    let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData);

    updatedSpreadsheetData.map((sheetRow, i) => {
      if (i === activeCell.row) {
        sheetRow[iIndexes.C_CODE].value = item.code;
        sheetRow[iIndexes.C_NAME].value = item.currency;
      }

      return sheetRow;
    });

    ReactDOM.flushSync(() => {
      let newRows = createEmptySheetRows(null, 10);
      // setSheetData( [...sheetData,...newRows.slice(sheetData.length)]);

      setSheetData([
        ...updatedSpreadsheetData,
        ...newRows.slice(sheetData.length),
      ]);
    });
    hidePopUps()
    setActiveCell({});
  };

  const onClickPopupListItem = (e, row, type = "reason") => {
    setItemToSheet(row);
  };
  const selectAllCells = (e) => {
    setCount((prev)=>prev+1)
    let updatedSheetData=[]
    if(count%2!==0){
        updatedSheetData = sheetData.map((row, rowIndex) => {
            return row.map((cell, colIndex) => {
                return {
                    ...cell,
                    className: `selectcell-class cellClass cellClass_${rowIndex}_${colIndex}`
                };
            });
        });
    }else{
        updatedSheetData = sheetData.map((row, rowIndex) => {
            return row.map((cell, colIndex) => {
                return {
                    ...cell,
                    className: `cellClass cellClass_${rowIndex}_${colIndex}`
                };
            });
        });
    }

setSheetData(updatedSheetData);

};

function validateSheetData(){
  let error = false;
  const data = sheetData.map((row,rowI)=>{
    return row.map((col,colI)=>{
      if(col?.required && !col.value && row[0].value && row[1].value){
        error = true
        return {...col,className:col.className+" required"}
      }else{
        return {...col,className:`cellClass cellClass_${rowI}_${colI}`}
      }
    })
  })
  console.log(data)
  setSheetData([...data])
  return error
}
  const handleSaveTransfer = async () => {
    setMessage(null)
    const validationErr = validateSheetData()
    console.log(validationErr)
    if(validationErr){
      return;
    }
    try {
      const rowsFormatted = sheetData
        .map((row, i) => {
          return {
            currencyCode: row[0]["value"],
            currencyName: row[1]["value"],
            denomination: parseFloat(row[2]["value"]),
            value: parseFloat(row[3]["value"]),
            netAmount: +row[4]["value"],
            index: i,
          };
        })
        .filter((d) => d.currencyCode && d.currencyName);
      if (rowsFormatted.length === 0) {
        const error = {
          text: "We did not find anything to save!",
        };
        setSpreadsheetErrors([error]);
        return;
      }
      const body = { items: rowsFormatted ,depositId: state.deposit.id};

      const res = await APIEndpoint.post(
        "cashRegister/deposits/denominations",
        body,
      );
      setMessage({ type: "success", text: res.message });
      setTimeout(() => {
        navigate("/cashRegister/cashDeposits",{state:{depositId:state.deposit?.id}})
      }, 2000);
    } catch (error) {
      const errorObj = JSON.parse(error?.message);
      const err = {
        text: errorObj.data.message || errorObj.data?.errors.message,
      };
      let errArray = []
      if(errorObj.data?.errors?.errors?.length > 0){
        errorObj.data.errors.errors.forEach(error=>{
          errArray.push({text:`${error.message} in row ${+error.row+1}`})
        })
        const updatedSheet = sheetData.map((row,ri)=>{
          return row.map((col,ci)=>{
            const errI = errorObj.data?.errors?.errors.findIndex(e=>e.row==ri)
            if(errI !== -1 && ci==0){
              return {...col,className:`error cellClass cellClass_${ri}_${ci}`}
            }else{
              return {...col,className:`cellClass cellClass_${ri}_${ci}`}
            }
          })
        })
        console.log(updatedSheet)
        setSheetData([...updatedSheet])
      }else if(errorObj.data?.errors?.length > 0){
        errorObj.data.errors.forEach(error=>{
          errArray.push({text:`${error.message}`})
        })
      }
      setSpreadsheetErrors([err,...errArray]);
    }
  };

  function removeRow() {
    if(count%2===0){
      let updatedSheetData = initialData.map((row, rowIndex) => {
          return row.map((cell, colIndex) => {
              return {
                  ...cell,
                  className: `cellClass cellClass_${rowIndex}_${colIndex}`
              };
          });
      });
      setSheetData(updatedSheetData);
  }else{
    let jsonSpreadsheetData = JSON.stringify(sheetData);
    let inSheetData = JSON.parse(jsonSpreadsheetData);
    if (Object.keys(activeCell).length === 0) {
      inSheetData.pop();
      setSheetData([...inSheetData]);
    } else {
      let selectedRowIndex = activeCell.row;
      if (selectedRowIndex || selectedRowIndex === 0) {
        inSheetData.splice(selectedRowIndex, 1);
        let updatedSheetData = inSheetData.map((row, rowIndex) => {
          return row.map((cell, colIndex) => {
              return {
                  ...cell,
                  className: `cellClass cellClass_${rowIndex}_${colIndex}`
              };
          });
      });
        setSheetData([...updatedSheetData]);
      }
    }
  }

  hidePopUps()
  setSelectedCellsArr([])

  }

  return (
    <>
      <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
        <div className="sheet-container sm-scrollbar">
          <div className="header mb-2 flexRow justifyContentSpaceBetween">
          <div className="header m-2 mb-2 flexRow justifyContentSpaceBetween">
                        <div className="leftBlock alignItemsCenter">
                            <button onClick={ (e) => handleSaveTransfer(e) }
                                className="alignItemsCenter add-item-button"><FaSave style={{marginRight:'5px'}}/>{ t('buttons.save') }</button> &nbsp;
                           {checkSheetData()?<><button onClick={ (e) => selectAllCells(e) }
                                className="alignItemsCenter flexRow add-item-button-all m-3">{count%2===0?<BiSolidSelectMultiple  style={{marginRight:'5px'}} />:<FaRegCheckSquare  style={{marginRight:'5px'}} />}Select All</button>   <button onClick={ (e) => removeRow(e) }
                                className="alignItemsCenter flexRow remove-button"><RiDeleteBin6Line style={{marginRight:'5px'}} />{ t('buttons.deleteRows') }</button></>:<></> }&nbsp;
                        </div>
                        <div className="rightBlock alignItemsCenter">
                            {spreadsheetErrors.length >= 1 ? spreadsheetErrors[0].message : null}
                        </div>
                    </div>
            <div style={{ color: "#E70000" }} className="rightBlock alignItemsCenter">
              {spreadsheetErrors.length > 1
                ? spreadsheetErrors[0].text
                : null}
              {/* {spreadsheetErrors.length > 1 &&(
                <div className="flexCol">
                  {spreadsheetErrors.map((error) => {
                return <span style={{ color: "#E70000" }}>{error.text}</span>;
              })}
                </div>
              )} */}
              {message && (
              <span
                style={{ color: message.type === "success" ? "#fff" : "#E70000" }}
              >
               {message.text}
              </span>
              )}
            </div>
            <button onClick={goToModuleHome}>
              <img src={close} alt="close" />
            </button>
          </div>
          <div className="se-titlebar">{<span>{`${t("pages.cashRegisterContent.cashDeposits.editDonomination")}${state.details && state.details?.code}`}</span>}</div>

          <ReactSpreadsheet
            className='spread-sheet'
            data={sheetData}
            columnLabels={sheetLabels}
            darkMode={true}
            onChange={calcTotal}
            onActivate={handleActiveCellChange}
            // onBlur={handleCellBlur}
            // onKeyDown={handleCellKeyDown}
            // DataEditor={(p) => <SpreadsheetEditorC colDef={sheetColDefs[p.column]} {...p} />}
            onSelect={handleCellSelect}
            cellClassName="custom-cell"
            // onCellCommit={onCellCommitHandle}
          />
          <div className="flexRow alignItemsCenter margTop8">
            <button
              onClick={() => addRowsToSheet()}
              className="add-item-button"
              title={t("spreadsheet.addmoreRows")}
            >
              {t("buttons.addRows")}
            </button>
          </div>
        </div>
        <div className="cellSelectionBlockCurrencyCodes sm-scrollbar">
          {currencyState.length ? (
            <ul>
              {currencyState.length &&
                currencyState.map((row, i) => (
                  <li
                    key={i}
                    onClick={(e) => onClickPopupListItem(e, row, "item")}
                  >
                    {row.code}
                  </li>
                ))}
            </ul>
          ) : (
            <ul>
              <li>No Match Found!</li>
            </ul>
          )}
        </div>
        <div className="cellSelectionBlockCurrencyNames sm-scrollbar">
          {currencyState.length ? (
            <ul>
              {currencyState.length &&
                currencyState.map((row, i) => (
                  <li
                    key={i}
                    onClick={(e) => onClickPopupListItem(e, row, "item")}
                  >
                    {row.currency}
                  </li>
                ))}
            </ul>
          ) : (
            <ul>
              <li>No Match Found!</li>
            </ul>
          )}
        </div>
      </div>
    </>
  );
};