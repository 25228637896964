import React, { useEffect, useState } from 'react'
import { DataGrid, NGODatePicker } from '../../../common';
import editIcon2 from "../../../assets/edit2.svg"
import { DFPAPIEndpointGalley } from '../../../utils/axios';
import { useTranslation } from 'react-i18next';

// import { PageSizes } from '../../../utils/configs';
import { debounce, formatDateToYYYYMMDD ,translationLength} from '../../../utils/commonFunctions';
// import closeIcon from "../../../images/icons/Icon2.png"
import usePermissions from '../../../utils/userPermissions';
const Columns = [
  {field: 'key', headerName: 'Key'},
  {field: 'date', headerName: 'Date',valueFormatter:params=>params.value?.split('T')[0].split("-").reverse().join("/")},
  {field: 'warehouse', headerName: 'Warehouse'},
  {field: 'caterer', headerName: 'Caterer'},
  {field: 'menuid', headerName: 'Menu ID'},
]

const keyArr = ["DELTAJ", "DELASC", "DELCFC", "BOMTAJ", "BOMASC", "BOMCFC",    "MAATAJ", "MAACAFS", "BLRTAJ", "CCUTAJ", "HYDCAFS", "COKCAFS", "PNQCAFS", "GOITAJ", "GOXTAJ", "AMDCAFS", "TRVUSK", "IXEOPRL", "ATQTAJ"]
const wHArry = ["DEL", "DEL", "DEL", "BOM", "BOM", "BOM", "MAA", "MAA", "BLR", "CCU", "HYD", "COK","PNQ", "GOI", "GOX", "AMD", "TRV", "IXE", "ATQ"]
const catererArr = [ "TAJ", "ASC", "CFC", "TAJ", "ASC", "CFC", "TAJ", "CAFS", "TAJ", "TAJ", "CAFS", "CAFS",     "CAFS", "TAJ", "TAJ", "CAFS", "USK", "OPRL", "TAJ"]


const actionColumnProperties = {
  pinned: "right",
  minWidth: 80,
  width: 300,
  autoHeight: true,
  tooltipField: "Edit",
};

export const MealRotation = () => {
  const [actionItems] = useState([{
    title: "Actions",
    attribute: "edit",
  }])
  const { t } = useTranslation();
  const [filters, setFilters] = useState({})
  const [isReloadTable, setIsReloadTable] = useState(false)
  const [createMenuRotation, setCreateMenuRotation] = useState(false)
  const [rotationCreateDates, setRotationCreateDates] = useState([])
  const [rotationData, setRotationData] = useState({
    mealNumber:4,
    repeat:3
  })
  const [rDataExist, setRDataExist] = useState(false)
  const { hasPermission: createMealRotation } = usePermissions('create', "configuration.fsc.meal-rotation");
  const { hasPermission: editMealRotation } = usePermissions('edit', "configuration.fsc.meal-rotation");


  useEffect(() => {
    // Get today's date
    const today = new Date();

    // Calculate one month ahead
    const oneMonthAhead = new Date(today);
    oneMonthAhead.setMonth(oneMonthAhead.getMonth() + 1);

    // Generate dates array
    const datesArray = getDates(today, oneMonthAhead);
    setRotationCreateDates([...datesArray])
  }, [])


  async function fetchMealRotationData() {
    try {
      const filterParams = objectToUrlParams(filters)
      const resp = await DFPAPIEndpointGalley.get("galley/ai/menu-rotation/configs?"+filterParams)
      if(resp.status){
        const {data:{menuRotationPolicy}} = resp
        const formatted = menuRotationPolicy.map(data=>{
          return {
            key:data.key,
            date:data.date,
            warehouse:data.warehouse.name,
            caterer:data.caterer.name,
            menuid:data.menuId
          }
        })
        const response = {
          count:formatted.length,
          items:[...formatted]
        }
        return response
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getActionCellIcons = (cellDef, data) => {
    if (cellDef.attribute === "edit" && editMealRotation) {
      return [{ src: editIcon2, value: "edit", title: "Edit" }];
    }

    return [];
  };

  const filterChange = debounce((e)=>handleFilterChange(e), 400)

  const handleFilterChange = (e)=>{
     const {name,value} = e.target
     if(value){
       setFilters(prev=>{
          return {...prev,[name]:value}
       })
     }else{
       setFilters(prev=>{
          delete prev[name]
          return {...prev}
       })
     }
     setIsReloadTable(!isReloadTable)
  }

  function objectToUrlParams(obj) {
    return Object.keys(obj).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(key==="date"?formatDateToYYYYMMDD(obj[key]):obj[key])}`).join('&');
  }

  function getDates(startDate, endDate) {
    const dates = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        dates.push(new Date(currentDate).toLocaleDateString());
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }

  function onChangeRotInp(e) {
    const key = e.target.dataset.key
    const selectedDate = e.target.dataset.date
    const index = e.target.dataset.index
    const value = +e.target.value

    if(value > 4 || value <= 0){
      alert("Invalid input")
      e.target.value = ""
      return;
    }else if(isNaN(value)){
      alert("Input must be a number")
      e.target.value = ""
      return;
    }

    setRDataExist(true)

    if(index === 0){
      autoFillDown(key,selectedDate,index,value)
    }else{
      autoFillDown(key,selectedDate,index,value)
      autoFillUp(key,selectedDate,index,value)
    }
  }

  function autoFillUp(key,selectedDate,index,value) {
    let inc = 0
    let val = value-1
    // let ind = index
    for (let i = index-1; i >= 0; i--) {
      if(inc === rotationData.repeat){
        val = val-1
        inc = 0
      }
      if(val === 0){
        val = rotationData.mealNumber
      }
      const inp = document.getElementById(key+"-"+i)
      inp.value = val
      inc = inc+1
    }
  }

  function autoFillDown(key,selectedDate,index,value) {
    let inc = 0
    let val = +value
    // let ind = index
    for (let i = index; i !== -1; i++) {
      console.log(inc,"inc",rotationData.repeat)
      if(inc === rotationData.repeat){
        val = val+1
        inc = 0
      }
      if(val === rotationData.mealNumber+1){
        val = 1
      }
      const inp = document.getElementById(key+"-"+i)
      if(!inp) break;
      inp.value = val
      inc = inc+1
    }
  }

  function rotationDataChange(e) {
    const {name,value} = e.target
    if(+value < 1) return;
    if(rDataExist){
      const confirm = window.confirm('This will reset all inputs. Do you want to continue?')
      if(confirm){
        setRotationData(prev=>{
          return {...prev,[name]:+value}
        })
        resetRotationData()
      }else{
        e.target.value = rotationData[name]
      }
    }else{
      setRotationData(prev=>{
        return {...prev,[name]:+value}
      })
    }

  }

  function resetRotationData() {
    const rotationDataInputs = document.querySelectorAll(".rotationDataInp")
    rotationDataInputs.forEach(elem=>{
      elem.value = ""
    })
    setRDataExist(false)
  }

  if(createMenuRotation){
    return (
        <>
        <div className='flexRow margTop8 margBot10 justifyContentSpaceBetween alignItemsCenter'>
          <div className='flexRow'>
          <div className='margRight10'>
            <label htmlFor="mealNumber">Number of Meal&nbsp;&nbsp;</label>
            <input className='edit-input' style={{padding:"0.5rem"}}
            onChange={rotationDataChange}
            name='mealNumber'
            type="number"
            value={rotationData.mealNumber} />
          </div>
          <div className='margRight10'>
            <label htmlFor="mealNumber">Repeat&nbsp;&nbsp;</label>
            <input className='edit-input' style={{padding:"0.5rem"}}
            onChange={rotationDataChange}
            name='repeat'
            type="number"
            value={rotationData.repeat} />
          </div>
          </div>
          <button style={{marginRight:"15px"}} className="add-item-button" onClick={()=>setCreateMenuRotation(!createMenuRotation)}>
            + Save
          </button>
          {/* <button onClick={()=>setCreateMenuRotation(!createMenuRotation)}>
            <img src={closeIcon} alt="close" />
          </button> */}
        </div>
        <div className='margBot10 margTop8' style={{overflow:"auto",height:"70vh",width:"100%",paddingBottom:"10px"}}>
           <table className='mealRotationTable'>
            <thead style={{position:"sticky",top:0,backgroundColor:"#000"}}>
              <tr>
                <th style={{width:"5%"}}>
                  <div style={{borderBottom:"none",borderLeft:"none",borderRight:"none"}} className='headCell'>Key</div>
                </th>
                {keyArr.map((key)=>(
                  <th style={{width:"5%"}}>
                  <div style={{borderBottom:"none",borderRight:"none"}} title={key} className='headCell'>{key.slice(0,4)}</div>
                  </th>
                ))}
              </tr>
              <tr>
                <th>
                 <div style={{borderBottom:"none",borderLeft:"none",borderRight:"none",width:"auto"}} className='headCell'>Warehouse</div>
                </th>
                {wHArry.map((wh)=>(
                  <th style={{width:"5%"}}>
                  <div style={{borderBottom:"none",borderRight:"none"}} className='headCell'>{wh}</div>
                  </th>
                ))}
              </tr>
              <tr>
                <th>
                 <div style={{borderLeft:"none",borderRight:"none"}} className='headCell'>Caterer</div>
                </th>
                {catererArr.map((cr)=>(
                  <th>
                  <div style={{borderRight:"none"}} className='headCell'>{cr}</div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rotationCreateDates.map((date,i)=>{
                return (
                  <tr key={i}>
                    <td>{date}</td>
                    {keyArr.map((key)=>{
                      return (
                        <td style={{padding:"8px",paddingTop:"2px",paddingBottom:"2px"}} key={key}>
                          <input
                          onChange={onChangeRotInp}
                          data-index={i}
                          id={`${key}-${i}`}
                          type="text"
                          data-key={key}
                          data-date={date}
                          className={`edit-input rotationDataInp ${key}-${i}`}
                          style={{width:"43px",height:"30px",margin:"0.3rem 0rem",alignSelf:"center"}}
                          />
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
           </table>
        </div>
        </>
    )
  }


  return (
    <div className="flexCol full-flex ag-theme-alpine-dark">
       <div className='flexRow margBot10 margTop8 justifyContentSpaceBetween alignItemsCenter'>
          <div className='flexRow alignItemsCenter'>
              {/* filters */}
              <div className='margRight10'>
                <select
                  onChange={filterChange}
                  className="select-item darkBlue-bg"
                  id="sectorType"
                  name='sectorType'
                >
                  <option value="">Sector Type</option>
                  <option value="int">Int</option>
                  <option value="dom">Dom</option>
                  <option value="vip">VIP</option>
                </select>
              </div>
              <div className='margRight10'>
                <input
                onChange={filterChange}
                className='edit-input'
                name='catererCode'
                type="text"
                placeholder='Caterer' />
              </div>
              <div className='margRight10'>
                <input
                onChange={filterChange}
                className='edit-input'
                name='warehouseCode'
                type="text"
                placeholder='Warehouse' />
              </div>
              <div className='margRight10'>
                <input
                onChange={filterChange}
                className='edit-input'
                name='menuId'
                type="text"
                placeholder='Menu ID' />
              </div>
              <div className='margRight10'>
                <NGODatePicker
                  className="orderByDateInp"
                  value={filters?.date || null}
                  name="date"
                  placeholder={"Date"}
                  onChange={filterChange}
                  dateformat="dd-mm-yyyy"
                />
              </div>
            {/* filters */}
          </div>
        { createMealRotation? <div className="flexRow justifyContentFlexEnd alignItemsBaseline">
             <button onClick={()=>setCreateMenuRotation(!createMenuRotation)} className="add-item-button">+ Create</button>
          </div>:<></>}
        </div>

        <DataGrid
            className="full-flex"
            columns={Columns}
            initialPageLimit={10}
            showDefaultFilters={false}
            actionProperties={actionColumnProperties}
            noResultsMessage={"No Data"}
            rowSelection="multiple"
            columnResize={true}
            actions={actionItems}
            getActionCellIcons={getActionCellIcons}
            getRows={fetchMealRotationData}
            resetDataSource={isReloadTable}
          />
           {
      t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
      }
    </div>
  )
}