/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState,useEffect } from 'react'
import '../assets/styles/modal.scss'
import moment from 'moment';
import closeIcon from '../images/icons/Icon2.png'
import { formatDateByTimezone } from "../utils/commonFunctions";
import { MdPrint } from "react-icons/md";
import { APIEndpoint } from '../utils/axios';

export const Code = (props) => {
  return (
      <div className="overlay" role="dialog">
      <div className="dialog sm-scrollbar">
        <div className="dialog__content">
          <img className="modal__close_icon" alt='Close' src={closeIcon} onClick={props.onClose} />
          <h2 className="dialog__title subheading-text-medium fontMedium">{props.title}</h2>
          <hr />
         {props.data.success?<div id="print_content" className="dialog__footer flexRow justifyContentSpaceBetween flexWrap">
              <table>
                <tr style={{backgroundColor:'#1d3e4e'}}>
                  <td>Base</td>
                  <td>{props.data?.clusterName}</td>
                </tr>
                <tr>
                  <td>Sector</td>
                  <td>{props.data?.storeName}</td>
                </tr>
                <tr style={{backgroundColor:'#1d3e4e'}}>
                  <td>Date</td>
                  <td>{props.data?.flightDate}</td>
                </tr>
              </table>
              <div className="dialog__footer qr_code">
                <img alt='qrCode' src={props.data?.qrCode} />
              </div>
          </div>:<div className="flexRow justifyContentCenter alignItemsCenter fontMedium">
             {props.errorMessage}
          </div>}
        </div>
     {props.data.success?  <div className="dialog__content flexRow justifyContentFlexStart">
          <button className="done-button" onClick={props.onPrint}><MdPrint size={15} style={{marginRight:'5px'}} />Print</button>
        </div>:<></>}
      </div>
    </div>
  );
}
export const QRCodeModal = (props) => {
  const [data, setData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");


  useEffect(() => {
    if(props.fields){
      getQRCode();
    }
  }, [props])

  const getQRCode = async () => {
    const {storeId,id} =props?.fields;
      try {
        const resp = await APIEndpoint.get('uplifts/qrCode/'+storeId+'/'+id);
        if(resp.success){
          setData(resp);
        }
      } catch (error) {
        setErrorMessage(error.data?.message||'Uplift not confirmed yet.')
      }

  };

  const onClose = (e) => {
    removeContainerOverlay();
    props.onClose();
  }

  const onPrint = () => {
    window.print();

  };


  const removeContainerOverlay = () => {
    /**
     * Remove overlay to parent class
     */
    (document.querySelectorAll('.content-container')[0]).classList.remove('modal-overlay-bg2');
  };

  return (
    <Code
      title={props.title}
      data ={data}
      errorMessage={errorMessage}
      onClose={onClose}
      onPrint={onPrint}
      >
    </Code>
  )
}