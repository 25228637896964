/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect,useRef } from "react";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Spreadsheet from "react-spreadsheet"; // , { createEmptyMatrix }
import closeIcon from "../../../images/icons/Icon2.png"
import { APIEndpoint } from "../../../utils/axios";
import { useTranslation } from "react-i18next";
import * as ReactDOM from 'react-dom';
import {AlertModal } from "../../../common";
import { FaRegCheckSquare ,FaSave} from "react-icons/fa";
import { BiSolidSelectMultiple } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { updateButtonTxtStatus, validateCellAndGetData } from "../../../utils/commonFunctions";
import { DEFAULT_SHEET_ROWS } from "../../../utils/configs";

/**
 * Integration pending
 */
// function getModeOfReturns() {
//     return [
//         { key: 'Credit Note', value: 'Credit Note' },
//         { key: 'Delivery Challan', value: 'Delivery Challan' } 
//     ];
// };

const iIndexes = {
    ITEM_CODE: 0,
    ITEM_NAME: 1,
    ITEM_UNIT: 2,
    ITEM_REQ_QTY: 3,
    ITEM_RECI_QTY: 4,
    ITEM_RETURNED_QTY: 5,
    ITEM_REVERSE_GRN: 6,
    ITEM_MODE_OF_RETURN: 7,
    ITEM_REASON: 8
};
const sheetLabels = ['Item Code', 'Item Name', 'Unit',
    'Requested Quantity', 'Received Quantity', 'Returned Quantity', 'Reverse GRN',
    'Mode of Return', 'Reason'];

const createEmptySheetRows = (noOfRows = DEFAULT_SHEET_ROWS) => {
    const rows = [];
    for( var i=0; i < noOfRows; i++) {
        rows.push([
            {
                id: 'itemCode',
                value: '',
                defaultValue: '',
                className: 'cellClass1',
                width: '200px',
                readOnly: true,
                type: 'string',
                required: true
            },
            {
                id: 'itemName',
                value: '',
                defaultValue: '',
                className: 'cellClass1',
                width: '200px',
                readOnly: true,
                type: 'string',
                required: true
            },
            {
                id: 'unit',
                value: '',
                defaultValue: '',
                className: 'cellClass1',
                width: '400px',
                readOnly: true,
                type: 'string',
                required: true
            },
            {
                id: 'requestedQuantity',
                value: '',
                defaultValue: '',
                className: 'cellClass1',
                width: '200px',
                readOnly: true,
                type: 'number',
            },
            {
                id: 'receivedQuantity',
                value: '',
                defaultValue: '',
                className: 'cellClass1',
                width: '200px',
                readOnly: true,
                type: 'number'
            },
            {
                id: 'returnedQuantity',
                value: '',
                defaultValue: '',
                className: 'cellClass1',
                width: '200px',
                readOnly: true,
                type: 'number'
            },
            {
                id: 'reverseGRN',
                value: '',
                defaultValue: '',
                className: 'cellClass1',
                width: '200px',
                type: 'number',
                readOnly:false,
                required: true
            },
            {
                id: 'modeOfReturn',
                value: '',
                defaultValue: '',
                type:'string',
                showPopup:true,
                className: 'cellClass cellClass_' + i + '_' + iIndexes.ITEM_MODE_OF_RETURN,
                width: '200px',
                readOnly:false,
                required: true
            },
            {
                id: 'reason',
                value: '',
                defaultValue: '',
                readOnly:false,
                className: 'cellClass cellClass_' + i + '_' + iIndexes.ITEM_REASON,
                width: '200px',
                required: true
            }
        ]);
    }
    return rows;
}

/**
 * Automatically creating rows
 */
// const initialData = createEmptyMatrix(10, sheetLabels.length);
/**
 * Manually creating rows
 */
 const initialData = [];// createEmptySheetRows(DEFAULT_SHEET_ROWS - 1);

export const ReverseGRNCreate = (props) => {
    const urlParams = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const {state:rGrncreate}=useLocation();
    const modRetRef=useRef(null);
    const [reverseGRNDetails, setReverseGRNDetails] = useState({});
    const [state, setState] = useState({ isInvalidAccess: false, message: '' });
    const [count, setCount] = useState(1);
    const [cellChange,setCellChange]=useState({});
    const [sheetData, setSheetData] = useState(initialData);
    const [spreadsheetErrors, setSpreadsheetErrors] = useState([]);
    const modelStyle = { maxWidth: '50%', margin: 'auto 10% 100% 10%' };
    const [activeCell, setActiveCell] = useState({});
    const [selectedCellsArr, setSelectedCellsArr] = useState([]);
    const targetEltCodes = document.getElementsByClassName("cellSelectionBlockItemCodes")[0];

    useEffect( () => {
        if ( urlParams.hasOwnProperty('grnId') ) {
            getLOIDetailsById(urlParams.grnId);
        }
    }, [ urlParams ]);
    const returnMode=[{
        "id": 1,
        "modeOfReturn": "Credit Note",
    },{
        "id": 2,
        "modeOfReturn": "Delivery Challan",
    }]

    const errorFields=[{id:1,label:'Error',message:'Quanatity cannot be greater than the balance'},{id:2,label:'Error',message:'Field is required'}]

    useEffect(() => {
        const addErrorDiv = (cells, message) => {
          cells.forEach((cell) => {
            if (!cell.querySelector(".sheetErrDiv")) {
              const errorDiv = document.createElement("div");
              errorDiv.classList.add("sheetErrDiv");
              errorDiv.textContent = message;
              cell.appendChild(errorDiv);
            }
          });
        };
      
        const timeout = setTimeout(() => {
          const errorCells = document.querySelectorAll(".errorCellData");
          const errorCellGRN = document.querySelectorAll(".errorCellGRNrev");
      
          if (errorCells.length) {
            addErrorDiv(errorCells, errorFields[0]?.message);
          }
          if (errorCellGRN.length) {
            addErrorDiv(errorCellGRN, errorFields[1]?.message);
          }
        }, 100);
      
        return () => clearTimeout(timeout);
      }, [ activeCell]);
      

      useEffect(() => {
        if(!Object.keys(cellChange).length)return;
        const timeout = setTimeout(() => {

            let jsonSpreadsheetData = JSON.stringify(sheetData);
            let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData);
         
            const updatedSheetData = updatedSpreadsheetData.map((row, rowIndex) => {
                return row.map((cell, cellIndex) => {
                  if (rowIndex === activeCell.row) {
                    const errDiv = document.querySelector(`.Spreadsheet__cell.cellClass.cellClass_${activeCell.row}_${activeCell.column} .sheetErrDiv`);
                    const errDivSheet = document.querySelectorAll(`.sheetErrDiv`);
                    if (errDiv !== null) {
                        errDiv.remove(); 
                    }
                    
                    if (errDivSheet && errDivSheet.length) {
                        errDivSheet.forEach(el => el.remove());
                    }
                    
                    if ((cell.id === "modeOfReturn" || cell.id === "reason") && cell.className === cellChange.className && cell.className.includes('errorCellGRNrev')) {
               
                      const updatedClassName = cell.className.split(" ").filter(className => className !== "errorCellGRNrev").join(" ");
              
                      return {
                        ...cell,
                        className: updatedClassName, 
                      };
                    }
                  }
              
                  return { ...cell };
                });
              });
              setSheetData([...updatedSheetData]);
              setCellChange({});
        }, 100);
      
        return () => clearTimeout(timeout);
      }, [cellChange]);




    const getLOIDetailsById = (grnId) => {
        APIEndpoint.get('purchaseOrders/grns/findById/' + grnId)
        .then( resp => {
            if (resp) {
                const lOIDetails = resp.data;
                setReverseGRNDetails(lOIDetails);
            }
        })
        .catch( error => {
            console.log('Invalid page access!, Reverse GRN details not found for given ID.', error.message);

            setState({ ...state, isInvalidAccess: true,
                message: "Invalid page access!, GRN details are not found, On click OK, you will be redirected to Purchase Orders page." });
        });
    };

    useEffect( () => {
        if (Object.keys(reverseGRNDetails).length > 0) {
            setReverseGRNItemsToSheet(reverseGRNDetails);
        }
    }, [reverseGRNDetails]);

    const setReverseGRNItemsToSheet = (reverseGRNDetails) => {
        if (Object.keys(reverseGRNDetails).length === 0) {
            return 0;
        }
        let updatedSpreadsheetData = Object.assign([], sheetData);
        const items = reverseGRNDetails.items;
        const itmLngth = items.length;
        /**
         * Validate whether required items already plotted
         */
        let newRows = [];
        if ( items && itmLngth > 0 && updatedSpreadsheetData.length < itmLngth ) {

            let newlyRequiredRows = itmLngth - updatedSpreadsheetData.length;
            if (newlyRequiredRows < DEFAULT_SHEET_ROWS) {
                newlyRequiredRows = DEFAULT_SHEET_ROWS;
            }
            newRows = createEmptySheetRows(newlyRequiredRows);

        } else {
            newRows = createEmptySheetRows(DEFAULT_SHEET_ROWS);
        }

        updatedSpreadsheetData = [ ...newRows ];

        updatedSpreadsheetData.forEach( (sheetRow, i) => {
            if ( items[i] ) {
                updatedSpreadsheetData[i][iIndexes.ITEM_CODE].value = items[i].itemCode;
                updatedSpreadsheetData[i][iIndexes.ITEM_NAME].value = items[i].itemName;
                updatedSpreadsheetData[i][iIndexes.ITEM_UNIT].value = items[i].unit;
                updatedSpreadsheetData[i][iIndexes.ITEM_REQ_QTY].value = items[i].requestedQuantity;
                updatedSpreadsheetData[i][iIndexes.ITEM_RECI_QTY].value = items[i].receivedQuantity;
                updatedSpreadsheetData[i][iIndexes.ITEM_RETURNED_QTY].value = items[i].returnedQuantity;
                updatedSpreadsheetData[i][iIndexes.ITEM_REVERSE_GRN].value = 0;
                updatedSpreadsheetData[i][iIndexes.ITEM_MODE_OF_RETURN].value = items[i].modeOfReturn;
                updatedSpreadsheetData[i][iIndexes.ITEM_REASON].value = items[i].reason ||'';
                // sheetRef.setCellData(updatedSpreadsheetData[i]);
            }

        });

        // const updatedSpreadsheetData = sheetData.map( (row) => {
        //     const nextRow = [ ...row ];
        //     // nextRow.length = 10;
        //     nextRow.value = ;
        //     return nextRow;
        // } );

        setSheetData( [ ...updatedSpreadsheetData ]);

    };


    //     if(Object.keys(activeCell).length>0){

    //     let jsonSpreadsheetData = JSON.stringify( sheetData );
    //     let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData);
    //     const updatedSheetData = updatedSpreadsheetData.map((row, rowIndex) => {
    //         return row.map((cell, cellIndex) => {
               
    //             if (rowIndex === activeCell.row) {
        
    //                 const reverseGRNValue = cell.id === 'reverseGRN' ? cell.value : "";
    //                 const receiQtyValue = cell.id === 'receivedQuantity' ? cell.value : "";
        
    //                 const reverseGRNParsed = reverseGRNValue ? parseInt(reverseGRNValue, 10) : NaN;
    //                 const receiQtyParsed = receiQtyValue ? parseInt(receiQtyValue, 10) : NaN;
    //     // console.log(cell)
    //      console.log(reverseGRNParsed)
    //      console.log(receiQtyParsed)


        
    //                 if (!isNaN(reverseGRNParsed) && !isNaN(receiQtyParsed) && reverseGRNParsed > receiQtyParsed) {
        
             
    //                     if (cell.id === 'reverseGRN') {
    //                         return {
    //                             ...cell,
    //                             className: `errorCellData cellClass cellClass1`,
    //                         };
    //                     }
    //                 }
    //             }
        
    //             return { ...cell };
    //         });
    //     });
        
    //     setSheetData(updatedSheetData);
        
    //     }

    // }, [activeCell]);


    const addRowsToSheet = (rowsToAdd = 10) => {
        const newRows = createEmptySheetRows(rowsToAdd);
        setSheetData( [...sheetData, ...newRows ]);
    };

    const checkSheetData=()=>{
        let data=false;
        sheetData?.map(upl=>{
            upl?.forEach((val,ind)=>{
                if(val&&val?.value){
                    data=true
                }
            })
    
        })
        return data
    }
    const selectAllCells = (e) => {
        setCount((prev)=>prev+1)
        let updatedSheetData=[]
        if(count%2!==0){
            updatedSheetData = sheetData.map((row, rowIndex) => {
                return row.map((cell, colIndex) => {
                    return {
                        ...cell,
                        className: `selectcell-class cellClass cellClass_${rowIndex}_${colIndex}`
                    };
                });
            });
        }else{
            updatedSheetData = sheetData.map((row, rowIndex) => {
                return row.map((cell, colIndex) => {
                    return {
                        ...cell,
                        className: `cellClass cellClass_${rowIndex}_${colIndex}`
                    };
                });
            });
        }
    
    setSheetData(updatedSheetData);
    
    };

    const removeRow = (e) => {
        if(count%2===0){
            let updatedSheetData = initialData.map((row, rowIndex) => {
                return row.map((cell, colIndex) => {
                    return {
                        ...cell,
                        className: `cellClass cellClass_${rowIndex}_${colIndex}`
                    };
                });
            });
            setSheetData(updatedSheetData);
        }else{
        let jsonSpreadsheetData = JSON.stringify( sheetData );
        let inSheetData = JSON.parse(jsonSpreadsheetData);
        let selectedRowIndex = activeCell.row
        if(selectedRowIndex>=0){
            inSheetData.splice(selectedRowIndex,1)
        }else{
            inSheetData.pop()
        }

        /**
         * Remove a last row from the sheet
         */
        // setSheetData( (data) => {
        //     return data.slice(0, data.length -1)
        // });

        /**
         * Clear the content of the cell content
         */
        // console.log('selectedCellsArr:', selectedCellsArr);
        selectedCellsArr.map( selectedCell => {
            const defaultVal = inSheetData[selectedCell.row][selectedCell.column].defaultValue;

            inSheetData[selectedCell.row][selectedCell.column].value = defaultVal || '';

            return selectedCell;
        });

        ReactDOM.flushSync( () => {
            setSheetData([ ...inSheetData ]);
        });

		setSelectedCellsArr([]);
    }
    };

    const showValidationErrors = (e, inSheetData, errorClass='cellDataErrorCls' ) => {
        let isRowHaveError = false;
        const postItems = [];
       const saveData= inSheetData.map( (cellRow, rowIdx) => {
            

            const itemCode = validateCellAndGetData(cellRow[iIndexes.ITEM_CODE]);
            const itemName = validateCellAndGetData(cellRow[iIndexes.ITEM_NAME]);

            if (!itemCode || !itemName ) {
                return cellRow;
            }
            cellRow=cellRow.map((cell,celInd)=>{
            if ( cell && cell?.required === true && cell?.readOnly === false ) {    
                if(cell.id==='modeOfReturn' && (!cell.value||cell.value===undefined) && !cell.className.includes('errorCellGRNrev')){
                    isRowHaveError=true;
                    return{...cell,className:`errorCellGRNrev ${cell.className}`}
                }else{
                    isRowHaveError=false;

                }
                if(cell.id==='reason' &&(!cell.value||cell.value===undefined) && !cell.className.includes('errorCellGRNrev')){
                    isRowHaveError=true;
                    return{...cell,className:`errorCellGRNrev ${cell.className}`}
                }else{
                    isRowHaveError=false;
                }
            }
            return cell;
            }

            )
            if ( !isRowHaveError ) {

                const item = reverseGRNDetails.items.find( row => row.itemCode === cellRow[iIndexes.ITEM_CODE].value );
                if (item) {
                    postItems.push({
                        _id: item._id,
                        index: rowIdx,
                        itemId: item.itemId,
                        itemCode: cellRow[iIndexes.ITEM_CODE].value,
                        itemName: cellRow[iIndexes.ITEM_NAME].value,
                        unit: cellRow[iIndexes.ITEM_UNIT].value,
                        requestedQuantity: parseInt(cellRow[iIndexes.ITEM_REQ_QTY].value),
                        receivedQuantity: parseInt(cellRow[iIndexes.ITEM_RECI_QTY].value),
                        reverseGrnQuantity: parseInt(cellRow[iIndexes.ITEM_REVERSE_GRN].value),
                        modeOfReturn: cellRow[iIndexes.ITEM_MODE_OF_RETURN].value,
                        reason: cellRow[iIndexes.ITEM_REASON].value
                    });
                }
                
            }
 

            return cellRow;
        });
        if (isRowHaveError) {
            // updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' } );
            setSpreadsheetErrors([ { message: 'Please fill all required details.' } ]);
        }

        setSheetData( [ ...saveData ]);

        return {
                sheetData: saveData, 
                validRows: postItems,
                isRowHaveError
            };

    };

    const onClickPopupListItem = (e, row) => {  
        setItemToSheet(row);       
    }

    const setItemToSheet = (item) => {

        if (Object.keys(item).length === 0) {
            return 0;
        }


        let jsonSpreadsheetData = JSON.stringify( sheetData );
        let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData);

        setSheetData(
            updatedSpreadsheetData.map( (sheetRow, i) => {
                if (i === activeCell.row) {
    
                    sheetRow[iIndexes.ITEM_MODE_OF_RETURN].value = item.modeOfReturn;
                }
                return sheetRow;
            }));
        setActiveCell({});
        hidePopupList();
    }


    const saveSheetData = (e) => {
        // updateButtonTxtStatus(true, e, 'Saving...', true);
        setSpreadsheetErrors([]);

        let inSheetData = Object.assign([], sheetData);
        const respArrayData = showValidationErrors(e, inSheetData);
        if(!checkSheetData()){
            setSpreadsheetErrors([ ...spreadsheetErrors, { message: 'Nothing to Save.' } ]);
            return false;
        }
        if (!spreadsheetErrors.length && respArrayData.validRows.length === 0 && checkSheetData()) {
            // show global alert message
            setSpreadsheetErrors([ ...spreadsheetErrors, { message: 'Please fix the errors and try again.' } ]);
            return false;
        }
        
        if ( !respArrayData.validRows.length ) {
            return false;
        }


        const data = {
            id: urlParams.grnId || '',
            warehouseId: urlParams.warehouseId ? parseInt(urlParams.warehouseId) : '',
            grnId: urlParams.grnId || '',
            items: respArrayData.validRows || []
        }

        APIEndpoint.post('reversegrn/finalize', data)
        .then( apiResp => {
            /**
             * Update success response
             */
            setSheetData(initialData);
            updateButtonTxtStatus(true, e, 'Redirecting...', true, { defaultButtonText: 'Save'} );
            setSpreadsheetErrors([ ...spreadsheetErrors, { message: apiResp.msg || 'Successfully created reverse GRN.' } ]);
            setTimeout( () => {
                goToLOIHome();
            }, 3000);
        })
        .catch( (error) => {
            const apiData = JSON.parse(error.message)
            const {data}=apiData;
            if ( data) {
                const errorMsg = data.errors.errors[0]?.message || data.errors.message;
                setSpreadsheetErrors([ ...spreadsheetErrors, { message: errorMsg, error: 1 } ]);
            }
            else if (data.msg) {
                setSpreadsheetErrors([ ...spreadsheetErrors, { message: data.msg, error: 1 } ]);
            }
        });

        return false;
    };

    
    const showItemListPopup = (eltPosition, activeCellIn) => {

        if (activeCellIn.column === iIndexes.ITEM_MODE_OF_RETURN) {
            if (targetEltCodes) {
                targetEltCodes.style.display = 'block';
                targetEltCodes.style.top = ( eltPosition.height + eltPosition.top + 2 ).toFixed() + 'px';
                targetEltCodes.style.left = eltPosition.left.toFixed() + 'px';
            }
        } 
    }

    const hidePopupList = () =>{
    
            if (targetEltCodes) {
                targetEltCodes.style.display = 'none';
            }
    }


    const handleActiveCellChange = (activeCell) => {
        setActiveCell(activeCell);
        hidePopupList();
        if ( sheetData[activeCell.row][activeCell.column] ) {
            const cellDetailsObj = sheetData[activeCell.row][activeCell.column];
            if ( cellDetailsObj.hasOwnProperty('showPopup') && cellDetailsObj.showPopup === true ) {

                const sourceElt = document.getElementsByClassName('cellClass_' + activeCell.row + '_' + activeCell.column)[0];
                if ( sourceElt ) {
                    const eltPosition = sourceElt.getBoundingClientRect();


                    showItemListPopup(eltPosition, activeCell);
                }

            }

        }
    }

    const handleCellBlur = () => {
   
    }

    const handleCellKeyDown = (keyboardEvent) => {
        let jsonSpreadsheetData = JSON.stringify(sheetData);
        let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData);
        if (activeCell && Object.keys(activeCell).length > 0) {
            if (updatedSpreadsheetData[activeCell.row][activeCell.column].type === 'number' &&  keyboardEvent.target.value ) {
                keyboardEvent.target.value = keyboardEvent.target.value.replace(/[^0-9]$/g, '');
            }
        }
        
        const updatedSheetData = updatedSpreadsheetData.map((row, rowIndex) => {
            return row.map((cell, cellIndex) => {
               
                if (rowIndex === activeCell.row) {

                        if (cell.id === 'reverseGRN') {
                            return {
                                ...cell,
                                className: `cellClass1`,
                            };
                        }                 
                }
        
                return { ...cell };
            });
        });
        
        setSheetData(updatedSheetData);
  
        return false;
    }

    function onChangeSheet(data){
            setSheetData(data);
            let jsonSpreadsheetData = JSON.stringify(data);
            let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData);
            let receiQtyValue,reverseGRNValue,reverseGRNParsed,receiQtyParsed;
    

            const updatedSheetData = updatedSpreadsheetData.map((row, rowIndex) => {
                return row.map((cell, cellIndex) => {
                    if (rowIndex === activeCell.row) {
                        if(cell.id === 'reverseGRN'){
                        reverseGRNValue=cell.value;
                         reverseGRNParsed = reverseGRNValue === '0' || reverseGRNValue === 0 ? 0 : (reverseGRNValue ? parseInt(reverseGRNValue, 10) : NaN);
                        }
                        if( cell.id === 'receivedQuantity' ){
                            receiQtyValue=cell.value;
                            receiQtyParsed = typeof receiQtyValue ==='number' ? parseInt(receiQtyValue, 10) : NaN;

                        }
                        if (!isNaN(reverseGRNParsed) && !isNaN(receiQtyParsed) && (reverseGRNParsed > receiQtyParsed)) {
                            if (cell.id === 'reverseGRN') {
                                return {
                                    ...cell,
                                    className: `errorCellData cellClass cellClass1`,
                                };
                            }
                        }
                        if (!isNaN(reverseGRNParsed) && !isNaN(receiQtyParsed) && (reverseGRNParsed <= receiQtyParsed)) {
                            if (cell.id === 'reverseGRN') {
                                return {
                                    ...cell,
                                    className: `cellClass1`,
                                };
                            }
                        }
                    }
            
                    return { ...cell };
                });
            });
            
            setSheetData(updatedSheetData);
            
            

    }


    
    const handleModeChange = (mode) => {
 
    };

    const onCellCommitHandle = (prevCell, nextCell, coordinates) => {
    setCellChange(nextCell);
    const errLabel=document.querySelectorAll('.sheetErrDiv');
    if(errLabel.length>0){
    errLabel.forEach((el)=>el.remove());
    }
        console.log('OnCellChangesCommit-prevCell:', prevCell, 'nextCell:', nextCell, 'coordinates:', coordinates)
    }
    const goToLOIHome = () => {
        navigate('/procurement/purchase-orders');
    };
    const handleOnContinue = async () => {
        goToLOIHome();
    }

    return (
        <>
            <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
                    <div className="header m-2 mb-2 flexRow justifyContentSpaceBetween" >

                        <div className="leftBlock alignItemsCenter flexRow">
                            <button onClick={ (e) => saveSheetData(e) }
                                className="alignItemsCenter add-item-button"><FaSave style={{marginRight:'5px'}}/>{ t('buttons.save') }</button> &nbsp;
                           {checkSheetData()?<><button onClick={ (e) => selectAllCells(e) }
                                className="alignItemsCenter flexRow add-item-button-all m-3">{count%2===0?<BiSolidSelectMultiple  style={{marginRight:'5px'}} />:<FaRegCheckSquare  style={{marginRight:'5px'}} />}Select All</button>   <button onClick={ (e) => removeRow(e) }
                                className="alignItemsCenter flexRow remove-button"><RiDeleteBin6Line style={{marginRight:'5px'}} />{ t('buttons.deleteRows') }</button></>:<></> }&nbsp;
                        </div>


                        {spreadsheetErrors.length >= 1? <div className={`padding4 margTop9-5 alert alert-${spreadsheetErrors[0].message.includes('Successfully') ? 'success' : 'danger'}`}>
                        {spreadsheetErrors.length >= 1 ? spreadsheetErrors[0]?.message : null}
                        </div>:null}
                        <button onClick={goToLOIHome}  >
                            <img src={closeIcon} alt=""/>
                        </button>
                    </div>
                   <div className="se-titlebar">{<span>{`${t('pages.procurementSub.purchaseOrdersSub.reverseGRN').slice(0, t('pages.procurementSub.purchaseOrdersSub.reverseGRN').lastIndexOf(" "))} ${rGrncreate?.code}`}</span>}</div>

                <div className="sheet-container sm-scrollbar reversrGrnCreate">
                    {/* Entered data: { activeCell && Object.keys(activeCell).length > 0 ? 
                        sheetData[activeCell.row][activeCell.column].type : null }

                        { JSON.stringify(selectedCellsArr) } */}
                    <Spreadsheet
                        // ref={sheetRef}
                        data={sheetData}
                        columnLabels={sheetLabels}
                        darkMode={true}

                        // getBindingsForCell={[]}
                        onChange={onChangeSheet}
                        onActivate={handleActiveCellChange}
                        onBlur={handleCellBlur}
                        onKeyDown={handleCellKeyDown}
                        onModeChange={handleModeChange}
                        onSelect={setSelectedCellsArr}
                        onCellCommit={onCellCommitHandle}
                        // Cell={LOICELLComponent}
                        // DataViewer={LOIViewComponent}

                    />

</div>
<div className="flexRow alignItemsCenter margTop8">
                        <button onClick={ () => addRowsToSheet() }
                            className="add-item-button" title={ t('spreadsheet.addmoreRows') }>{ t('buttons.addRows') }</button>
                    </div>
                    <div ref={modRetRef} className = "cellSelectionBlockItemCodes sm-scrollbar">
                       <ul>
                        {
                        returnMode.map((d,i)=>{
                                return <li key={i} onClick={(e) => onClickPopupListItem(e, d) }>{d.modeOfReturn}</li>
                            })
                        }
                       </ul>
                    </div>


                {
                    Boolean(state.isInvalidAccess) && (
                    <AlertModal
                        title={'Alert: Invalid Access'}
                        customModalStyle={ modelStyle }
                        description={ state.message }
                        onContinue={handleOnContinue}
                        onClose={handleOnContinue}
                    />
                    )
                }

            </div>
        </>
    );
}



