/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from "i18next";
import {HOST_SERVER} from '../utils/configs';
import { capitalizeFirstLetter } from '../utils/commonFunctions';
import { languages } from '../utils/languages';
import { APIEndpoint } from '../utils/axios';
import { themesList, setTheme, DEFAULT_THEME_INDEX } from '../utils/themes';
import UserSession from '../utils/userSessionClass';
import { getCurrentMenuItem } from "./SidebarComponent";
/**
 * Icons
 */
import akasalogo from '../assets/akasalogo.svg'
import enlang from '../assets/enlang.svg';
import frnlang from '../assets/france.svg';
import spainlang from '../assets/spainlang.svg';
import uaelang from '../assets/uaelang.svg';
import locationSvg from '../assets/icons/svg/location-icon.svg';
import themeSvg from '../assets/icons/svg/theme-icon.svg';
import signOutSvg from '../assets/icons/svg/signout-icon.svg';


export const TopHeaderComponent = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const userSession = new UserSession();
  const sessionVals = userSession.getSessionValues();
  const currentLanguageCode = i18next.language || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const [themeClassObj, setThemeClassObj] = React.useState(themesList[DEFAULT_THEME_INDEX]);
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [logoSite, setLogoSite] = React.useState("");
  const [loginMode, setLoginMode] = React.useState("");
  const logoImg=`${HOST_SERVER}/assets/images/public/company-logo.png`;

  let heading = location.state;
  if (!heading) {
    heading = getCurrentMenuItem(location);
  }
  React.useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr'
  }, [currentLanguage, t]);

  const setUserTheme = (theme = themesList[0]) => {
    setThemeClassObj(theme);
    setTheme(theme);
  };

  React.useEffect(() => {
    let theme = themeClassObj;
    if (localStorage.getItem('theme') && typeof localStorage.getItem('theme') === 'object') {
      theme = JSON.parse(localStorage.getItem('theme'));
    }
    if(localStorage.getItem('sType')){
      setLoginMode(localStorage.getItem('sType'));
    }
    setUserTheme(theme);
    checkLogoUrl()
  }, []);

  React.useEffect(() => {
    /**
     * Set title
     */
    if (heading && heading?.title) {
      document.title = t(`company.productName`) + ' ' + t(`pages.${heading.title.key}`);

      if (heading.subTitle) {
        document.title += ' - ' + t(`pages.${heading.title.key}Sub.${heading.subTitle.key}`);

        if (heading.subMenuItem2) {
          document.title += ' - ' + t(`pages.${heading.title.key}Sub.${heading.subTitle.key}Sub.${heading.subMenuItem2.key}`);
        }
      }

    }
    else {
      document.title = t(`company.productName`);
    }

  }, [t, heading]);

  React.useEffect(() => {
    /**
     * Auto hide dropdown menu if open after some time
     */
    if (showDropdown) {
      setTimeout(() => {
        setShowDropdown(false);
      }, 10000);
    }
  }, [showDropdown]);

const checkLogoUrl=async()=>{
    try {
      const imgUrl = await APIEndpoint.get(logoImg);
    if(imgUrl && !logoSite?.length){
      setLogoSite(logoImg)
    }
    } catch (error) {
     

    }
}
  const getMonthLangFormat=(sessionVals)=>{
    const {login_date}=sessionVals;
    let month = "",
     num = "",
     loginDate=""
    for (let i=0; i<login_date.length; i++)
    {
        if (!isNaN(String(login_date[i]) * 1))
            num+=login_date[i];
        if((login_date[i] >= 'A' && login_date[i] <= 'Z') ||
         (login_date[i] >= 'a' && login_date[i] <= 'z'))
            month+=login_date[i];
    }

    switch (month) {
      case 'January':
        loginDate= `${num.substring(0, 2)}  ${t('months.jan')}  ${num.substring(num.length - 4)}`
        break;
        case 'February':
       loginDate= `${num.substring(0, 2)}  ${t('months.feb')}  ${num.substring(num.length - 4)}`
        break;
        case 'March':
        loginDate= `${num.substring(0, 2)}  ${t('months.mar')}  ${num.substring(num.length - 4)}`
         break;
         case 'April':
         loginDate= `${num.substring(0, 2)}  ${t('months.apr')}  ${num.substring(num.length - 4)}`
          break;
          case 'May':
          loginDate= `${num.substring(0, 2)}  ${t('months.may')}  ${num.substring(num.length - 4)}`
           break;
           case 'June':
           loginDate= `${num.substring(0, 2)}  ${t('months.june')}  ${num.substring(num.length - 4)}`
            break;
            case 'July':
            loginDate= `${num.substring(0, 2)}  ${t('months.july')}  ${num.substring(num.length - 4)}`
             break;
             case 'August':
             loginDate= `${num.substring(0, 2)}  ${t('months.aug')}  ${num.substring(num.length - 4)}`
              break;
              case 'September':
              loginDate= `${num.substring(0, 2)}  ${t('months.sep')}  ${num.substring(num.length - 4)}`
               break;
               case 'October':
               loginDate= `${num.substring(0, 2)}  ${t('months.oct')}  ${num.substring(num.length - 4)}`
                break;
                case 'November':
                loginDate= `${num.substring(0, 2)}  ${t('months.nov')}  ${num.substring(num.length - 4)}`
                 break;
                 case 'December':
                 loginDate= `${num.substring(0, 2)}  ${t('months.dec')}  ${num.substring(num.length - 4)}`
                  break;
      default:
        break;
    }
    return loginDate;
  }

  React.useEffect(() => {
    if (!sessionVals.token) {
      navigate('/login');
      return <></>;
    }

    if (sessionVals.login_date) {
      getMonthLangFormat(sessionVals)
    }
  }, [sessionVals,t]);


  const toggleShowDropdown = () => {
    setShowDropdown(!showDropdown);
  }

  const signOutHandler = () => {
    // Clear session data
    localStorage.removeItem('sType');
    localStorage.clear();
    userSession.destroySession();
    navigate('/login');
  };

  const getCountryFlags=(langCode)=>{

    let langImage;

    switch (langCode) {
      case 'sp':
        langImage = <img className="icon-lang" alt='spanish' src={spainlang} />;
        break;
      case 'en':
        langImage = <img className="icon-lang" alt='english' src={enlang} />;
        break;
      case 'fr':
        langImage = <img className="icon-lang" alt='french' src={frnlang} />;
        break;
      case 'ar':
        langImage = <img className="icon-lang" alt='arabic' src={uaelang} />;
        break;
      default:

        langImage = <img className="icon-lang" alt='english' src={enlang} />;
        break;
    }

    return langImage;

  }

  return (
    <>
      <div className='top-header-container width100 flexRow justifyContentFlexStart alignItemsCenter'>
        <div className='flexRow logo-block alignItemsCenter'>
          <button className='btn-close' onClick={props.onMenu}>
            <i className='icon-menu' />
          </button>
          {loginMode && <img src={logoSite} alt="logo" onClick={() => navigate('/dashboard')} className='logo' /> }
        </div>
        <div className='flexRow full-flex alignItemsCenter breadcrumb-row'>
          {/* {
            Boolean(heading) &&
            <div className="flexRow alignItemsCenter breadcrumb-section">
              {t(`pages.${heading.title.key}`)}

               { heading.subTitle ?
                <>
                  &nbsp;<img src={RightAngleVector} alt=">" className='navicon' />
                  &nbsp;{ t(`pages.${heading.title.key}Sub.${heading.subTitle.key}`) }

                  {heading.subMenuItem2 ? <>
                    &nbsp;<img src={RightAngleVector} alt=">" className='navicon' />
                    &nbsp;{ t(`pages.${heading.title.key}Sub.${heading.subTitle.key}Sub.${heading.subMenuItem2.key}`) }
                  </>: null}

                </>
                :
                ''
              }

            </div>
          } */}
          <div className="top-header-menu ">
            <div className='menu-container'onClick={() => toggleShowDropdown()} >
              <div className="dropdown-pic-block" >{sessionVals?.username?.slice(0,2).toUpperCase()}</div>
              <div className={`dropdown-content-block${currentLanguageCode==='ar' ? '-arabic' : ""}`} style={{ display: showDropdown ? 'block' : 'none' }} onMouseLeave={()=>toggleShowDropdown()}>
                <div className="menu-item">
                  <span className='text-link'><img src={locationSvg} alt="" className='menuitemicon' /> {t('topHeader.location')}</span>
                </div>
                <div className="menu-item">
                  <img src={themeSvg} alt="" className='menuitemicon' /> <button
                    className="btn btn-link dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {t(`themeNames.${themeClassObj?.title}`)}
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                    {themesList.map((row, index) => (
                      <li key={index} onClick={() => {
                        setUserTheme(row)
                      }}>
                        <h6 className="dropdown-item">
                          {capitalizeFirstLetter( t(`themeNames.${row.title}`) )}
                        </h6>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="menu-item">
                  <button
                    className="btn btn-link dropdown-toggle unset-width"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {getCountryFlags(currentLanguage.country_code)} &nbsp;
                    {t(`languageNames.${currentLanguage.country_code}`)}
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    {languages.map(({ code, name, country_code }) =>
                    (
                      <li key={country_code}
                        onClick={() => {
                          i18next.changeLanguage(code)
                        }}>
                       <h6 className={currentLanguage.country_code!==code?"dropdown-item":"dropdown-item body-text-extrabold"} >
                           <span className='flexRow alignItemsCenter'>{getCountryFlags(code)}&nbsp;&nbsp;{t(`languageNames.${code}`)}</span>
                        </h6>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="menu-item" onClick={signOutHandler}>
                  <span className='text-link'><img src={signOutSvg} alt="" className='menuitemicon' /> {t('topHeader.signout')}</span>
                </div>
                <div className="menu-text">
                  <span>{sessionVals.login_time ? getMonthLangFormat(sessionVals) : ''}<br />
                  {t('topHeader.loginAtText')} {sessionVals.login_time}</span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>);
}