import React, {useState } from 'react';
import { FilterBodyTemplate } from "../../../utils/configs";
import { APIEndpoint ,DFPAPIEndpointGalley} from "../../../utils/axios";
import { DataGrid,EditModal, UploadModal } from "../../../common";
import {onDownloadXlsFile} from '../../../utils/commonFunctions';
import { useTranslation } from 'react-i18next';
import Edit2 from "../../../assets/edit2.svg";
import viewIcon from "../../../assets/view.svg";
// import { DEFAULT_SHEET_ROWS } from "../../../utils/configs";
import uploadItemIcon from '../../../assets/icons/upload-items.svg';
import addIcon from '../../../assets/icons/addIcon.svg';
import { useNavigate } from "react-router-dom";



export const CorporateSegmentsPage = (props) => {
  const { t,ready } = useTranslation();
  const [state, setState] = useState({});
  const navigate = useNavigate();
  const [ uploadFileStatusBlock, setUploadFileStatusBlock ] = useState('');
  const [, setModalFormStatusObj] = useState({ text: '' });
  const [ uploadedFile, setUploadedFile ] = useState([])

  const Columns = [
    { field: 'segment', headerName: 'Segment', suppressSizeToFit:true,flex:1, resizable:true,filter: true },
    { field: 'segmentCode', headerName: 'Timing', suppressSizeToFit:true, flex:1,resizable:true, filter: true },
    {
      headerName:'Actions',
      pinned:'right',
       autoResize:true,
        width:280,
        minWidth:230,
        resizable: false,
        suppressSizeToFit:true,
        cellRenderer: actionsCorporateSegment,
        valueGetter: function(params) {
          return params.sectors;
        }
      }
  ]
  const EditFormFields = [
    {label: 'Csv', attribute: 'segmentItems', type: 'csv5', disabled: false, options: [], value: "" },
  ];

  function actionsCorporateSegment(props){
    return (
      props.data?
      <>
      <div className='flexRow margTop8'>
         <div className='icon-aircraft-container'>
        <img className='aircraft-actions' alt='upload' onClick={(e) => setSector(props.data, 'uploadItems')} src={uploadItemIcon}/>
        <span className="tooltip-action">Upload</span>
        </div>
       {props.data&&props.data?.segmentItems.length? <><div className='icon-aircraft-container'>
        <img className='aircraft-actions' alt='edit' onClick={(e) => setSector(props.data, 'edit')} src={Edit2}/>
        <span className="tooltip-action">Edit Items</span>
        </div>
        <div className='icon-aircraft-container'>
        <img className='aircraft-actions' alt='view' onClick={(e) => setSector(props.data, 'view')} src={viewIcon}/>
        <span className="tooltip-action">View</span>
        </div></>:<>
        <div className='icon-aircraft-container'>
        <img className='aircraft-actions' alt='add' onClick={(e) => setSector(props.data, 'add')} src={addIcon}/>
        <span className="tooltip-action">Add Items</span>
        </div>
        </>}
      </div>
      </> : <></>);
  }


  const setSector = (data,type) => {

    if(type==='view'){
      setState( (_prev) => {
        return { ..._prev, selected: {...data ,action: type},resetDataSource:false}
      });
    }
    else if(type==='add'){
        navigate(`/estimation/corporate-segments/segment/items/${data?.segment}`);

    }
    else if(type==='edit'){
      navigate(`/estimation/corporate-segments/segment/items/${data?.segment}`);

  }
   else if(type==='uploadItems'){
    setState((_) => {
      return { ..._, resetDataSource: false,selected:{...data,action:'uploadItems'},delete:true ,deleteProg:false,edit:false};
    })
   }
   else{
    setState( (_prev) => {
      return { ..._prev, selected: {...data ,action: type},resetDataSource:false}
    });
   }
  };


  const onClose = () => {
    setState((_prev) => {
      return { ..._prev, selected: false }
    });
  }

  const onDownloadSampleFile=()=>{
    onDownloadXlsFile('samples/downloads/Corporate_Items_Upload_Sample','Corporate_Items_Upload_Sample')
}

  const onUploadClose=()=>{
    if(state.selected.action==='configureGalley'){
      setState((_prev) => {
          return { ..._prev, selected: false,edit:false,resetDataSource: true  }
      });
    }
    else if(state.selected.action==='uploadLayout'&&uploadFileStatusBlock.length>0){
      setState((_prev) => {
        return { ..._prev, selected: false,edit:false,resetDataSource: true  }
    });
    }else{
      setState((_prev) => {
        return { ..._prev, selected: false,edit:false,resetDataSource: false  }
    });
    }
  setUploadFileStatusBlock('');
  setModalFormStatusObj({text:''});
  }
  const onUploadFileChangeHandler = async (e) => {
    const file=e.target.files[0];
    setUploadedFile(e.target.files[0]);
    setUploadFileStatusBlock('<p>Please wait while we process your data...</p>');

    const formData = new FormData();
    formData.append('scheduleLater', false);
    formData.append('listName', undefined);
    formData.append('targetSectorType', undefined);
    formData.append('stores', []);
    formData.append('warehouses', []);
    formData.append('file', file);
    try{
    await DFPAPIEndpointGalley.post(`estimation/corporatesegment/${state.selected.segment}/uploaditems`, formData);
    setUploadedFile([]);
    setUploadFileStatusBlock('<p>File Imported Successfully.</p>');
    }catch(err){
      e.target.value = null;
      setUploadedFile([]);
      setUploadFileStatusBlock('<p>No Records Found</p>');
    }
};


  const fetchCorporateSegments = async (iPageNo = 1, iPageLimit = 10, iSearchText = '') => {
    const filterData = JSON.parse(JSON.stringify(FilterBodyTemplate));
    filterData.page = iPageNo;
    filterData.limit = iPageLimit;
    filterData.searchParam = iSearchText;
    const res = await APIEndpoint.post('estimation/corporatesegment/process', filterData);
    if (res.items) {
      res.items = res.items.map((_) => {
        return {
          ..._,
          percentageText: `${_.percentage}%`
        }
      })
    }
    return res;
  }
  return (
    <div className="device-page op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      <DataGrid
        columnResize
        showDefaultFilters
        enableGridSearch={false}
        className='full-flex'
        columns={Columns}
        getRows={fetchCorporateSegments}
        resetDataSource={state.resetDataSource}
      />
       {state && Boolean(state.selected) &&state.selected?.action === "view"&& (
          <EditModal
            key={state.selected?.action}
            title={ `${state.selected?.segment}-Items`}
            onClose={onClose}
            fields={ EditFormFields.map( (elt) => {
                  return {...elt, initialValue: state.selected[elt.attribute]}
                })
            }
          />
        )
     }
{
        state && Boolean(state.selected)&&state.selected.action==='uploadItems'&&
        (<UploadModal
            modelTitle={`Upload-${state.selected.segment} Item`}
            onUploadClose={onUploadClose}
            onDownloadSampleFile={onDownloadSampleFile}
            uploadedFile = { uploadedFile }
            onUploadFileChangeHandler = { (e) => onUploadFileChangeHandler(e) }
            uploadFileStatusBlock = { uploadFileStatusBlock }
        />)
      }
      {
      !ready &&<div className='overlay_hide sub'></div>
      }
    </div>
  )
}
